<div class="row">
    <div class="col-md-12">
      <app-paziente-card [id]="patientId" (modifica)="showPanelDettagli()"></app-paziente-card>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <app-condizioni-cliniche [id]="patientId"></app-condizioni-cliniche>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <app-stato-di-salute [id]="patientId"></app-stato-di-salute>
    </div>
  </div>