<div class="row">
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-12">
                <label>Seleziona ICD da aggiungere:</label>
            </div>
        </div>
        <div class="row p-fluid">
            <div class="mt-2 col-lg-8 col-md-6 col-sm-12">
                <p-autoComplete [(ngModel)]="selected" [suggestions]="listIcd" (completeMethod)="search($event)"
                    field="label" placeholder="Cerca ICD" [minLength]="1" [size]="10"></p-autoComplete>
            </div>
            <div class="mt-2 col-lg-2 col-md-3 col-sm-12" style="text-align: center;">
                <button type="button" (click)="show()" pButton icon="far fa-list-alt"
                    class="p-button-secondary" label="Albero ICD"></button>
            </div>
            <div class="mt-2 col-lg-2 col-md-3 col-sm-12">
                <button type="button" (click)="aggiungi()" pButton icon="fas fa-plus" label="Aggiungi"
                    class="p-button-raised"></button>
            </div>
        </div>
    </div>
</div>