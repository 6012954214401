<ng-container *ngIf="crea === true && patient.id!==undefined" style="margin: 0cm">

  <!-- Prima parte. Ci sono più layout -->
  <ng-container>
    <ng-container *ngTemplateOutlet="primaPagina"></ng-container>
    <ng-container *ngIf="layout===1 && (avvisi.length > 0 || avvisiVerificati.length > 0)">
      <!-- Pagina solo per gli avvisi (se ci sono)-->
      <ng-container>
        <div class="row col-md-12 pagina">
          <ng-container *ngTemplateOutlet="intestazione"></ng-container>
          <div class="col-md-12 row afterIntestazione">
            <div class="col-md-12" id="listaNonVerificati">
              <ng-container
                *ngTemplateOutlet="listaEventi;context:{$implicit: {lista:avvisi, titolo:'Ultimi eventi non verificati rispetto al periodo selezionato'}}">
              </ng-container>
            </div>
            <div class="col-md-12" id="listaVerificati">
              <ng-container
                *ngTemplateOutlet="listaEventi;context:{$implicit: {lista:avvisiVerificati, titolo:'Ultimi eventi verificati rispetto al periodo selezionato'}}">
              </ng-container>
            </div>
          </div>
          <ng-container *ngTemplateOutlet="pieDiPagina"></ng-container>
        </div>
      </ng-container>
      <div class="break"></div>
    </ng-container>
  </ng-container>
  <!-- /Prima parte -->

  <!-- Sintomi -->
  <ng-container>
    <div class="col-md-12 row pagina">
      <ng-container *ngTemplateOutlet="intestazione"></ng-container>
      <div class="col-md-12 row afterIntestazione">
        <div class="col-md-12">
          <div class="card mt-3">
            <div class="card-header bg-primary">
              <h4>Dolore, assorbenti cambiati al giorno e PSA totale (fonte Mobile App)</h4>
            </div>
            <div class="card-body">
              <!-- Fonte Mobile App -->
              <app-monitoraggio-header *ngIf="patient.id !== undefined" [isReport]="true" [identifier]="identifier"
                [date]="date" altezzaGrafico="330" larghezzaGrafico="1020"></app-monitoraggio-header>
              <app-grafici-domande [domande]="domandaPSA" *ngIf="patient.id !== undefined" [identifier]="identifier"
                [date]="date" altezzaGrafico="330" larghezzaGrafico="1020" [showHeader]="false"></app-grafici-domande>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="pieDiPagina"></ng-container>
    </div>
  </ng-container>
  <!-- /Sintomi -->

  <div class="break"></div>

  <!-- Pagina 1 smartwatch -->
  <ng-container>
    <div class="col-md-12 row pagina">
      <ng-container *ngTemplateOutlet="intestazione"></ng-container>
      <div class="col-md-12 row afterIntestazione">
        <div class="col-md-12">
          <div class="card mt-3">
            <div class="card-header bg-primary">
              <h4>Parametri vitali: (fonte Smartwatch)</h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-10">
                  <app-grafici-werable altezzaGrafico="270" larghezzaGrafico="850" [showHeader]="false"
                    tipoGrafico="Frequenza cardiaca" [identifier]="identifier" [source]="'wearable'" [date]="date"
                    (stats)="getMeasure('HR',$event)">
                  </app-grafici-werable>
                </div>
                <div class="col-md-2" style="padding-left: 65px;margin-top: 110px">
                  <p>FC min: {{statsHR.min}} </p>
                  <p>FC avg: {{statsHR.avg}}</p>
                  <p>FC max: {{statsHR.max}}</p>
                </div>
                <div class="col-md-10">
                  <app-grafici-werable altezzaGrafico="270" larghezzaGrafico="850" [showHeader]="false"
                    tipoGrafico="Pressione sanguigna" [identifier]="identifier" [source]="'wearable'" [date]="date"
                    (stats)="getMeasure('SYS',$event)">
                  </app-grafici-werable>
                </div>
                <div class="col-md-2" style="padding-left: 65px;margin-top: 40px">
                  <p>Sis. min: {{statsSys.min}} </p>
                  <p>Sis. avg: {{statsSys.avg}}</p>
                  <p>Sis. max: {{statsSys.max}}</p>
                  <p>Dia. min: {{statsDis.min}} </p>
                  <p>Dia. avg: {{statsDis.avg}}</p>
                  <p>Dia. max: {{statsDis.max}}</p>
                </div>
                <div class="col-md-10">
                  <app-grafici-werable altezzaGrafico="270" larghezzaGrafico="850" [showHeader]="false"
                    tipoGrafico="Ossigenazione" [identifier]="identifier" [source]="'wearable'" [date]="date"
                    (stats)="getMeasure('SPO',$event)">
                  </app-grafici-werable>
                </div>
                <div class="col-md-2" style="padding-left: 65px;margin-top: 110px">
                  <p>Spo2 min: {{statsSpo.min}} </p>
                  <p>Spo2 avg: {{statsSpo.avg}}</p>
                  <p>Spo2 max: {{statsSpo.max}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="pieDiPagina"></ng-container>
    </div>
  </ng-container>
  <!-- /Pagina 1 smartwatch -->

  <!-- Pagina 2 smartwatch -->
  <ng-container>
    <div class="col-md-12 row pagina">
      <ng-container *ngTemplateOutlet="intestazione"></ng-container>
      <div class="col-md-12 row afterIntestazione">
        <div class="col-md-12">
          <div class="card mt-3">
            <div class="card-header bg-primary">
              <h4>Parametri vitali: (fonte Smartwatch)</h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-10">
                  <app-grafici-werable altezzaGrafico="270" larghezzaGrafico="850" [showHeader]="false"
                    tipoGrafico="Frequenza respiratoria" [identifier]="identifier" [source]="'wearable'" [date]="date"
                    (stats)="getMeasure('BR',$event)">
                  </app-grafici-werable>
                </div>
                <div class="col-md-2" style="padding-left: 65px;margin-top: 110px">
                  <p>FR min: {{statsBR.min}} </p>
                  <p>FR avg: {{statsBR.avg}}</p>
                  <p>FR max: {{statsBR.max}}</p>
                </div>
                <div class="col-md-12">
                  <app-grafici-werable altezzaGrafico="270" larghezzaGrafico="900" [showHeader]="false"
                    tipoGrafico="Passi" [identifier]="identifier" [source]="'wearable'" [date]="date">
                  </app-grafici-werable>
                </div>
                <div class="col-md-12">
                  <app-grafici-werable altezzaGrafico="270" larghezzaGrafico="900" [showHeader]="false"
                    tipoGrafico="Sonno" [identifier]="identifier" [source]="'wearable'" [date]="date">
                  </app-grafici-werable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="pieDiPagina"></ng-container>
    </div>
  </ng-container>
  <!-- /Pagina 2 smartwatch -->

  <div class="break">{{gestioneLayout()}}</div>

  <!-- Farmaci -->
  <ng-container *ngIf="listaFarmaci && listaFarmaci.length > 0">
    <div class="col-md-12 row pagina">
      <ng-container *ngTemplateOutlet="intestazione"></ng-container>
      <div class="col-md-12 row afterIntestazione">
        <div class="col-md-12">
          <div class="card mt-3">
            <div class="card-header bg-primary">
              <h4>Farmaci</h4>
            </div>
            <div class="card-body row">
              <!-- Fonte Mobile App -->
              <div class="col-md-12" *ngFor="let farmaco of nomiFarmaci">
                <app-grafici-farmaci [showHeader]="false" altezzaGrafico="300" larghezzaGrafico="1020"
                  [identifier]="identifier" [date]="date" tipoGrafico="{{farmaco}}"></app-grafici-farmaci>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="pieDiPagina"></ng-container>
    </div>
  </ng-container>
  <!-- /Farmaci -->

  <div class="break"></div>

  <!-- Monitoraggio ICF -->
  <ng-container>
    <div class="col-md-12 row pagina">
      <ng-container *ngTemplateOutlet="intestazione"></ng-container>
      <div class="col-md-12 row afterIntestazione">
        <div class="col-md-12">
          <div class="card mt-3">
            <div class="card-header bg-primary">
              <h4>Monitoraggio ICF</h4>
            </div>
            <div class="card-body">
              <!-- Fonte Mobile App -->
              <app-grafico-icf-header *ngIf="patient.id !== undefined" altezzaGrafico="500" larghezzaGrafico="1020"
                [patientId]="identifier" [date]="date" [isReport]="true">
              </app-grafico-icf-header>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="pieDiPagina"></ng-container>
    </div>
  </ng-container>
  <!-- /Monitoraggio ICF -->
</ng-container>

<!-- Template utilizzati -->
<ng-template #generale>
  <div class="col-md-6">
    <div class="card">
      <div class="card-header bg-primary">
        <h4>Anagrafica del paziente</h4>
      </div>
      <div class="card-body row">
        <div class="col-md-12 row">
          <div class="col-md-6 text-left">
            <h5 class="display-inline">Paziente: </h5>
            <h6 class="display-inline">{{patient.nominativo || ""}}</h6>
          </div>
          <div class="col-md-6 text-left">
            <h5 class="display-inline">Data di nascita: </h5>
            <h6 class="display-inline">{{patient.birthDate || ""}}</h6>
          </div>
          <div class="col-md-6 text-left">
            <h5 class="display-inline">Genere: </h5>
            <h6 class="display-inline">{{patient.gender || ""}}</h6>
          </div>
          <div class="col-md-6 text-left">
            <h5 class="display-inline">CF: </h5>
            <h6 class="display-inline">{{patient.cf || ""}}</h6>
          </div>
          <div class="col-md-6 text-left">
            <h5 class="display-inline">Cellulare: </h5>
            <h6 class="display-inline">{{patient.cell || ""}}</h6>
          </div>
          <div class="col-md-6 text-left">
            <h5 class="display-inline">Email: </h5>
            <h6 class="display-inline">{{patient.email || ""}}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card">
      <div class="card-header bg-primary">
        <h4>Periodo di osservazione</h4>
      </div>
      <div class="card-body row">
        <div class="col-md-12 row">
          <div class="col-md-6 row">
            <div class="col-md-3" style="text-align:right">
              <h5>Da:</h5>
            </div>
            <div class="col-md-9" style="text-align:left">
              <h5>{{inizioOsservazione || ""}}</h5>
            </div>
            <div class="col-md-3" style="text-align:right">
              <h5>A:</h5>
            </div>
            <div class="col-md-9" style="text-align:left">
              <h5>{{fineOsservazione || ""}}</h5>
            </div>
          </div>
          <div class="col-md-6" style="text-align:center">
            <h5>Data di inizio monitoraggio:</h5>
            <h5>{{dataPresaInCarico || ""}}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #intestazione>
  <div class="col-md-12 row text-center intestazione">
    <div class="col-md-4">
      <!-- <img src="assets/img/provaLogo.png" alt="Errore: Logo Nemo" class="imgIntestazione"> -->
      <!-- Logo Nemo -->
    </div>
    <div class="col-md-4">
      <h1 *ngIf="patient.identificativo === undefined">Report</h1>
      <h1 *ngIf="patient.identificativo !== undefined && patient.identificativo.length < 8">Report
        {{patient.identificativo}}</h1>
      <h3 *ngIf="patient.identificativo !== undefined && patient.identificativo.length > 7" style="padding-top:5mm">
        Report {{patient.identificativo}}</h3>
    </div>
    <div class="col-md-4">
      <!-- <img src="assets/img/Logo-Login1.png" alt="Errore: Logo eda"> -->
      <!-- Logo eda -->
    </div>
  </div>
  <div class="col-md-12 row generale">
    <ng-container *ngTemplateOutlet="generale"></ng-container>
  </div>
</ng-template>

<ng-template #pieDiPagina>
  <div class="col-md-12 piePagina row">
    <div class="col-md-4 text-left"></div>
    <div class="col-md-4 text-center">
      <h5>
        <!-- Indirizzo cliente -->
      </h5>
    </div>
    <div class="col-md-4 text-right">
      <h5 class="numeroPagina">{{setNumeroPagina()}}</h5>
    </div>
  </div>
</ng-template>

<ng-template #listaEventi let-parametri>
  <div class="card mt-3" *ngIf="parametri.lista.length > 0">
    <div class="card-header bg-primary">
      <h4>{{parametri.titolo}}</h4>
    </div>
    <div class="card-body">
      <!-- Lista eventi non verificati -->
      <p-table [value]="parametri.lista">
        <ng-template pTemplate="header">
          <tr>
            <th>Data rilevazione</th>
            <th>Misura</th>
            <th>Valore</th>
            <th>Alert</th>
            <th>Livello</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-avviso>
          <tr>
            <td>{{avviso.dataRilevazione}}</td>
            <td>{{avviso.misura}}</td>
            <td>{{avviso.valore}}</td>
            <td>{{avviso.messaggioAlert}}</td>
            <td>{{avviso.livello}}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

</ng-template>

<ng-template #templateAnamnesi>
  <div class="card mt-5" id="cardAnamnesi">
    <div class="card-header bg-primary">
      <h4>Anamnesi patologica</h4>
    </div>
    <div class="card-body row">
      <div class="col-md-12">
        <span id="anamnesiPatologicaProssima">
          <p>Nessuna anamnesi patologica prossima.</p>
        </span>
      </div>
      <div class="col-md-12">
        <span id="anamnesiPatologicaRemota">
          <p>Nessuna anamnesi patologica remota.</p>
        </span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #templateTerapie>
  <div class="card" id="cardTerapie">
    <div class="card-header bg-primary">
      <h4>Terapie</h4>
    </div>
    <div class="card-body row">
      <div class="col-md-12">
        <!-- Descrizione dei campi Terapie e Terapie domiciliari-->
        <span id="descrizioneTerapie">
          <p>Nessuna terapia.</p>
        </span>
      </div>
      <div class="col-md-12">
        <span id="descrizioneTerapieDomiciliari">
          <p>Nessuna terapia domiciliare.</p>
        </span>
      </div>
      <div class="col-md-12 row">
        <div class="col-md-12" *ngIf="listaFarmaci.length === 0">
          <p>Nessun farmaco.</p>
        </div>
        <ng-container *ngIf="listaFarmaci.length > 0">
          <b>Farmaci:</b>
          <div class="col-md-12" *ngFor="let farmaco of listaFarmaci">
            <p style="font-size: 11pt;">{{farmaco}}</p>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #templateCondizioniCliniche>
  <div class="card mt-5" id="cardCondizioniCliniche">
    <div class="card-header bg-primary">
      <h4>Condizioni cliniche</h4>
    </div>
    <div class="card-body row">
      <div class="col-md-12" *ngIf="condizioniCliniche.length === 0">
        <b>Nessuna patologia inserita.</b>
      </div>
      <div class="col-md-12" *ngFor="let icd of condizioniCliniche">
        <h6>{{icd}}</h6>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #templateStatoSalute>
  <div class="card" id="cardStatoSalute">
    <div class="card-header bg-primary">
      <h4>Stato di salute</h4>
    </div>
    <div class="card-body row">
      <div class="col-md-12" *ngFor="let icf of filterListICF">
        <h6>{{icf}}</h6>
      </div>
      <div class="col-md-12" *ngIf="punteggioNLAB > -1">
        <h6>Punteggio scala NLAB: {{punteggioNLAB}}</h6>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #primaPagina>
  <ng-container *ngIf="layoutPrimaPagina === 0">
    <!-- Entra tutto in una pagina -->
    <!-- Se ci sono solo una tipologia di avvisi, prova a metterli in questa pagina -->
    <div class="row col-md-12 pagina primaPagina">
      <ng-container *ngTemplateOutlet="intestazione"></ng-container>
      <div class="col-md-12 row afterIntestazione">
        <div class="col-md-5 row">
          <div class="col-md-12">
            <ng-container *ngTemplateOutlet="templateCondizioniCliniche"></ng-container>
            <ng-container *ngTemplateOutlet="templateStatoSalute"></ng-container>
          </div>
        </div>
        <div class="col-md-7 row" style="margin-left:0.5cm">
          <div class="col-md-12">
            <ng-container *ngTemplateOutlet="templateAnamnesi"></ng-container>
            <ng-container *ngTemplateOutlet="templateTerapie"></ng-container>
          </div>
        </div>
        <div class="col-md-12" *ngIf="valutazioneMonitoraggio != undefined">
          <div class="card">
            <div class="card-header bg-primary">Valutazione di fine monitoraggio</div>
            <div class="card-body row">
              <div class="col-md-12" style="margin-bottom:5px">
                <!-- Aggiungere ngModel -->
                <p-editor [(ngModel)]="valutazioneMonitoraggio" [style]="{'height':'300px'}">
                </p-editor>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12" id="pezzettoAvvisi" *ngIf="layout===0">
          <ng-container
            *ngTemplateOutlet="listaEventi;context:{$implicit: {lista:avvisiVerificati, titolo:'Ultimi eventi verificati rispetto al periodo selezionato'}}">
          </ng-container>
          <ng-container
            *ngTemplateOutlet="listaEventi;context:{$implicit: {lista:avvisi, titolo:'Ultimi eventi non verificati rispetto al periodo selezionato'}}">
          </ng-container>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="pieDiPagina"></ng-container>
    </div>
    <div class="break"></div>
  </ng-container>

  <ng-container *ngIf="layoutPrimaPagina === 1">
    <!-- Anamnesi e terapie in una pagina -->
    <!-- Condizioni cliniche e stato di salute in quella dopo -->
    <!-- Se ci sono solo una tipologia di avvisi, prova a metterli nella seconda pagina -->
    <div class="row col-md-12 pagina primaPagina">
      <ng-container *ngTemplateOutlet="intestazione"></ng-container>
      <div class="col-md-12 row afterIntestazione">
        <div class="col-md-12">
          <ng-container *ngTemplateOutlet="templateAnamnesi"></ng-container>
        </div>
        <div class="col-md-12">
          <ng-container *ngTemplateOutlet="templateTerapie"></ng-container>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="pieDiPagina"></ng-container>
    </div>
    <div class="break"></div>
    <div class="row col-md-12 pagina">
      <ng-container *ngTemplateOutlet="intestazione"></ng-container>
      <div class="col-md-12 row afterIntestazione">
        <div class="col-md-6">
          <ng-container *ngTemplateOutlet="templateCondizioniCliniche"></ng-container>
        </div>
        <div class="col-md-6">
          <ng-container *ngTemplateOutlet="templateStatoSalute"></ng-container>
        </div>
        <ng-container *ngTemplateOutlet="templateSoloUnTipoAvvisi"></ng-container>
      </div>
      <ng-container *ngTemplateOutlet="pieDiPagina"></ng-container>
    </div>
    <div class="break"></div>
  </ng-container>
</ng-template>

<ng-template #templateSoloUnTipoAvvisi>
  <div class="col-md-12" id="pezzettoAvvisi" *ngIf="layout===0">
    <ng-container
      *ngTemplateOutlet="listaEventi;context:{$implicit: {lista:avvisiVerificati, titolo:'Eventi verificati'}}">
    </ng-container>
    <ng-container *ngTemplateOutlet="listaEventi;context:{$implicit: {lista:avvisi, titolo:'Eventi non verificati'}}">
    </ng-container>
  </div>
</ng-template>