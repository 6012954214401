export const deambulazioneOpt = [
    { value: '', label: 'Seleziona una opzione', disabled: true },
    { value: 'LA12302-8', label: 'Autonomo' },
    { value: 'LA12303-6', label: 'Assistito'},
    { value: 'LA12304-4', label: 'Completamente assistito'},
];

export const autonomiaOpt = [
    { value: '', label: 'Seleziona una opzione', disabled: true },
    { value: 'si', label: 'Si' },
    { value: 'no', label: 'No'},
    { value: 'con supporto', label: 'Con Supporto'},
];

export const siNoOpt = [
    { value: '', label: 'Seleziona una opzione', disabled: true },
    { value: 'NO', label: 'No' },
    { value: 'LA19443-3', label: 'Stadio I' },
    { value: 'LA19444-1', label: 'Stadio II' },
    { value: 'LA19445-8', label: 'Stadio III' },
    { value: 'LA19446-6', label: 'Stadio IV' },
    { value: 'LA19447-4', label: 'Non classificabile' },
];

export const alvoOpt = [
    { value: '', label: 'Seleziona una opzione', disabled: true },
    { value: 'regolare', label: 'Regolare' },
    { value: 'stitico', label: 'Stitico' },
    { value: 'diarroico', label: 'Diarroico' },
    { value: 'alterno', label: 'Alterno' },
];

export const diuresiMinzioneOpt = [
    { value: '', label: 'Seleziona una opzione', disabled: true },
    { value: 'LA25320-5', label: 'Normali' },
    { value: 'LA25308-0', label: 'Anuria' },
    { value: 'LA17661-2', label: 'Disuria' },
    { value: 'LA7445-5', label: 'Incontinenza' },
    { value: 'LA25318-9', label: 'Nicturia' },
    { value: 'LA25321-3', label: 'Oliguria' },
    { value: 'LA25323-9', label: 'Poliuria' },
];

export const sonnoOpt = [
    { value: '', label: 'Seleziona una opzione', disabled: true },
    { value: 'LA25896-4', label: 'Regolare' },
    { value: 'LA25901-2', label: 'Insonnia' },
    { value: 'LA25899-8', label: 'Intermittente' },
];



