<!-- <button (click)="creaQuestionario()">Crea questionario</button> -->
<!-- <button (click)="aggiornaQuestionario()">Aggiorna questionario</button> -->
<h1 class="mt-3" style="text-align:center">Scala Barthel</h1>
<div class="p-fluid mt-3">
    <div class="row mt-3 ml-1" *ngFor="let domanda of domande">
        <ng-container *ngIf="domanda.tipo === 'display'">
            <div class="col-md-12">
                <h4>{{domanda.testo}}</h4>
            </div>
        </ng-container>
        <ng-container *ngIf="domanda.tipo === 'integer'">
            <div class="col-md-12 mt-4 row">
                <div class="col-md-7"><h5 class="mt-4">{{domanda.testo}}</h5></div>
                <div class="col-md-4 custom-slider">
                    <ng5-slider style="white-space: nowrap;" [(value)]="domanda.risposta"
                        [options]="domanda.options"></ng5-slider>
                </div>
                <div class="col-md-1"></div>
            </div>
        </ng-container>
        <ng-container *ngIf="domanda.tipo === 'quantity'">
            <div class="col-md-12 mt-4">
                <h4>{{domanda.testo}}: {{domanda.risposta || ''}}</h4>
                <h4>{{esito}}</h4>
            </div>
        </ng-container>
    </div>
    <div class="row" style="margin-top: 50px;">
        <div class="col-md-12">
            <div class="float-right" style="margin-right: 15px; margin-bottom: 15px;">
                <button *ngIf="checkPermessoModifica()" pButton label="Salva" icon="fa fa-save" iconPos="left"
                    class="p-button-success" type="button" (click)="salvaRisposteBarthel()"></button>
            </div>
        </div>
    </div>
</div>