<form [formGroup]="stileDiVitaForm" class="p-fluid">
    <div class="row">
        <!-- Stato civile -->
        <div class="col-md-4 mt-2">
            <label for="patient-marital-status">Stato civile:</label>
            <p-dropdown id="patient-marital-status" [options]="statoCivileOpt" formControlName="patient-marital-status"
                dropdownIcon="fas fa-chevron-down"></p-dropdown>
        </div>
        <!-- ./Stato civile -->
        <!-- Livello di istruzione -->
        <div class="col-md-4 mt-2">
            <label for="63504-5">Livello di istruzione:</label>
            <p-dropdown id="63504-5" [options]="livelloDiIstrOpt" formControlName="63504-5"
                dropdownIcon="fas fa-chevron-down"></p-dropdown>
        </div>
        <!-- ./Livello di istruzione -->
        <!-- Fumo -->
        <div class="col-md-4 mt-2">
            <label for="72166-2">Fumatore:</label>
            <p-dropdown id="72166-2" [options]="fumatoreOpt" formControlName="72166-2"
                dropdownIcon="fas fa-chevron-down"></p-dropdown>
        </div>
        <!-- ./Fumo -->
    </div>
    <div class="row">
        <!-- Uso di alcolici -->
        <div class="col-md-4 mt-2">
            <label for="68518-0">Uso di alcolici:</label>
            <p-dropdown id="68518-0" [options]="alcoliciOpt" formControlName="68518-0"
                dropdownIcon="fas fa-chevron-down"></p-dropdown>
        </div>
        <!-- ./Uso di alcolici -->
        <!-- Alimentazione -->
        <div class="col-md-4 mt-2">
            <label for="alimentation">Alimentazione:</label>
            <p-dropdown id="alimentation" [options]="alimentazioneOpt" formControlName="alimentation"
                dropdownIcon="fas fa-chevron-down"></p-dropdown>
        </div>
        <!-- ./Alimentazione -->
        <!-- Attività fisica -->
        <div class="col-md-4 mt-2">
            <label for="physical-activity">Attivit&agrave; fisica:</label>
            <p-dropdown id="physical-activity" [options]="attivitaFisicaOpt" formControlName="physical-activity"
                dropdownIcon="fas fa-chevron-down"></p-dropdown>
        </div>
        <!-- ./Attività fisica -->
    </div>
    <div class="row">
        <!-- Attività lavorativa -->
        <span class="col-md-6 mt-2">
            <label for="11295-3">Attivit&agrave; lavorativa:</label>
            <input formControlName="11295-3" type="text" id="11295-3" placeholder="Attivit&agrave; lavorativa"
                pInputText />
        </span><!-- ./Attività lavorativa -->
        <!-- Vuoto -->
        <span class="col-md-6 mt-2">
        </span><!-- ./Vuoto -->
    </div>
    <div class="row mt-2" *ngIf="checkPermessoModifica()">
        <div class="col-md-12">
            <div class="float-right">
                <button pButton label="Salva" icon="fa fa-save" iconPos="left" id="saveStileDiVita"
                    class="p-button-success" type="button" (click)="save()"></button>
            </div>
        </div>
    </div>
</form>