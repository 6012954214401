import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CustomizationService } from 'src/app/service/customization.service';
import { TokenService } from 'src/app/service/token.service';
import { UserService } from 'src/app/service/user-service.service';
import { endpoints } from 'src/endpoint/endpoints';


@Component({
  selector: 'app-privacy-modal',
  templateUrl: './privacy-modal.component.html',
  styleUrls: ['./privacy-modal.component.scss']
})
export class PrivacyModalComponent implements OnInit {

  form;
  linkTermini;
  linkInformativa;

  constructor(private toastr: ToastrService, private fb: FormBuilder, private userService: UserService, private ref: DynamicDialogRef,
    private router: Router, private tokenService: TokenService, private http: HttpClient, private customizationService: CustomizationService) { }

  static open(dialogService: DialogService, altezza: string, larghezza: string) {
    return dialogService.open(PrivacyModalComponent, {
      baseZIndex: 1500,
      // showHeader: false,
      header: 'Privacy policy',
      height: altezza,
      width: larghezza,
      dismissableMask: false,
      closable: false

    });
  }

  ngOnInit() {
    // const user = this.userService.retriveUser();

    this.linkInformativa = this.customizationService.getInformativaPaziente();

    this.buildForm()
  }

  buildForm() {
    this.form = this.fb.group({
      checkTerms: [false, this.linkTermini != undefined ? Validators.requiredTrue : []],
      checkPrivacy: [false, Validators.requiredTrue],
      checkUsage: [false, Validators.requiredTrue],
    });
  }

  save() {
    if (!this.form.invalid) {
      localStorage.setItem('privacy_user', 'accepted');
      this.ref.close();
    } else {
      this.toastr.warning('Accettare tutte le condizioni');
    }
  }

  decline() {
    this.tokenService.deleteToken();
    this.userService.deleteUser();
    this.ref.close();
    this.router.navigate(['/login']);
  }

}
