<div class="card" *ngIf="icf">
    <div class="card-header bg-primary">Stato di Salute</div>
    <div class="card-body">
        <div class="row" *ngIf="icf.length > 0">
            <div class="col-md-12">
                <div *ngFor="let elem of icf; index as i">
                    <i class="far fa-file-alt mr-1"></i> <strong>
                        {{elem.item[0].code.coding[0].code}}{{elem.item[0].valueString}} -
                        {{elem.item[0].code.coding[0].display}}</strong>
                    <hr *ngIf="i < icf.length -1">
                </div>
            </div>
        </div>
        <div class="row" *ngIf="icf.length === 0">
            <div class="col-md-12">
                Nessun ICF inserito.
            </div>
        </div>
    </div>
</div>