import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Authority, Organizations, User } from '../main/administration-panel/domain/user';
import { endpoints } from 'src/endpoint/endpoints';
import { PazientiService } from '../main/pazienti/service/pazienti.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private user;

  private utente = 'USER';

  constructor(private pazientiService: PazientiService) {
  }

  public setUser(user) {
    this.user = user;
  }
  /**
   * Ritorna l'utente salvato in memoria del service
   * NB! può essere NULL o Undefined
   */
  public getUser() {
    return this.user;
  }

  public saveUser(user) {
    localStorage.setItem(this.utente, JSON.stringify(user));
    this.setUser(user);
  }

  public deleteUser() {
    this.user = null;
    localStorage.removeItem(this.utente);
  }
  /**
   * Ritorna l'utente salvato nel LocalStorage
   * (usare preferibilmente questo metodo al posto del get)
   */
  public retriveUser() {
    let usr = JSON.parse(localStorage.getItem(this.utente));
    this.setUser(usr);
    return usr;
  }

  public isAdmin(): boolean {
    const usr: User = JSON.parse(localStorage.getItem(this.utente));
    if (usr === null) {
      return false;
    }
    return this.checkAutority(usr.authorities, 'ADMIN');
  }

  public isSuperAdmin(): boolean {
    const usr: User = JSON.parse(localStorage.getItem(this.utente));
    if (usr === null) {
      return false;
    }
    return this.checkAutority(usr.authorities, 'SUPER_ADMIN');
  }

  public isMedico(user?): boolean {
    const usr: User = user ?? JSON.parse(localStorage.getItem(this.utente));
    if (usr === null) {
      return false;
    }
    return this.checkAutority(usr.authorities, 'MEDICO') || this.checkAuthorityWithSubstring(usr.authorities, 'MEDICO');
  }

  public isPaziente(): boolean {
    const usr: User = JSON.parse(localStorage.getItem(this.utente));
    if (usr === null) {
      return false;
    }
    return this.checkAutority(usr.authorities, 'PAZIENTE');
  }

  public isSuper(): boolean {
    const usr: User = JSON.parse(localStorage.getItem(this.utente));
    if (usr === null) {
      return false;
    }
    return this.checkAutority(usr.authorities, 'SUPERMEDICO') || this.checkAutority(usr.authorities, 'SUPER GESTORE CENTRALE OPERATIVA');
  }

  public isOperatore(): boolean {
    const usr: User = JSON.parse(localStorage.getItem(this.utente));
    if (usr === null) {
      return false;
    }
    return this.checkAutority(usr.authorities, 'OPERATORE');
  }

  public isGestoreCentraleOperativa(): boolean {
    const usr: User = JSON.parse(localStorage.getItem(this.utente));
    if (usr === null) {
      return false;
    }
    return this.checkAuthorityWithSubstring(usr.authorities, 'GESTORE');
  }

  public isAccountNotnew(): boolean {
    const usr: User = JSON.parse(localStorage.getItem(this.utente));
    if (usr === null) {
      return false;
    }
    return usr ? usr.accountNotnew : false;
  }

  public getCurrentOrganization() {
    const usr: User = JSON.parse(localStorage.getItem(this.utente));
    return usr.organization.organization;
  }

  public getCurrentOrganizationObj() {
    const usr: User = JSON.parse(localStorage.getItem(this.utente));
    return usr.organization;
  }

  public setCurrentOrganization(org: Organizations) {
    this.user.organization = org;
    this.saveUser(this.user);
  }

  private checkAutority(authorities: Authority[], authority: string): boolean {
    let ret = false;
    for (const element of authorities) {
      if (element.authority === authority) { ret = true; }
    }
    return ret;
  }

  private checkAuthorityWithSubstring(authorities: Authority[], authority: string): boolean {
    const matches = authorities.filter(x => x.authority.includes(authority));
    return matches.length > 0;
  }

  /**
   * Controlla il permesso specificato sulla sezione specificata.
   * Il parametro patient viene utilizzato per controllare l'organization.
   * Se l'utente ed il paziente fanno parte della stessa organization vengono controllati i permessi sull'organization;
   * altrimenti vengono controllati i permessi per le sottostrutture.
   * @param rule Sezione della quale si vogliono controllare i permessi
   * @param permission Tipo di permesso da controllare
   * @param patient paziente specifico. Serve per controllare l'organizzazione. In caso di null, viene recuperato quello selezionato.
   * @returns true se si ha il permesso
   */
  public checkPermission(rule: string, permission: string, patient?): boolean {
    const u = this.retriveUser();
    if (u === null) {
      return false;
    }

    if (patient == null) {
      patient = this.pazientiService.getPatient();
      if (patient == null) {
        return false;
      }
    }

    // Risorsa proveniente da una risposta del fhir
    if (patient.resource != undefined) {
      patient = patient.resource;
    }

    // Risorsa proveniente dall'oauth
    if (patient.username != undefined) {
      // Il paziente è una risorsa dell'oauth quindi bisogna mapparlo come risorsa fhir
      // in modo da evitare if/else ovunque
      patient = this.pazientiService.createPatientObjectFromOauthPatient(patient);
    }


    try {
      const isSameOrganization = this.isSameOrganization(patient, u);
      if (isSameOrganization) {
        return this.checkPermissionSameOrganization(rule, permission, patient, u);
      } else {
        return this.checkPermissionOtherOrganization(rule, permission);
      }
    } catch (error) {
      console.error("Errore nel controllo delle organization di appartenenza. Il paziente, il practitioner, la lista dei practitioner o il riferimento dell'organization sono undefined/null.");
      return false;
    }

  }

  /**
   * Controlla se il paziente è gestito dal practitioner
   * @param patient paziente di cui si vuole controllare il practitioner
   * @param practitioner practitioner che potrebbe avere il paziente
   * @returns true se il practitioner è nella lista dei practitioner del paziente
   */
  checkIsHisPractitioner(patient, practitioner) {
    return this.haveThisPractitioner(patient, practitioner);
  }

  /**
   * Controlla se si ha un determinato permesso su una determinata sezione.
   * NOTA: Non viene controllata l'organization ecc...
   * @param rule Sezione di cui si vuole controllare i permessi
   * @param permission Permesso da controllare
   * @returns true se si ha il permesso in quella sezione
   */
  checkOnlyPermission(rule: string, permission: string) {
    const permissions = this.getPermissionsFromAuthorities(rule);
    if (permissions != undefined && permissions[permission] != undefined) {
      return permissions[permission];
    }
    return false;
  }

  /**
   * Controlla i permessi inerenti alle altre organizzazioni
   * @param rule sezione di cui si vuole controllare i permessi
   * @param permission read, modify, delete, canSeeAll oppure manageAll
   * @returns true se l'utente ha i permessi, false se l'utente non ha i permessi
   */
  checkPermissionOtherOrganization(rule: string, permission: string): boolean {
    let permissions = this.getPermissionsFromAuthorities(rule);
    if (permissions == undefined) {
      return false;
    }
    switch (permission) {
      case "read":
        return permissions["manageSubstructures"] ?? false;
      case "modify":
      case "delete":
        switch (rule) {
          case "Monitoraggio":
          case "Norifiche":
            return permissions["manageSubstructures"] ?? false;
          default:
            return false;
        }
      default:
        return false;
    }
  }

  /**
   * Controlla i permessi inerenti alla propria organizzazione
   * @param rule sezione di cui si vuole controllare i permessi
   * @param permission read, modify, delete, canSeeAll oppure manageAll
   * @returns true se l'utente ha i permessi, false se l'utente non ha i permessi
   */
  checkPermissionSameOrganization(rule: string, permission: string, patient, practitioner): boolean {

    // Se non è un paziente dell'utente loggato vengono controllati i permessi generali
    if (!this.checkIsHisPractitioner(patient, practitioner)) {
      if (permission == "read") {
        return this.checkOnlyPermission(rule, "canSeeAll");
      } else if (permission == "modify" || permission == "delete") {
        return this.checkOnlyPermission(rule, "manageAll");
      }
    }

    // Altrimenti vengono controllati i permessi normalmente
    return this.checkOnlyPermission(rule, permission);
  }

  /**
   * Restituisce la lista di permessi della sezione desiderata
   * @param rule sezione da cercare
   * @returns lista di permessi
   */
  public getPermissionsFromAuthorities(rule: string) {
    const usr = this.retriveUser();
    if (usr == null || usr == undefined) {
      return undefined;
    }
    for (let indiceAuthorities = 0; indiceAuthorities < usr.authorities.length; indiceAuthorities++) {
      let aut = this.retriveUser().authorities[indiceAuthorities];
      for (let indiceRules = 0; indiceRules < aut.rules.length; indiceRules++) {
        let r = aut.rules[indiceRules];
        if (r.sectionAuth.section === rule) {
          return r;
        }
      }
    }
    return undefined;
  }

  /**
   * Controlla se il paziente selezionato ed il practitioner fanno parte della stessa organizzazione
   * @param patient paziente selezionato
   * @param practitioner utente loggato
   * @returns true se il riferimento dell'organization di cui fa parte il practitioner è nella lista dei practitioner del paziente 
   */
  isSameOrganization(patient, practitioner) {
    if (patient == null || practitioner == null) {
      throw new Error();
    }
    if (patient.generalPractitioner == undefined) {
      throw new Error();
    }
    const co = practitioner.organization.referenceId;
    return this.haveThisPractitioner(patient, co);
  }

  /**
   * Controlla se nella lista dei practitioners del paziente compare la reference selezionata
   * @param patient Paziente
   * @param reference Practitioner/<id> oppure Organization/<id>
   */
  haveThisPractitioner(patient, reference): boolean {
    if (patient == null || reference == null) {
      throw new Error();
    }
    if (patient.generalPractitioner == undefined) {
      throw new Error();
    }

    if (reference.username) {
      // È un medico quindi estraggo l'organization
      reference = reference.organization.referenceId;
    }

    return patient.generalPractitioner.find(p => p.reference == reference) != undefined;
  }

  /**
   * Controlla se l'utente ha almeno un canSeeAll a true in modo da far vedere tutta la lista dei pazienti.
   */
  public checkPermissionCanSeeAllPatientList(): boolean {
    let canSeeAll = false;
    if (this.retriveUser().authorities && this.retriveUser().authorities[0].rules) {
      this.retriveUser().authorities[0].rules.forEach(rule => {
        if (rule.canSeeAll === true) {
          canSeeAll = true;
        }
      });
    }
    return canSeeAll;
  }

  /**
   * Restiruisce una Promise con dentro la stringa di reference
   * @param http 
   * @returns Promise con dentro la stringa delle reference. Vedi {@function extractPractitionersAndOrganizationsIdFromResponseAndAddSelf}
   */
  public getPractitionersAsPromise(http: HttpClient) {
    if (!this.isSuperAdmin() && !this.canManageSubstructures()) {
      let ids = this.concatSelfIdAndOrganizationId();
      return Promise.resolve<any>(ids.toString() as string);
    } else {
      return http.get(endpoints.getAllChildrenOrganization).toPromise().then(resp => {
        return this.extractPractitionersAndOrganizationsIdFromResponseAndAddSelf(resp);
      });
    }
  }

  /**
   * Crea una stringa di id concatenati attraverso virgole
   * resp è la risposta che contiene la lista di organization
   * resp.status può essere OK o KO
   * resp.object è la lista
   * @returns id concatenati con la virgola presenti nella resp + il proprio id
   */
  public extractPractitionersAndOrganizationsIdFromResponseAndAddSelf(resp) {
    if (resp.status == "OK") {
      let ids = [];
      resp.object.forEach(organization => {
        if (organization.referenceId !== undefined && organization.referenceId !== null) {
          ids.push(this.extractIdFromReferenceString(organization.referenceId));
        }
      });
      // Concatena il proprio id e quella dellìorganization eventualmente
      ids = this.concatSelfIdAndOrganizationId(ids);
      return ids.toString();
    } else {
      return undefined;
    }
  }

  concatSelfIdAndOrganizationId(ids: Array<string> = new Array<string>()) {
    // Aggiugo la reference dell'utente loggato così vede di sicuro i suoi
    if (this.getUser().referenceId !== undefined && this.getUser().referenceId !== null) {
      ids.push(this.extractIdFromReferenceString(this.getUser().referenceId));
    }
    // Se ha il canSeeAll aggiungo l'id dell'organization
    if (this.checkPermissionCanSeeAllPatientList() && this.getUser().organization.referenceId != null) {
      ids.push(this.extractIdFromReferenceString(this.getUser().organization.referenceId));
    }
    return ids;
  }

  /**
   * Controlla se ha almeno un manageSubstructures a true
   * @returns true se ha almeno un manageStructures a true; false altrimenti
   */
  public canManageSubstructures(): boolean {
    if (this.retriveUser().authorities) {
      for (let authority of this.retriveUser().authorities) {
        if (authority.rules) {
          for (let rule of authority.rules) {
            if (rule.manageSubstructures === true) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  /**
   * Estrae l'id dalla reference della risorsa.
   * @param reference stringa formattata <Risorsa FHIR>/<id>
   * @returns <id>
   */
  extractIdFromReferenceString(reference: string): string {
    return reference.split("/")[1];
  }

  /**
   * Metodo pensato per la visualizzazione dei tasti nella barra laterale.
   * Per poter entrare in una sezione c'è bisogno di almeno un permesso di lettura.
   * @param rule Sezione della quale si vuole controllare il permesso di lettura
   * @returns true se ha read o canSeeAll
   */
  public checkAnyReadPermissionByRule(rule: string) {
    if (this.retriveUser() == null) {
      return false;
    }

    return this.checkOnlyPermission(rule, "read") || this.checkOnlyPermission(rule, "canSeeAll");
  }

  /**
   * Metodo che controlla i permessi per la visualizzazione degli elementi sulla sidebar.
   * Se c'è un paziente selezionato vengono controllati i permessi inerenti a quel paziente;
   * Altrimenti vengono controllati i permessi solo sulla sezione.
   * @param rule Sezione da controllare
   * @returns true se l'utente ha il permesso di visualizzare quella sezione [del paziente selezionato]
   */
  public checkPermissionForSidebar(rule: string): boolean {
    if (this.retriveUser() == null) {
      return false;
    }

    const patient = this.pazientiService.getPatient();
    // Nessun paziente selezionato
    if (patient == null) {
      return this.checkAnyReadPermissionByRule(rule);
    }

    // Controllo dei permessi della sezione del paziente selezionato
    return this.checkPermission(rule, "read", patient);
  }

}
