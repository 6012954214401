import { Component, OnInit } from '@angular/core';
import { setTheme } from 'ngx-bootstrap/utils';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'cwa-app';
  currentOrg = 'eda';
  coloreLogo = '#009479'; // Verde

  constructor() {
    setTheme('bs4'); // or 'bs4'
  }

  ngOnInit(): void {
    if (window.location.href.includes('nia.nemolab')) {
      this.currentOrg = 'NEMO';
      this.coloreLogo = '#5C8DA6';
    } else {
      this.currentOrg = 'eda';
      this.coloreLogo = '#009479';
    }
  }

}
