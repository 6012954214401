import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import { SelectItem } from "primeng/api";
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { Ticket } from "src/app/model/ticket";
import { UserService } from "src/app/service/user-service.service";
import { markAllAsTouched, updateValueAndValidity } from "src/app/utils/utils";
import { validateEmail } from "src/app/utils/validators";
import { endpoints } from "src/endpoint/endpoints";


@Component({
  selector: 'app-help-modal',
  templateUrl: './help-modal.component.html',
  styleUrls: ['./help-modal.component.scss']
})
export class HelpModalComponent implements OnInit {

  form: FormGroup;
  organization: string;
  listTag: SelectItem[];
  mittenteUsername: string;
  mittenteNomeCognome: string;
  mittenteEmail: string;

  // tslint:disable-next-line: max-line-length
  constructor(private ref: DynamicDialogRef, private sanitizer: DomSanitizer, private userService: UserService, private config: DynamicDialogConfig, private fb: FormBuilder, private http: HttpClient, private toastr: ToastrService) { }

  static open(dialogService: DialogService, input, altezza: string, larghezza: string) {
    return dialogService.open(HelpModalComponent, {
      baseZIndex: 1500,
      // showHeader: false,
      header: 'Assistenza',
      height: altezza,
      width: larghezza,
      dismissableMask: true,
      data: input,
      contentStyle: { overflow: 'visible' }
    });
  }

  ngOnInit() {

    this.organization = this.userService.getCurrentOrganization();
    this.mittenteUsername = this.userService.getUser().username;
    this.mittenteNomeCognome = this.userService.getUser().nome + ' ' + this.userService.getUser().cognome;
    this.mittenteEmail = this.userService.getUser().email;

    this.form = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, validateEmail]],
      oggetto: ['', Validators.required],
      testo: ['', Validators.required],
      selectedTag: ['', Validators.required],
      eventoSospetto: ['']
    });

    this.form.patchValue({
      name: this.mittenteNomeCognome,
      email: this.mittenteEmail
    });

    this.getAllTagsByOrganization(this.organization);
  }

  openIFU() {
    window.open('https://www.eda.it/manuali/manuale_medico.pdf', '_blank');
  }

  invia() {
    markAllAsTouched(this.form);
    updateValueAndValidity(this.form);
    const tagSelezionata = JSON.stringify(this.form.value.selectedTag);

    // tslint:disable-next-line: triple-equals
    if (tagSelezionata === '') {
      this.toastr.error('Errore nel campo Oggetto. Inserire un valore');
    } else {
      if (!this.form.invalid) {
        const ticket = new Ticket();
        ticket.username = this.mittenteUsername.trim();
        ticket.nomeCognome = this.mittenteNomeCognome.trim();
        ticket.mittente = this.form.value.email.trim();
        ticket.testo = this.form.value.testo;
        ticket.oggetto = this.form.value.oggetto.trim();
        ticket.tag = this.form.value.selectedTag.trim();
        ticket.organization = this.organization.trim();
        ticket.refereceId = this.userService.getUser().referenceId;
        ticket.eventoSospetto = this.form.value.eventoSospetto[0] === 'true' ? true : false;

        this.http.post(endpoints.postTicket, ticket).toPromise().then((resp: any) => {
          if (resp) {
            if (resp.status === 'OK') {
              this.toastr.success('Richiesta di supporto inviata correttamente, ti risponderemo il prima possibile.');
            } else {
              this.toastr.error(resp.desc);
            }
          }
        }).catch(err => {
          console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
          this.toastr.error('Errore nella richiesta di assistenza.');
        });
        this.ref.close();
      } else {
        this.toastr.error('Form non valida! Riempire tutti i campi correttamente.');
      }

    }

  }

  getAllTagsByOrganization(organization) {
    this.http.get(endpoints.getAllTagsByOrganization, {
      headers: { Organization: organization }
    })
      .toPromise().then((resp: any) => {
        if (resp) {
          this.listTag = new Array<SelectItem>();
          this.listTag.push({ value: '', label: 'Seleziona una opzione', disabled: true });
          resp.object.forEach(el => {
            this.listTag.push({ value: el, label: el });
          });
        }
      }).catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Impossibile caricare le tag', 'Errore');
      });
  }

  stripHtml(html) {
    // Create a new div element
    const temporalDivElement = document.createElement('div');
    // Set the HTML content with the providen
    temporalDivElement.innerHTML = html;
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || '';
  }


}
