import { BreakpointState } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
import {
  Component,

  OnInit
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LogEventService } from 'src/app/main/pazienti/service/log-event.service';
import { PazientiService } from 'src/app/main/pazienti/service/pazienti.service';
import { AppService } from 'src/app/service/app.service';
import { QuestionnaireService } from 'src/app/service/questionnaire-service.service';
import { UserService } from 'src/app/service/user-service.service';
import { endpoints } from 'src/endpoint/endpoints';

@Component({
  selector: 'app-scale-di-valutazione-mmse',
  templateUrl: './scale-di-valutazione-mmse.component.html',
  styleUrls: ['./scale-di-valutazione-mmse.component.scss']
})
export class ScaleDiValutazioneMMSEComponent implements OnInit {

  // Per gestire il mobile
  isMobile = false;
  observer;

  clinicalImpressionId;
  idQuestionario;

  domande;

  intervalliEta;
  intervalloEtaSelezionato;
  anniScolarizzazione;
  anniScolarizzazioneSelezionati;
  coefficienteDiAggiustamento;

  punteggio;
  punteggioAggiustato;
  mostraPunteggi = false;

  ultimaRispostaMutuaEsclusione; // Ci sono due domande che sono in mutua esclusione
  listaMutuaEsclusione;

  isVisit = false;
  patientId;

  constructor(private toastr: ToastrService, private userService: UserService, private pazientiService: PazientiService,
    private http: HttpClient, private route: ActivatedRoute, private appServ: AppService, private questionariService: QuestionnaireService,
    private logService:LogEventService) { }

  ngOnInit() {
    this.getIsInVisite();
    this.getQuestionario();
    this.observer = this.appServ.mdAppObs
      .subscribe((statex: BreakpointState) => {
        this.isMobile = statex.matches;
      });

    this.listaMutuaEsclusione = new Array();
    this.listaMutuaEsclusione.push('12');
    this.listaMutuaEsclusione.push('13');

    this.patientId = this.pazientiService.getPatientId();
  }

  // Solo se non esiste nel database. Attenzione a non creare duplicati!!!
  creaQuestionario() {
    this.questionariService.createQuestionnaire(QuestionnaireService.MMSE);
  }

  // Usare solo quando è realmente necessario
  aggiornaQuestionario() {
    this.questionariService.updateQuestionnaire(QuestionnaireService.MMSE, this.idQuestionario);
  }

  getIsInVisite() {
    const cId = this.route.parent.parent.snapshot.paramMap.get('vId');
    if (cId) {
      this.isVisit = true;
      this.clinicalImpressionId = cId;
    }
  }

  getQuestionario() {
    const obj = {
      nome: 'MMSE',
      versione: '1'
    };
    this.http.post(endpoints.getQuestionnaireByNameByVersion, obj).toPromise()
      .then((resp: any) => {
        this.idQuestionario = resp.id;
        this.domande = new Array();
        resp.item.forEach(item => {
          let domanda: any;
          domanda = {};
          domanda.testo = item.text;
          domanda.tipo = item.type;
          domanda.linkId = item.linkId;
          if (domanda.testo === 'Coefficiente di aggiustamento') {
            this.intervalliEta = new Array();
            this.anniScolarizzazione = new Array();
            item.item.forEach(sezione => {
              if (sezione.text === 'Intervallo di età') {
                sezione.option.forEach(intervallo => {
                  this.intervalliEta.push({
                    label: intervallo.valueCoding.display,
                    value: 0
                  });
                });
              } else {
                sezione.item.forEach(intervallo => {
                  this.anniScolarizzazione.push({
                    label: intervallo.text,
                    value: 0
                  });
                });
              }
            });
            this.coefficienteDiAggiustamento = item;
          } else {
            if (domanda.tipo === 'integer') {
              domanda.domande = new Array();
              const customStepsArray = new Array();
              item.option.forEach(d => {
                // Creo la struttura per lo slider
                customStepsArray.push({
                  value: d.valueCoding.code,
                  legend: ''
                });
                domanda.options = {
                  showTicksValues: true,
                  stepsArray: customStepsArray,
                  disabled: !this.checkPermessoModifica()
                };
              });
              domanda.domande = customStepsArray;
            } else {
              domanda.riposta = undefined;
            }
          }
          if (domanda.testo !== 'Test somministrabile') {
            this.domande.push(domanda);
          }

        });
        this.getRisposte();
      });
  }

  getRisposte() {
    let endpointToCall;
    let obj: any;
    obj = {};
    if (this.isVisit) {
      obj = { clinicalImpressionId: this.clinicalImpressionId, questionnaireId: this.idQuestionario };
      endpointToCall = endpoints.getClinicalImpressionSurveyResponse;
    } else {
      obj = { patientId: this.patientId, questionnaireId: this.idQuestionario };
      endpointToCall = endpoints.getClinicalImpressionSurveyResponseNLAB;
    }
    this.http.post(endpointToCall, obj).toPromise()
      .then((resp: any) => {
        this.punteggio = 0;
        const risposte = resp.item;
        this.domande.forEach((domanda, indice) => {
          if (domanda.testo === 'Coefficiente di aggiustamento') {
            // tslint:disable-next-line: max-line-length
            this.intervalloEtaSelezionato = this.intervalliEta.find(e => e.label === risposte[indice].answer[0].valueString.split('|')[0]);
            // tslint:disable-next-line: max-line-length
            this.anniScolarizzazioneSelezionati = this.anniScolarizzazione.find(e => e.label === risposte[indice].answer[0].valueString.split('|')[1]);
          } else {
            if (domanda.tipo === 'integer') {
              domanda.risposta = risposte[indice].answer[0].valueInteger + '';
            } else {
              domanda.risposta = risposte[indice].answer[0].valueString + '';
            }
          }
        });      
      this.logService.getAllOpt([[resp]],null);
      }).catch(err => {
        if (err.status === 404) {
          this.toastr.info('Questionario non eseguito.');
          this.logService.resetGlobalVarLog();
        } else {
          console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
          this.toastr.error('Errore nel caricamento delle risposte del questionario.');
        }
      });
  }

  controlloRisposte() {
    // Controllo gli anni di scolarizzazione e l'età
    if (this.anniScolarizzazioneSelezionati === undefined || this.intervalloEtaSelezionato === undefined) {
      return false;
    }

    // Controllo quelle con mutua esclusione
    // const mutuaEsclusione = this.domande.filter(d => this.listaMutuaEsclusione.includes(d.linkId) && Number(d.risposta) > 0);
    // if (mutuaEsclusione.length > 1) {
    //   return false;
    // }

    return true;
  }

  salva() {
    if (!this.controlloRisposte()) {
      this.toastr.warning('Ci sono errori nella compilazione delle risposte.');
      return;
    }

    const domandaPunteggio = this.domande.find(d => d.testo === 'Totale');
    domandaPunteggio.risposta = 0;

    let endpointToCall;
    let obj: any;
    obj = {};
    if (this.isVisit) {
      obj = {
        answers: new Array(),
        questionnaireId: this.idQuestionario,
        clinicalImpressionId: this.clinicalImpressionId
      };
      endpointToCall = endpoints.addClinicalImpressionSurveyResponse;
    } else {
      obj = {
        answers: new Array(),
        questionnaireId: this.idQuestionario,
        patientId: this.patientId
      };
      endpointToCall = endpoints.addClinicalImpressionSurveyResponseNLABsub;
    }

    this.domande.forEach(domanda => {
      if (Number.isNaN(domanda.risposta)) {
        domanda.risposta = 0;
      }
      if (domanda.testo === 'Coefficiente di aggiustamento') {
        // ATTENZIONE: Prima età e poi scolarizzazione
        obj.answers.push({
          linkId: domanda.linkId,
          answer: this.intervalloEtaSelezionato.label + '|' + this.anniScolarizzazioneSelezionati.label,
          type: domanda.tipo
        });
      } else {
        if (domanda.tipo === 'integer') {
          domandaPunteggio.risposta += Number(domanda.risposta);
        }
        if (domanda.tipo === 'quantity' && domanda.testo.includes('corretto')) {
          this.punteggio = domandaPunteggio.risposta;
          this.calcolaPunteggioAggiustato();
        }
        obj.answers.push({
          linkId: domanda.linkId,
          answer: domanda.risposta + '',
          type: domanda.tipo
        });
      }
    });

    this.http.post(endpointToCall, obj).toPromise()
      .then((resp: any) => {
        this.toastr.success('Salvataggio avvenuto con successo.');
        this.getQuestionario();
      }).catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Errore nel salvataggio delle risposte del questionario.');
      });
  }

  checkPermessoModifica() {
    if (this.isVisit) {
      return this.userService.checkPermission('Osservazioni', 'modify') && this.userService.checkPermission('Visite', 'modify');
    } else {
      return this.userService.checkPermission('Anagrafica pazienti', 'modify')
    }
  }

  calcolaPunteggioAggiustato() {
    this.coefficienteDiAggiustamento.item[1].item.forEach(fascia => {
      if (fascia.text === this.anniScolarizzazioneSelezionati.label) {
        fascia.option.forEach(valori => {
          if (valori.valueCoding.display === this.intervalloEtaSelezionato.label) {
            this.punteggioAggiustato = Number(valori.valueCoding.code) + Number(this.punteggio);
            this.punteggioAggiustato = this.punteggioAggiustato.toFixed(1);
            (this.domande.find(d => d.testo.includes('corretto'))).risposta = this.punteggioAggiustato;
          }
        });
      }
    });
  }
}
