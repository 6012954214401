<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header bg-primary">Dettagli del paziente</div>
            <div class="card-body">
                <form [formGroup]="patientForm" *ngIf="patient && patientForm">

                    <div class="form-group row">
                        <div class="col-md-4 form-group">
                            <!-- NOTA: L'immagine deve essere 128x128 -->
                            <img id="patient-photo" style="margin-left:25px"
                                class="profile-user-img img-fluid img-circle image" [src]="this.patient.photo" />
                            <input id="patient-photo-button" style="margin-left:25px; margin-top:5px" type="file"
                                accept="image/*" (change)="changeListener($event)" disabled="true" />
                        </div>
                        <span class="col-md-4">
                            <label for="inputNome">Nome{{(isRequired("name") ? "*" : "")}}</label>
                            <input type="text" class="form-control" id="inputNome" placeholder="Inserisci nome"
                                formControlName="name"
                                [ngClass]="{'is-invalid': patientForm.get('name').invalid && patientForm.get('name').touched}">
                        </span>
                        <span class="col-md-4">
                            <label for="inputCognome">Cognome{{(isRequired("surname") ? "*" : "")}}</label>
                            <input type="text" class="form-control" id="inputCognome" placeholder="Inserisci cognome"
                                formControlName="surname"
                                [ngClass]="{'is-invalid': patientForm.get('surname').invalid && patientForm.get('surname').touched}">
                        </span>
                    </div>

                    <div class="form-group row">
                        <span class="col-md-4">
                            <label for="inputIdentificativo">Codice Identificativo (ID){{(isRequired("identifier") ? "*"
                                : "")}}</label>
                            <input type="text" class="form-control" id="inputIdentificativo"
                                [ngClass]="patientForm.get('identifier').touched && patientForm.get('identifier').invalid ? 'ng-invalid' : 'ng-valid'"
                                placeholder="Inserisci Identificativo" formControlName="identifier"
                                [ngClass]="{'is-invalid': patientForm.get('identifier').invalid && patientForm.get('identifier').touched}">
                        </span>
                        <span class='col-md-4'>
                            <label for="inputCodiceFiscale">Codice Fiscale{{(isRequired("fiscal_code") ? "*" :
                                "")}}</label>
                            <input type="text" class="form-control" id="inputCodiceFiscale"
                                placeholder="Inserisci Codice Fiscale" formControlName="fiscal_code"
                                [ngClass]="{'is-invalid': patientForm.get('fiscal_code').invalid && patientForm.get('fiscal_code').touched}">
                        </span>
                        <span class="col-md-2">
                            <label for="inputData">Data di nascita{{(isRequired("birthDate") ? "*" : "")}}</label>
                            <div class="input-group date">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fa fa-calendar"></i>
                                    </span>
                                </div>
                                <input type="text" id="inputData" class="form-control" placeholder="Data di Nascita"
                                    bsDatepicker formControlName="birthDate" [bsConfig]="datePickerConf"
                                    [ngClass]="{'is-invalid': patientForm.get('birthDate').invalid && patientForm.get('birthDate').touched}" />
                            </div>
                        </span>
                        <span class="col-md-2">
                            <label>Comune di residenza{{(isRequired("city") ? "*" : "")}}</label>
                            <input type="text" class="form-control" placeholder="Comune di residenza"
                                formControlName="city"
                                [ngClass]="{'is-invalid': patientForm.get('city').invalid && patientForm.get('city').touched}" />
                        </span>
                    </div>

                    <div class="form-group row">
                        <span class="col-md-4">
                            <label for="inputTelNumber">Cellulare{{(isRequired("tel") ? "*" : "")}}</label>
                            <input type="text" class="form-control" id="inputTelNumber" placeholder="Inserisci Telefono"
                                formControlName="tel"
                                [ngClass]="{'is-invalid': patientForm.get('tel').invalid && patientForm.get('tel').touched}">
                        </span>
                        <span class="col-md-4">
                            <label for="inputEmail">Email{{(isRequired("email") ? "*" : "")}}</label>
                            <input type="text" class="form-control" id="inputEmail" placeholder="Inserisci email"
                                formControlName="email"
                                [ngClass]="{'is-invalid': patientForm.get('email').invalid && patientForm.get('email').touched}">
                        </span>
                        <span class="col-md-4">
                            <div class="row">
                                <label for="gender" style="margin-right: 1em">Genere{{(isRequired("gender") ? "*" :
                                    "")}}</label>
                            </div>
                            <div class="row">
                                <div class="col-md-12 row">
                                    <div class="custom-control custom-radio col-md-6 col-sm-6">
                                        <input class="custom-control-input" type="radio" id="customRadioMale"
                                            formControlName="gender" value="{{gender.MALE}}"
                                            [ngClass]="{'is-invalid': patientForm.get('gender').invalid && patientForm.get('gender').touched}">
                                        <label for="customRadioMale" class="custom-control-label">Maschio</label>
                                    </div>
                                    <div class="custom-control custom-radio col-md-6 col-sm-6">
                                        <input class="custom-control-input" type="radio" id="customRadioFemale"
                                            formControlName="gender" value="{{gender.FEMALE}}"
                                            [ngClass]="{'is-invalid': patientForm.get('gender').invalid && patientForm.get('gender').touched}">
                                        <label for="customRadioFemale" class="custom-control-label">Femmina</label>
                                    </div>
                                </div>
                                <div class="col-md-12 row">
                                    <div class="custom-control custom-radio col-md-6 col-sm-6">
                                        <input class="custom-control-input" type="radio" id="customRadioOther"
                                            formControlName="gender" value="{{gender.OTHER}}"
                                            [ngClass]="{'is-invalid': patientForm.get('gender').invalid && patientForm.get('gender').touched}">
                                        <label for="customRadioOther" class="custom-control-label">Altro</label>
                                    </div>
                                    <div class="custom-control custom-radio col-md-6 col-sm-6">
                                        <input class="custom-control-input" type="radio" id="customRadioUnknow"
                                            formControlName="gender" value="{{gender.UNKNOW}}"
                                            [ngClass]="{'is-invalid': patientForm.get('gender').invalid && patientForm.get('gender').touched}">
                                        <label for="customRadioUnknow" class="custom-control-label">Sconosciuto</label>
                                    </div>
                                </div>
                            </div>
                        </span>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-4" *ngIf="canViewListaAssegna()">
                            <label>Assegna medici*</label>
                            <p-multiSelect appendTo="body" class="p-fluid" [defaultLabel]="defaultLabelListaMedici"
                                [options]="medici" formControlName="mediciSelezionati" [filter]="true"
                                filterBy="nome,cognome,username"
                                [ngClass]="{'is-invalid': patientForm.get('mediciSelezionati').invalid}"
                                [emptyFilterMessage]="emptyFilterMessageListaMedici" [baseZIndex]="1501">
                                <ng-template let-select pTemplate="selectedItems">
                                    <div *ngFor="let val of select">
                                        <span style="font-weight: bold;">Nome:&nbsp;</span>
                                        <span>{{val.nome}}&nbsp;</span>
                                        <span style="font-weight: bold;">Cognome:&nbsp;</span>
                                        <span>{{val.cognome}}&nbsp;</span>
                                        <span *ngIf="select.length > 1">,&nbsp;</span>
                                    </div>
                                    <span *ngIf="!select || select.length === 0"
                                        class="p-multiselected-empty-token p-corner-all">{{defaultLabelListaMedici}}</span>
                                </ng-template>
                                <ng-template let-medico pTemplate="item">
                                    <span style="font-weight: bold;">Nome:&nbsp;</span>
                                    <span>{{medico.nome}}&nbsp;</span>
                                    <span style="font-weight: bold;">Cognome:&nbsp;</span>
                                    <span>{{medico.cognome}}&nbsp;</span>
                                    <span style="font-weight: bold;">Username:&nbsp;</span>
                                    <span>{{medico.username}}&nbsp;</span>
                                </ng-template>
                            </p-multiSelect>
                        </div>

                        <!-- <div class="col-md-4 form-group" *ngIf="isInDetail()">
                            <label>Account mobile app</label>
                            <input type="text" class="form-control" placeholder="Account mobile app"
                                formControlName="accountMobileApp">
                        </div>
                        <div class="col-md-4 form-group" *ngIf="isInDetail()">
                            <label>Password mobile app</label>
                            <input type="text" class="form-control" placeholder="Password mobile app"
                                formControlName="passwordMobileApp">
                        </div> -->
                    </div>
                    <div class="row" *ngIf="false">
                        <div class="col-md-12">
                            <button *ngIf="checkModify()" class="to-right p-button-success" pButton label="Salva"
                                icon="fa fa-save" iconPos="left" id="send" type="button" (click)="salva()"></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="row" *ngIf="this.id && this.typeListView === 'Pazienti presi in carico'">
    <div class="col-md-12">
        <div class="card" *ngIf="false">
            <div class="card-header border-0">
                <div class="p-fluid justify-content-between">
                    <h3 class="card-title">Dati attivazione</h3>
                </div>
            </div>
            <div class="card-body">
                <app-dettagli-attivazione-servizio></app-dettagli-attivazione-servizio>
            </div>
        </div>
    </div>
</div>

<div class="row" *ngIf="this.id">
    <div class="col-md-12">

        <div class="card" *ngIf="false">
            <div class="card-header border-0">
                <div class="p-fluid justify-content-between">
                    <h3 class="card-title">Gestione caregiver</h3>
                </div>
            </div>
            <div class="card-body">
                <app-related-person-detail></app-related-person-detail>
            </div>
        </div>
    </div>
</div>



<app-related-person-component [id]="patientId" [related]="null" *ngIf="patientId && org === 'NEMO'" section='patient'>
</app-related-person-component>