import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {

  message: string;
  result: boolean;
  constructor(private modalRef: BsModalRef) { }

  confirm(): void {
    this.result = true;
    this.modalRef.hide();
  }

  decline(): void {
    this.result = false;
    this.modalRef.hide();
  }

  ngOnInit() {
  }

}
