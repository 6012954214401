import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import * as moment from 'moment';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { itLocale } from 'ngx-bootstrap/locale';
import { ToastrService } from 'ngx-toastr';
import { ValidatorsManager } from 'src/app/model/ValidatorsManager';
import { AppService } from 'src/app/service/app.service';
import { UserService } from 'src/app/service/user-service.service';
import { capitalize } from 'src/app/utils/utils';
import { endpoints } from 'src/endpoint/endpoints';
import { environment } from 'src/environments/environment';
import { gender } from '../../administration-panel/domain/gender.enum';
import { Patient } from '../model/patient';
import { PazientiService } from '../service/pazienti.service';

@Component({
  selector: 'app-scheda-paziente',
  templateUrl: './scheda-paziente.component.html',
  styleUrls: ['./scheda-paziente.component.scss']
})

export class SchedaPazienteComponent implements OnInit {

  medici; // Lista dei medici a cui possono essere assegnati i pazienti
  mediciSelezionati; // Lista dei medici a cui verrà assegnato il paziente
  defaultLabelListaMedici = 'Selezionare almeno un medico';
  emptyFilterMessageListaMedici = 'Nessun medico trovato';

  patient = {} as Patient;

  patientForm: FormGroup;

  datePickerConf = { isAnimated: true, adaptivePosition: true, dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue' };

  locale = 'it';

  gender = gender;

  aggiornamento;

  org;

  validatorsManager: ValidatorsManager = new ValidatorsManager();

  @Output() patientId;

  @Input() id;

  @Output() update: EventEmitter<any> = new EventEmitter();

  typeListView;

  // flag per gestire se il form serve a modificare oppure ad inserire un nuovo paziente
  isNew = false;

  constructor(private fb: FormBuilder, private toastr: ToastrService, private http: HttpClient, private localeService: BsLocaleService,
    private datePipe: DatePipe, private pazientiService: PazientiService, private userService: UserService, private route: ActivatedRoute,
    private appServ: AppService) {
    this.update.emit(this.update);
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.typeListView = this.appServ.getTypeViewList();
    this.aggiornamento = false;
    itLocale.invalidDate = 'Data non valida';
    defineLocale(this.locale, itLocale);
    this.localeService.use(this.locale);
    this.getPatient();

    if (!this.id) {
      this.buildForm(this.patient);
      this.getAllAvailableUsers();
    }
    this.org = this.userService.getCurrentOrganization();
    if (this.org === 'VIVISOL' || this.org === 'AQUA') {
      this.org = 'NEMO';
    }

  }

  canViewListaAssegna() {
    return this.userService.isAdmin() || this.userService.isSuperAdmin() || this.userService.isOperatore() || this.userService.isGestoreCentraleOperativa();
  }

  getAllAvailableUsers() {
    this.http.get(endpoints.getUsers).toPromise().then((resp: any) => {
      if (resp) {
        let ms = [];
        ms = resp.object.filter((elem) =>
          elem.referenceId !== 'null'
          && this.userService.isMedico(elem)
          && elem.organization.organization === this.userService.getCurrentOrganization());
        this.medici = new Array();
        ms.forEach(medico => {
          // tslint:disable-next-line: max-line-length
          this.medici.push({ value: { value: medico.referenceId, nome: medico.nome, cognome: medico.cognome, username: medico.username }, nome: medico.nome, cognome: medico.cognome, username: medico.username });
        });
        this.mediciSelezionati = new Array();
        if (this.patient.generalPractitioner) {
          this.patient.generalPractitioner.forEach(reference => {
            const el = this.medici.find(m => m.value.value === reference.reference);
            if (el) {
              this.mediciSelezionati.push(el.value);
            }
          });
        } else {
          if (this.userService.isMedico()) {
            const medico = this.medici.find(m => m.value.value === this.userService.getUser().referenceId);
            this.mediciSelezionati.push(medico.value);
          }
        }
        // Aggiorno il widget
        this.patientForm.get('mediciSelezionati').setValue(this.mediciSelezionati);
      } else {
        this.toastr.error('Errore nel recuperare la lista di medici');
        console.error('Errore nel recuperare la lista dei medici');
      }
    }).catch(err => {
      this.toastr.error('Errore nel recuperare la lista di medici');
      if (err.status == 403) {
        this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
        return;
      }
      console.error('Errore nel recuperare la lista dei medici, errore: ', err);
    });
  }

  getPatient() {
    if (this.id) {
      this.http.get(endpoints.getPatientByPatientId + this.id).toPromise().then((resp: any) => {
        if (resp && resp.success == "1") {
          this.pazientiService.setPatient(resp.message);
          this.mapPatientObject(resp.message);
          this.buildForm(this.patient);
          this.patientForm.get('identifier').disable();
          this.patientForm.get('name').disable();
          this.patientForm.get('surname').disable();
          this.patientForm.get('fiscal_code').disable();
          this.patientForm.get('email').disable();
          this.patientForm.get('birthDate').disable();
          this.patientForm.get('tel').disable();
          this.patientForm.get('city').disable();
          this.patientForm.get('gender').disable();
          this.patientForm.get('photo').disable();
          this.getAllAvailableUsers();
        }
      }).catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Errore nel recupero del Paziente');
      });
    } else {
      this.isNew = true;
      this.getSanitizedPhoto();
      return this.patient;
    }
  }

  findUserByReference(reference) {
    let toReturn = null;
    this.medici.forEach(u => {
      if (u.value === reference) {
        toReturn = u;
      }
    });
    return toReturn;
  }

  isRequired(campo) {
    return this.validatorsManager.isRequired(campo);
  }

  buildForm(patientIn) {
    // Popolo la lista di campi obbligatori
    // Usare il formControlName
    this.validatorsManager.addCampoObbligatorio("identifier");
    this.validatorsManager.addCampoObbligatorio("fiscal_code");
    this.validatorsManager.addCampoObbligatorio("birthDate");
    this.validatorsManager.addCampoObbligatorio("email");
    this.validatorsManager.addCampoObbligatorio("gender");

    // Aggiungo i Validators dinamicamente
    const validators: Array<Array<any>> = new Array();

    this.validatorsManager.addValidatorByFormControlName('email', Validators.email);
    this.validatorsManager.addValidatorByFormControlName('fiscal_code', Validators.pattern('^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$'));
    this.validatorsManager.addValidatorByFormControlName('name', Validators.pattern("[^*]*$"));
    this.validatorsManager.addValidatorByFormControlName('surname', Validators.pattern("[^*]*$"));

    if (!this.userService.isMedico()) {
      this.validatorsManager.addCampoObbligatorio('mediciSelezionati');
    }

    this.patientForm = this.fb.group({
      name: [patientIn.name],
      surname: [patientIn.surname],
      identifier: [patientIn.identifier],
      fiscal_code: [patientIn.fiscal_code],
      email: [patientIn.email],
      birthDate: [patientIn.birthDate],
      tel: [patientIn.tel],
      city: [patientIn.city],
      gender: [patientIn.gender],
      photo: [patientIn.photo],
      mediciSelezionati: [''],
      accountMobileApp: (patientIn.identifier ? environment.company + patientIn.identifier : ''),
      passwordMobileApp: (patientIn.identifier ? environment.company + patientIn.identifier : '')
    }, { validators: this.ValidateDate.bind(this) });

    this.patientForm.get('accountMobileApp').disable();
    this.patientForm.get('passwordMobileApp').disable();
    if (!this.checkModify()) {
      this.patientForm.disable();
    }
    if (this.id) {
      this.patientForm.get('identifier').disable();
    }
    // Setto i vari validators
    this.validatorsManager.form = this.patientForm;
    this.validatorsManager.aggiungiValidatorObbligatori();
    this.validatorsManager.setAllValidatorsToForm()
  }

  checkModify() {
    if (this.isNew) {
      return this.userService.checkOnlyPermission('Anagrafica pazienti', 'insert');
    } else {
      return this.userService.checkPermission('Anagrafica pazienti', 'modify', this.patient);
    }
  }

  salva() {
    this.patientForm.markAllAsTouched();
    if (this.patientForm.invalid) {
      for (const key in this.patientForm.controls) {
        if (this.patientForm.controls[key].status == "INVALID") {
        }
      }
      if (this.patientForm.get('name').invalid || this.patientForm.get('surname').invalid) {
        this.toastr.warning('Rimuovere tutti i caratteri speciali non supportati da nome e/o cognome.');
        return;
      }
      this.toastr.warning('Compilare tutti i campi obbligatori.');
      return;
    }
    let medici = Array();
    this.patientForm.get("mediciSelezionati").value.forEach(medico => {
      if (undefined === medico.value.value) {
        medico.value = { value: medico.value };
      }
      medici.push(medico.value);
    });
    if (medici.length < 1) {
      this.toastr.warning("Bisogna selezionare almeno un medico.");
      return;
    }
    const pat = this.patientForm.getRawValue();
    pat.mediciSelezionati = medici;
    pat.birthDate = this.adattaData(pat.birthDate);
    pat.photo = this.patient.photo.replace('data:image/png;base64,', '');
    pat.photo = pat.photo.replace('data:image/jpg;base64,', '');
    pat.photo = pat.photo.replace('data:image/jpeg;base64,', '');

    if (this.userService.isSuperAdmin()) {
      (pat as any).organization = this.userService.getCurrentOrganization();
    }
    if (!this.id) {
      this.http.post(endpoints.addPatient, pat).toPromise().then((resp: any) => {
        if (resp) {
          if (resp.success === '1') {
            // this.selected = resp.object;
            this.patientId = resp.message;
            this.toastr.success('Paziente salvato correttamente');
          } else {
            console.error(resp.message)
            if (resp.message.length > 0) {
              this.toastr.error(resp.message);
            } else {
              this.toastr.error("Impossibile creare il nuovo paziente.");
            }
          }
        }
      }).catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Errore nel salvataggio del paziente.');
      });
    } else {
      pat.patientID = this.id;
      this.http.post(endpoints.updatePatient, pat).toPromise().then((resp: any) => {
        if (resp) {
          if (resp.success === '1') {
            // this.selected = resp.object;
            this.toastr.success('Paziente modificato correttamente');
            this.updatePazienteTab();
          } else {
            console.error(resp.message);
            if (resp.message.length > 0) {
              this.toastr.error(resp.message);
            } else {
              this.toastr.error("Impossibile salvare le modifiche apportate al paziente.");
            }
          }
        }
      }).catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Errore nel salvataggio del paziente.');
      });
    }
  }

  adattaData(date): any {
    if (typeof date === 'string') {
      const dateParts = date.split('/');
      // tslint:disable-next-line: radix
      const dateToTrans = new Date(Number.parseInt(dateParts[2]), Number.parseInt(dateParts[1]) - 1, Number.parseInt(dateParts[0]));
      return this.datePipe.transform(dateToTrans, 'yyyy-MM-dd');
    } else {
      return this.datePipe.transform(date, 'yyyy-MM-dd');
    }

  }

  adattaDataSet(date: string): string {
    return this.datePipe.transform(date, 'dd/MM/yyyy');
  }

  getSanitizedPhoto() {
    if (this.patient.photo) {
      let prefix = '';
      if (!this.patient.photo.includes('data:image/')) {
        prefix = 'data:image/png;base64,';
      }
      this.patient.photo = prefix + this.patient.photo;
    } else {
      this.getBase64ImageFromUrl('./assets/img/patient.png')
        .then(result => {
          this.patient.photo = result as string;
          this.patient.photo = this.patient.photo.replace(' charset=utf-8;', '');
        })
        .catch(err => console.error(err));
    }
  }

  changeListener($event): void {
    this.readURL($event.target);
  }

  readURL(input) {
    const th = this;
    if (input.files && input.files[0]) {
      // tslint:disable-next-line: prefer-const
      let reader = new FileReader();
      reader.onload = (e) => {
        // $('#imagePreview').css('background-image', this.getSanitizedPhoto(reader.result));
        $('#imagePreview').hide();
        $('#imagePreview').fadeIn(650);
        th.patient.photo = reader.result as string;
      };
      reader.readAsDataURL(input.files[0]);
    }
  }

  mapPatientObject(pat: any) {
    this.patient.gender = capitalize(pat.gender);

    // tslint:disable-next-line: forin
    for (const identifier of pat.identifier) {
      switch (identifier.use) {
        case 'official':
          if (identifier.value) {
            this.patient.fiscal_code = identifier.value;
          }
          break;
        case 'usual':
          this.patient.identifier = identifier.value;
          break;
        default:
          break;
      }
    }

    for (const telecom of pat.telecom) {
      switch (telecom.system) {
        case 'phone':
          if (telecom.value) {
            this.patient.tel = telecom.value;
          }
          break;
        case 'email':
          if (telecom.value) {
            this.patient.email = telecom.value;
          }
          break;
        default:
          break;
      }
    }

    this.patient.name = this.pazientiService.getPatientNameOrEmpty();

    this.patient.surname = this.pazientiService.getPatientSurnameOrEmpty();

    if (pat.address) {
      this.patient.city = pat.address[0].city;
    }

    if (pat.photo) {
      this.patient.photo = 'data:image/png;base64,' + pat.photo[0].data;
    } else {
      this.getSanitizedPhoto();
    }

    this.patient.birthDate = this.adattaDataSet(pat.birthDate);

    this.patient.generalPractitioner = pat.generalPractitioner;

  }

  updatePazienteTab() {
    this.aggiornamento = !this.aggiornamento;
    this.update.emit(this.update);
  }


  ValidateDate(control: AbstractControl) {
    if (control.get('birthDate').value && !moment(control.get('birthDate').value, 'DD/MM/YYYY', true).isValid()) {
      this.toastr.error('Data non valida.');
    }
  }


  async getBase64ImageFromUrl(imageUrl) {
    const res = await fetch(imageUrl);
    const blob = await res.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        resolve(reader.result);
      }, false);

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });
  }

  isInDetail() {
    if (this.id) {
      return true;
    } else {
      return false;
    }
  }

}
