import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import { ButtonModule } from 'primeng/button';
import { PanelModule } from 'primeng/panel';
import { InputGroupHoverDirective } from '../directive/input-group-hover.directive';
import { NumbersOnly } from '../directive/numbers-only.directive';
import { SanitizePipe } from '../utils/sanitize.pipe';
import { PasswordChangeComponent } from './password-change/password-change.component';
import {PasswordModule} from 'primeng/password';


@NgModule({
  declarations: [
    SanitizePipe,
    InputGroupHoverDirective,
    NumbersOnly,
    PasswordChangeComponent,

  ],
  imports: [
    CommonModule,
    FormsModule,
    ShowHidePasswordModule,
    ReactiveFormsModule,
    PanelModule,
    ButtonModule,
    TooltipModule,
    PasswordModule
  ],
  exports: [
    SanitizePipe,
    InputGroupHoverDirective,
    NumbersOnly,
    ShowHidePasswordModule,
    PasswordChangeComponent
  ]
})
export class GlobalModule { }
