import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { ToastrService } from "ngx-toastr";
import { last } from "rxjs-compat/operator/last";
import { endpoints } from "src/endpoint/endpoints";
import { AlertService } from "../main/pazienti/alert-management/service/alert.service";
import { DateFromTo } from "../main/pazienti/monitoraggio/Model/date-from-to";
import { SerieType } from "../main/pazienti/monitoraggio/Utils/grafici-utils";
import { PazientiService } from "../main/pazienti/service/pazienti.service";
import { Condition } from "../model/Condition";
import { CustomizationService } from "../service/customization.service";
import { TokenService } from "../service/token.service";
import { UserService } from "../service/user-service.service";



@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  @ViewChild('op', { static: false }) op: ElementRef;

  @ViewChild('graficoSintomi', { static: false }) graficoSintomi: any;
  @ViewChild('graficoWerable', { static: false }) graficoWerable: any;
  @ViewChild('graficoDomande', { static: false }) graficoDomande: any;
  @ViewChild('graficoFarmaci', { static: false }) graficoFarmaci: any;
  @ViewChild('graficoHeader', { static: false }) graficoHeader: any;
  @ViewChild('graficoVs', { static: false }) graficoVs: any;
  @ViewChild('all', { static: false }) all: ElementRef;
  @ViewChild('fakeChart', { static: true }) fakeChart: ElementRef;


  statsHR = {
    min: 0,
    avg: 0,
    max: 0,
  };
  statsBR = {
    min: 0,
    avg: 0,
    max: 0,
  };;
  statsSys = {
    min: 0,
    avg: 0,
    max: 0,
  };;
  statsDis = {
    min: 0,
    avg: 0,
    max: 0,
  };;
  statsSpo = {
    min: 0,
    avg: 0,
    max: 0,
  };;

  paziente;
  subscriber;
  nome: string;
  datePickerConf = { isAnimated: true, adaptivePosition: true, dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue' };
  date: DateFromTo;
  locale = 'it';
  dataGrafici: Date[];
  identifier;
  checkedMenu = [
    { label: 'Oggi', checked: false, days: 0, begin: 0, name: 'oggi' },
    { label: 'Ieri', checked: false, days: 1, begin: 1, name: 'ieri' },
    { label: 'Ultimi 7 giorni', checked: false, days: 7, begin: 0, name: 'ultimi7' },
    { label: 'Ultimi 30 giorni', checked: true, days: 30, begin: 0, name: 'ultimi30' },
    { label: 'Questo Mese', checked: false, days: 0, begin: 0, begin_day: 1, name: 'questo_mese' },
    { label: 'Mese Precedente', checked: false, days: 0, begin: 0, month: 1, name: 'mese_precedente' },
  ];
  descrizione;
  listICD: Condition[];
  filterListICD: Condition[];
  epicrisi;
  clinicalImpressionId;
  listICF = [];
  filterListICF = [];
  valutazioneMonitoraggio;

  observations = [];
  rows = [];
  clinicalImpressionDate: Date;
  terapie: any;
  terapieDomiciliari: any;

  stroke: ApexStroke = {
    show: true,
    curve: 'smooth',
    lineCap: 'butt',
    width: 3,
    dashArray: 0,
  };

  strokeBar: ApexStroke = {
    width: 1
  };

  hrChartArray: SerieType =
    {
      name: 'Frequenza cardiaca',
      color: ['#FA4443'],
      serie: [],
      display: ['Frequenza cardiaca'],
      type: ['area'],
      maxyaxis: 230,
      stroke: this.stroke,
      markers: false,
      fill: true
    };

  bpChartArray: SerieType =
    {
      name: 'Pressione sanguigna',
      color: ['#FA4443', '#008FFB'],
      serie: [],
      display: ['Pressione (Max)', 'Pressione (Min)'],
      type: ['area', 'area'],
      maxyaxis: 200,
      stroke: this.stroke,
      markers: false,
      fill: true
    };

  ossigenoChartArray: SerieType =
    {
      name: 'Ossigenazione',
      color: '#008FFB',
      serie: [],
      display: ['Ossigenazione'],
      type: ['area'],
      maxyaxis: 100,
      stroke: this.stroke,
      markers: false,
      fill: true
    };

  stepsChartArray: SerieType =
    {
      name: 'Passi',
      color: '#008FFB',
      serie: [],
      display: ['Passi'],
      type: ['bar'],
      maxyaxis: 30000,
      stroke: this.strokeBar,
      markers: false,
      fill: true
    };

  xAxis = {
    /*type: 'datetime',
    labels: {
      offsetX: -15
    }*/
    type: 'datetime',
    labels: {
      rotate: -45,
      rotateAlways: false,
      offsetX: -15,
      style: {
        fontSize: '12px'
      }
    }
  };

  yAxis = {
    max: 4,
    min: 0,
    tickAmount: 4,
    labels: {
      offsetX: 5,
      style: {
        color: '#008FFB',
        // fontSize: '' + fontSize,
      },
      formatter: (y) => {
        if (typeof y !== 'undefined' && y === 3) {
          return 'Completo';
        } else if (typeof y !== 'undefined' && y === 1) {
          return 'Incompleto';
        } else {
          return '';
        }
      },
      minWidth: 60,
      maxWidth: 160
    }
  };

  capeChartArray: SerieType =
    {
      name: 'Capecitabina',
      color: '#008FFB',
      serie: [],
      display: ['Capecitabina'],
      type: ['scatter'],
      markers: true,
      yaxis: this.yAxis,
      xaxis: this.xAxis,
      fill: false,
      grid: true
    };

  chartB: ApexCharts;
  chartD: ApexCharts;

  // Variabili che utilizzo
  idPaziente = ""; // Viene ricevuto tramite URL
  patient: any = {}; // Usando l'id nell'URL viene richiesto al server
  condizioniCliniche = Array();
  punteggioNLAB = undefined;
  descrizioneAnamnesiPatologicaProssima = "";
  descrizioneAnamnesiPatologicaRemota = "";
  terapieFarmacologiche = new Array();
  listaFarmaci = new Array();
  dataPresaInCarico = null;
  token = ""; // Viene ricevuto dall'URL
  inizioOsservazione = null;
  fineOsservazione = null; // Viene ricevuto dall'URL. Formato yyymmddyyymmdd
  crea = false; // In caso di errore fa visualizzare la pagina bianca
  nomiFarmaci = new Array(); // Per gestire i grafici dei farmaci
  avvisi = new Array();
  avvisiVerificati = new Array();
  avvisiNonVerificatiInPrimaPagina = true;
  // 0 -> tutto sulla prima pagina e una pagina per quelli visualizzati
  // 1 -> avvisi non visualizzati sulla seconda pagina e visualizzati sulla terza
  // 2 -> una pagina con avvisi visualizzati e non
  layout = 1;
  layoutPrimaPagina = 0;
  NUMERO_ALERT = 6;
  lunghezzaDescrizioni = 0;

  domandaPSA;

  testLog = "";

  constructor(public pazientiService: PazientiService, private toastr: ToastrService,
    public route: ActivatedRoute, public localeService: BsLocaleService,
    public datePipe: DatePipe, private http: HttpClient, private userService: UserService,
    private router: Router, private tokenService: TokenService,
    private alertService: AlertService, private customizationService: CustomizationService) { }


  ngOnInit() {
    // recupero informazioni dall'url
    this.route.queryParamMap.subscribe((params) => {
      if (null === params.get("id") || null === params.get("token") || null === params.get("date")) {
        if (null === params.get("id")) {
          console.error("id utente non passato.");
        }
        if (null === params.get("token")) {
          console.error("token non passato.");
        }
        if (null === params.get("date")) {
          console.error("date non passato.");
        }
        console.error("Non sono stati passati tutti i parametri obbligatori.");
        // Non fa vedere niente
        this.crea = false;
        return;
      }
      this.aggiornaIdPaziente(params.get("id"));
      this.token = params.get("token");
      this.tokenService.saveToken({ access_token: this.token });
      this.setDateOsservazione(params.get("date"));
      this.http.get(endpoints.me).subscribe(resp => {
        if (resp) {
          this.userService.saveUser(resp);
          // Recupero le informazioni del paziente usando solo l'id
          this.getListaICD();
          this.getStatoDiSalute();
          // Questa parte dipende dall'oggetto paziente
          this.pazientiService.getPatientFromServerByPatientId(this.idPaziente).then(resp => {
            this.pazientiService.setPatient(resp);
            this.patient = this.pazientiService.getPatient();
            if (this.patient.birthDate.includes("T")) {
              this.patient.birthDate = this.patient.birthDate.split("T")[0];
              this.patient.birthDate = this.patient.birthDate.split("/").reverse().join("/");
            }
            this.aggiornaCampiPaziente(this.patient);
            this.setDataPresaInCarico();
            this.domandaPSA = this.customizationService.getQuestionariGraficiByName("psa");
          });
          // Gestione alert
          this.http.get(endpoints.getCountAlertByPatientIdByCountByInterval + "/" + this.idPaziente + '/' + this.NUMERO_ALERT + '/' + this.getParametroIntervalloQueryFromParams(params.get("date"))).toPromise()
            .then((resp: any) => {
              if (resp.entry) {
                this.avvisi = this.createAlertArrayFromResponse(resp.entry);
              }
            });
          this.http.get(endpoints.getCountAlertByPatientIdByCountByIntervalCorrected + "/" + this.idPaziente + '/' + this.NUMERO_ALERT + '/' + this.getParametroIntervalloQueryFromParams(params.get("date"))).toPromise()
            .then((resp: any) => {
              if (resp.entry) {
                this.avvisiVerificati = this.createAlertArrayFromResponse(resp.entry);
              }
            });
        }
      }, err => {
        console.error(err);
        this.router.navigate(['/login']);
        this.tokenService.deleteToken();
        this.toastr.error('Errore nella retrive dell\'utente.');
      });
      // Ho caricato tutte le informazioni quindi posso creare
      this.crea = true;
    });
  }

  getParametroIntervalloQueryFromParams(date) {
    const dataInizio = new Date();
    dataInizio.setFullYear(date.substring(0, 4));
    dataInizio.setMonth(date.substring(4, 6));
    dataInizio.setMonth(dataInizio.getMonth() - 1);
    dataInizio.setDate(date.substring(6, 8));
    dataInizio.setDate(dataInizio.getDate() - 1);
    const meseInizio = (dataInizio.getMonth() + 1 > 9) ? dataInizio.getMonth() + 1 : "0" + (dataInizio.getMonth() + 1);
    const giornoInizio = (dataInizio.getDate() > 9) ? dataInizio.getDate() : "0" + (dataInizio.getDate());
    let str = "date=gt" + dataInizio.getFullYear() + "-" + meseInizio + "-" + giornoInizio;
    const dataFine = new Date();
    dataFine.setFullYear(date.substring(8, 12));
    dataFine.setMonth(date.substring(12, 14));
    dataFine.setMonth(dataFine.getMonth() - 1);
    dataFine.setDate(date.substring(14, 16));
    dataFine.setDate(dataFine.getDate() + 1);
    const meseFine = (dataFine.getMonth() + 1 > 9) ? dataFine.getMonth() + 1 : "0" + (dataFine.getMonth() + 1);
    const giornoFine = (dataFine.getDate() > 9) ? dataFine.getDate() : "0" + (dataFine.getDate());
    str += "&date=lt" + dataFine.getFullYear() + "-" + meseFine + "-" + giornoFine;
    return str;
  }

  gestioneLayout() {
    if (this.avvisi !== undefined && this.avvisiVerificati !== undefined) {
      if (this.avvisi.length > 0 && this.avvisiVerificati.length > 0) {
        // Dato che ci sono entrambe le liste, vanno di sicuro in una pagina a parte
        this.layout = 1;
      }
    }

    const cardAnamnesi = document.getElementById("cardAnamnesi");
    const cardTerapie = document.getElementById("cardTerapie");
    const cardCondizioniCliniche = document.getElementById("cardCondizioniCliniche");
    const cardStatoSalute = document.getElementById("cardStatoSalute");

    // Controllo che siano stati renderizzati tutti i campi
    if (cardAnamnesi === null ||
      cardTerapie === null ||
      cardCondizioniCliniche === null ||
      cardStatoSalute === null) {
      return;
    }

    if (cardAnamnesi.offsetHeight + cardTerapie.offsetHeight > 700) {
      this.layoutPrimaPagina = 1;
    } else {
      // Può entrare tutto nella prima pagina
      this.layoutPrimaPagina = 0;
      // Potrebbero entrare anche gli avvisi
      if ((cardAnamnesi.offsetHeight + cardTerapie.offsetHeight < 550) &&
        (cardCondizioniCliniche.offsetHeight + cardStatoSalute.offsetHeight < 550) &&
        (this.avvisi.length === 0 || this.avvisiVerificati.length === 0)) {
        this.layout = 0;
      }
    }
    this.layout = 1;
  }

  createAlertArrayFromResponse(entry): Array<any> {
    const toReturn = new Array();
    entry.forEach(en => {
      toReturn.push(this.createAlertObjectFromResource(en.resource));
    });
    return toReturn;
  }

  createAlertObjectFromResource(resource) {
    return {
      misura: this.alertService.getTypeMeasure(resource.code.coding[0].code),
      valore: resource.valueQuantity.value,
      dataRilevazione: this.getItalianStringDateFromDateObject(new Date(resource.effectiveDateTime)),
      messaggioAlert: this.getMessaggioAlert(resource.referenceRange[0].low.value, resource.referenceRange[0].high.value, resource.valueQuantity.value),
      livello: this.getLivelloAlert(resource.referenceRange[0].low.value, resource.referenceRange[0].high.value, resource.valueQuantity.value)
    }
  }

  /**
   * Si gestisce in base al 10% di tolleranza.
   * 1 = giallo -> non supera il 10%
   * 2 = rosso -> supera il 10%
   */
  getLivelloAlert(minimo, massimo, valore) {
    valore = Number(valore);
    minimo = Number(minimo);
    massimo = Number(massimo);

    if (valore < minimo) {
      if (valore < (minimo / 10 * 90)) {
        return 2;
      } else {
        return 1;
      }
    } else {
      if (valore > (massimo / 110 * 10)) {
        return 2;
      } else {
        return 1;
      }
    }
  }

  getMessaggioAlert(minimo, massimo, valore) {
    valore = Number(valore);
    minimo = Number(minimo);
    massimo = Number(massimo);
    if (valore < minimo) {
      return "Valore sotto la soglia impostata di " + minimo;
    } else if (valore > massimo) {
      return "Valore sopra la soglia impostata di " + massimo;
    }
    // Si potrebbe fare tutto in una riga
    // return "Valore " + (valore < minimo ? "sotto" : "sopra") + " la soglia impostata di " + (valore < minimo ? minimo : massimo);
  }

  setNumeroPagina() {
    const pagine = document.getElementsByClassName("numeroPagina");
    for (let indice = 0; indice < pagine.length; indice++) {
      pagine[indice].textContent = (indice + 1) + "/" + pagine.length;
    }
  }

  aggiornaCampiPaziente(paziente) {
    this.pazientiService.setPatient(paziente);
    // Converto la data
    paziente.birthDate = this.getItalianDateFromPatient(paziente.birthDate);
    // Converto il genere
    paziente.gender = this.getTraduzioneGenere(paziente.gender);
    // Estraggo il codice fiscale
    const tmpCF = paziente.identifier.find(i => i.use === "official");
    paziente.cf = tmpCF.value === null ? "" : tmpCF.value;
    // Estraggo l'identificativo per il titolo
    paziente.identificativo = (paziente.identifier.find(i => i.use === "usual")).value;
    // Estraggo il nominativo
    paziente.nominativo = this.pazientiService.getPatientNameOrEmpty() + " " + this.pazientiService.getPatientSurnameOrEmpty();
    // Estraggo il numero di cellulare
    const tempCell = paziente.telecom.find(t => t.system === "phone");
    paziente.cell = tempCell !== null ? tempCell.value : "";
    // Estraggo l'email
    paziente.email = (paziente.telecom.find(t => t.system === "email")).value;
  }

  getItalianDateFromPatient(d) {
    const toReturn = d.split('-');
    return "" + toReturn[2] + "/" + toReturn[1] + "/" + toReturn[0];
  }

  getTraduzioneGenere(genere) {
    switch (genere) {
      case "male":
        return "Maschio";
      case "female":
        return "Femmina";
      case "other":
        return "Altro";
      default:
        return "Sconosciuto";
    }
  }

  aggiornaIdPaziente(id) {
    this.identifier = id;
    this.idPaziente = id;
  }

  setDateOsservazione(date) {
    this.inizioOsservazione = date.substring(6, 8) + "/" + date.substring(4, 6) + "/" + date.substring(0, 4);
    this.fineOsservazione = date.substring(14, 16) + "/" + date.substring(12, 14) + "/" + date.substring(8, 12);
    // Setto anche l'oggetto che rappresenta questo periodo
    const tempDateInizio = new Date();
    tempDateInizio.setFullYear(date.substring(0, 4));
    tempDateInizio.setMonth(Number(date.substring(4, 6) - 1));
    tempDateInizio.setDate(date.substring(6, 8));
    this.setDateTimeTo0(tempDateInizio);
    const tempDateFine = new Date();
    tempDateFine.setFullYear(date.substring(8, 12));
    tempDateFine.setMonth(Number(date.substring(12, 14) - 1));
    tempDateFine.setDate(date.substring(14, 16));
    this.setDateTimeTo0(tempDateFine);
    this.date = new DateFromTo(tempDateInizio, tempDateFine);
  }

  setDateTimeTo0(date: Date) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
  }

  getListaICD() {
    let tempAnamnesiPatologica;
    this.pazientiService.getListaICDRequest(this.idPaziente).then((resp: any) => {
      let tempICD = resp.message;
      tempICD = tempICD.filter(elem => {
        if (elem.code.coding[0].code === 'DescrizioneAnamnesiPatologicaRemota' ||
          elem.code.coding[0].code === 'DescrizioneAnamnesiPatologicaProssima') {
          return false;
        } else {
          return true;
        }
      });
      tempICD.forEach(obj => {
        this.condizioniCliniche.push(obj.code.coding[0].code + " - " + obj.code.coding[0].display);
      });
      // Recupero le descrizioni delle anamnesi patologiche evitando di fare un'altra chiamata
      tempAnamnesiPatologica = resp.message.find(elem => elem.code.coding[0].code === 'DescrizioneAnamnesiPatologicaProssima');
      if (tempAnamnesiPatologica !== undefined && document.getElementById("anamnesiPatologicaProssima") !== null) {
        document.getElementById("anamnesiPatologicaProssima").innerHTML = '<b>Anamnesi patologica prossima:</b><br>' + this.gestisciFormatoDescrizione(tempAnamnesiPatologica.code.coding[0].display);
      }
      this.pazientiService.getAnamnesiPatologicaRemotaRequest(this.idPaziente).then((resp: any) => {
        let tempAnamnesiPatologicaRemota = resp.message.find(elem => elem.code.coding[0].code === 'DescrizioneAnamnesiPatologicaRemota');
        if (document.getElementById("anamnesiPatologicaProssima") !== null && tempAnamnesiPatologica !== undefined) {
          document.getElementById("anamnesiPatologicaProssima").innerHTML = '<b>Anamnesi patologica prossima:</b><br>' + this.gestisciFormatoDescrizione(tempAnamnesiPatologica.code.coding[0].display);
        }
        if (document.getElementById("anamnesiPatologicaRemota") !== null && tempAnamnesiPatologicaRemota !== undefined) {
          document.getElementById("anamnesiPatologicaRemota").innerHTML = '<b>Anamnesi patologica remota:</b><br>' + this.gestisciFormatoDescrizione(tempAnamnesiPatologicaRemota.code.coding[0].display);
        }
      });
    }).catch(err => {
      console.error(err);
      this.toastr.error('Impossibile caricare le condizioni cliniche.');
    });
  }

  gestisciFormatoDescrizione(descrizione) {
    this.lunghezzaDescrizioni += descrizione.length;
    descrizione = descrizione.replaceAll('<p><br></p>', '');
    descrizione = descrizione.replaceAll('<br>', '');
    return descrizione;
  }

  getStatoDiSalute() {
    this.http.get(endpoints.getAllClinicalImpressionByPatientId + '?patientId=' + this.identifier)
      .toPromise().then((resp: any) => {
        if (resp) {
          if (resp.message.entry) {
            this.clinicalImpressionId = resp.message.entry[resp.message.entry.length - 1].resource.id;
            const anamnesiPatologica = resp.message.entry[resp.message.entry.length - 1].resource.summary;
            this.setInformazioniPrimaVisita(resp.message.entry[0]);
            this.getFindingICF();
            this.getMedicationRequests();
            this.getTerapie();
            this.setPunteggioNLAB();
            const lastVisit = resp.message.entry[resp.message.entry.length - 1].resource
            if (lastVisit) {
              if (lastVisit.note !== undefined) {
                this.valutazioneMonitoraggio = lastVisit.note[0].text;
              }
            }

          }
        }
      })
      .catch(err => {
        console.error(err);
        this.toastr.error('Impossibile caricare lo stato di salute.', 'Errore');
      });
  }

  setPunteggioNLAB() {
    // Recupero l'id del questionario
    this.http.post(endpoints.getQuestionnaireByNameByVersion, { nome: 'NLAB', versione: '1' }).toPromise()
      .then((resp: any) => {
        const idQuestionario = resp.id
        // Provo a prendere quello dell'ultima visita
        this.http.post(endpoints.getClinicalImpressionSurveyResponse, { clinicalImpressionId: this.clinicalImpressionId, questionnaireId: idQuestionario, patientId: this.idPaziente }).toPromise()
          .then((resp: any) => {
            this.punteggioNLAB = resp.item.find(r => r.linkId === "44").answer[0].valueInteger;
          }).catch(err => {
            if (err.status === 404) {
              // Se non c'è, vedo se c'è quello senza visita
              this.http.post(endpoints.getClinicalImpressionSurveyResponseNLAB, { questionnaireId: idQuestionario, patientId: this.idPaziente }).toPromise()
                .then((resp: any) => {
                  this.punteggioNLAB = resp.item.find(r => r.linkId === "44").answer[0].valueInteger;
                }).catch(err => {
                  // Se non c'è nemmeno quello allora lascio vuoto
                  this.punteggioNLAB = undefined;
                });
            } else {
              console.error(err);
              this.toastr.error('Errore nel caricamento delle risposte del questionario NLAB.');
            }
          });
      });
  }

  setDataPresaInCarico() {
    this.dataPresaInCarico = this.estraiDataAmmissione(this.patient);
  }

  setInformazioniPrimaVisita(visita) {
    return;
    this.dataPresaInCarico = this.getItalianStringDateFromDateObject(new Date(visita.resource.date));
  }

  getItalianStringDateFromDateObject(obj: Date, separator = "/"): String {
    let tempMonth = "" + (obj.getMonth() + 1);
    tempMonth = Number(tempMonth) < 10 ? "0" + tempMonth : tempMonth;
    let tempDay = "" + obj.getDate();
    tempDay = Number(tempDay) < 10 ? "0" + tempDay : tempDay;
    return tempDay + separator + tempMonth + separator + obj.getFullYear();
  }

  getFindingICF() {
    this.http.get(endpoints.getFindingICFVisite + '?clinicalImpressionId=' + this.clinicalImpressionId)
      .toPromise().then((resp: any) => {
        if (resp) {
          this.listICF = resp.message;
        }
        const tempICF = this.listICF.slice(0, 4);
        tempICF.forEach(icf => {
          this.filterListICF.push(icf.item[0].code.coding[0].code + icf.item[0].valueString + " - " + icf.item[0].code.coding[0].display);
        });
      })
      .catch(err => {
        console.error(err);
        this.toastr.error('Impossibile caricare lo stato di salute.', 'Errore');
      });
  }

  getMedicationRequests() {
    this.http.post(endpoints.getMedicationRequestForVisit, { clinicalImpressionId: this.clinicalImpressionId }).toPromise()
      .then((resp: any) => {
        if (resp) {
          this.listaFarmaci = new Array();
          resp.forEach(f => {
            // Nome farmaco
            let temp = f.medicationCodeableConcept.coding[0].display;
            if (temp.includes("Lonsurf")) {
              this.nomiFarmaci.push("Lonsurf / Tas 102")
            } else {
              this.nomiFarmaci.push(temp);
            }
            // Stato
            temp += ', stato: ' + this.getStatoFromFarmaco(f);
            // Periodo
            temp += ', periodo: ' + this.getPeriodoFromFarmaco(f);
            // Frequenza
            temp += ', frequenza: ' + this.getFrequenzaFromFarmaco(f);
            // Aggiungo alla lista dei farmaci
            this.listaFarmaci.push(temp);
          });
        } else {
          this.toastr.error('Errore nel caricamento dei farmaci.');
          console.error('Errore: ', resp);
        }
      }).catch(err => {
        console.error(err);
        this.toastr.error('Errore nel caricamento dei Dati.');
      });
  }

  getTerapie() {
    this.http.get(endpoints.getMedicationRequests + this.clinicalImpressionId).toPromise()
      .then((resp: any) => {
        if (resp) {
          if (resp.success === '1') {
            let lunghezza = 0;
            resp.message.forEach(terapia => {
              if (terapia.medicationCodeableConcept.coding[0].code === 'therapy') {
                lunghezza += terapia.medicationCodeableConcept.text.length;
                document.getElementById('descrizioneTerapie').innerHTML = "<b>Terapie:</b><br>" + this.gestisciFormatoDescrizione(terapia.medicationCodeableConcept.text);
              } else if (terapia.medicationCodeableConcept.coding[0].code === 'therapy_at_home') {
                lunghezza += terapia.medicationCodeableConcept.text.length;
                document.getElementById('descrizioneTerapieDomiciliari').innerHTML = "<b>Terapie domiciliari:</b><br>" + this.gestisciFormatoDescrizione(terapia.medicationCodeableConcept.text);
              }
            });
          } else {
            this.toastr.error('Errore nel caricamento delle terapie.');
            console.error('Errore: ', resp);
          }
        } else {
          this.toastr.error('Errore nel caricamento delle terapie.');
          console.error('Errore: ', resp);
        }
      }).catch(err => {
        console.error(err);
        this.toastr.error('Errore nel caricamento dei Dati.');
      });
  }

  getStatoFromTerapiaFarmacologica(farmaco) {
    switch (farmaco.resource.status) {
      case "active":
        return "attivo";
      case "stopped":
        return "interrotto";
      case "on-hold":
        return "sospeso";
      case "completed":
        return "completato";
    }
  }

  getPeriodoFromTerapiaFarmacologica(farmaco) {
    const startDate = this.getItalianStringDateFromDateObject(new Date(farmaco.resource.effectivePeriod.start));
    const endDate = this.getItalianStringDateFromDateObject(new Date(farmaco.resource.effectivePeriod.end));
    switch (this.getStatoFromTerapiaFarmacologica(farmaco)) {
      case "attivo":
        return startDate;
      default:
        return startDate + " - " + endDate;
    }

  }

  getStatoFromFarmaco(farmaco): String {
    switch (farmaco.status) {
      case "active":
        return "attivo";
      case "completed":
        return "completo";
      case "stopped":
        return "interrotto";
      case "on-hold":
        return "sospeso";
      default:
        return "non definito";
    }
  }

  getPeriodoFromFarmaco(farmaco) {
    const startDate = this.getItalianStringDateFromDateObject(new Date(farmaco.dispenseRequest.validityPeriod.start));
    const endDate = this.getItalianStringDateFromDateObject(new Date(farmaco.dispenseRequest.validityPeriod.end));
    return startDate + " - " + endDate;
  }

  getFrequenzaFromFarmaco(farmaco) {
    let temp = farmaco.dosageInstruction[0].timing;
    if (temp.event) {
      return "mensile";
    } else if (temp.repeat.dayOfWeek) {
      return "settimanale";
    } else {
      return "giornaliero";
    }
  }

  getTerapieFarmacologiche() {
    this.http.get(endpoints.getTerapieFarmacologicheByPatientId + this.idPaziente).toPromise()
      .then((resp: any) => {
        this.terapieFarmacologiche = new Array();
        resp.entry.forEach(farmacoServer => {
          this.terapieFarmacologiche.push(farmacoServer.resource.medicationCodeableConcept.text + ", stato: " + this.getStatoFromTerapiaFarmacologica(farmacoServer) + ", periodo: " + this.getPeriodoFromTerapiaFarmacologica(farmacoServer));
        });
      }).catch(err => {
        this.terapieFarmacologiche = new Array();
        console.error(err);
        this.toastr.error('Errore nel caricamento delle terapie farmacologiche.', 'ERRORE');
      });
  }

  ngOnDestroy() {
    //this.subscriber.unsubscribe();
  }

  getMeasure(type, ms) {
    if (type == "HR") {
      this.statsHR = ms;
    } else if (type == "SYS") {
      this.statsSys.min = ms.min.split('/')[0];
      this.statsSys.avg = ms.avg.split('/')[0];
      this.statsSys.max = ms.max.split('/')[0];
      this.statsDis.min = ms.min.split('/')[1];
      this.statsDis.avg = ms.avg.split('/')[1];
      this.statsDis.max = ms.max.split('/')[1];
    } else if (type == "BR") {
      this.statsBR = ms;
    } else if (type == "SPO") {
      this.statsSpo = ms;
    }
  }

  private estraiDataAmmissione(patientIn) {
    if (patientIn.extension && patientIn.extension.length > 1) {
      return patientIn.extension.find(elem => {
        if (elem.url && elem.url.includes('extensions#admissiondate')) {
          if (elem.valueDateTime) {
            if (elem.valueDateTime.includes("T")) {
              elem.valueDateTime = elem.valueDateTime.split("T")[0];
            }
            elem.valueDateTime = elem.valueDateTime.split("-").reverse().join("/");
          } else {
            elem.valueDateTime = new Date();
          }
          return elem.valueDateTime;
        }
      })?.valueDateTime ?? undefined;
    }
    return undefined;
  }
}
