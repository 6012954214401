import { environment } from 'src/environments/environment';
import { getIndexSuffixByMeasureId } from 'src/app/model/MappingElastic';

export const endpointsChart = {
    searchSelfReportScores: environment.elastic + '/self-report-scores/scores/_search',
    searchSelfReportResponses: environment.elastic + '/self-report-responses/responses/_search',
    searchEventsResponses: environment.elastic + '/events-responses/responses/_search',
    searchHeloHr: environment.elastic + '/helo-hr-measures/measures/_search',
    searchHeloBp: environment.elastic + '/helo-bp-measures/measures/_search',
    searchHeloBreath: environment.elastic + '/helo-breath-measures/measures/_search',
    searchHeloSteps: environment.elastic + '/helo-steps-measures/measures/_search',
    searchHeloMood: environment.elastic + '/helo-mood-measures/measures/_search',
    searchHeloFatigue: environment.elastic + '/helo-fatigue-measures/measures/_search',
    searchHeloOxigen: environment.elastic + '/helo-oxygen-measures/measures/_search',
    searchHeloSleep: environment.elastic + '/helo-sleep-measures/measures/_search',
    searchICFs: environment.resourceServer + '/api/getICFsByPatientId?patientId=',
    searchVyvoCovidHr: environment.elastic + '/vyvo-covid19-hr-measures/measures/_search',
    searchVyvoCovidOxigen: environment.elastic + '/vyvo-covid19-spo2-measures/measures/_search',
    searchNemoHr: environment.elastic + '/vyvo-covid19-nemo-hr-measures/measures/_search',
    searchNemoOxigen: environment.elastic + '/vyvo-covid19-nemo-spo2-measures/measures/_search',

};


export function getEndpointsMeasure(measure: string, organization: string): string {
    return environment.elastic + '/' + organization.toLowerCase() + '-' + getIndexSuffixByMeasureId(measure) + '/_search';
}

export function getEndpointsData(measure: string, organization: string): string {
    return environment.elastic + '/' + organization.toLowerCase() + '-' + measure + 's/_search';
}

export function getEndpointsEvents( organization: string): string {
    return environment.elastic + '/' + organization.toLowerCase() + '-events/_search';
}