<form [formGroup]="statoDiSaluteForm" class="p-fluid row form-inline">
    <div class="col-md-12 form-group">
        <div class="col-md-4">
            <label class="float-left">Deambulazione</label>
        </div>
        <div class="col-md-5">
            <p-dropdown [options]="deambulazioneOpt" formControlName="57251-1" dropdownIcon="fas fa-chevron-down">
            </p-dropdown>
        </div>
    </div>
    <div class="col-md-12 form-group">
        <div class="col-md-4">
            <label class="float-left">Autonomia</label>
        </div>
        <div class="col-md-5">
            <p-dropdown [options]="autonomiaOpt" formControlName="autonomia" dropdownIcon="fas fa-chevron-down">
            </p-dropdown>
        </div>
    </div>
    <div class="col-md-12 form-group">
        <div class="col-md-4">
            <label class="float-left">Nutrizione</label>
        </div>
        <div class="col-md-5">
            <input formControlName="nutrizione" type="text" placeholder="Nutrizione" pInputText />
        </div>
    </div>
    <div class="col-md-12 form-group">
        <div class="col-md-4">
            <label class="float-left">Piaghe da Decubito</label>
        </div>
        <div class="col-md-5">
            <p-dropdown [options]="siNoOpt" formControlName="72527-5" dropdownIcon="fas fa-chevron-down"></p-dropdown>
        </div>
    </div>
    <div class="col-md-12 form-group">
        <div class="col-md-4">
            <label class="float-left">Alvo</label>
        </div>
        <div class="col-md-5">
            <p-dropdown [options]="alvoOpt" formControlName="80263-7" dropdownIcon="fas fa-chevron-down"></p-dropdown>
        </div>
    </div>
    <div class="col-md-12 form-group">
        <div class="col-md-4">
            <label class="float-left">Diuresi e minzione</label>
        </div>
        <div class="col-md-5">
            <p-dropdown [options]="diuresiMinzioneOpt" formControlName="80332-0" dropdownIcon="fas fa-chevron-down">
            </p-dropdown>
        </div>
    </div>
    <div class="col-md-12 form-group">
        <div class="col-md-4">
            <label class="float-left">Sonno</label>
        </div>
        <div class="col-md-5">
            <p-dropdown [options]="sonnoOpt" formControlName="82235-3" dropdownIcon="fas fa-chevron-down"></p-dropdown>
        </div>
    </div>
</form>
<div class="row mt-2">
    <div class="col-md-12">
        <div class="float-right">
            <button *ngIf="checkPermessoModifica()" pButton label="Salva" icon="fa fa-save" iconPos="left" id="saveStileDiVita"
                class="p-button-success" type="button" (click)="salva()"></button>
        </div>
    </div>
</div>