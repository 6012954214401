import { FormGroup, FormControl } from '@angular/forms';

export function markAllAsUntouched(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
        const control = formGroup.get(key);
        if (control instanceof FormControl) {
            control.markAsUntouched();
        }
    });
}

export function markAllAsTouched(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
        const control = formGroup.get(key);
        if (control instanceof FormControl) {
            control.markAsTouched();
        }
    });
}

export function markAllAsValid(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
        const control = formGroup.get(key);
        if (control instanceof FormControl) {
            control.setErrors(null);

        }
    });
}

export function updateValueAndValidity(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
        const control = formGroup.get(key);
        if (control instanceof FormControl) {
            control.updateValueAndValidity();
        }
    });
}

export function generateRandom(num: number): any[] {
    const random = [];
    for (let i = 0; i < num; i++) {
        random.push('_' + Math.random().toString(36).substr(2, 9));
    }
    return random;
}

export const capitalize = (s) => {
    if (typeof s !== 'string') {
        return '';
    }
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export function getLabelFromOptions(value: string, options): string {
    return options.forEach(el => {
        if (el.value === value) {
            return el.label;
        }
    });
}

export function isNullOrEmpty(str: string): boolean {
    return (str === undefined || str === '' || str === null);
}

export function getFirstProperty(obj: any) {
    let restituito = false;
    for (const prop in obj) {
        if (!restituito) {
            restituito = true;
            return obj[prop];
        }
    }
}
