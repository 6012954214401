import { BreakpointObserver } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  mdAppObs;

  mdPlusObs;

  smallObs;

  interObs;

  sidebarCollapse;

  patientListFrom = new BehaviorSubject<string>(null);

  listSections;


  typeViewList = new BehaviorSubject<string>(null);

  constructor(public breakpointObserver: BreakpointObserver, private http: HttpClient) {
    this.mdAppObs = this.breakpointObserver.observe(['(max-width: 1360px)']);
    this.sidebarCollapse = this.breakpointObserver.observe(['(max-width: 1370px)']);
    this.mdPlusObs = this.breakpointObserver.observe(['(max-width: 1510px)']);
    this.smallObs = this.breakpointObserver.observe(['(max-width: 992px)']);
    this.interObs = this.breakpointObserver.observe(['(min-width: 640px) and (max-width: 1120px)']);
  }

  setPatientListFrom(from: string) {
    this.patientListFrom.next(from);
  }

  getPatientListFrom() {
    return this.patientListFrom.value;
  }

  getPatientListFromObs() {
    return this.patientListFrom;
  }

  getLocalSections() {
    return this.listSections;
  }

  setListSections(list) {
    this.listSections = list;
  }

  setTypeViewList(type) {
    this.typeViewList.next(type);
  }

  getTypeViewList() {
    return this.typeViewList.value;
  }

  getTypeViewListBehavior() {
    return this.typeViewList;
  }

}
