import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SelectItem } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { UserService } from 'src/app/service/user-service.service';
import { endpoints } from 'src/endpoint/endpoints';
import { PazientiService } from '../../service/pazienti.service';
import { DateFromTo } from '../Model/date-from-to';
import { getBarChartOptions } from '../Utils/apexchart-opt';
import { getSeriesDataValue, SerieType } from '../Utils/grafici-utils';

@Component({
  selector: 'app-grafici-farmaci',
  templateUrl: './grafici-farmaci.component.html',
  styleUrls: ['./grafici-farmaci.component.scss']
})
export class GraficiFarmaciComponent implements OnInit, OnDestroy {

  @Input() identifier;

  @Input('date') set date(date: DateFromTo) {
    if (!date.compare(this._date)) {
      this.observer.next(date);
    }
  }

  // Usato solo per il report
  @Input() showHeader: boolean;
  @Input() tipoGrafico;
  @Input() altezzaGrafico;
  @Input() larghezzaGrafico;

  // tslint:disable-next-line: variable-name
  _date: DateFromTo;

  observer: BehaviorSubject<DateFromTo>;

  dateFromString: string;
  dateToString: string;
  dateFromTimeStamp;
  dateToTimeStamp;

  opt: SelectItem[];

  selected = 'Regorafenib';

  @ViewChild('chart', { static: true }) chartRef: ElementRef;

  chart: ApexCharts;

  patientId;

  xAxis = {
    /*type: 'datetime',
    labels: {
      offsetX: -15
    }*/
    type: 'datetime',
    labels: {
      rotate: -45,
      rotateAlways: false,
      offsetX: -15,
      style: {
        fontSize: '12px'
      }
    }
  };

  yAxis = {
    max: 4,
    min: 0,
    tickAmount: 4,
    labels: {
      offsetX: 5,
      style: {
        color: '#008FFB',
        // fontSize: '' + fontSize,
      },
      formatter: (y) => {
        if (typeof y !== 'undefined' && y === 3) {
          return 'Completo';
        } else if (typeof y !== 'undefined' && y === 1) {
          return 'Incompleto';
        } else {
          return '';
        }
      },
      minWidth: 60,
      maxWidth: 160
    }
  };

  grid: ApexGrid = {
    position: 'back',
    xaxis: {
      lines: {
        show: false
      }
    },
    yaxis: {
      lines: {
        show: false
      }
    },
    row: {
      colors: ['#17a2b8', '#17a2b8', '#ffc107', '#ffc107'],
      opacity: 0.5
    }
  };

  capeChartArray: SerieType =
    {
      name: 'Capecitabina',
      color: '#008FFB',
      serie: [],
      display: ['Capecitabina'],
      type: ['scatter'],
      markers: true,
      yaxis: this.yAxis,
      xaxis: this.xAxis,
      fill: false,
      grid: true
    };

  constructor(private http: HttpClient, private toastr: ToastrService, private datePipe: DatePipe, private userService: UserService,private patientService: PazientiService) {
    this.observer = new BehaviorSubject<DateFromTo>(null);
  }

  ngOnInit() {
    const paziente = this.patientService.getPatient();
    this.patientId = paziente.id;
    if (this.showHeader === undefined) {
      this.showHeader = true;
    }
    this.observer.subscribe((input) => {
      if (this.identifier && input) {
        this._date = input;
        this.dateFromString = this.datePipe.transform(input.getDateFrom(), 'yyyy-MM-dd');
        this.dateToString = this.datePipe.transform(input.getDateTo(), 'yyyy-MM-dd');
        this.dateFromTimeStamp = Math.round(input.getDateFrom().getTime());
        this.dateToTimeStamp = Math.round(input.getDateTo().getTime());
        this.dropdownChange(this.selected);
      }
    });
    this.opt = [
      { label: 'Regorafenib', value: 'Regorafenib' },
      { label: 'Capecitabina', value: 'Capecitabina' },
      { label: 'Lonsurf/Tas 102', value: 'Lonsurf/Tas 102' }
    ];

    if (this.tipoGrafico !== undefined) {
      this.dropdownChange(this.tipoGrafico);
      this.selected = this.tipoGrafico;
    }
  }

  ngOnDestroy(): void {
    this.observer.unsubscribe();
  }

  dropdownChange(event) {
    switch (event) {
      case 'Capecitabina':
        this.createChart(this.capeChartArray, 'CapecitabinaChartID', 'Capecitabina');
        break;
      case 'Regorafenib':
        this.createChart(this.capeChartArray, 'RegorafenibChartID', 'Regorafenib');
        break;
      case 'Lonsurf/Tas 102':
        this.createChart(this.capeChartArray, 'LonsurfChartID', 'Lonsurf/Tas 102');
        break;
      default:
        break;
    }
  }

  createChartDataURI(chartArray: SerieType, id, title, date, identifier, chartref) {
    return new Promise((resolve, reject) => {
      this.identifier = identifier;
      this.dateFromString = this.datePipe.transform(date.getDateFrom(), 'yyyy-MM-dd');
      this.dateToString = this.datePipe.transform(date.getDateTo(), 'yyyy-MM-dd');

      this.getData(chartArray.name).then((response: any) => {
        const series: ApexAxisChartSeries = [];
        let seriesLabels = null;
        let labels = [];
        if (response.hits && response.hits.hits && response.hits.hits.length > 0) {
          seriesLabels = getSeriesDataValue(response, chartArray.display, chartArray.type, chartArray.name);
          if (Array.isArray(seriesLabels.serie)) {
            seriesLabels.serie.forEach(element => {
              series.push(element);
            });
          } else {
            series.push(seriesLabels.serie);
          }
          labels = seriesLabels.labels;
        }
        if (series && series.length === 0) {
          chartArray.display.forEach((el, i) => {
            series.push({ data: [], type: chartArray.type[i], name: el });
          });
        }
        const chartArr = new Array<SerieType>();
        chartArr.push(chartArray);
        // tslint:disable-next-line: max-line-length
        const options = getBarChartOptions(id, title, series, chartArr, labels, chartArray.yaxis, chartArray.stroke, chartArray.markers, chartArray.fill, chartArray.grid);
        options.grid = this.grid;

        this.chart = new ApexCharts(chartref.nativeElement, options);
        this.chart.render();
        resolve(this.chart);

      }).catch(err => {
        console.error(err);
        this.toastr.error('Errore nel recupero dei dati.');
      });
    });
  }

  createChart(chartArray: SerieType, id, title) {
    chartArray.name = title;
    this.getData(chartArray.name).then((response: any) => {
      const series: ApexAxisChartSeries = [];
      let seriesLabels = null;
      let labels = [];
      if (response.hits && response.hits.hits && response.hits.hits.length > 0) {
        seriesLabels = getSeriesDataValue(response, chartArray.display, chartArray.type, chartArray.name);
        if (Array.isArray(seriesLabels.serie)) {
          seriesLabels.serie.forEach(element => {
            series.push(element);
          });
        } else {
          series.push(seriesLabels.serie);
        }
        labels = seriesLabels.labels;
      }
      if (series && series.length === 0) {
        chartArray.display.forEach((el, i) => {
          series.push({ data: [], type: chartArray.type[i], name: el });
        });
      }
      const chartArr = new Array<SerieType>();
      chartArr.push(chartArray);
      // tslint:disable-next-line: max-line-length
      const options = getBarChartOptions(id, title, series, chartArr, labels, chartArray.yaxis, chartArray.stroke, chartArray.markers, chartArray.fill, chartArray.grid);
      if (this.altezzaGrafico !== undefined) {
        options.chart.height = this.altezzaGrafico;
      }
      if (this.larghezzaGrafico !== undefined) {
        options.chart.width = this.larghezzaGrafico;
      }
      options.grid = this.grid;
      if (this.chart) {
        this.chart.updateOptions(options, false, false, false);
      } else {
        this.chart = new ApexCharts(this.chartRef.nativeElement, options);
        this.chart.render();
      }
    }).catch(err => {
      console.error(err);
      this.toastr.error('Errore nel recupero dei dati.');
    });

  }

  getData(event) {
    if(event ==='Lonsurf/Tas 102'){
      event="L";
    }
    return this.http.get(endpoints.elasticQuery+"getFarmaco"+"/"+this.patientId+"/"+this.dateFromTimeStamp+"/"+this.dateToTimeStamp+"/"+event+"/-events").toPromise();
  }

}
