<div *ngIf="isLogged; else nonLogged">

  <div *ngIf="!isNotNew; else notNew">

    <div class="login-page">
      <form *ngIf="form" [formGroup]="form" (ngSubmit)="cambiaPassword()">
        <div class="login-box" style="width: 100%">
          <div class="login-logo">
            <img style="width: 22rem; margin-left: -1%;" src="assets/img/Logo-Login1.png" alt="" />
          </div>
          <!-- /.login-logo -->
          <span class="p-fluid">
            <div class="row mb-3 align-middle text-center">
              <div class="col-12">
                <h4 *ngIf="!this.showToLogin && !this.isPaziente">
                  Cambio password
                </h4>
              </div>
            </div>
            <h4 style="text-align: center;" *ngIf="this.showToLogin && this.isPaziente">
              Password aggiornata correttamente.<br>
              Ora è possibile effettuare il login sull'applicazione.
            </h4>
            <h4 style="text-align: center;" *ngIf="this.showToLogin && !this.isPaziente">
              Password aggiornata correttamente
            </h4>
            <div class="mb-3" *ngIf="!this.showToLogin && !this.isPaziente">
              <!-- TODO Mettere icona font awesome come specifica -->
              <!-- <span class="p-inputgroup-addon">
                  <i class="fas fa-lock"></i>
                </span> -->
              <p-password formControlName="password" [feedback]="false" [toggleMask]="true">
                <!-- <input formControlName="password" id="inputPassword{{randomEle[0]}}" placeholder="Inserisci password" /> -->
              </p-password>

              <div class="invalid-text"
                *ngIf="form.get('password').invalid && (form.get('password').dirty || form.get('password').touched)">
                <!-- <span>{{form.errors | json}}</span> -->
                <!-- <span>{{form.get('password').errors | json}}</span> -->
                <!-- <p>{{form.get('password').errors | json}}</p> -->
                <p
                  *ngIf="getFirstOfObject(form.get('password').errors) && getFirstKey(form.get('password').errors) == 'required'">
                  Inserire una password.
                </p>
                <p
                  *ngIf="getFirstOfObject(form.get('password').errors) && getFirstKey(form.get('password').errors) == 'hasNumber'">
                  Deve contenere almeno 1 numero!
                </p>
                <p
                  *ngIf="getFirstOfObject(form.get('password').errors) && getFirstKey(form.get('password').errors) == 'hasCapitalCase'">
                  Deve contenere almeno 1 Maiuscola!
                </p>
                <p
                  *ngIf="getFirstOfObject(form.get('password').errors) && getFirstKey(form.get('password').errors) == 'hasSmallCase'">
                  Deve contenere almeno 1 Minuscola!
                </p>
                <p
                  *ngIf="getFirstOfObject(form.get('password').errors) && getFirstKey(form.get('password').errors) == 'hasSpecialCharacters'">
                  Deve contenere almeno 1 Carattere Speciale!
                </p>
                <p
                  *ngIf="getFirstOfObject(form.get('password').errors) && getFirstKey(form.get('password').errors) == 'sequenceInvalid'">
                  Sequenza non valida! No numeriche (1234), o alfabetiche (abcd) o qwerty
                </p>
                <p
                  *ngIf="getFirstOfObject(form.get('password').errors) && getFirstKey(form.get('password').errors) == 'minlength'">
                  Lunghezza almeno 10 caratteri!
                </p>
                <p
                  *ngIf="getFirstOfObject(form.get('password').errors) && getFirstKey(form.get('password').errors) == 'maxlength'">
                  Lunghezza massima 16 caratteri!
                </p>
                <p
                  *ngIf="getFirstOfObject(form.get('password').errors) && getFirstKey(form.get('password').errors) == 'historyInvalid'">
                  La password digitata è uguale a quella precedente
                </p>
                <p
                  *ngIf="getFirstOfObject(form.get('password').errors) && getFirstKey(form.get('password').errors) == 'usernameInvalid'">
                  La password non può contenere lo username
                </p>
              </div>

            </div>

            <div class="mb-3" *ngIf="!this.showToLogin && !this.isPaziente">
              <!-- <span class="p-inputgroup-addon">
                  <i class="fas fa-lock"></i>
                </span> -->
              <p-password formControlName="confirmPassword" [feedback]="false" [toggleMask]="true">
                <!-- <input formControlName="confirmPassword" id="inputConfirmPassword{{randomEle[0]}}"
                  placeholder="Conferma password" /> -->
              </p-password>
            </div>
            <div class="invalid-text"
              *ngIf="form.get('confirmPassword').invalid && (form.get('confirmPassword').dirty || form.get('confirmPassword').touched)">
              <!-- <p>{{form.get('confirmPassword').errors | json}}</p> -->
              <p
                *ngIf="getFirstOfObject(form.get('confirmPassword').errors) && getFirstKey(form.get('confirmPassword').errors) == 'NoPassswordMatch'">
                La password non corrisponde
              </p>
            </div>

            <div class="row">
              <div class="col-6">
              </div>
              <div class="col-6">
                <button pButton type="submit" *ngIf="!this.showToLogin && !this.isPaziente"
                  class="p-button-raised p-button-rounded p-button-small" label="Salva Password"></button>
              </div>
              <!-- /.col -->
            </div>
          </span>
        </div>
        <!-- /.login-box -->
      </form>
      <button pButton type="button" *ngIf="this.showToLogin && !this.isPaziente"
        class="p-button-raised p-button-rounded p-button-small mt-2" (click)="toLogin()"
        label="{{this.getUserDetail() ? 'Ritorna al login' : 'Vai alla home'}}"></button>
    </div>

  </div>
  <ng-template #notNew>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Cambio Password</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a routerLink="/main">Home</a></li>
              <li class="breadcrumb-item active">Cambio Password</li>
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-md-6">
            <p-panel>
              <p-header>
                Inserisci Nuova Password
              </p-header>
              <form *ngIf="form" [formGroup]="form" class="p-fluid">
                <div class="row mb-2">
                  <div class="input-group">
                    <div class="input-group-prepend col-3">
                      <span class="input-group-text col-12">Password</span>
                    </div>
                    <p-password formControlName="password" [feedback]="false" [toggleMask]="true">
                      <!-- <input formControlName="password" id="inputPassword{{randomEle[0]}}"
                        placeholder="Inserisci password" /> -->
                    </p-password>
                  </div>
                </div>

                <div class="invalid-text"
                  *ngIf="form.get('password').invalid && (form.get('password').dirty || form.get('password').touched)">
                  <!-- <span>{{form.errors | json}}</span> -->
                  <!-- <span>{{form.get('password').errors | json}}</span> -->
                  <!-- <p>{{form.get('password').errors | json}}</p> -->
                  <p
                    *ngIf="getFirstOfObject(form.get('password').errors) && getFirstKey(form.get('password').errors) == 'required'">
                    Inserire una password.
                  </p>
                  <p
                    *ngIf="getFirstOfObject(form.get('password').errors) && getFirstKey(form.get('password').errors) == 'hasNumber'">
                    Deve contenere almeno 1 numero!
                  </p>
                  <p
                    *ngIf="getFirstOfObject(form.get('password').errors) && getFirstKey(form.get('password').errors) == 'hasCapitalCase'">
                    Deve contenere almeno 1 Maiuscola!
                  </p>
                  <p
                    *ngIf="getFirstOfObject(form.get('password').errors) && getFirstKey(form.get('password').errors) == 'hasSmallCase'">
                    Deve contenere almeno 1 Minuscola!
                  </p>
                  <p
                    *ngIf="getFirstOfObject(form.get('password').errors) && getFirstKey(form.get('password').errors) == 'hasSpecialCharacters'">
                    Deve contenere almeno 1 Carattere Speciale!
                  </p>
                  <p
                    *ngIf="getFirstOfObject(form.get('password').errors) && getFirstKey(form.get('password').errors) == 'sequenceInvalid'">
                    Sequenza non valida! No numeriche (1234), o alfabetiche (abcd) o qwerty
                  </p>
                  <p
                    *ngIf="getFirstOfObject(form.get('password').errors) && getFirstKey(form.get('password').errors) == 'minlength'">
                    Lunghezza almeno 10 caratteri!
                  </p>
                  <p
                    *ngIf="getFirstOfObject(form.get('password').errors) && getFirstKey(form.get('password').errors) == 'maxlength'">
                    Lunghezza massima 16 caratteri!
                  </p>
                  <p
                    *ngIf="getFirstOfObject(form.get('password').errors) && getFirstKey(form.get('password').errors) == 'historyInvalid'">
                    La password digitata è uguale a quella precedente
                  </p>
                  <p
                    *ngIf="getFirstOfObject(form.get('password').errors) && getFirstKey(form.get('password').errors) == 'usernameInvalid'">
                    La password non può contenere lo username
                  </p>
                </div>


                <div class="row mb-2">
                  <div class="input-group">
                    <div class="input-group-prepend col-3">
                      <span class="input-group-text col-12">Conferma password</span>
                    </div>
                    <p-password formControlName="confirmPassword" [feedback]="false" [toggleMask]="true">
                      <!-- <input formControlName="confirmPassword" id="inputConfirmPassword{{randomEle[0]}}"
                        placeholder="Conferma password" /> -->
                    </p-password>
                  </div>

                </div>
                <div class="invalid-text"
                  *ngIf="form.get('confirmPassword').invalid && (form.get('confirmPassword').dirty || form.get('confirmPassword').touched)">
                  <!-- <p>{{form.get('confirmPassword').errors | json}}</p> -->
                  <p
                    *ngIf="getFirstOfObject(form.get('confirmPassword').errors) && getFirstKey(form.get('confirmPassword').errors) == 'NoPassswordMatch'">
                    La password non corrisponde
                  </p>
                </div>
                <span style="text-align:right" class="row justify-content-end mt-2">
                  <div class="col-md-4 mt-2">
                    <p-button type="submit" class="p-button-success" (click)="salva()" label="Salva password">
                    </p-button>
                  </div>
                </span>

              </form>
            </p-panel>
          </div>
        </div>
      </div>
    </section>

  </ng-template>
</div>

<ng-template #nonLogged>

  <div class="login-page">
    <form *ngIf="form" [formGroup]="form" (ngSubmit)="cambiaPassword()">
      <div class="login-box" style="width: 100%">
        <div class="login-logo">
          <img style="width: 22rem; margin-left: -1%;" src="assets/img/Logo-Login1.png" alt="" />
        </div>
        <!-- /.login-logo -->
        <span class="p-fluid">
          <div class="row mb-3 align-middle text-center">
            <div class="col-12">
              <h4 *ngIf="!this.showToLogin && !this.isPaziente">
                Cambio password
              </h4>
            </div>
          </div>
          <h4 style="text-align: center;" *ngIf="this.showToLogin && this.isPaziente">
            Password aggiornata correttamente.<br>
            Ora è possibile effettuare il login sull'applicazione.
          </h4>
          <h4 style="text-align: center;" *ngIf="this.showToLogin && !this.isPaziente">
            Password aggiornata correttamente
          </h4>
          <div class="mb-3" *ngIf="!this.showToLogin && !this.isPaziente">

            <!-- TODO Mettere icona font awesome come specifica -->
            <!-- <span class="p-inputgroup-addon">
                <i class="fas fa-lock"></i>
              </span> -->
            <p-password formControlName="password" [feedback]="false" [toggleMask]="true">
              <!-- <input formControlName="password" id="inputPassword{{randomEle[0]}}" placeholder="Inserisci password" /> -->
            </p-password>

            <div class="invalid-text"
              *ngIf="form.get('password').invalid && (form.get('password').dirty || form.get('password').touched)">
              <!-- <span>{{form.errors | json}}</span> -->
              <!-- <span>{{form.get('password').errors | json}}</span> -->
              <!-- <p>{{form.get('password').errors | json}}</p> -->
              <p
                *ngIf="getFirstOfObject(form.get('password').errors) && getFirstKey(form.get('password').errors) == 'required'">
                Inserire una password.
              </p>
              <p
                *ngIf="getFirstOfObject(form.get('password').errors) && getFirstKey(form.get('password').errors) == 'hasNumber'">
                Deve contenere almeno 1 numero!
              </p>
              <p
                *ngIf="getFirstOfObject(form.get('password').errors) && getFirstKey(form.get('password').errors) == 'hasCapitalCase'">
                Deve contenere almeno 1 Maiuscola!
              </p>
              <p
                *ngIf="getFirstOfObject(form.get('password').errors) && getFirstKey(form.get('password').errors) == 'hasSmallCase'">
                Deve contenere almeno 1 Minuscola!
              </p>
              <p
                *ngIf="getFirstOfObject(form.get('password').errors) && getFirstKey(form.get('password').errors) == 'hasSpecialCharacters'">
                Deve contenere almeno 1 Carattere Speciale!
              </p>
              <p
                *ngIf="getFirstOfObject(form.get('password').errors) && getFirstKey(form.get('password').errors) == 'sequenceInvalid'">
                Sequenza non valida! No numeriche (1234), o alfabetiche (abcd) o qwerty
              </p>
              <p
                *ngIf="getFirstOfObject(form.get('password').errors) && getFirstKey(form.get('password').errors) == 'minlength'">
                Lunghezza almeno 10 caratteri!
              </p>
              <p
                *ngIf="getFirstOfObject(form.get('password').errors) && getFirstKey(form.get('password').errors) == 'maxlength'">
                Lunghezza massima 16 caratteri!
              </p>
              <p
                *ngIf="getFirstOfObject(form.get('password').errors) && getFirstKey(form.get('password').errors) == 'historyInvalid'">
                La password digitata è uguale a quella precedente
              </p>
              <p
                *ngIf="getFirstOfObject(form.get('password').errors) && getFirstKey(form.get('password').errors) == 'usernameInvalid'">
                La password non può contenere lo username
              </p>
            </div>

          </div>

          <div class="mb-3" *ngIf="!this.showToLogin && !this.isPaziente">
            <!-- <span class="p-inputgroup-addon">
                <i class="fas fa-lock"></i>
              </span> -->
            <p-password formControlName="confirmPassword" [feedback]="false" [toggleMask]="true">
              <!-- <input formControlName="confirmPassword" id="inputConfirmPassword{{randomEle[0]}}"
                placeholder="Conferma password" /> -->
            </p-password>
          </div>
          <div class="invalid-text"
            *ngIf="form.get('confirmPassword').invalid && (form.get('confirmPassword').dirty || form.get('confirmPassword').touched)">
            <!-- <p>{{form.get('confirmPassword').errors | json}}</p> -->
            <p
              *ngIf="getFirstOfObject(form.get('confirmPassword').errors) && getFirstKey(form.get('confirmPassword').errors) == 'NoPassswordMatch'">
              La password non corrisponde
            </p>
          </div>
          <div class="row">
            <div class="col-6">
            </div>
            <div class="col-6">
              <button pButton type="submit" *ngIf="!this.showToLogin && !this.isPaziente"
                class="p-button-raised p-button-rounded p-button-small" label="Salva Password"></button>
            </div>
            <!-- /.col -->
          </div>
        </span>
      </div>
      <!-- /.login-box -->
    </form>
    <button pButton type="button" *ngIf="this.showToLogin && !this.isPaziente"
      class="p-button-raised p-button-rounded p-button-small mt-2" (click)="toLogin()"
      label="{{this.getUserDetail() ? 'Ritorna al login' : 'Vai alla home'}}"></button>
  </div>

</ng-template>