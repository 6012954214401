import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgApexchartsModule } from 'ng-apexcharts';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { GlobalModule } from 'src/app/global/global.module';
import { GraficoIcfHeaderComponent } from '../monitoraggio-icf/grafico-icf-header/grafico-icf-header.component';
import { GraficiDomandeComponent } from '../monitoraggio/grafici-domande/grafici-domande.component';
import { GraficiFarmaciComponent } from '../monitoraggio/grafici-farmaci/grafici-farmaci.component';
import { GraficiSintomiComponent } from '../monitoraggio/grafici-sintomi/grafici-sintomi.component';
import { GraficiVsComponent } from '../monitoraggio/grafici-vs/grafici-vs.component';
import { GraficiWerableComponent } from '../monitoraggio/grafici-werable/grafici-werable.component';
import { MonitoraggioHeaderComponent } from '../monitoraggio/monitoraggio-header/monitoraggio-header.component';



@NgModule({
  declarations: [
    MonitoraggioHeaderComponent,
    GraficiSintomiComponent,
    GraficiWerableComponent,
    GraficiDomandeComponent,
    GraficiFarmaciComponent,
    GraficoIcfHeaderComponent,
    GraficiVsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    GlobalModule,
    OverlayPanelModule,
    BsDatepickerModule.forRoot(),
    MenuModule,
    ButtonModule,
    ToggleButtonModule,
    NgApexchartsModule,
    ProgressSpinnerModule,
    DropdownModule,
  ], exports: [
    MonitoraggioHeaderComponent,
    GraficiSintomiComponent,
    GraficiWerableComponent,
    GraficiDomandeComponent,
    GraficiFarmaciComponent,
    GraficiVsComponent,
    GraficoIcfHeaderComponent
  ]
})
export class SharedGraficiModule { }
