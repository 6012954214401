import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PasswordChangeComponent } from './global/password-change/password-change.component';
import { LoginComponent } from './login/login.component';
import { ReportComponent } from './report/report.component';
import { ReportDataResolver } from './report/ReportDataResolver';


const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'global/password-change', component: PasswordChangeComponent },
  { path: 'report', component: ReportComponent, resolve: { datiReport: ReportDataResolver } },
  { path: '', redirectTo: '/main/pazienti/listapazienti', pathMatch: 'full', },
  { path: '**', redirectTo: '/main/pagenotfound' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
