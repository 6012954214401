<form [formGroup]="devicesForm" class="p-fluid row form-inline" *ngIf="devices">
    <!-- Portatore di CVC -->
    <div class="col-md-12 form-group">
        <div class="col-md-4">
            <label class="float-left" for="52124006">Portatore di CVC:</label>
        </div>
        <div class="col-md-5">
            <p-dropdown id="52124006" [options]="activeInactiveOptPortatoreCVC" formControlName="52124006"
                dropdownIcon="fas fa-chevron-down"></p-dropdown>
        </div>
    </div>
    <!-- ./Portatore di CVC -->
    <!-- Portatore di stomia -->
    <div class="col-md-12 form-group">
        <div class="col-md-4">
            <label class="float-left" for="35398009">Portatore di stomia:</label>
        </div>
        <div class="col-md-5">
            <p-dropdown id="35398009" [options]="activeInactiveOptStomia" formControlName="35398009"
                dropdownIcon="fas fa-chevron-down"></p-dropdown>
        </div>
    </div>

    <div class="col-md-12 form-group" *ngIf="this.org === 'NEMO'">
        <div class="col-md-4">
            <label class="float-left" for="35398009">Portatore di catetere vescicale:</label>
        </div>
        <div class="col-md-5">
            <p-dropdown id="35398009" [options]="activeInactiveOptVescicale" formControlName="20568009"
                dropdownIcon="fas fa-chevron-down"></p-dropdown>
        </div>
    </div>

    <div class="col-md-12 form-group" *ngIf="this.org === 'NEMO'">
        <div class="col-md-4">
            <label class="float-left" for="35398009">Portatore di sondino gastrico:</label>
        </div>
        <div class="col-md-5">
            <p-dropdown id="35398009" [options]="activeInactiveOptSondino" formControlName="25062003"
                dropdownIcon="fas fa-chevron-down"></p-dropdown>
        </div>
    </div>

    <div class="col-md-12 form-group" *ngIf="this.org === 'NEMO'">
        <div class="col-md-4">
            <label class="float-left" for="35398009">Portatore di PEG/RIG:</label>
        </div>
        <div class="col-md-5">
            <p-dropdown id="35398009" [options]="activeInactiveOptPeg" formControlName="235159007"
                dropdownIcon="fas fa-chevron-down"></p-dropdown>
        </div>
    </div>

    <div class="col-md-12 form-group" *ngIf="this.org === 'NEMO'">
        <div class="col-md-4">
            <label class="float-left" for="35398009">Portatore di CVP:</label>
        </div>
        <div class="col-md-5">
            <p-dropdown id="35398009" [options]="activeInactiveCVP" formControlName="398176008"
                dropdownIcon="fas fa-chevron-down"></p-dropdown>
        </div>
    </div>

    <div class="col-md-12 form-group" *ngIf="this.org === 'NEMO'">
        <div class="col-md-4">
            <label class="float-left" for="35398009">Utilizzo NIV:</label>
        </div>
        <div class="col-md-5">
            <p-dropdown id="35398009" [options]="activeInactiveNIV" formControlName="713655003"
                dropdownIcon="fas fa-chevron-down"></p-dropdown>
        </div>
    </div>

    <div class="col-md-12 form-group" *ngIf="this.org === 'NEMO'">
        <div class="col-md-4">
            <label class="float-left" for="35398009">Portatore di tracheostomia:</label>
        </div>
        <div class="col-md-5">
            <p-dropdown id="35398009" [options]="activeInactiveTreacheostomia" formControlName="129121000"
                dropdownIcon="fas fa-chevron-down"></p-dropdown>
        </div>
    </div>

    <div class="col-md-12 form-group" *ngIf="this.org === 'NEMO'">
        <div class="col-md-4">
            <label class="float-left" for="35398009">Utilizzo di assistente tosse:</label>
        </div>
        <div class="col-md-5">
            <p-dropdown id="35398009" [options]="activeInactiveTosse" formControlName="334986003"
                dropdownIcon="fas fa-chevron-down"></p-dropdown>
        </div>
    </div>
    <!-- ./Portatore di stomia -->
    <!-- Portatore di altro -->
    <div class="col-md-12 form-group">
        <div class="col-md-4">
            <label for="other_device" class="float-left">Portatore di altro:</label>
        </div>
        <div class="col-md-5">
            <input formControlName="other_device" type="text" id="other_device" placeholder="Portatore di altro"
                pInputText />
        </div>
    </div>
    <!-- ./Portatore di altro -->
    <!-- Dispositivo di monitoraggio -->
    <div class="col-md-12 form-group">
        <div class="col-md-4">
            <label for="account_monitoring_device" class="float-left">Dispositivo di monitoraggio:</label>
        </div>
        <div class="col-md-5 form-group">
            <div class="col-md-6 pr-1 p-0">
                <input formControlName="account_monitoring_device" type="text" id="account_monitoring_device"
                    placeholder="Watch-Lite-SE-0000" pInputText />
            </div>
            <div class="col-md-6 pl-1 p-0">
                <p-inputMask formControlName="mac_monitoring_device" type="text" id="mac_monitoring_device"
                    placeholder="MAC dispositivo" mask="**:**:**:**:**:**"
                    style="text-transform: uppercase;"></p-inputMask>
            </div>
        </div>
        <p-button label="Associa" icon="fa fa-link" iconPos="left" class="p-button-success" type="button"
            (click)="salvaDispositivi()" *ngIf="checkPermessoModifica()" style="align-self: flex-start;"></p-button>
    </div>
    <div class="col-md-12 form-group">
        <div class="col-md-4">
            <label for="mobile_app" class="float-left">Mobile app:</label>
        </div>
        <div class="col-md-5 form-group">
            <div class="col-md-6 pr-1 p-0">
                <input type="text" class="form-control" placeholder="Nome utente" formControlName="accountMobileApp"
                    style="width: 100%;">
            </div>
            <div class="col-md-6 pl-1 p-0">
                <input type="text" class="form-control" placeholder="Password" formControlName="passwordMobileApp"
                    style="width: 100%;">
            </div>
        </div>
        <p-button type="button" label="Genera credenziali" style="align-self: flex-start;"
            *ngIf="showGeneraCredenzialiButton" (onClick)="sycronizePatient()">
        </p-button>
    </div>

    <!-- ./Dispositivo di monitoraggio -->
    <!-- Tasto salva -->
    <div class="col-md-12">
        <div class="float-right">
            <button pButton label="Salva" icon="fa fa-save" iconPos="left" class="p-button-success" type="button"
                (click)="salvaDispositivi()" *ngIf="checkPermessoModifica()"></button>
        </div>
    </div>
    <!-- ./Tasto salva -->
</form>