import { BreakpointState } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LogEventService } from 'src/app/main/pazienti/service/log-event.service';
import { PazientiService } from 'src/app/main/pazienti/service/pazienti.service';
import { AppService } from 'src/app/service/app.service';
import { QuestionnaireService } from 'src/app/service/questionnaire-service.service';
import { UserService } from 'src/app/service/user-service.service';
import { endpoints } from 'src/endpoint/endpoints';

@Component({
  selector: 'app-scale-di-valutazione-percorsocovid19',
  templateUrl: './scale-di-valutazione-percorsocovid19.component.html',
  styleUrls: ['./scale-di-valutazione-percorsocovid19.component.scss']
})
export class ScaleDiValutazionePERCORSOCOVID19Component implements OnInit {

  // Per gestire il mobile
  isMobile = false;
  observer;

  clinicalImpressionId;
  idQuestionario;

  domande;

  isVisit = false;
  patientId;

  obbligatorie;

  @Output()
  statusQuestionnaire = new EventEmitter<boolean>();

  constructor(private toastr: ToastrService, private userService: UserService, private pazientiService: PazientiService,
              private http: HttpClient, private route: ActivatedRoute, private appServ: AppService, private questionariService: QuestionnaireService,
              private logService: LogEventService) { }

  ngOnInit() {
    this.getIsInVisite();
    this.getQuestionario();
    this.observer = this.appServ.mdAppObs
      .subscribe((statex: BreakpointState) => {
        this.isMobile = statex.matches;
      });
    this.patientId = this.pazientiService.getPatientId();

    this.obbligatorie = new Array();
    this.obbligatorie.push({testo: 'Sintomatologia respiratoria COVID19', valore: 'true'});
    this.obbligatorie.push({testo: 'Esito', valore: 'false'});
  }

  // Solo se non esiste nel database. Attenzione a non creare duplicati!!!
  creaQuestionario() {
    this.questionariService.createQuestionnaire(QuestionnaireService.PERCORSOCOVID19);
  }

  // Usare solo quando è realmente necessario
  aggiornaQuestionario() {
    this.questionariService.updateQuestionnaire(QuestionnaireService.PERCORSOCOVID19, this.idQuestionario);
  }

  getIsInVisite() {
    const cId = this.route.parent.parent.snapshot.paramMap.get('vId');
    if (cId) {
      this.isVisit = true;
      this.clinicalImpressionId = cId;
    }
  }

  getQuestionario() {
    const obj = {
      nome: 'PercorsoCOVID19',
      versione: '1'
    };
    this.http.post(endpoints.getQuestionnaireByNameByVersion, obj).toPromise()
      .then((resp: any) => {
        this.idQuestionario = resp.id;
        this.domande = new Array();
        resp.item.forEach(item => {
          let domanda: any;
          domanda = {};
          domanda.testo = item.text;
          domanda.tipo = item.type;
          domanda.linkId = item.linkId;
          domanda.risposta = undefined;
          domanda.mostraSottodomande = false;
          domanda.risposte = new Array();
          if (domanda.tipo === 'integer') {
            if (item.option) {
              item.option.forEach(opt => {
                domanda.risposte.push({
                  label: opt.valueCoding.display,
                  value: opt.valueCoding.code
                });
              });
            } else {
              if (item.item) {
                this.gestisciSottodomande(domanda, item);
              }
            }
            if (item.item) {
              if (item.option) {
                item.item.forEach(extra => {
                  const optId = extra.linkId.split('extra')[1];
                  const dom = domanda.risposte.find(d => ('' + d.value) === ('' + optId));
                  dom.extras = new Array();
                  if (extra.option) {
                    extra.option.forEach(optex => {
                      switch (extra.type) {
                        case 'quantity':
                          dom.extras.push({
                            value: undefined,
                            label: optex.valueCoding.display,
                            tipo: extra.type
                          });
                          break;
                        case 'string':
                          dom.extras.push({
                            value: undefined,
                            label: undefined,
                            tipo: extra.type
                          });
                          break;
                        case 'integer':
                          dom.tipoExtra = 'integer';
                          dom.extras.push({
                            label: optex.valueCoding.display,
                            value: optex.valueCoding.code
                          });
                          extra.risposta = undefined;
                          break;
                      }
                    });
                  } else {
                    dom.extras.push({
                      value: undefined,
                      label: undefined,
                      tipo: extra.type
                    });
                  }
                });
              }
            }
          } else if (domanda.tipo === 'boolean') {
            domanda.risposte.push({
              value: 'true',
              label: 'SI'
            });
            domanda.risposte.push({
              value: 'false',
              label: 'NO'
            });
            this.gestisciSottodomande(domanda, item);
          }
          this.domande.push(domanda);
        });
        this.getRisposte();
      });
  }

  aggiungiStrutturaExtra(sd, lista) {
    let sdConExtra = lista.find(d => ('' + d.linkId) === ('' + sd.linkId.split('extra')[1]));
    if (sdConExtra === undefined) {
      lista.forEach(sottodomanda => {
        if (sottodomanda.risposte && sdConExtra === undefined) {
          sdConExtra = sottodomanda.risposte.find(d => ('' + d.value) === ('' + sd.linkId.split('extra')[1]));
        }
      });
      if (sdConExtra === undefined) {
        console.warn('Non è stato caricato un extra.');
        return;
      }
    }
    sdConExtra.extra = {
      tipo: sd.type,
      testo: (sd.text ? sd.text : ''),
      risposta: undefined
    };
  }

  gestisciSottodomande(domanda, item) {
    domanda.sottodomande = new Array();
    item.item.forEach(sd => {
      if (sd.linkId.includes('extra')) {
        this.aggiungiStrutturaExtra(sd, domanda.sottodomande);
      } else {
        domanda.sottodomande.push(this.creaSottodomanda(sd));
      }
    });
  }

  creaSottodomanda(sd) {
    let sottodomanda: any;
    sottodomanda = {};
    sottodomanda.testo = sd.text;
    sottodomanda.linkId = sd.linkId;
    sottodomanda.tipo = sd.type;
    sottodomanda.risposta = undefined;
    sottodomanda.risposte = new Array();
    switch (sottodomanda.tipo) {
      case 'boolean':
        sottodomanda.risposte.push({
          value: 'true',
          label: 'SI'
        });
        sottodomanda.risposte.push({
          value: 'false',
          label: 'NO'
        });
        break;
      case 'string':
        sottodomanda.risposte.push({
          tipo: sd.type,
          testo: (sd.text ? sd.text : ''),
          risposta: undefined
        });
        break;
      case 'integer':
        sd.option.forEach(opt => {
          sottodomanda.risposte.push({
            label: opt.valueCoding.display,
            value: opt.valueCoding.code
          });
        });
        break;
    }
    return sottodomanda;
  }

  getRisposte() {
    let obj: any;
    obj = {};
    if (this.isVisit) {
      obj = { clinicalImpressionId: this.clinicalImpressionId, questionnaireId: this.idQuestionario };
    } else {
      obj = { patientId: this.patientId, questionnaireId: this.idQuestionario };
    }
    this.http.post(endpoints.getClinicalImpressionSurveyResponse, obj).toPromise()
      .then((resp: any) => {
        const risposte = resp.item;
        let indice = 0;
        (this.domande.filter(d => d.tipo !== 'display')).forEach(domanda => {
          if (domanda.testo.includes('Altra sintomatologia COVID19')) {
            domanda.risposta = new Array();
            risposte[indice].answer.forEach(ris => {
              domanda.risposta.push('' + ris.valueInteger);
              if (ris.item) {
                const dom = domanda.risposte.find(d => Number(d.value) === Number(ris.valueInteger));
                if (ris.item[0].answer[0].valueString) {
                  dom.extras[0].value = ris.item[0].answer[0].valueString + '';
                } else {
                  dom.risposta = ris.item[0].answer[0].valueInteger + '';
                }
              }
            });
          } else {
            switch (domanda.tipo) {
              case 'integer':
                if (risposte[indice].answer.length === 1) {
                  let dom;
                  if (domanda.risposte.length === 0 && domanda.sottodomande.length > 0) {
                    dom = domanda.sottodomande[0];
                  } else {
                    dom = domanda;
                  }
                  if (risposte[indice].answer[0].valueInteger !== undefined) {
                    dom.risposta = '' + risposte[indice].answer[0].valueInteger;
                  } else if (risposte[indice].answer[0].valueBoolean !== undefined) {
                    dom.risposta = dom.risposte.find(d => ('' + d.value) === ('' + risposte[indice].answer[0].valueBoolean));
                  }
                  if (risposte[indice].answer[0].item) {
                    if (risposte[indice].answer[0].item.length === 1) {
                      if (risposte[indice].answer[0].item[0].answer[0].valueString) {
                        // tslint:disable-next-line: max-line-length
                        domanda.risposte[Number(domanda.risposta)].extras[0].value = risposte[indice].answer[0].item[0].answer[0].valueString + '';
                      } else if (risposte[indice].answer[0].item[0].answer[0].valueInteger) {
                        // tslint:disable-next-line: max-line-length
                        domanda.risposte[Number(domanda.risposta)].risposta = risposte[indice].answer[0].item[0].answer[0].valueInteger + '';
                      }
                    }
                  }
                } else {
                  if (domanda.sottodomande) {
                    domanda.sottodomande.forEach((sottodomanda, indiceSottodomanda) => {
                      switch (sottodomanda.tipo) {
                        case 'boolean':
                          // tslint:disable-next-line: max-line-length
                          sottodomanda.risposta = sottodomanda.risposte.find(s => ('' + s.value) === ('' + risposte[indice].answer[indiceSottodomanda].valueBoolean));
                          break;
                      }
                      if (sottodomanda.extra && risposte[indice].answer[indiceSottodomanda].item) {
                        sottodomanda.extra.risposta = risposte[indice].answer[indiceSottodomanda].item[0].answer[0].valueString + '';
                      }
                    });
                  }
                }
                break;
              case 'boolean':
                domanda.risposta = (domanda.risposte.find(r => ('' + r.value) === ('' + risposte[indice].answer[0].valueBoolean)).value);
                if (risposte[indice].answer.length > 1) {
                  domanda.sottodomande.forEach((sottodomanda, indiceSottodomanda) => {
                    switch (sottodomanda.tipo) {
                      case 'string':
                        sottodomanda.risposta = risposte[indice].answer[indiceSottodomanda + 1].valueString + '';
                        break;
                      case 'integer':
                        sottodomanda.risposta = risposte[indice].answer[indiceSottodomanda + 1].valueInteger + '';
                        if (risposte[indice].answer[indiceSottodomanda + 1].item) {
                          // Solo per Esito
                          // tslint:disable-next-line: max-line-length
                          sottodomanda.risposte[Number(sottodomanda.risposta)].extra.value = risposte[indice].answer[indiceSottodomanda + 1].item[0].answer[0].valueString + '';
                        }
                        break;
                    }
                  });
                }
                break;
            }
          }
          indice++;
          this.mostrareSottodomande(domanda);
        });
        this.statusQuestionnaire.next(true);
        this.logService.getAllOpt([[resp]], null);
      }).catch(err => {
        if (err.status === 404) {
          this.statusQuestionnaire.next(false);
          this.toastr.info('Questionario non eseguito.');
          this.logService.resetGlobalVarLog();
        } else {
          console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
          this.toastr.error('Errore nel caricamento delle risposte del questionario.');
        }
      });
  }

  salva() {
    let obj: any;
    obj = {};
    if (this.isVisit) {
      obj = {
        answers: new Array(),
        questionnaireId: this.idQuestionario,
        clinicalImpressionId: this.clinicalImpressionId
      };
    } else {
      obj = {
        answers: new Array(),
        questionnaireId: this.idQuestionario,
        patientId: this.patientId
      };
    }
    try {
      (this.domande.filter(d => d.tipo !== 'display')).forEach(domanda => {
        let answer: any;
        answer = {};
        switch (domanda.tipo) {
          case 'integer':
            if (domanda.risposte.length > 0) {
              // La risposta di base sta in domanda.risposta
              if (domanda.risposta === undefined || domanda.risposta === null) {
                throw new Error('Non sono state risposte tutte le domande.');
              }
              if (domanda.testo.includes('Altra sintomatologia COVID19')) {
                // Gestione risposte multiple
                answer.valuesInteger = new Array();
                domanda.risposta.forEach(ris => {
                  answer.valuesInteger.push({valueInteger: ris + ''});
                });
                answer.valuesInteger.forEach(sottoRisposta => {
                  const risposta = domanda.risposte.find(d => Number(d.value) === Number(sottoRisposta.valueInteger));
                  if (risposta.extras) {
                    sottoRisposta.extras = this.creaExtrasPerSalvare(risposta);
                  }
                });
              } else {
                answer.valueInteger = domanda.risposta + '';
                // Controllo gli extra
                if (domanda.risposte[domanda.risposta].extras) {
                  answer.extras = this.creaExtrasPerSalvare(domanda.risposte[domanda.risposta]);
                }
              }
            } else {
              if (domanda.sottodomande) {
                answer.sottodomande = new Array();
                domanda.sottodomande.forEach((sottodomanda, indiceSottodomanda) => {
                  if (indiceSottodomanda === 0 || (indiceSottodomanda > 0 && domanda.sottodomande[0].risposta === 'true')) {
                  let sottorisposta: any;
                  sottorisposta = {};
                  switch (sottodomanda.tipo) {
                    case 'boolean':
                        if (sottodomanda.risposta === undefined || sottodomanda.risposta === null) {
                            throw new Error('Non sono state risposte tutte le domande.');
                        }
                        sottorisposta.valueBoolean = sottodomanda.risposta + '';
                        if (sottodomanda.extra && sottorisposta.valueBoolean === 'true') {
                          if (sottodomanda.extra.risposta === undefined || sottodomanda.extra.risposta === null ||
                            sottodomanda.extra.risposta.length === 0) {
                            throw new Error('Non sono state risposte tutte le sottodomande.');
                          }
                          sottorisposta.extras = new Array();
                          sottorisposta.extras.push({valueString: '' + sottodomanda.extra.risposta});
                        }
                        break;
                    }
                  answer.sottodomande.push(sottorisposta);
                  }
                });
              }
            }
            break;
          case 'boolean':
            if (domanda.risposta === undefined || domanda.risposta === null) {
              throw new Error('Non sono state risposte tutte le domande.');
            }
            answer.valueBoolean = '' + domanda.risposta;
            if (domanda.sottodomande !== undefined && domanda.mostraSottodomande) {
              answer.sottodomande = new Array();
              domanda.sottodomande.forEach(sottodomanda => {
                if (sottodomanda.risposta === undefined || sottodomanda.risposta === null || sottodomanda.risposta.length === 0) {
                  throw new Error('Non sono state risposte tutte le sottodomande.');
                }
                switch (sottodomanda.tipo) {
                  case 'string':
                    answer.sottodomande.push({valueString: sottodomanda.risposta + ''});
                    break;
                  case 'integer':
                    answer.sottodomande.push({valueInteger: sottodomanda.risposta + ''});
                    if (sottodomanda.risposte[sottodomanda.risposta].extra) {
                      if (sottodomanda.risposte[sottodomanda.risposta].extra.value === undefined ||
                        sottodomanda.risposte[sottodomanda.risposta].extra.length === 0) {
                          throw new Error('Non sono state risposte tutte le sottodomande.');
                        }
                      answer.sottodomande[answer.sottodomande.length - 1].extras = new Array();
                      // tslint:disable-next-line: max-line-length
                      answer.sottodomande[answer.sottodomande.length - 1].extras.push({valueString: '' + sottodomanda.risposte[sottodomanda.risposta].extra.value});
                    }
                    break;
                }
              });
            }
            break;
        }

        obj.answers.push({
          linkId: domanda.linkId,
          answer,
          type: domanda.tipo
        });
      });
    } catch (Exception) {
      this.toastr.error(Exception.message);
      return;
    }

    this.http.post(endpoints.addClinicalImpressionSurveyResponse, obj).toPromise()
      .then((resp: any) => {
        this.toastr.success('Salvataggio avvenuto con successo.');
        this.getQuestionario();
      }).catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Errore nel salvataggio delle risposte del questionario.');
      });
  }

  checkPermessoModifica() {
    return this.userService.checkPermission('Osservazioni', 'modify') && this.userService.checkPermission('Visite', 'modify');
  }

  creaExtrasPerSalvare(risposta) {
    const extras = new Array();
    if (risposta.tipoExtra && risposta.tipoExtra === 'integer') {
      if (risposta.risposta === undefined ||
        risposta.risposta === null) {
        throw new Error('Non sono state risposte tutte le sottodomande.');
      }
      extras.push({valueInteger: '' + risposta.risposta});
    } else {
      risposta.extras.forEach(sottorisposta => {
        switch (sottorisposta.tipo) {
          case 'quantity':
            if (sottorisposta.value === undefined || sottorisposta.value === null) {
              throw new Error('Non sono state risposte tutte le domande.');
            }
            extras.push({valueQuantity: '' + sottorisposta.value});
            break;
          case 'string':
            if (sottorisposta.value === undefined || sottorisposta.value === null) {
              throw new Error('Non sono state risposte tutte le domande.');
            }
            extras.push({valueString: '' + sottorisposta.value});
            break;
        }
      });
    }
    return extras;
  }

  mostrareSottodomande(domanda) {
    if (domanda.risposta === undefined) {
      return false;
    }
    const obbligatoria = this.obbligatorie.find(d => domanda.testo.includes(d.testo));
    domanda.mostraSottodomande = (obbligatoria !== undefined && domanda.risposta === obbligatoria.valore);
  }

}
