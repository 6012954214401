export function getInputSelfReported(dateFrom: string, dateTo: string, patId, topic: string, prefix: string) {
    const ret = {
        from: 0,
        query: {
            bool: {
                filter: [
                    {
                        term: {
                            patientId: patId
                        }
                    },
                    {
                        term: {
                            idDomanda: prefix
                        }
                    }
                ],
                must: {
                    range: {
                        timestamp: {
                            gte: dateFrom,
                            lte: dateTo
                        }
                    }
                }
            }
        },
        size: 10000,
        sort: [
            {
                timestamp: {
                    order: 'asc'
                }
            }
        ]
    };
    //ret.query.bool.filter[1].term[prefix] = topic;
    return ret;
}

export function getInputEventsReported(dateFrom: string, dateTo: string, patId, topic: string, prefix: string) {
    const ret = {
        from: 0,
        query: {
            bool: {
                filter: [
                    {
                        term: {
                            patientId: patId
                        }
                    },
                    {
                        term: {
                            // tslint:disable-next-line: object-literal-key-quotes
                            // '': topic
                        }
                    }
                ],
                must: {
                    range: {
                        data: {
                            gte: dateFrom,
                            lte: dateTo
                        }
                    }
                }
            }
        },
        size: 100000,
        sort: [
            {
                data: {
                    order: 'asc'
                }
            }
        ]
    };
    //ret.query.bool.filter[1].term[prefix] = topic;
    return ret;
}

export function getInputEventsReported1(dateFrom: string, dateTo: string, patId, topic: string, prefix: string) {
    const ret = {
        from: 0,
        query: {
            bool: {
                filter: [
                    {
                        term: {
                            patientId: patId
                        }
                    },
                    {
                        term: {
                            idEvento: topic
                        }
                    }
                ],
                must: {
                    range: {
                        timestamp: {
                            gte: dateFrom,
                            lte: dateTo
                        }
                    }
                }
            }
        },
        size: 10000,
        sort: [
            {
                timestamp: {
                    order: 'asc'
                }
            }
        ]
    };
    //ret.query.bool.filter[1].term[prefix] = topic;
    return ret;
}

export function getInputPressureMeasures(dateFrom: string, dateTo: string, patId, sourceStr: string) {
    const ret = {
        from: 0,
        query: {
            bool: {
                filter: [
                    {
                        term: {
                            patientId: patId
                        }
                    },
                    {
                        query_string:
                        {
                            query: sourceStr,
                            default_field: 'source'
                        }
                    }
                ],
                must: {
                    range: {
                        timestamp: {
                            gte: dateFrom,
                            lte: dateTo
                        }
                    }
                }
            }
        },
        size: 10000,
        sort: [
            {
                timestamp: {
                    order: 'asc'
                }
            }
        ]
    };
    return ret;
}

export function getInputHelo_mdMeasures(dateFrom, dateTo, patientId) {
    const ret = {
        from: 0,
        query: {
            bool: {
                filter: [
                    {
                        term: {
                            infoPazienteId: patientId
                        }
                    },
                ],
                must: {
                    range: {
                        measuredateTimestamp: {
                            gte: dateFrom,
                            lte: dateTo
                        }
                    }
                }
            }
        },
        size: 100000,
        sort: [
            {
                measuredateTimestamp: {
                    order: 'asc'
                }
            }
        ]
    };
    return ret;
}

export function getInputHeloGroupMeasures(dateFrom, dateTo, patientId) {
    const ret = {
        from: 0,
        query: {
            bool: {
                filter: [
                    {
                        term: {
                            infoPazienteId: patientId
                        }
                    },
                ],
                must: {
                    range: {
                        groupIndex: {
                            gte: dateFrom,
                            lte: dateTo
                        }
                    }
                }
            }
        },
        size: 100000,
        sort: [
            {
                groupIndex: {
                    order: 'asc'
                }
            }
        ]
    };
    return ret;
}

// tslint:disable-next-line: max-line-length
export function getInputSelfReportedQuestion(dateFrom: string, dateTo: string, patId, idDom: string) {
    const ret = {
        from: 0,
        query: {
            bool: {
                filter: [
                    {
                        term: {
                            patientId: patId
                        }
                    },
                    {
                        term: {
                            idDomanda: idDom
                        }
                    }
                ],
                must: {
                    range: {
                        timestamp: {
                            gte: dateFrom,
                            lte: dateTo
                        }
                    }
                }
            }
        },
        size: 10000,
        sort: [
            {
                timestamp: {
                    order: 'asc'
                }
            }
        ]
    };
    // ret.query.bool.filter[1].term[prefix] = topic;
    return ret;
}

export function getInputHrMeasures(dateFrom: string, dateTo: string, patId, sourceStr: string) {
    var diffDays = Math.ceil((parseInt(dateTo) - parseInt(dateFrom)) / (1000 * 3600 * 24));

    const ret = {
        from: 0,
        query: {
            bool: {
                filter: [
                    {
                        term: {
                            patientId: patId
                        }
                    },
                    {
                        term: {
                            code: '1'
                        }
                    },
                    {
                        query_string:
                        {
                            query: sourceStr,
                            default_field: 'source'
                        }
                    }
                ],
                must: {
                    range: {
                        timestamp: {
                            gte: dateFrom,
                            lte: dateTo
                        }
                    }
                }
            }
        },
        size: 10000,
        sort: [
            {
                timestamp: {
                    order: 'asc'
                }
            }
        ]
    };

    if (diffDays > 14 && sourceStr !== 'Checkme') {
        (ret as any).aggs = {
            date_aggs: {
                date_histogram: {
                    field: 'timestamp',
                    interval: '1h',
                    min_doc_count: 1
                },
                aggs: {
                    avg_value: {
                        avg: {
                            field: 'value'
                        }
                    }
                }
            }
        }
        ret.size = 0;
    }
    return ret;
}

export function getInputBrMeasures(dateFrom: string, dateTo: string, patId, sourceStr: string) {
    const ret = {
        from: 0,
        query: {
            bool: {
                filter: [
                    {
                        term: {
                            patientId: patId
                        }
                    },
                    {
                        term: {
                            code: '4'
                        }
                    },
                    {
                        query_string:
                        {
                            query: sourceStr,
                            default_field: 'source'
                        }
                    }
                ],
                must: {
                    range: {
                        timestamp: {
                            gte: dateFrom,
                            lte: dateTo
                        }
                    }
                }
            }
        },
        size: 10000,
        sort: [
            {
                timestamp: {
                    order: 'asc'
                }
            }
        ]
    };
    return ret;
}

export function getInputTemperatureMeasures(dateFrom: string, dateTo: string, patId, sourceStr: string) {
    const ret = {
        from: 0,
        query: {
            bool: {
                filter: [
                    {
                        term: {
                            patientId: patId
                        }
                    },
                    {
                        term: {
                            code: '14'
                        }
                    },
                    {
                        query_string:
                        {
                            query: sourceStr,
                            default_field: 'source'
                        }
                    }
                ],
                must: {
                    range: {
                        timestamp: {
                            gte: dateFrom,
                            lte: dateTo
                        }
                    }
                }
            }
        },
        size: 10000,
        sort: [
            {
                timestamp: {
                    order: 'asc'
                }
            }
        ]
    };
    return ret;
}

export function getInputFaticaMeasures(dateFrom: string, dateTo: string, patId) {
    const ret = {
        from: 0,
        query: {
            bool: {
                filter: [
                    {
                        term: {
                            patientId: patId
                        }
                    },
                    {
                        term: {
                            code: '10'
                        }
                    },
                ],
                must: {
                    range: {
                        timestamp: {
                            gte: dateFrom,
                            lte: dateTo
                        }
                    }
                }
            }
        },
        size: 10000,
        sort: [
            {
                timestamp: {
                    order: 'asc'
                }
            }
        ]
    };
    return ret;
}

export function getInputMoodMeasures(dateFrom: string, dateTo: string, patId) {
    const ret = {
        from: 0,
        query: {
            bool: {
                filter: [
                    {
                        term: {
                            patientId: patId
                        }
                    },
                    {
                        term: {
                            code: '7'
                        }
                    },
                ],
                must: {
                    range: {
                        timestamp: {
                            gte: dateFrom,
                            lte: dateTo
                        }
                    }
                }
            }
        },
        size: 10000,
        sort: [
            {
                timestamp: {
                    order: 'asc'
                }
            }
        ]
    };
    return ret;
}

export function getInputStepsMeasures(dateFrom: string, dateTo: string, patId) {
    const ret = {
        query: {
            bool: {
                filter: [
                    {
                        term: {
                            patientId: patId
                        }
                    },
                    {
                        term: {
                            code: '6'
                        }
                    },
                ],
                must: {
                    range: {
                        timestamp: {
                            gte: dateFrom,
                            lte: dateTo,
                            format: 'epoch_millis'
                        }
                    }
                }
            }
        },
        aggs: {
            stepsDays: {
                date_histogram: {
                    field: 'timestamp',
                    interval: '1d',
                    time_zone: 'Europe/Berlin',
                    min_doc_count: 1
                },
                aggs: {
                    stepsDay: {
                        sum: {
                            field: 'value'
                        }
                    }
                }
            }
        },
        size: 0,
        sort: [
            {
                timestamp: {
                    order: 'asc'
                }
            }
        ]
    };
    return ret;
}

export function getInputSpo2Measures(dateFrom: string, dateTo: string, patId, sourceStr: string) {
    const ret = {
        from: 0,
        query: {
            bool: {
                filter: [
                    {
                        term: {
                            patientId: patId
                        }
                    },
                    {
                        query_string:
                        {
                            query: sourceStr,
                            default_field: 'source'
                        }
                    }
                ],
                must: {
                    range: {
                        timestamp: {
                            gte: dateFrom,
                            lte: dateTo
                        }
                    }
                }
            }
        },
        size: 10000,
        sort: [
            {
                timestamp: {
                    order: 'asc'
                }
            }
        ]
    };
    return ret;
}

export function getInputSleepMeasures(dateFrom: string, dateTo: string, patId) {
    const ret = {
        from: 0,
        query: {
            bool: {
                filter: [
                    {
                        term: {
                            patientId: patId
                        }
                    },
                ],
                must: {
                    range: {
                        timestamp: {
                            gte: dateFrom,
                            lte: dateTo
                        }
                    }
                }
            }
        },
        size: 10000,
        sort: [
            {
                timestamp: {
                    order: 'asc'
                }
            }
        ]
    };
    return ret;
}