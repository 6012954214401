import { BreakpointState } from '@angular/cdk/layout';
import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { HelpModalComponent } from 'src/app/modals/help-modal/help-modal.component';
import { AppService } from 'src/app/service/app.service';
import { TokenService } from 'src/app/service/token.service';
import { UserService } from 'src/app/service/user-service.service';
import { AlertService } from '../pazienti/alert-management/service/alert.service';
import { PazientiService } from '../pazienti/service/pazienti.service';
// import * as adminlte from 'admin-lte/dist/js/adminlte.js';

declare var $;

@Component({
  selector: 'app-menu-sidebar',
  templateUrl: './menu-sidebar.component.html',
  styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit, AfterViewInit, OnDestroy {

  // @ViewChild('menuPazienti', { static: false }) menuPazienti: ElementRef;
  @ViewChild('sidebar', { static: false }) sidebar: ElementRef;
  @ViewChild('menuDettagli', { static: false }) menudettagli: ElementRef;

  @ViewChild('stiledivita', { static: false }) stiledivita: ElementRef;
  @ViewChild('terapiefarm', { static: false }) terapiefarm: ElementRef;
  @ViewChild('devices', { static: false }) devices: ElementRef;
  @ViewChild('statodisalute', { static: false }) statodisalute: ElementRef;
  @ViewChild('anamnesipatologicaprossima', { static: false }) anamnesipatologicaprossima: ElementRef;
  @ViewChild('anamnesipatologicaremota', { static: false }) anamnesipatologicaremota: ElementRef;
  @ViewChild('visite', { static: false }) visite: ElementRef;
  @ViewChild('monitoraggio', { static: false }) monitoraggio: ElementRef;
  @ViewChild('monitoraggioIcf', { static: false }) monitoraggioIcf: ElementRef;
  @ViewChild('alert', { static: false }) alert: ElementRef;
  @ViewChild('listapazienti', { static: false }) listapazienti: ElementRef;
  @ViewChild('listapazientiattivazione', { static: false }) listapazientiattivazione: ElementRef;


  menuPazientiOpen = false;

  patientId;
  patient;
  subscriber;
  subPat;

  subLayout;
  subSmall;
  subSidebar;

  mobile = false;
  small = false;

  dettOpen = false;

  adminOpen = false;

  routerSub;

  subscriberAlert;

  subTypeView;

  countAlerts;

  // Utente
  loggedUser;

  org;

  items;

  detailEvent = false;
  administrationEvent = false;

  aggiornaMenu: EventEmitter<boolean>;

  // tslint:disable-next-line: max-line-length
  constructor(private render: Renderer2, private router: Router, private userService: UserService, private pazientiService: PazientiService,
    // tslint:disable-next-line: max-line-length
    private tokenService: TokenService, private appSrv: AppService, private dialogService: DialogService, private alertService: AlertService) { }

  ngOnInit() {
    this.aggiornaMenu = new EventEmitter<boolean>();
    this.alertService.findCountAlert();
    this.appSrv.setTypeViewList('Pazienti presi in carico');
    this.subscriber = this.pazientiService.patientId.subscribe(id => this.patientId = id);
    this.subPat = this.pazientiService.patient.subscribe(pat => { this.patient = pat; this.refreshMenu(); });
    this.subscriberAlert = this.alertService.alerts.subscribe(alerts => this.countAlerts = alerts);
    this.subLayout = this.appSrv.mdAppObs
      .subscribe((statex: BreakpointState) => {
        if (statex.matches) {
          this.mobile = true;
        } else {
          this.mobile = false;
        }
      });
    this.subSmall = this.appSrv.smallObs
      .subscribe((statex: BreakpointState) => {
        if (statex.matches) {
          this.small = true;
        } else {
          this.small = false;
        }
      });
    this.subSidebar = this.appSrv.sidebarCollapse
      .subscribe((statex: BreakpointState) => {
        if (!statex.matches) {
          $('[data-widget="pushmenu"]').PushMenu('expand');
        } else {
          $('[data-widget="pushmenu"]').PushMenu('collapse');
        }
      });

    this.changeDetailView();

    if (this.router.url.includes('adminpanel')) {
      this.administrationEvent = true;
    } else {
      this.administrationEvent = false;
    }

    this.loggedUser = this.userService.getUser();
    this.appSrv.setPatientListFrom(null);
    this.org = this.userService.getCurrentOrganization();
    if (this.org === 'VIVISOL' || this.org === 'AQUA') {
      this.org = 'NEMO';
    }
    this.refreshMenu();

    this.routerSub = this.router.events.subscribe(ev => {
      this.changeDetailView();
      this.refreshMenu();
    });

    this.subTypeView = this.appSrv.getTypeViewListBehavior().subscribe(ev => {
      this.changeDetailView();
      this.refreshMenu();
    });


    this.aggiornaMenu.subscribe(ev => {
      this.refreshMenu();
    })

  }

  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
    this.subLayout.unsubscribe();
    this.routerSub.unsubscribe();
    this.subscriberAlert.unsubscribe();
    this.subSidebar.unsubscribe();
    this.subTypeView.unsubscribe();
  }

  changeDetailView() {
    if (this.router.url.includes('profilosanitario')) {
      this.detailEvent = true;
    } else {
      this.detailEvent = false;
    }
  }

  activateMenu(section) {
    return this.router.url.includes(section)
  }

  refreshMenu() {
    this.items = [
      /*{
        id: "sidebar_menu_19",
        label: 'Dashboard',
        icon: 'fas fa-tachometer-alt nav-icon',
        expanded: this.activateMenu('dashboard'),
        command: () => {
          this.appSrv.setPatientListFrom(null);
          if (this.small) {
            $('[data-widget="pushmenu"]').PushMenu('toggle');
          }
          // tslint:disable-next-line: max-line-length
          this.router.navigate(['/main/dashboard']);
        }
      },
      /*{
        id: "sidebar_menu_1",
        label: 'Nuovo Paziente',
        icon: 'fas fa-user-plus nav-icon',
        command: () => {
          this.resetPatient();

          this.appSrv.setPatientListFrom(null);
          if (this.small) {
            $('[data-widget="pushmenu"]').PushMenu('toggle');
          }
          // tslint:disable-next-line: max-line-length
          this.router.navigate(['/main/pazienti/schedapaziente']);
        },
        visible: this.checkPermessoNuovoPaziente(),
        expanded: this.activateMenu('schedapaziente'),
      },*/
      {
        id: "sidebar_menu_2",
        label: 'Lista Pazienti',
        icon: 'fas fa-users nav-icon',
        expanded: this.activateMenu('listapazienti'),
        command: () => {

          this.resetPatient();
          this.appSrv.setPatientListFrom('lista_pazienti');
          if (this.small) {
            $('[data-widget="pushmenu"]').PushMenu('toggle');
          }
          this.router.navigate(['/main/pazienti/listapazienti']);

        }
      },
      {
        id: "sidebar_menu_3",
        label: 'Dettagli',
        icon: 'nav-icon fas fa-notes-medical',
        visible: this.checkPermessoLetturaDettagli() && this.patient != null,
        expanded: this.detailEvent || this.activateMenu('profilosanitario'),
        items: [],
        command: () => {
          this.detailEvent = !this.detailEvent;
          this.administrationEvent = false;
          this.aggiornaMenu.next(true);
        }
      },
      {
        id: "sidebar_menu_4", label: 'Stile di vita', icon: 'far fa-id-badge nav-icon',
        expanded: this.activateMenu('stiledivita') && this.activateMenu('profilosanitario'),
        visible: this.detailEvent && this.patient != null,
        command: () => {
          this.appSrv.setPatientListFrom('stiledivita');
          // adminlte.ControlSidebar.toggle();
          if (this.small) {
            $('[data-widget="pushmenu"]').PushMenu('toggle');
          }
          // tslint:disable-next-line: max-line-length
          this.router.navigate([this.patient ? '/main/pazienti/profilosanitario/' + this.patientId + '/stiledivita' : '/main/pazienti/listapazienti']);
        }
      },
      {
        id: "sidebar_menu_5", label: 'Terapie Farmacologiche', icon: 'fas fa-pills nav-icon',
        expanded: this.activateMenu('terapiefarmacologiche') && this.activateMenu('profilosanitario'),
        visible: this.detailEvent && this.patient != null,
        command: () => {
          this.appSrv.setPatientListFrom('terapiefarmacologiche');
          if (this.small) {
            $('[data-widget="pushmenu"]').PushMenu('toggle');
          }
          // tslint:disable-next-line: max-line-length
          this.router.navigate([this.patient ? '/main/pazienti/profilosanitario/' + this.patientId + '/terapiefarmacologiche' : '/main/pazienti/listapazienti']);
        }
      },
      {
        id: "sidebar_menu_6", label: 'Devices', icon: 'fas fa-first-aid nav-icon',
        expanded: this.activateMenu('devices') && this.activateMenu('profilosanitario'),
        visible: this.detailEvent && this.patient != null,
        command: () => {
          this.appSrv.setPatientListFrom('devices');
          if (this.small) {
            $('[data-widget="pushmenu"]').PushMenu('toggle');
          }
          // tslint:disable-next-line: max-line-length
          this.router.navigate([this.patient ? '/main/pazienti/profilosanitario/' + this.patientId + '/devices' : '/main/pazienti/listapazienti']);
        }
      },
      {
        id: "sidebar_menu_7", label: 'Stato di salute', icon: 'fas fa-briefcase-medical nav-icon',
        expanded: this.activateMenu('statodisalute') && this.activateMenu('profilosanitario'),
        visible: this.detailEvent && this.patient != null,
        command: () => {
          this.appSrv.setPatientListFrom('statodisalute');
          if (this.small) {
            $('[data-widget="pushmenu"]').PushMenu('toggle');
          }
          // tslint:disable-next-line: max-line-length
          this.router.navigate([this.patient ? '/main/pazienti/profilosanitario/' + this.patientId + '/statodisalute' : '/main/pazienti/listapazienti']);
        }
      },
      {
        id: "sidebar_menu_8", label: 'Anamnesi Pat. Prossima', icon: 'fas fa-file-medical-alt nav-icon',
        expanded: this.activateMenu('anamnesipatologicaprossima') && this.activateMenu('profilosanitario'),
        visible: this.detailEvent && this.patient != null,
        command: () => {
          this.appSrv.setPatientListFrom('anamnesipatologicaprossima');
          if (this.small) {
            $('[data-widget="pushmenu"]').PushMenu('toggle');
          }
          // tslint:disable-next-line: max-line-length
          this.router.navigate([this.patient ? '/main/pazienti/profilosanitario/' + this.patientId + '/anamnesipatologicaprossima' : '/main/pazienti/listapazienti']);
        }
      },
      {
        id: "sidebar_menu_9", label: 'Anamnesi Pat. Remota', icon: 'fas fa-file-medical nav-icon',
        expanded: this.activateMenu('anamnesipatologicaremota') && this.activateMenu('profilosanitario'),
        visible: this.detailEvent && this.patient != null,
        command: () => {
          this.appSrv.setPatientListFrom('anamnesipatologicaremota');
          if (this.small) {
            $('[data-widget="pushmenu"]').PushMenu('toggle');
          }
          // tslint:disable-next-line: max-line-length
          this.router.navigate([this.patient ? '/main/pazienti/profilosanitario/' + this.patientId + '/anamnesipatologicaremota' : '/main/pazienti/listapazienti']);
        }
      },
      {
        id: "sidebar_menu_10",
        label: 'Visite',
        icon: 'fas fa-user-md nav-icon',
        visible: this.checkPermessoLetturaVisite() && (this.appSrv.getTypeViewList() !== 'Pazienti in Attivazione' && this.appSrv.getTypeViewList() !== 'Pazienti in Valutazione'),
        expanded: this.activateMenu('visite'),
        command: () => {

          this.appSrv.setPatientListFrom('visite');
          if (this.small) {
            $('[data-widget="pushmenu"]').PushMenu('toggle');
          }
          // tslint:disable-next-line: max-line-length
          this.router.navigate([this.patient ? '/main/pazienti/visite/' + this.patientId : '/main/pazienti/listapazienti']);
        }
      },
      {
        id: "sidebar_menu_11",
        label: 'Monitoraggio',
        icon: 'fas fa-chart-line nav-icon',
        visible: this.checkPermessoLetturaMonitoraggio() && (this.appSrv.getTypeViewList() !== 'Pazienti in Attivazione' && this.appSrv.getTypeViewList() !== 'Pazienti in Valutazione'),
        expanded: this.activateMenu('monitoraggio/'),
        command: () => {

          this.appSrv.setPatientListFrom('monitoraggio');
          if (this.small) {
            $('[data-widget="pushmenu"]').PushMenu('toggle');
          }
          // tslint:disable-next-line: max-line-length
          this.appSrv.setTypeViewList('Pazienti presi in carico');
          this.pazientiService.setDateFromTo(null);
          this.patientId = this.pazientiService.getPatientId();
          this.router.navigate([this.patient ? '/main/pazienti/monitoraggio/' + this.patientId : '/main/pazienti/listapazienti']);
        }
      },
      {
        id: "sidebar_menu_12",
        label: 'Monitoraggio Stato di salute',
        icon: 'fas fa-chart-line nav-icon',
        visible: this.checkPermessoLetturaMonitoraggio() && (this.appSrv.getTypeViewList() !== 'Pazienti in Attivazione' && this.appSrv.getTypeViewList() !== 'Pazienti in Valutazione'),
        expanded: this.activateMenu('monitoraggioicf'),
        command: () => {

          this.appSrv.setPatientListFrom('monitoraggioicf');
          if (this.small) {
            $('[data-widget="pushmenu"]').PushMenu('toggle');
          }
          // tslint:disable-next-line: max-line-length
          this.appSrv.setTypeViewList('Pazienti presi in carico');
          this.pazientiService.setDateFromTo(null);
          this.patientId = this.pazientiService.getPatientId();
          this.router.navigate([this.patient ? '/main/pazienti/monitoraggioicf/' + this.patientId : '/main/pazienti/listapazienti']);
        }
      },
      {
        id: "sidebar_menu_13",
        label: 'Alert Management',
        icon: 'fas fa-book-medical nav-icon',
        visible: this.checkPermessoLetturaNotifiche() && this.appSrv.getTypeViewList() !== 'Pazienti in Attivazione',
        expanded: this.activateMenu('alert'),
        command: () => {

          this.appSrv.setPatientListFrom('alert');
          if (this.small) {
            $('[data-widget="pushmenu"]').PushMenu('toggle');
          }
          // tslint:disable-next-line: max-line-length
          this.patientId = this.pazientiService.getPatientId();
          this.router.navigate([this.patient ? '/main/pazienti/alertmanagement/' + this.patientId : '/main/pazienti/listapazienti']);
        }
      },
      {
        id: "sidebar_menu_14",
        label: 'Amministrazione',
        icon: 'nav-icon fas fa-cogs',
        visible: this.isAdmin() || this.checkPermessoGestioneUtenti(),
        expanded: this.administrationEvent || this.activateMenu('adminpanel'),
        items: [],
        command: () => {
          this.administrationEvent = !this.administrationEvent;
          this.detailEvent = false;
          this.aggiornaMenu.next(true);
        }
      },
      {
        id: "sidebar_menu_15", label: 'Gestione Utenti', icon: 'fas fa-users-cog nav-icon',
        expanded: this.activateMenu('adminpanel/utenti'),
        visible: this.administrationEvent,
        command: () => {
          this.resetPatient();
          this.appSrv.setPatientListFrom(null);
          if (this.small) {
            $('[data-widget="pushmenu"]').PushMenu('toggle');
          }
          // tslint:disable-next-line: max-line-length
          this.router.navigate(['/main/adminpanel/utenti']);
        }
      },
      {
        id: "sidebar_menu_16", label: 'Gestione Ruoli', icon: 'fas fa-user-shield nav-icon',
        expanded: this.activateMenu('adminpanel/ruoli'),
        visible: this.isSuperAdmin() && this.administrationEvent,
        command: () => {
          this.resetPatient();
          this.appSrv.setPatientListFrom(null);
          if (this.small) {
            $('[data-widget="pushmenu"]').PushMenu('toggle');
          }
          // tslint:disable-next-line: max-line-length
          this.router.navigate(['/main/adminpanel/ruoli']);
        }
      },
      {
        id: "sidebar_menu_17", label: 'Gestione Organizations', icon: 'fas fa-hospital nav-icon',
        expanded: this.activateMenu('adminpanel/organizations'),
        visible: this.isSuperAdmin() && this.administrationEvent,
        command: () => {
          this.resetPatient();
          this.appSrv.setPatientListFrom(null);
          if (this.small) {
            $('[data-widget="pushmenu"]').PushMenu('toggle');
          }
          // tslint:disable-next-line: max-line-length
          this.router.navigate(['/main/adminpanel/organizations']);
        }
      },
      {
        id: "sidebar_menu_18", label: 'Gestione questionari', icon: 'fas fa-question nav-icon',
        visible: this.isSuperAdmin() && this.administrationEvent,
        expanded: this.activateMenu('adminpanel/questionari'),
        command: () => {
          this.resetPatient();
          this.appSrv.setPatientListFrom(null);
          if (this.small) {
            $('[data-widget="pushmenu"]').PushMenu('toggle');
          }
          // tslint:disable-next-line: max-line-length
          this.router.navigate(['/main/adminpanel/questionari']);
        }
      },
      {
        id: "sidebar_menu_19", label: 'Pannello Log', icon: 'pi pi-book nav-icon',
        visible: this.administrationEvent,
        expanded: this.activateMenu('adminpanel/pannello_log'),
        command: () => {
          this.resetPatient();
          this.appSrv.setPatientListFrom(null);
          if (this.small) {
            $('[data-widget="pushmenu"]').PushMenu('toggle');
          }
          // tslint:disable-next-line: max-line-length
          this.router.navigate(['/main/adminpanel/pannello_log']);
        }
      },
      {
        id: "sidebar_menu_20", label: 'Pannello Elastic', icon: 'pi pi-filter nav-icon',
        visible: this.isSuperAdmin() && this.administrationEvent,
        expanded: this.activateMenu('adminpanel/pannelloElastic'),
        command: () => {
          this.resetPatient();
          this.appSrv.setPatientListFrom(null);
          if (this.small) {
            $('[data-widget="pushmenu"]').PushMenu('toggle');
          }
          // tslint:disable-next-line: max-line-length
          this.router.navigate(['/main/adminpanel/pannelloElastic']);
        }
      }
    ];
  }


  ngAfterViewInit(): void {
    // $('[data-widget="treeview"]').each(function () {
    //   AdminLte.Treeview._jQueryInterface.call($(this), 'init');
    // });
    // const arrayElementMenuDett = [
    //   { route: 'stiledivita', element: this.stiledivita },
    //   { route: 'terapiefarmacologiche', element: this.terapiefarm },
    //   { route: 'devices', element: this.devices },
    //   { route: 'statodisalute', element: this.statodisalute },
    //   { route: 'anamnesipatologicaprossima', element: this.anamnesipatologicaprossima },
    //   { route: 'anamnesipatologicaremota', element: this.anamnesipatologicaremota },
    // ];
    // const arrayElementMenu = [
    //   { route: 'visite', element: this.visite },
    //   { route: 'monitoraggio', element: this.monitoraggio },
    //   { route: 'monitoraggioicf', element: this.monitoraggioIcf },
    //   { route: 'alertmanagement', element: this.alert },
    //   { route: 'listapazienti', element: this.listapazienti },
    // ];
    // this.routerSub = this.router.events.subscribe(ev => {
    //   if (ev instanceof NavigationEnd) {
    //     if (ev.url.indexOf('profilosanitario') > 0) {
    //       const rotta = ev.url.split('/')[ev.url.split('/').length - 1];
    //       const element = arrayElementMenuDett.find(el => el.route === rotta);
    //       if (element) {
    //         this.removeActive();
    //         if (element.element) {
    //           this.render.addClass(element.element.nativeElement, 'active');
    //         } else {
    //         }
    //       }
    //       // tslint:disable-next-line: max-line-length
    //     } else if (ev.url.indexOf('visite') > 0 || ev.url.indexOf('monitoraggio') > 0 || ev.url.indexOf('monitoraggioicf') > 0 || ev.url.indexOf('alertmanagement') > 0 || ev.url.indexOf('listapazienti') > 0) {
    //       const rotta = ev.url.split('/')[ev.url.split('/').length - 2];
    //       const element = arrayElementMenu.find(el => el.route === rotta);
    //       if (element) {
    //         this.removeActive();
    //         this.render.addClass(element.element.nativeElement, 'active');
    //       }
    //     }
    //   }
    // });
    // // aggiunto per far vedere la lista pazienti
    // this.removeActive();
    // this.render.addClass(this.listapazienti.nativeElement, 'active');
    // this.resetPatient();
    // this.appSrv.setPatientListFrom('lista_pazienti');
    // if (this.small) {
    //   $('[data-widget="pushmenu"]').PushMenu('toggle');
    // }
    // if (this.router.url.indexOf('pazienti') > 0) {
    //   this.render.addClass(this.menuPazienti.nativeElement, 'menu-open');
    // } else {
    //   this.render.removeClass(this.menuPazienti.nativeElement, 'menu-open');
    // }
  }


  checkPermessoNuovoPaziente(): boolean {
    return this.userService.checkOnlyPermission('Anagrafica pazienti', 'insert');
  }

  resetPatient() {
    this.pazientiService.resetPatient();
  }

  logout() {
    this.tokenService.deleteToken();
    this.userService.deleteUser();
    this.pazientiService.resetPatient();
    this.router.navigate(['/login']);
  }

  isAdmin() {
    return this.userService.isAdmin() || this.userService.isSuperAdmin();
  }

  isSuperAdmin() {
    return this.userService.isSuperAdmin();
  }

  checkPermessoGestioneUtenti(): boolean {
    return this.userService.checkOnlyPermission('Gestione utenti', 'read');
  }

  stileDiVitaHandler(event, el) {
    this.removeActive();
    this.render.addClass(el, 'active');
    this.appSrv.setPatientListFrom('stiledivita');
    // adminlte.ControlSidebar.toggle();
    if (this.small) {
      $('[data-widget="pushmenu"]').PushMenu('toggle');
    }
    // tslint:disable-next-line: max-line-length
    this.router.navigate([this.patient ? '/main/pazienti/profilosanitario/' + this.patientId + '/stiledivita' : '/main/pazienti/listapazienti']);
  }

  terapieHandler(event, el) {
    this.removeActive();
    this.render.addClass(el, 'active');
    this.appSrv.setPatientListFrom('terapiefarmacologiche');
    if (this.small) {
      $('[data-widget="pushmenu"]').PushMenu('toggle');
    }
    // tslint:disable-next-line: max-line-length
    this.router.navigate([this.patient ? '/main/pazienti/profilosanitario/' + this.patientId + '/terapiefarmacologiche' : '/main/pazienti/listapazienti']);
  }

  devicesHandler(event, el) {
    this.removeActive();
    this.render.addClass(el, 'active');
    this.appSrv.setPatientListFrom('devices');
    if (this.small) {
      $('[data-widget="pushmenu"]').PushMenu('toggle');
    }
    // tslint:disable-next-line: max-line-length
    this.router.navigate([this.patient ? '/main/pazienti/profilosanitario/' + this.patientId + '/devices' : '/main/pazienti/listapazienti']);
  }

  statoDiSaluteHandler(event, el) {
    this.removeActive();
    this.render.addClass(el, 'active');
    this.appSrv.setPatientListFrom('statodisalute');
    if (this.small) {
      $('[data-widget="pushmenu"]').PushMenu('toggle');
    }
    // tslint:disable-next-line: max-line-length
    this.router.navigate([this.patient ? '/main/pazienti/profilosanitario/' + this.patientId + '/statodisalute' : '/main/pazienti/listapazienti']);
  }

  anamnesiPatPossHandler(event, el) {
    this.removeActive();
    this.render.addClass(el, 'active');
    this.appSrv.setPatientListFrom('anamnesipatologicaprossima');
    if (this.small) {
      $('[data-widget="pushmenu"]').PushMenu('toggle');
    }
    // tslint:disable-next-line: max-line-length
    this.router.navigate([this.patient ? '/main/pazienti/profilosanitario/' + this.patientId + '/anamnesipatologicaprossima' : '/main/pazienti/listapazienti']);
  }

  anamnesiPatRemHandler(event, el) {
    this.removeActive();
    this.render.addClass(el, 'active');
    this.appSrv.setPatientListFrom('anamnesipatologicaremota');
    if (this.small) {
      $('[data-widget="pushmenu"]').PushMenu('toggle');
    }
    // tslint:disable-next-line: max-line-length
    this.router.navigate([this.patient ? '/main/pazienti/profilosanitario/' + this.patientId + '/anamnesipatologicaremota' : '/main/pazienti/listapazienti']);
  }

  dashboardHandler(event, el) {
    this.removeActive();
    this.render.addClass(el, 'active');
    this.resetPatient();
    this.appSrv.setPatientListFrom(null);
    if (this.small) {
      $('[data-widget="pushmenu"]').PushMenu('toggle');
    }
    // tslint:disable-next-line: max-line-length
    this.router.navigate(['/main/dashboard']);
  }

  nuovoPazienteHandler(event, el) {
    this.removeActive();
    this.render.addClass(el, 'active');
    this.resetPatient();
    this.appSrv.setPatientListFrom(null);
    if (this.small) {
      $('[data-widget="pushmenu"]').PushMenu('toggle');
    }
    // tslint:disable-next-line: max-line-length
    this.router.navigate(['/main/pazienti/schedapaziente']);
  }

  listaPazientiHandler(event, el) {
    this.removeActive();
    this.render.addClass(el, 'active');
    this.resetPatient();
    this.appSrv.setPatientListFrom('lista_pazienti');
    if (this.small) {
      $('[data-widget="pushmenu"]').PushMenu('toggle');
    }
    // tslint:disable-next-line: max-line-length
    this.router.navigate(['/main/pazienti/listapazienti']);
  }

  // listaPazientiAttivazioneHandler(event, el) {
  //   this.removeActive();
  //   this.render.addClass(el, 'active');
  //   this.resetPatient();
  //   this.appSrv.setPatientListFrom('lista_pazienti_attivazione');
  //   if (this.small) {
  //     $('[data-widget="pushmenu"]').PushMenu('toggle');
  //   }
  //   // tslint:disable-next-line: max-line-length
  //   this.router.navigate(['/main/pazienti/listapazientiattivazione']);
  // }

  visiteHandler(event, el) {
    this.removeActive();
    this.render.addClass(el, 'active');
    // this.resetPatient();
    this.appSrv.setPatientListFrom('visite');
    if (this.small) {
      $('[data-widget="pushmenu"]').PushMenu('toggle');
    }
    // tslint:disable-next-line: max-line-length
    this.router.navigate([this.patient ? '/main/pazienti/visite/' + this.patientId : '/main/pazienti/listapazienti']);
  }

  needActivation() {
    let org = this.userService.getCurrentOrganization();
    if (org === 'VIVISOL' || org === 'AQUA') {
      org = 'NEMO';
    }
    if (org === 'NEMO') {
      return true;
    } else {
      return false;
    }
  }

  monitoraggioHandler(event, el) {
    this.removeActive();
    this.render.addClass(el, 'active');
    // this.resetPatient();
    this.appSrv.setPatientListFrom('monitoraggio');
    if (this.small) {
      $('[data-widget="pushmenu"]').PushMenu('toggle');
    }
    // tslint:disable-next-line: max-line-length
    this.pazientiService.setDateFromTo(null);
    this.patientId = this.pazientiService.getPatientId();
    this.router.navigate([this.patient ? '/main/pazienti/monitoraggio/' + this.patientId : '/main/pazienti/listapazienti']);
  }


  monitoraggioIcfHandler(event, el) {
    this.removeActive();
    this.render.addClass(el, 'active');
    // this.resetPatient();
    this.appSrv.setPatientListFrom('monitoraggioicf');
    if (this.small) {
      $('[data-widget="pushmenu"]').PushMenu('toggle');
    }
    // tslint:disable-next-line: max-line-length
    this.pazientiService.setDateFromTo(null);
    this.patientId = this.pazientiService.getPatientId();
    this.router.navigate([this.patient ? '/main/pazienti/monitoraggioicf/' + this.patientId : '/main/pazienti/listapazienti']);
  }

  alertHandler(event, el) {
    this.removeActive();
    this.render.addClass(el, 'active');
    // this.resetPatient();
    this.appSrv.setPatientListFrom('alert');
    if (this.small) {
      $('[data-widget="pushmenu"]').PushMenu('toggle');
    }
    // tslint:disable-next-line: max-line-length
    this.patientId = this.pazientiService.getPatientId();
    this.router.navigate([this.patient ? '/main/pazienti/alertmanagement/' + this.patientId : '/main/pazienti/listapazienti']);
  }

  amministrazioneUtentiHandler(event, el) {
    this.removeActive();
    this.render.addClass(el, 'active');
    this.resetPatient();
    this.appSrv.setPatientListFrom(null);
    if (this.small) {
      $('[data-widget="pushmenu"]').PushMenu('toggle');
    }
    // tslint:disable-next-line: max-line-length
    this.router.navigate(['/main/adminpanel/utenti']);
  }

  amministrazioneRuoliHandler(event, el) {
    this.removeActive();
    this.render.addClass(el, 'active');
    this.resetPatient();
    this.appSrv.setPatientListFrom(null);
    if (this.small) {
      $('[data-widget="pushmenu"]').PushMenu('toggle');
    }
    // tslint:disable-next-line: max-line-length
    this.router.navigate(['/main/adminpanel/ruoli']);
  }

  amministrazioneOrganizationHandler(event, el) {
    this.removeActive();
    this.render.addClass(el, 'active');
    this.resetPatient();
    this.appSrv.setPatientListFrom(null);
    if (this.small) {
      $('[data-widget="pushmenu"]').PushMenu('toggle');
    }
    // tslint:disable-next-line: max-line-length
    this.router.navigate(['/main/adminpanel/organizations']);
  }

  amministrazioneQuestionariHandler(event, el) {
    this.removeActive();
    this.render.addClass(el, 'active');
    this.resetPatient();
    this.appSrv.setPatientListFrom(null);
    if (this.small) {
      $('[data-widget="pushmenu"]').PushMenu('toggle');
    }
    // tslint:disable-next-line: max-line-length
    this.router.navigate(['/main/adminpanel/questionari']);
  }

  removeActive() {
    const el = this.sidebar.nativeElement.querySelectorAll('.nav-link');
    for (const element of el) {
      this.render.removeClass(element, 'active');
    }
  }

  checkPermessoLetturaDettagli(): boolean {
    return this.userService.checkPermissionForSidebar("Dettagli");
  }

  checkPermessoLetturaVisite(): boolean {
    return this.userService.checkPermissionForSidebar('Visite');
  }

  showPanelHelp($event) {
    const input = {
    };
    const ref = HelpModalComponent.open(this.dialogService, input, '70%', '70%');
    ref.onClose.subscribe((res: any) => {

    });
  }

  checkPermessoLetturaMonitoraggio(): boolean {
    return this.userService.checkPermissionForSidebar('Monitoraggio');
  }

  checkPermessoLetturaNotifiche(): boolean {
    return this.userService.checkPermissionForSidebar("Notifiche");
  }

}
