import { SafeHtml } from '@angular/platform-browser';
export class Ticket {

  id: number;
  username: string;
  nomeCognome: string;
  refereceId: string;
  organization: string;
  mittente: string;
  oggetto: string;
  tag: string;
  testo: SafeHtml;
  dataApertura: Date;
  dataRisoluzione: Date;
  stato: string;
  descrizioneIntervento: string;
  eventoSospetto: boolean;

}
