<!-- <button (click)="creaQuestionario()">Crea questionario</button> -->
<!-- <button (click)="aggiornaQuestionario()">Aggiorna questionario</button> -->
<h1 class="mt-3" style="text-align:center">Questionario Percorso Covid19</h1>
<div class="p-fluid mt-3">
    <div class="row mt-3 ml-1" *ngFor="let domanda of domande">
        <ng-container *ngIf="domanda.tipo === 'integer'">
            <ng-container *ngIf="domanda.risposte.length > 0">
                <div class="col-md-12 mt-2">
                    <h5>{{domanda.testo}}</h5>
                </div>
                <form style="margin-left: 15px" class="mt-1">
                    <div class="col-md-12 custom-control custom-radio"
                        *ngFor="let risposta of domanda.risposte">
                        <ng-container *ngIf="domanda.testo.includes('Altra sintomatologia COVID19')">
                            <p-checkbox name="{{domanda.linkId}}.{{risposta.value}}" value="{{risposta.value}}" label="{{risposta.label}}"
                                [(ngModel)]="domanda.risposta"></p-checkbox>
                        </ng-container>
                        <ng-container *ngIf="!domanda.testo.includes('Altra sintomatologia COVID19')">
                            <input type="radio" class="custom-control-input" name="{{domanda.linkId}}{{risposta.value}}"
                                [value]="risposta.value" [(ngModel)]="domanda.risposta"
                                [disabled]="!checkPermessoModifica()"
                                id="{{domanda.linkId}}{{risposta.value}}">
                            <label class="custom-control-label"
                                for="{{domanda.linkId}}{{risposta.value}}">{{risposta.label}}</label>
                        </ng-container>
                        <ng-container *ngIf="risposta.extras">
                            <ng-container *ngIf="risposta.tipoExtra && risposta.tipoExtra === 'integer'">
                                <form style="margin-left: 15px" class="mt-1">
                                    <div class="col-md-12 custom-control custom-radio"
                                        *ngFor="let ex of risposta.extras">
                                        <input type="radio" class="custom-control-input" name="{{domanda.linkId}}re{{risposta.value}}"
                                            [value]="ex.value" [(ngModel)]="risposta.risposta"
                                            [disabled]="!checkPermessoModifica()"
                                            id="{{domanda.linkId}}re{{ex.value}}">
                                        <label class="custom-control-label"
                                            for="{{domanda.linkId}}re{{ex.value}}">{{ex.label}}</label>
                                    </div>
                                </form>
                            </ng-container>
                            <ng-container *ngIf="!risposta.tipoExtra">
                                <ng-container *ngFor="let extra of risposta.extras; let i = index">
                                    <ng-container *ngIf="extra.tipo === 'quantity'">
                                        <div class="p-inputgroup">
                                            <input type='number' pInputText [(ngModel)]="extra.value" class="form-control"
                                                min="0" value="0" name="{{domanda.linkId}}e{{i}}" [disabled]="!checkPermessoModifica()" />
                                            <span style="min-width: 3em;" class="p-inputgroup-addon d-flex justify-content-center" [innerHTML]="extra.label"></span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="extra.tipo === 'string'">
                                        <textarea [rows]="3" [cols]="100" pInputTextarea autoResize="autoResize" [(ngModel)]="extra.value" class="form-control" name="{{domanda.linkId}}e{{i}}" [disabled]="!checkPermessoModifica()"></textarea>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                </form>
            </ng-container>
            <ng-container *ngIf="domanda.risposte.length === 0">
                <div class="col-md-12 mt-1">
                    <h5>{{domanda.testo}}</h5>
                </div>
                <ng-container *ngIf="domanda.sottodomande">
                    <div class="col-md-12 row mt-4" *ngFor="let sottodomanda of domanda.sottodomande">
                        <ng-container *ngIf="sottodomanda.tipo === 'boolean'">
                            <div class="col-md-7">
                                <h6>{{sottodomanda.testo}}</h6>
                            </div>
                            <div class="col-md-2">
                                <p-selectButton [options]="sottodomanda.risposte" optionLabel="label" optionValue="value"
                                    [disabled]="!checkPermessoModifica()" [(ngModel)]="sottodomanda.risposta">
                                </p-selectButton>
                            </div>
                            <div class="col-md-3"></div>
                            <div class="col-md-12 row mt-5" *ngIf="sottodomanda.extra">
                                <ng-container *ngIf="!sottodomanda.extra.testo">
                                    <div class="col-md-12">
                                        <textarea [rows]="3" [cols]="100" pInputTextarea autoResize="autoResize" [(ngModel)]="sottodomanda.extra.risposta"
                                            class="form-control" name="{{domanda.linkId}}s{{sottodomanda.linkId}}" [disabled]="!checkPermessoModifica()">
                                        </textarea>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="sottodomanda.extra.testo">
                                    <div class="col-md-3">
                                        <h6>{{sottodomanda.extra.testo}}</h6>
                                    </div>
                                    <div class="col-md-3">
                                        <input type='text' pInputText [(ngModel)]="sottodomanda.extra.risposta" class="form-control"
                                            name="{{domanda.linkId}}s{{sottodomanda.linkId}}" [disabled]="!checkPermessoModifica()"/>
                                    </div>
                                    <div class="col-md-6"></div>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="domanda.tipo === 'boolean'">
            <div class="col-md-7 mt-2">
                <h5>{{domanda.testo}}</h5>
            </div>
            <div class="col-md-2 mt-2">
                <p-selectButton [options]="domanda.risposte" optionLabel="label" optionValue="value"
                    [disabled]="!checkPermessoModifica()" [(ngModel)]="domanda.risposta" (click)="mostrareSottodomande(domanda)">
                </p-selectButton>
            </div>
            <div class="col-md-3"></div>
            <ng-container *ngIf="domanda.sottodomande !== undefined && domanda.mostraSottodomande">
                <div class="col-md-12 row" style="margin-top: 1%">
                    <ng-container *ngFor="let sottodomanda of domanda.sottodomande">
                        <ng-container *ngIf="sottodomanda.tipo === 'string'">
                            <div class="col-md-3">
                                <h6>{{sottodomanda.testo}}</h6>
                            </div>
                            <div class="col-md-3">
                                <input type='text' pInputText [(ngModel)]="sottodomanda.risposta" class="form-control"
                                    name="{{domanda.linkId}}s{{sottodomanda.linkId}}" [disabled]="!checkPermessoModifica()"/>
                            </div>
                            <div class="col-md-6"></div>
                        </ng-container>
                        <ng-container *ngIf="sottodomanda.tipo === 'integer'">
                            <form style="margin-left: 15px">
                                <div class="col-md-12 custom-control custom-radio"
                                    *ngFor="let sottorisposta of sottodomanda.risposte">
                                    <input type="radio" class="custom-control-input" name="{{domanda.linkId}}re{{sottodomanda.linkId}}"
                                        [value]="sottorisposta.value" [(ngModel)]="sottodomanda.risposta"
                                        [disabled]="!checkPermessoModifica()"
                                        id="{{domanda.linkId}}re{{sottorisposta.value}}">
                                    <label class="custom-control-label"
                                        for="{{domanda.linkId}}re{{sottorisposta.value}}">{{sottorisposta.label}}</label>
                                    <ng-container *ngIf="sottorisposta.extra">
                                        <ng-container *ngIf="sottorisposta.extra.testo.length > 0">
                                            <div class="col-md-3">
                                                <h6>{{sottorisposta.extra.testo}}</h6>
                                            </div>
                                            <div class="col-md-3">
                                                <input type='text' pInputText [(ngModel)]="sottorisposta.extra.value" class="form-control"
                                                    name="{{domanda.linkId}}s{{sottodomanda.linkId}}.{{sottorisposta.linkId}}" [disabled]="!checkPermessoModifica()"/>
                                            </div>
                                            <div class="col-md-6"></div>
                                        </ng-container>
                                        <ng-container *ngIf="sottorisposta.extra.testo.length === 0">
                                            <div class="col-md-12">
                                                <textarea [rows]="3" [cols]="100" pInputTextarea autoResize="autoResize" [(ngModel)]="sottorisposta.extra.value" class="form-control" name="{{domanda.linkId}}re{{sottodomanda.value}}" [disabled]="!checkPermessoModifica()"></textarea>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </form>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>
    </div>
    <div class="row" style="margin-top: 50px;">
      <div class="col-md-12">
          <div class="float-right" style="margin-right: 15px; margin-bottom: 15px;">
              <button *ngIf="checkPermessoModifica()" pButton label="Salva" icon="fa fa-save" iconPos="left"
              class="p-button-success" type="button" (click)="salva()"></button>
          </div>
      </div>
    </div>
</div>