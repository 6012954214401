import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SelectItem } from 'primeng/api';
import { Device } from 'src/app/model/Device';
import { AppService } from 'src/app/service/app.service';
import { UserService } from 'src/app/service/user-service.service';
import { endpoints } from 'src/endpoint/endpoints';
import { LogEventService } from '../../service/log-event.service';
import { PazientiService } from '../../service/pazienti.service';
import { activeInactiveOpt } from './model/OptionsDevices';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss']
})
export class DevicesComponent implements OnInit {

  // Informazioni paziente
  patientId;
  patient;
  subscriber;

  // Form
  devicesForm;
  activeInactiveOptPortatoreCVC: SelectItem[];
  activeInactiveOptStomia: SelectItem[];


  activeInactiveOptVescicale: SelectItem[];
  activeInactiveOptSondino: SelectItem[];
  activeInactiveOptPeg: SelectItem[];
  activeInactiveCVP: SelectItem[];
  activeInactiveNIV: SelectItem[];
  activeInactiveTreacheostomia: SelectItem[];
  activeInactiveTosse: SelectItem[];
  // Dispositivi
  devices;

  // Per un controllo "intelligente"
  daControllare = new Array();

  org;

  // Per controllare se è nella visita
  isInVisita: boolean;
  lastVisit;
  clinicalImpressionId;

  // Nuovo Evento Log
  resourceHistory;
  newEvent;
  history;
  tempObj: any;
  tempArrayForPatient: any;
  CODE = "Device";

  showAccountInfo = false;

  // Questa variabile regola la visualizzazione del tasto per generare le credenziali, che serve solo se il paziente non è presente nell'oauth (errore di importazione)
  showGeneraCredenzialiButton = false;


  constructor(private http: HttpClient, private pazientiService: PazientiService,
    private fb: FormBuilder, private toastr: ToastrService, private route: ActivatedRoute,
    private userService: UserService, private appServ: AppService, private logService: LogEventService) { }

  ngOnInit() {
    this.isInVisita = this.getIsInVisite();
    this.org = this.userService.getCurrentOrganization();
    if (this.org === 'VIVISOL' || this.org === 'AQUA') {
      this.org = 'NEMO';
    }
    this.buildForm();
    this.daControllare = new Array();
    this.patientId = this.pazientiService.getPatientId();
    this.CODE = this.CODE + "/" + this.patientId;
    if (this.isInVisita) {
      this.getDevicesFromClinicalImpression();
    } else {
      this.getDevices();
    }
    this.subscriber = this.pazientiService.patient.subscribe(patient => {
      this.patient = patient;
      this.history = this.patient.meta.versionId;
      this.resourceHistory = "Patient/" + this.patientId + "/_history/" + this.history;
      if (patient && this.patient.identifier != null) {
        this.updateFormByPatient(this.patient.identifier);
      }
    });
    this.getPatient(this.patientId);
    this.clinicalImpressionId = this.route.parent.snapshot.paramMap.get('vId');
    this.getLastClinicalImpression();
    this.getUsername();
  }

  initResources() {
    this.isInVisita = this.getIsInVisite();
    this.org = this.userService.getCurrentOrganization();
    //this.buildForm();
    this.daControllare = new Array();
    this.patientId = this.pazientiService.getPatientId();
    this.callGetDevices();
  }

  callGetDevices() {
    if (this.isInVisita) {
      this.getDevicesFromClinicalImpression();
    } else {
      this.getDevices();
    }
  }

  getDevicesFromClinicalImpression() {
    this.daControllare = new Array();
    this.http.get(endpoints.getDevicesByClinicalImpressionId + this.route.parent.snapshot.paramMap.get('vId')).toPromise()
      .then((resp: any) => {
        if (resp.success === '1') {
          this.devices = new Array();
          resp.message.forEach(element => {
            this.devices.push(element);
          });
          this.updateFormByDevicesForVisit(this.devices);
        } else {
          this.toastr.error('Errore nel caricamento dei dispositivi.');
        }
        this.logService.getAllOpt([this.devices, [this.pazientiService.getVisita()]], null);
      }).catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Errore nel caricamento dei dispositivi.', 'ERRORE');
      });
  }

  getDevices() {
    this.daControllare = new Array();
    this.http.get((this.isInVisita ? endpoints.getDevicesByPatientIdVisite : endpoints.getDevicesByPatientIdDettagli) + "/" + this.patientId).toPromise()
      .then((resp: any) => {
        this.devices = new Array();
        if (resp.entry) {
          if (this.isLastVisit()) {
            resp.entry.forEach(element => {
              if (element.resource.id != null && element.resource.meta != null) {
                this.logService.newEventNoCheck(element.resource.resourceType + "/" + element.resource.id + "/_history/" + element.resource.meta.versionId, null, null, this.userService.getUser().username);
              }
            });
          }
          resp.entry.forEach(element => {
            this.devices.push(element.resource);
          });
          this.updateFormByDevices(this.devices);
        }
        this.unsubscribeAllFields();
        this.subscribeAllFields();
        this.logService.getAllOpt([this.devices], this.CODE)
      }).catch(err => {
        console.error(err);
        this.toastr.error('Errore nel caricamento dei dispositivi.', 'ERRORE');
      });
  }

  updateFormByPatient(identifiers) {
    identifiers.forEach(element => {
      if (element.type != null && element.type.coding) {
        this.devicesForm.get(element.type.coding[0].code).setValue(element.value);
      }
    });
    if (this.patient.extension != undefined) {
      let ext = this.patient.extension.find(elem => (elem.url && elem.url.includes('extensions#firstpassword')));
      if (ext != undefined) {
        this.devicesForm.get('passwordMobileApp').setValue(ext.valueString ?? "");
      }
    }
    this.unsubscribeAllFields();
    this.subscribeAllFields();
  }

  updateFormByDevicesForVisit(devices) {
    devices.forEach((d) => {
      if (d.code.coding[0].code === 'other_device') {
        this.devicesForm.get(d.code.coding[0].code).setValue(d.valueString);
      } else {
        this.devicesForm.get(d.code.coding[0].code).setValue(d.valueString.toLowerCase());
      }
    });
    this.unsubscribeAllFields();
    this.subscribeAllFields();
  }

  updateFormByDevices(devices: Device[]) {
    let v = '';
    devices.forEach((d: Device) => {
      switch (d.type.coding[0].code) {
        case 'other_device':
          v = d.note != undefined ? d.note[0].text : "";
          break;
        case 'account_monitoring_device':
        case 'mac_monitoring_device':
          v = d.type.coding[0].display;
          break;
        default:
          v = d.status;
      }
      this.devicesForm.get(d.type.coding[0].code).setValue(v);
    });
    this.unsubscribeAllFields();
    this.subscribeAllFields();
  }

  salvaDispositiviVisita() {
    let oldD = null;
    var tempRes;
    const nuoveObservationsArray = new Array();
    const observationsDaAggionare = new Array();
    let saved = false;
    this.daControllare.forEach(element => {
      oldD = this.getDeviceObservationByCode(element);
      if (oldD == null) {
        nuoveObservationsArray.push(this.createObservationObjectFromField(element));
      } else if (oldD.valueString.toLowerCase() !== this.devicesForm.get(element).value.toLowerCase()) {
        saved = true;
        // Chiamo direttamente l'update
        const obj = {
          id: oldD.id,
          value: {
            type: 'string',
            unit: '',
            value: this.devicesForm.get(element).value
          }
        };
        if (this.isLastVisit) {
          obj['updatePatient'] = this.patientId;
        }
        if (obj.id != null) {
          tempRes = "Observation/";
          if (oldD.meta.versionId == 1) {
            this.http.post(endpoints.checkLogEventResource, tempRes + obj.id + "/_history/" + oldD.meta.versionId).toPromise().then((resp: any) => {
              if (resp)
                this.logService.newEventNoCheck(tempRes + obj.id + "/_history/" + oldD.meta.versionId, null, null, this.userService.getUser().username);
            })
          }
          this.logService.newEvent(tempRes + obj.id + "/_history/" + oldD.meta.versionId, null, null, this.userService.getUser().username);
        }

        this.http.post(endpoints.updateObservationVisite, obj).toPromise()
          .then((resp: any) => {
            if (resp.success === '1') {
              this.getDevicesFromClinicalImpression();
              if (this.isLastVisit() && this.isInVisita) {
                this.getDevices();
              }
              this.getPatient(this.patientId);
              this.toastr.success('Salvataggio avvenuto con successo.');
            } else {
              this.toastr.error('Errore nel salvataggio dei dispositivi.');
            }
          }).catch(err => {
            console.error(err);
            if (err.status == 403) {
              this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
              return;
            }
            this.toastr.error('Errore nel caricamento dei Dati.');
          });
      }
    });

    // Controllo le nuove
    if (nuoveObservationsArray.length > 0) {
      const obj = {
        clinicalImpressionId: this.clinicalImpressionId,
        code: {
          coding: {
            code: 'devices',
            system: 'http://hl7.org/fhir/observation-category'
          }
        },
        observations: nuoveObservationsArray,
        updatePatient: this.isLastVisit()
      };
      this.history = this.patient.meta.versionId;
      this.resourceHistory = "Patient/" + this.patientId + "/_history/" + this.history;
      saved = true;
      this.http.post(endpoints.addInvestigationVisite, obj).toPromise()
        .then((resp: any) => {
          if (resp.success === '0') {
            this.toastr.error('Errore nel salvataggio dei dispositivi.');
          } else {
            this.getDevicesFromClinicalImpression();
            if (this.isLastVisit() && this.isInVisita) {
              this.getDevices();
            }
            this.toastr.success('Salvataggio avvenuto con successo.');
          }
        }).catch(err => {
          console.error(err);
          if (err.status == 403) {
            this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
            return;
          }
          this.toastr.error('Errore nel salvataggio dei dispositivi.', 'ERRORE');
        });
    }

    if (!saved) {
      this.toastr.info('Nulla da salvare.');
      return;
    }
  }

  getDeviceObservationByCode(code) {
    let dev = null;
    this.devices.forEach(d => {
      if (d.code.coding[0].code === code) {
        dev = d;
      }
    });
    return dev;
  }

  getLastClinicalImpression(): any {
    this.http.get((this.isInVisita ? endpoints.getLastClinicalImpressionByPatientIdVisite : endpoints.getLastClinicalImpressionByPatientIdDettagli) + "/" + this.patientId).toPromise()
      .then((resp: any) => {
        if (resp.total > 0) {
          this.lastVisit = resp.entry[0].resource;
        } else {
          this.lastVisit = undefined;
        }
      }).catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Errore nel caricamento dei Dati.');
      });
  }

  isLastVisit(): boolean {
    if (this.lastVisit) {
      // tslint:disable-next-line: triple-equals
      if (this.clinicalImpressionId == this.lastVisit.id) {
        return true;
      }
    }
    return false;
  }

  createObservationObjectFromField(code) {
    return {
      category: {
        coding: {
          code: 'devices',
          system: 'http://hl7.org/fhir/observation-category'
        }
      },
      code: {
        coding: {
          'code': code,
          display: this.getDisplayFromCode(code),
          system: 'http://snomed.info/sct'
        }
      },
      value: {
        type: 'string',
        unit: '',
        value: this.devicesForm.get(code).value
      }
    };
  }

  getDisplayFromCode(code: string): string {
    switch (code) {
      case '52124006':
        return 'CVC';
      case '35398009':
        return 'Stomia';
      case '20568009':
        return 'Catetere vescicale';
      case '25062003':
        return 'Sondino gastrico';
      case '235159007':
        return 'PEG/RIG';
      case '398176008':
        return 'CVP';
      case '713655003':
        return 'NIV';
      case '129121000':
        return 'Tracheostomia';
      case '334986003':
        return 'Assistente tosse';
      case 'other_device':
        return 'Altro';
    }
  }

  salvaDispositivi() {
    // Controllo se ci sono modifiche
    if (this.daControllare.length === 0) {
      this.toastr.info('Nulla da salvare.');
      return;
    }

    // Faccio un controllo sui campi dei dispositivi indossabili
    let tmpValue = this.devicesForm.get('mac_monitoring_device').value;
    if (tmpValue != undefined && tmpValue.length > 0) {
      // Faccio un ulteriore controllo sostituendo tutti i - con i :
      this.devicesForm.get('mac_monitoring_device').setValue((tmpValue as string).toUpperCase().split("-").join(":"));
    }
    tmpValue = this.devicesForm.get('account_monitoring_device').value;
    if (tmpValue != undefined && tmpValue.length > 0) {
      if (tmpValue == "Watch-Lite-SE-") {
        tmpValue = "";
      }
      if (!tmpValue.toLowerCase().includes('watch')) {
        this.toastr.warning("Inserire il modello del dispositivo oltre all'identificativo. Esempio: Watch-Lite-SE-0000");
        return;
      }
      let t = "" + tmpValue;
      t = t.toLowerCase().split(" ").join("").split("-").join("");
      if (t.includes("litese")) {
        tmpValue = "Watch-Lite-SE-" + (tmpValue as string).substring(tmpValue.length - 4);
      } else if (t.includes("lite")) {
        tmpValue = "Watch-Lite-" + (tmpValue as string).substring(tmpValue.length - 4);
      } else if (t.includes("gen")) {
        tmpValue = "VYVO Watch Gen2-" + (tmpValue as string).substring(tmpValue.length - 4);
      }
      this.devicesForm.get('account_monitoring_device').setValue(tmpValue);
    }

    // Gestione diversa per la visita
    if (this.isInVisita) {
      return this.salvaDispositiviVisita();
    }

    // Scorro l'array delle possibili modifiche
    // aggiorno man mano che trovo le modifiche
    let saved = false;
    const dispositiviNuovi = new Array();
    let obj = null;
    this.daControllare.forEach(id => {
      let dev;
      switch (id) {
        case '52124006':
        case '35398009':
        case '20568009':
        case '25062003':
        case '235159007':
        case '398176008':
        case '713655003':
        case '129121000':
        case '334986003':
          dev = this.getDeviceByIdFromDevices(id);
          if (dev == null) {
            dispositiviNuovi.push(this.creaNuovoDispositivo(id));
            break;
          }
          if (dev.status !== this.devicesForm.get(id).value) {
            dev.status = this.devicesForm.get(id).value;
            this.putDevice(dev.id, dev);
            saved = true;
          }
          break;
        case 'other_device':
          dev = this.getDeviceByIdFromDevices(id);
          if (dev == null) {
            dispositiviNuovi.push(this.creaNuovoDispositivo(id));
            break;
          }
          if (dev.note == undefined) {
            dev.note = [];
            dev.note[0] = {} as any;
            dev.note[0].text = "";
          }
          if (dev.note[0].text !== this.devicesForm.get(id).value) {
            dev.note[0].text = this.devicesForm.get(id).value;
            this.putDevice(dev.id, dev);
            saved = true;
          }
          break;
        case 'account_monitoring_device':
        case 'mac_monitoring_device':
          // Questi valori sono salvati nel paziente
          dev = this.getDeviceByIdFromPatient(id);
          if (obj == null) {
            obj = {
              patientId: this.patientId
            };
          }
          if (dev === null || dev.value !== this.devicesForm.get(id).value) {
            obj[id] = this.devicesForm.get(id).value;
          }
          break;
      }
    });
    if (this.history == 1) {
      this.http.post(endpoints.checkLogEventResource, "Patient/" + this.patientId + "/_history/" + this.history).toPromise().then((resp: boolean) => {
        if (resp) {
          this.logService.newEventNoCheck(this.resourceHistory, this.CODE, true, this.userService.getUser().username);
        }
      })
    }
    this.resourceHistory = "Patient/" + this.patientId + "/_history/" + this.history;
    this.logService.newEventNoCheck(this.resourceHistory, this.CODE, true, this.userService.getUser().username);


    if (dispositiviNuovi.length > 0) {
      const obj2 = {
        patientId: this.patientId,
        devices: dispositiviNuovi
      };
      saved = true;
      this.http.put(endpoints.addDevicesDettagli, obj2).toPromise()
        .then((resp: any) => {
          if (resp.success === '1') {
            this.getDevices();
            this.getPatient(this.patientId);
            this.toastr.success('Salvatagio avvenuto con successo.');
          } else {
            this.toastr.error('Errore nel salvataggio dei dispositivi.', 'ERRORE');
          }
        }).catch(err => {
          console.error(err);
          if (err.status == 403) {
            this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
            return;
          }
          this.toastr.error('Errore nel salvataggio dei dispositivi.', 'ERRORE');
        }).finally(() => {
          this.initResources();
        });
    }

    if (obj != null) {
      this.updateMonitoringDevices(obj);
      saved = true;
    }
    if (!saved) {
      this.toastr.info('Nulla da salvare');
      return;
    }
  }

  getPatient(id) {
    if (id) {
      this.http.get(endpoints.getPatientByPatientId + id).toPromise().then((resp: any) => {
        if (resp && resp.success == "1") {
          this.pazientiService.setPatient(resp.message);
        }
      }).catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Errore nel retrive del Paziente');
      });
    }
  }

  creaNuovoDispositivo(id) {
    const d = {
      code: id,
      display: '',
      status: this.devicesForm.get(id).value,
      system: 'http://snomed.info/sct'
    };
    switch (id) {
      case '52124006':
        d.display = 'CVC';
        break;
      case '35398009':
        d.display = 'Stomia';
        break;
      case '20568009':
        d.display = 'Catetere vescicale';
        break;
      case '25062003':
        d.display = 'Sondino gastrico';
        break;
      case '235159007':
        d.display = 'PEG/RIG';
        break;
      case '398176008':
        d.display = 'CVP';
        break;
      case '713655003':
        d.display = 'NIV';
        break;
      case '129121000':
        d.display = 'Tracheostomia';
        break;
      case '334986003':
        d.display = 'Assistente tosse';
        break;
      case 'other_device':
        d.display = 'Altro';
        break;
    }
    return d;
  }

  updateMonitoringDevices(obj) {
    return this.http.post(endpoints.updateMonitorningDevicesFromDettagli, obj).toPromise()
      .then((resp: any) => {
        if (resp.success === '1') {
          this.getDevices();
          this.toastr.success('Salvatagio avvenuto con successo.');
          this.getPatient(this.patientId);
        } else {
          this.toastr.error('Errore nel salvataggio dei dispositivi.' + (resp.message ? " " + resp.message : ""));
        }
      }).catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Errore nel salvataggio dei dispositivi.', 'ERRORE');
      }).finally(() => {
        this.initResources();
      });
  }

  putDevice(id: string, dev: Device) {
    return this.http.put((this.isInVisita ? endpoints.putDeviceVisite : endpoints.putDeviceDettagli) + '/' + id, dev).toPromise()
      .then((resp: any) => {
        this.getDevices();
        this.toastr.success('Salvatagio avvenuto con successo.');
        this.getPatient(this.patientId);
      }).catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Errore nel salvataggio dei dispositivi.', 'ERRORE');
      }).finally(() => {
        this.initResources();
      });
  }

  getDeviceByIdFromDevices(id: string) {
    for (const d of this.devices) {
      if (d.type.coding[0].code === id) {
        return d;
      }
    }
    return null;
  }

  getDeviceByIdFromPatient(id: string) {
    for (const d of this.patient.identifier) {
      if (d.use !== undefined) {
        continue;
      }
      if (d.type.coding[0].code === id) {
        return d;
      }
    }
    return null;
  }

  buildForm() {
    let ident;
    for (const identifier of this.pazientiService.getPatient().identifier) {
      switch (identifier.use) {
        case 'usual':
          ident = identifier.value;
          break;
        default:
          break;
      }
    }

    this.devicesForm = this.fb.group({
      '52124006': [''],
      '35398009': [''],
      '20568009': [''],
      '25062003': [''],
      '235159007': [''],
      '398176008': [''],
      '713655003': [''],
      '129121000': [''],
      '334986003': [''],
      'other_device': [''],
      'account_monitoring_device': ['Watch-Lite-SE-'],
      'mac_monitoring_device': [''],
      'accountMobileApp': '',
      'passwordMobileApp': ''
    });
    this.activeInactiveOptPortatoreCVC = activeInactiveOpt;
    this.activeInactiveOptStomia = activeInactiveOpt;
    this.activeInactiveOptVescicale = activeInactiveOpt;
    this.activeInactiveOptSondino = activeInactiveOpt;
    this.activeInactiveOptPeg = activeInactiveOpt;
    this.activeInactiveCVP = activeInactiveOpt;
    this.activeInactiveNIV = activeInactiveOpt;
    this.activeInactiveTreacheostomia = activeInactiveOpt;
    this.activeInactiveTosse = activeInactiveOpt;
    if (!this.checkPermessoModifica()) {
      this.devicesForm.disable();
    }
    if (this.isInVisita) {
      this.devicesForm.get('account_monitoring_device').disable();
      this.devicesForm.get('mac_monitoring_device').disable();
    }
    this.devicesForm.get('accountMobileApp').disable();
    this.devicesForm.get('passwordMobileApp').disable();
    this.showGeneraCredenzialiButton = false;
    this.unsubscribeAllFields();
    this.subscribeAllFields();
  }


  getUsername() {
    this.http.get(endpoints.userbyreferenceid + '?referenceid=Patient/' + this.patientId).subscribe((resp: any) => {
      if (resp) {
        if (resp.status === 'OK' && resp.object != null) {
          this.devicesForm.controls['accountMobileApp'].setValue(resp.object.username);
          this.showAccountInfo = resp.object.enabled;
        } else {
          this.toastr.error('Errore nel retrive dell\'username');
          this.showGeneraCredenzialiButton = true;
        }
      }
    }, err => {
      console.error(err);
      if (err.status == 403) {
        this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
        return;
      }
    });
  }

  subscribeOnValueChange(id: string) {
    this.devicesForm.get(id).valueChanges.subscribe(val => {
      if (this.daControllare.indexOf(id) === -1) {
        this.daControllare.push(id);
      }
    });
  }

  unsubscribeOnValueChange(id: string) {
    this.devicesForm.get(id).valueChanges.unsubscribe();
  }

  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
    this.unsubscribeAllFields();
  }

  subscribeAllFields() {
    this.daControllare = new Array();
    this.subscribeOnValueChange('52124006');
    this.subscribeOnValueChange('35398009');
    this.subscribeOnValueChange('20568009');
    this.subscribeOnValueChange('25062003');
    this.subscribeOnValueChange('235159007');
    this.subscribeOnValueChange('398176008');
    this.subscribeOnValueChange('713655003');
    this.subscribeOnValueChange('129121000');
    this.subscribeOnValueChange('334986003');
    this.subscribeOnValueChange('other_device');
    this.subscribeOnValueChange('account_monitoring_device');
    this.subscribeOnValueChange('mac_monitoring_device');
  }

  unsubscribeAllFields() {
    this.daControllare = new Array();
    this.subscribeOnValueChange('52124006');
    this.subscribeOnValueChange('35398009');
    this.subscribeOnValueChange('other_device');
    this.subscribeOnValueChange('account_monitoring_device');
    this.subscribeOnValueChange('mac_monitoring_device');
  }

  checkPermessoModifica(): boolean {
    if (!this.isInVisita) {
      return this.userService.checkPermission('Dettagli', 'modify');
    } else {
      return this.userService.checkPermission('Visite', 'modify') && this.userService.checkPermission('Osservazioni', 'modify');
    }
  }

  getIsInVisite(): boolean {
    const cId = this.route.parent.snapshot.paramMap.get('vId');
    if (this.clinicalImpressionId != null) {
      this.tempObj = [{ id: this.clinicalImpressionId, resourceType: "ClinicalImpression" }];
      this.logService.newEvent("ClinicalImpression/" + this.clinicalImpressionId + "/_history/1", null, false, this.userService.getUser().username)
    }
    if (cId) {
      return true;
    }
  }
  sycronizePatient() {
    this.http.get(endpoints.syncronizePatient + this.patientId).toPromise()
      .then(() => {
        //Aggiorno username e password di >Mobile App<
        this.getUsername();
        this.getPatient(this.patientId);
        this.toastr.success("Generazione delle credenziali avvenuta con successo!");
      }).catch((err) => {
        if (err.status === 409) {
          this.toastr.info("Credenziali già presenti, nulla da generare!");
        } else {
          this.toastr.error("Errore nella generazione delle credenziali!");
        }
      });
  }

}
