<ng-container *ngIf="isReport === false">
    <div class="card col-md-12 p-0" *ngIf="org == 'NEMO'">
        <div class="card-header border-0">
            <div class="d-flex justify-content-between">
                <h3 class="card-title">NLAB SCALE</h3>
            </div>
        </div>
        <div class="card-body">
            <div class="row p-fluid">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <div #nlab></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card col-md-12 p-0">
        <div class="card-header border-0">
            <div class="d-flex justify-content-between">
                <h3 class="card-title">Stato di salute</h3>
            </div>
        </div>
        <div class="card-body">
            <div class="row p-fluid">
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-body" [ngStyle]="bgcolorB">
                            <div #groupB></div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-body" [ngStyle]="bgcolorD">
                            <div #groupD></div>
                        </div>
                    </div>
                    <!-- <div *ngIf="!doloreSeries && !faticaSeries && !nauseaSeries" class="p-fluid text-center">
                            <p-progressSpinner></p-progressSpinner>
                        </div> -->
                    <!-- <apx-chart *ngIf="doloreSeries && faticaSeries && nauseaSeries" #doloreChartRef [chart]="doloreChart" [series]="doloreSeries"
                            [xaxis]="xaxis" [yaxis]="yaxis" [tooltip]="tooltipDolore" [stroke]="stroke" [fill]="fill"
                            [labels]="labelsDolore" [colors]="['#D4526E']" [title]="titleDolore"
                            [subtitle]="subtitleDolore"></apx-chart> -->
        
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="isReport === true">
    <div class="row" style="margin-top: 0.5cm; text-align: center">
        <div class="col-md-12">
            <div #groupB></div>
        </div>
        <div class="col-md-12" style="margin-top: 0.5cm">
            <div #groupD></div>
        </div>
    </div>
</ng-container>


