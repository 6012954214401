<div class="card">
    <div class="card-header border-0" *ngIf="showHeader === true">
        <div class="p-fluid justify-content-between">
            <h3 class="card-title">Fonte: "Mobile App"</h3>
            <div class="to-right" style="width: 195px;">
                <p-dropdown [options]="opt" [(ngModel)]="selected" dropdownIcon="fas fa-chevron-down"
                    (ngModelChange)="dropdownChange($event)"></p-dropdown>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div #chart></div>
    </div>
</div>