<div class="row">
    <div class="col-12">
        <div class="col-md-12">
            <label>{{message}}</label>
            <p-editor [style]="{'height':'150px'}" [(ngModel)]="actions"></p-editor>
        </div>
    </div>
</div>
<div class="row mt-3">
    <div class="col-12">
        <div class="float-right">
            <button pButton label="Salva" icon="fa fa-save" iconPos="left" styleClass="p-button-raised" class="p-button-success" type="button"
                (click)="salva()"></button>
        </div>
    </div>
</div>