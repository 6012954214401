export function getIndexSuffixByMeasureId(id: string): string {
    if ('Pressione sanguigna' === id) {
        return 'pressure';
    }
    if ('Frequenza cardiaca' === id) {
        return 'hr-br';
    }
    if ('Frequenza respiratoria' === id) {
        return 'hr-br';
    }
    if ('Passi' === id) {
        return 'steps';
    }
    if ('Ossigenazione' === id) {
        return 'spo2';
    }
    if ('Mood' === id) {
        return 'mood';
    }
    if ('Fatica' === id) {
        return 'mood';
    }
    if ('Sonno' === id) {
        return 'sleep';
    }
    if ('Temperatura' === id) {
        return 'temperature';
    }
}
