import { UserService } from 'src/app/service/user-service.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { endpoints } from 'src/endpoint/endpoints';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  alerts: BehaviorSubject<string>;

  count = '0';

  constructor(private http: HttpClient, private usrService: UserService) {
    this.count = '0';
    this.alerts = new BehaviorSubject(null);
  }

  countAlert() {
    this.findCountAlert();
  }

  decrementAlert() {
    this.findCountAlert();
  }

  setAlert(count) {
    this.count = count;
    this.alerts.next(this.count);
  }

  findCountAlert() {
    const userId = this.usrService.getUser().referenceId.split('/')[1];
    this.http.get(endpoints.getCountAlert+'&performer='+userId).toPromise()
      .then((resp: any) => {
        if (resp.entry) {
          if (resp.total) {
            this.count = resp.total;
          }
          if (!resp.total) {
            if (resp.entry.length > 0) {
              this.count = '100+';
            } else {
              this.count = '0';
            }
          }
          this.alerts.next(this.count);
        }
      }).catch(err => {
        console.error(err);
        this.count = "0";
        this.alerts.next(this.count);
      });
  }

  public getTypeMeasure(code) {
    switch (code) {
      case '8867-4': return 'Frequenza cardiaca';
      case '59408-5': return 'Ossigenazione';
      case '8462-4': return 'Diastolica';
      case '8480-6': return 'Sistolica';
      case 'pain': return 'Dolore';
      default: return '';
    }
  }

}
