import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appInputGroupHover]'
})
export class InputGroupHoverDirective {

  private el: HTMLElement;
  private colorBefore;

  constructor(private hostElement: ElementRef, private render: Renderer2) {
    this.el = hostElement.nativeElement;
   }

  @HostListener('mouseenter', ['$event']) onMouseEnter(e) {
    // this.render.removeClass(this.el.children[1], 'p-inputtext:hover');
    this.el.childNodes.forEach( (element: HTMLElement) => {
      this.colorBefore = element.style.borderColor;
      this.render.setStyle(element, 'border-color', 'black');
    });
  }

  @HostListener('mouseleave', ['$event']) onMouseLeave(e) {
    this.el.childNodes.forEach( (element: HTMLElement) => {
      this.render.setStyle(element, 'border-color', this.colorBefore);
    });
  }

  // @HostListener('focus', ['$event']) onFocus(e) {
  //   this.el.childNodes.forEach( (element: HTMLElement) => {
  //     this.colorBefore = element.style.borderColor;
  //     this.render.setStyle(element, 'border-color', '#007ad9');
  //   });
  // }

  // @HostListener('focusout', ['$event']) onFocusout(e) {
  //   this.el.childNodes.forEach( (element: HTMLElement) => {
  //     this.render.setStyle(element, 'border-color', this.colorBefore);
  //   });
  // }

}
