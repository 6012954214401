<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header bg-primary">Dettagli del caregiver</div>
            <div class="card-body">
                <form [formGroup]="relatedPersonForm">

                    <div class="form-group row">
                        <span class="col-md-4">
                            <label for="inputNome">Nome{{(isRequired("name") ? "*" : "")}}</label>
                            <input type="text" class="form-control" id="inputNome" placeholder="Enter nome"
                                formControlName="name" [ngClass]="{'is-invalid': relatedPersonForm.get('name').touched && relatedPersonForm.get('name').invalid}">
                        </span>
                        <span class="col-md-4">
                            <label for="inputCognome">Cognome{{(isRequired("surname") ? "*" : "")}}</label>
                            <input type="text" class="form-control" id="inputCognome" placeholder="Enter cognome"
                                formControlName="surname" [ngClass]="{'is-invalid': relatedPersonForm.get('surname').touched && relatedPersonForm.get('surname').invalid}">
                        </span>
                        <span class="col-md-4">
                            <label for="patient-birth-date">Data di nascita{{(isRequired("birthDate") ? "*" : "")}}</label>
                            <div class="input-group date">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fa fa-calendar"></i>
                                    </span>
                                </div>
                                <input type="text" id="inputData" class="form-control" placeholder="Data di Nascita"
                                    bsDatepicker formControlName="birthDate" [bsConfig]="datePickerConf" [ngClass]="{'is-invalid': relatedPersonForm.get('birthDate').touched && relatedPersonForm.get('birthDate').invalid}" />
                            </div>
                        </span>
                    </div>

                    <div class="form-group row">
                        <span class='col-md-4'>
                            <label for="inputCodiceFiscale">Codice Fiscale{{(isRequired("fiscalCode") ? "*" : "")}}</label>
                            <input type="text" class="form-control" id="inputCodiceFiscale"
                                placeholder="Enter Codice Fiscale" formControlName="fiscalCode" [ngClass]="{'is-invalid': relatedPersonForm.get('fiscalCode').touched && relatedPersonForm.get('fiscalCode').invalid}">
                        </span>
                        <span class="col-md-4">
                            <label>Comune di residenza{{(isRequired("address") ? "*" : "")}}</label>
                            <input type="text" class="form-control" placeholder="Comune di residenza"
                                formControlName="address" [ngClass]="{'is-invalid': relatedPersonForm.get('address').touched && relatedPersonForm.get('address').invalid}" />
                        </span>
                        <span class="col-md-4">
                            <label>Livello di parentela{{(isRequired("relationship") ? "*" : "")}}:</label>
                            <p-dropdown id="relationship" class="p-fluid" [options]="relationshipOpt"
                                formControlName="relationship" dropdownIcon="fas fa-chevron-down" [ngClass]="{'is-invalid': relatedPersonForm.get('relationship').touched && relatedPersonForm.get('relationship').invalid}"></p-dropdown>
                        </span>
                    </div>

                    <div class="form-group row">
                        <span class="col-md-4">
                            <label for="inputTelNumber">Cellulare{{(isRequired("phone") ? "*" : "")}}</label>
                            <input type="text" class="form-control" id="inputTelNumber" placeholder="Enter Telefono"
                                formControlName="phone"
                                [ngClass]="{'is-invalid': relatedPersonForm.get('phone').touched && relatedPersonForm.get('phone').invalid}">
                            <div class="invalid-feedback" *ngIf="relatedPersonForm.get('phone').invalid && relatedPersonForm.get('phone').touched">
                                Inserire un numero di Telefono.
                            </div>
                        </span>
                        <span class="col-md-4">
                            <label for="inputEmail">Email{{(isRequired("email") ? "*" : "")}}</label>
                            <input type="text" class="form-control" id="inputEmail" placeholder="Enter email"
                                formControlName="email"
                                [ngClass]="{'is-invalid': relatedPersonForm.get('email').touched && relatedPersonForm.get('email').invalid}">
                            <div class="invalid-feedback" *ngIf="relatedPersonForm.get('email').invalid && relatedPersonForm.get('email').touched">
                                Inserire una email valida.
                            </div>
                        </span>
                        <span class="col-md-4">
                            <div class="row">
                                <label style="margin-right: 1em">Genere{{(isRequired("gender") ? "*" : "")}}</label>
                            </div>
                            <div class="row">
                                <div class="col-md-12 row">
                                    <div class="custom-control custom-radio col-md-6 col-sm-6">
                                        <input class="custom-control-input" type="radio" id="customRadioMale1"
                                            formControlName="gender" value="{{gender.MALE}}" [ngClass]="{'is-invalid': relatedPersonForm.get('gender').touched && relatedPersonForm.get('gender').invalid}">
                                        <label for="customRadioMale1" class="custom-control-label">Maschio</label>
                                    </div>
                                    <div class="custom-control custom-radio col-md-6 col-sm-6">
                                        <input class="custom-control-input" type="radio" id="customRadioFemale1"
                                            formControlName="gender" value="{{gender.FEMALE}}" [ngClass]="{'is-invalid': relatedPersonForm.get('gender').touched && relatedPersonForm.get('gender').invalid}">
                                        <label for="customRadioFemale1" class="custom-control-label">Femmina</label>
                                    </div>
                                </div>
                                <div class="col-md-12 row">
                                    <div class="custom-control custom-radio col-md-6 col-sm-6">
                                        <input class="custom-control-input" type="radio" id="customRadioOther1"
                                            formControlName="gender" value="{{gender.OTHER}}" [ngClass]="{'is-invalid': relatedPersonForm.get('gender').touched && relatedPersonForm.get('gender').invalid}">
                                        <label for="customRadioOther1" class="custom-control-label">Altro</label>
                                    </div>
                                    <div class="custom-control custom-radio col-md-6 col-sm-6">
                                        <input class="custom-control-input" type="radio" id="customRadioUnknow1"
                                            formControlName="gender" value="{{gender.UNKNOW}}" [ngClass]="{'is-invalid': relatedPersonForm.get('gender').touched && relatedPersonForm.get('gender').invalid}">
                                        <label for="customRadioUnknow1" class="custom-control-label">Sconosciuto</label>
                                    </div>
                                </div>
                            </div>
                        </span>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <button *ngIf="section !== 'patient'" class="to-right p-button-danger mr-2" pButton label="Chiudi" icon="fa fa-times"
                                iconPos="left" id="close" type="button" (click)="close()"></button>
                            <button class="to-right p-button-success mr-2" pButton label="Salva" icon="fa fa-save"
                                iconPos="left" id="send" type="button" (click)="salva()" *ngIf="checkPermessoModifica()"></button>
                            
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>