import { BreakpointState } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LogEventService } from 'src/app/main/pazienti/service/log-event.service';
import { PazientiService } from 'src/app/main/pazienti/service/pazienti.service';
import { AppService } from 'src/app/service/app.service';
import { QuestionnaireService } from 'src/app/service/questionnaire-service.service';
import { UserService } from 'src/app/service/user-service.service';
import { endpoints } from 'src/endpoint/endpoints';

@Component({
  selector: 'app-scale-di-valutazione-sdc',
  templateUrl: './scale-di-valutazione-sdc.component.html',
  styleUrls: ['./scale-di-valutazione-sdc.component.scss']
})
export class ScaleDiValutazioneSDCComponent implements OnInit {

  // Per gestire il mobile
  isMobile = false;
  observer;

  clinicalImpressionId;
  idQuestionario;

  domande;

  isVisit = false;
  patientId;

  constructor(private toastr: ToastrService, private userService: UserService, private pazientiService: PazientiService,
    private http: HttpClient, private route: ActivatedRoute, private appServ: AppService, private questionariService: QuestionnaireService,
    private logService: LogEventService) { }

  ngOnInit() {
    this.getIsInVisite();
    this.getQuestionario();
    this.observer = this.appServ.mdAppObs
      .subscribe((statex: BreakpointState) => {
        this.isMobile = statex.matches;
      });
    this.patientId = this.pazientiService.getPatientId();
  }

  // Solo se non esiste nel database. Attenzione a non creare duplicati!!!
  creaQuestionario() {
    this.questionariService.createQuestionnaire(QuestionnaireService.SDC);
  }

  // Usare solo quando è realmente necessario
  aggiornaQuestionario() {
    this.questionariService.updateQuestionnaire(QuestionnaireService.SDC, this.idQuestionario);
  }

  getIsInVisite() {
    const cId = this.route.parent.parent.snapshot.paramMap.get('vId');
    if (cId) {
      this.isVisit = true;
      this.clinicalImpressionId = cId;
    }
  }

  getQuestionario() {
    const obj = {
      nome: 'SDC',
      versione: '1'
    };
    this.http.post(endpoints.getQuestionnaireByNameByVersion, obj).toPromise()
      .then((resp: any) => {
        this.idQuestionario = resp.id;
        this.domande = new Array();
        resp.item.forEach(item => {
          let domanda: any;
          domanda = {};
          domanda.testo = item.text;
          domanda.tipo = item.type;
          domanda.linkId = item.linkId;
          domanda.risposta = undefined;
          domanda.risposte = new Array();
          if (domanda.tipo === 'integer') {
            item.option.forEach(opt => {
              domanda.risposte.push({
                label: opt.valueCoding.display,
                value: opt.valueCoding.code
              });
            });
          }
          this.domande.push(domanda);
        });
        this.getRisposte();
      });
  }

  getRisposte() {
    let endpointToCall;
    let obj: any;
    obj = {};
    if (this.isVisit) {
      obj = { clinicalImpressionId: this.clinicalImpressionId, questionnaireId: this.idQuestionario };
      endpointToCall = endpoints.getClinicalImpressionSurveyResponse;
    } else {
      obj = { patientId: this.patientId, questionnaireId: this.idQuestionario };
      endpointToCall = endpoints.getClinicalImpressionSurveyResponseNLAB;
    }
    this.http.post(endpointToCall, obj).toPromise()
      .then((resp: any) => {
        const risposte = resp.item;
        let indice = 0;
        (this.domande.filter(d => d.tipo !== 'display')).forEach(domanda => {
          if (domanda.tipo === 'integer') {
            domanda.risposta = '' + risposte[indice++].answer[0].valueInteger;
          } else if (domanda.tipo === 'quantity') {
            domanda.risposta = '' + risposte[indice++].answer[0].valueString;
          }
        });
        this.logService.getAllOpt([[resp]], null);
      }).catch(err => {
        if (err.status === 404) {
          this.toastr.info('Questionario non eseguito.'); 
          this.logService.resetGlobalVarLog();
        } else {
          console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
          this.toastr.error('Errore nel caricamento delle risposte del questionario.');
        }
      });
  }

  salva() {
    let endpointToCall;
    let obj: any;
    obj = {};
    if (this.isVisit) {
      obj = {
        answers: new Array(),
        questionnaireId: this.idQuestionario,
        clinicalImpressionId: this.clinicalImpressionId
      };
      endpointToCall = endpoints.addClinicalImpressionSurveyResponse;
    } else {
      obj = {
        answers: new Array(),
        questionnaireId: this.idQuestionario,
        patientId: this.patientId
      };
      endpointToCall = endpoints.addClinicalImpressionSurveyResponseNLABsub;
    }

    const domandaPunteggio = this.domande.find(d => d.testo === 'Totale');
    domandaPunteggio.risposta = 0;

    try {
      (this.domande.filter(d => d.tipo !== 'display')).forEach(domanda => {
        if (domanda.risposta === undefined || domanda.risposta === null) {
          throw new Error('Non sono state risposte tutte le domande.');
        }
        if (domanda.tipo === 'integer') {
          domandaPunteggio.risposta += Number(domanda.risposta);
        }
        obj.answers.push({
          linkId: domanda.linkId,
          answer: domanda.risposta + '',
          type: domanda.tipo
        });
      });
    } catch (Exception) {
      this.toastr.error(Exception.message);
      return;
    }

    this.http.post(endpointToCall, obj).toPromise()
      .then((resp: any) => {
        this.toastr.success('Salvataggio avvenuto con successo.');
        this.getQuestionario();
      }).catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Errore nel salvataggio delle risposte del questionario.');
      });
  }

  checkPermessoModifica() {
    if (this.isVisit) {
      return this.userService.checkPermission('Osservazioni', 'modify') && this.userService.checkPermission('Visite', 'modify');
    } else {
      return this.userService.checkPermission('Anagrafica pazienti', 'modify')
    }
  }

}
