import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-input-modal',
  templateUrl: './input-modal.component.html',
  styleUrls: ['./input-modal.component.scss']
})
export class InputModalComponent implements OnInit {

  message;

  actions;

  // tslint:disable-next-line: max-line-length
  constructor(private ref: DynamicDialogRef, private config: DynamicDialogConfig, private fb: FormBuilder, private toastr: ToastrService) { }

  static open(dialogService: DialogService, input, altezza: string, larghezza: string) {
    return dialogService.open(InputModalComponent, {
      baseZIndex: 1500,
      // showHeader: false,
      header: input.header,
      height: altezza,
      width: larghezza,
      dismissableMask: true,
      data: input,
    });
  }

  ngOnInit() {
    this.message = this.config.data.message;
  }

  salva() {
    if (this.actions) {
      this.ref.close(this.actions);
    } else {
      this.toastr.warning('E\' obbligatorio compilare tutti i campi');
    }
  }
}
