<div class="wrapper mainWrapper">
    <app-header></app-header>

    <app-menu-sidebar></app-menu-sidebar>
    <div class="content-wrapper px-4 py-2">
        <main>
            <!-- <section class="content"> -->
            <router-outlet></router-outlet>
            <!-- </section> -->
        </main>
    </div>

    <app-footer class="text-sm"></app-footer>
    <aside class="control-sidebar control-sidebar-dark"></aside>
    <!-- <div id="sidebar-overlay" (click)="toggleMenuSidebar()"></div> -->
</div>