import { PanelMenuModule } from 'primeng/panelmenu';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FullCalendarModule } from '@fullcalendar/angular';
import { MorrisJsModule } from 'angular-morris-js';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { EditorModule } from 'primeng/editor';
import { MenuModule } from 'primeng/menu';
import { TooltipModule } from 'primeng/tooltip';
import { GlobalModule } from '../global/global.module';
import { PageNotFoundComponent } from '../page-not-found/page-not-found.component';
import { DashboardComponent } from './dashboard-component/dashboard.component';
import { DettagliPazienteModalComponent } from './dettagli-paziente-modal/dettagli-paziente-modal.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { InfoPazienteModalComponent } from './info-paziente-modal/info-paziente-modal.component';
import { MainRoutingModule } from './main-routing.module';
import { MainComponent } from './main/main.component';
import { MenuSidebarComponent } from './menu-sidebar/menu-sidebar.component';
import { SharedPazientiModule } from './pazienti/shared-pazienti/shared-pazienti.module';

@NgModule({
  declarations: [
    DashboardComponent,
    MainComponent,
    FooterComponent,
    HeaderComponent,
    MenuSidebarComponent,
    PageNotFoundComponent,
    InfoPazienteModalComponent,
    DettagliPazienteModalComponent,
  ],
  imports: [
    CommonModule,
    GlobalModule,
    FormsModule,
    ShowHidePasswordModule,
    ReactiveFormsModule,
    HttpClientModule,
    MorrisJsModule,
    FullCalendarModule,
    TooltipModule,
    MenuModule,
    ButtonModule,
    SharedPazientiModule,
    DialogModule,
    EditorModule,
    PanelMenuModule,
    MainRoutingModule, // DEVE restare l'ultimo
  ],
  exports: [
    PageNotFoundComponent
  ]
})
export class MainModule { }
