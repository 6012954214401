import { Injectable } from '@angular/core';
import { Client, CompatClient, Stomp } from '@stomp/stompjs';
import * as SockJS from 'sockjs-client';
import { environment } from 'src/environments/environment';
import { TokenService } from './token.service';
import { UserService } from './user-service.service';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  client;
  ws: CompatClient;

  constructor(private tokenService: TokenService, private userService: UserService) { }


  connect() {
    this.client = new Client({
      brokerURL: 'ws://localhost:44888/ws' + '/secured/alert/?access_token=' + this.tokenService.getToken(),
      connectHeaders: {
        // authentication: this.tokenService.getToken()
      },
      debug: (str) => {
        console.log(str);
      },
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000
    });

    if (typeof WebSocket !== 'function') {
      // For SockJS you need to set a factory that creates a new SockJS instance
      // to be used for each (re)connect
      this.client.webSocketFactory = () => {
        // Note that the URL is different from the WebSocket URL
        return new SockJS('http://localhost:44888/ws' + '/secured/socket/alert/?access_token=' + this.tokenService.getToken());
      };
    }

    this.client.onConnect = (frame) => {
      // Do something, all subscribes must be done is this callback
      // This is needed because this will be executed after a (re)connect
    };

    this.client.onStompError = (frame) => {
      // Will be invoked in case of error encountered at Broker
      // Bad login/passcode typically will cause an error
      // Complaint brokers will set `message` header with a brief message. Body may contain details.
      // Compliant brokers will terminate the connection after any error
    };

    this.client.activate();
  }

  connect_ws() {
    // this._network.checkCredentials();
    // connect to stomp where stomp endpoint is exposed
    const socket = new SockJS(environment.mobile + '/secured/alert/?access_token=' + this.tokenService.getToken());
    // let socket = new WebSocket("ws://localhost:8080/greeting");
    this.ws = Stomp.over(socket);
    this.ws.connectHeaders = {
      // authentication: this.tokenService.getToken()
      Organization: this.userService.getCurrentOrganization()
    };
    this.ws.activate();
    this.ws.reconnectDelay = 5000;
    this.ws.heartbeatIncoming = 4000;
    this.ws.heartbeatOutgoing = 4000;
    this.ws.debug = (str) => {
      console.log(str);
    };
    this.ws.onStompError = (str) => {
      console.error(str);
    };
    this.ws.onWebSocketError = (str) => {
      console.error(str);
    };
    return this.ws;
  }

}
