import { FormGroup } from '@angular/forms';

export class Observation {
    resourceType?: string;
    id?: string;
    meta?: Meta;
    category?: Category[];
    code?: Code;
    subject?: Subject;
    valueCodeableConcept?: Code;
    valueString?: string;
    valueQuantity?: Quantity;
    referenceRange?: Range[];

    // tslint:disable-next-line: max-line-length
    constructor(category?, codeCode?, displayCode?, systemCode?, subject?, valueString?, valueCodeableConceptSystem?, valueQuantity?: Quantity, referenceRange?: Range[]) {
        if (category) {
            this.category = this.getCategory(category);
        }
        this.resourceType = 'Observation';
        if (codeCode && displayCode) {
            this.code = this.getCode(codeCode, displayCode, systemCode);
        }
        if (subject) {
            this.subject = this.getSubject(subject);
        }
        if (valueString != null && valueString !== undefined) {
            this.valueString = valueString;
        }
        if (valueCodeableConceptSystem) {
            this.valueCodeableConcept = this.getValueCodeableConcept(valueCodeableConceptSystem);
        }
        if (valueQuantity) {
            this.valueQuantity = valueQuantity;
        }
        if (referenceRange) {
            this.referenceRange = referenceRange;
        }
    }

    private getCategory(cat): Category[] {
        const ret = new Array<Category>();
        ret.push({});
        ret[0].coding = new Array<CodeCoding>();
        ret[0].coding.push({});
        ret[0].coding[0].code = cat;
        ret[0].coding[0].system = 'http://hl7.org/fhir/observation-category';
        return ret;
    }

    private getCode(codeC, display, system): Code {
        const code = {} as Code;
        code.coding = new Array<CodeCoding>();
        code.coding.push({} as CodeCoding);
        code.coding[0].code = codeC;
        code.coding[0].display = display;
        code.coding[0].system = system;
        return code;
    }

    private getSubject(subjectIn): Subject {
        const subject = {} as Subject;
        subject.reference = subjectIn;
        return subject;
    }

    public getValueCodeableConcept(system): Code {
        const valueCodeableConcept = {} as Code;
        valueCodeableConcept.coding = new Array<CodeCoding>();
        valueCodeableConcept.coding.push({} as CodeCoding);
        valueCodeableConcept.coding[0].system = system;
        return valueCodeableConcept;
    }
}

export interface Category {
    coding?: CategoryCoding[];
}

export interface CategoryCoding {
    system?: string;
    code?: string;
}

export interface Code {
    coding?: CodeCoding[];
}

export interface CodeCoding {
    system?: string;
    code?: string;
    display?: string;
    version?: string;
}

export interface Meta {
    versionId?: string;
    lastUpdated?: Date;
}

export interface Subject {
    reference?: string;
}

export interface Quantity {
    unit?: string;
    value?: any;
}

export interface Range {
    high?: Value;
    low?: Value;
}

export interface Value {
    value?: any;
}
/**
 * Aggiorna il valore del form con il valore delle observation
 * a patto che i formControl presenti nel FormGroup in inut
 * abbiano lo stesso nome del code nelle Observation
 */
export function updateFormByObs(obs: Observation[], form: FormGroup) {
    obs.forEach(elem => {
        if (elem.code && elem.code.coding[0]) {
            if (form.get(elem.code.coding[0].code)) {
                if (elem.valueCodeableConcept) {
                    form.get(elem.code.coding[0].code).setValue(elem.valueCodeableConcept.coding[0].code);
                } else if (elem.valueString != null || elem.valueString !== undefined) {
                    form.get(elem.code.coding[0].code).setValue(elem.valueString);
                } else if (elem.valueQuantity) {
                    form.get(elem.code.coding[0].code).setValue(elem.valueQuantity.value);
                } else if (elem.referenceRange) {
                    form.get(elem.code.coding[0].code).setValue([elem.referenceRange[0].low.value, elem.referenceRange[0].high.value]);
                }
            }
        }
    });
}

/** Controlla se gli elementi del form passato in input siano stati modificati
 * a patto che il nome delle form control sia uguale al nome del code delle Observation
 * ritorna un array contenente le observation modificate
 */
export function checkIsModified(form: FormGroup, input: Observation[]): Observation[] {
    const ret = new Array<Observation>();
    Object.keys(form.controls).forEach(el => {
        const find = input.find(x => x.code.coding[0].code === el);
        const controlValue = form.get(el).value;
        if (find) {
            if (find.valueCodeableConcept && find.valueCodeableConcept.coding) {
                if (find.valueCodeableConcept.coding[0].code !== controlValue) {
                    ret.push(find);
                }
            } else if (find.valueString != null && find.valueString !== undefined) {
                if (find.valueString !== controlValue) {
                    ret.push(find);
                }
            } else if (find.valueQuantity) {
                if (find.valueQuantity.value !== controlValue) {
                    ret.push(find);
                }
            }
            if (find.referenceRange) {
                if (find.referenceRange[0].low.value !== controlValue[0] || find.referenceRange[0].high.value !== controlValue[1]) {
                    ret.push(find);
                }
            }
        }
    });
    return ret;
}
/**
 * Fa l'udate dei valori dell'observation dai valori del form passato in input
 * a patto che il nome delle form control sia uguale al nome del code delle Observation
 * ritorna un array contenente le observation modificate
 */
export function updateObsByForm(obs: Observation[], form: FormGroup): Observation[] {
    const ret = new Array<Observation>();
    Object.keys(form.controls).forEach(el => {
        const find = obs.find(x => x.code.coding[0].code === el);
        if (find) {
            if (find.valueCodeableConcept) {
                const option = this.getOptByKey(el).find(x => x.value === form.get(el).value);
                find.valueCodeableConcept.coding[0].code = form.get(el).value;
                if (option) {
                    find.valueCodeableConcept.coding[0].display = option.label;
                }
            } else if (find.valueString != null && find.valueString !== undefined) {
                find.valueString = form.get(el).value;
            }
            ret.push(find);
        }
    });
    return ret;
}
