import { LayoutModule } from '@angular/cdk/layout';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, RadioControlValueAccessor, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { itLocale } from 'ngx-bootstrap/locale';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { GlobalModule } from 'src/app/global/global.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { MainModule } from './main/main.module';
import { SharedGraficiModule } from './main/pazienti/shared-grafici/shared-grafici.module';
import { ConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';
import { HelpModalComponent } from './modals/help-modal/help-modal.component';
import { InputModalComponent } from './modals/input-modal/input-modal.component';
import { PrivacyModalComponent } from './modals/privacy-modal/privacy-modal.component';
import { ReportComponent } from './report/report.component';
import { AppService } from './service/app.service';
import { AuthGuardService } from './service/auth-guard.service';
import { TokenInterceptor } from './service/interceptor/token-interceptor';
import { NetworkService } from './service/network-service.service';
import { TokenService } from './service/token.service';
import { UserService } from './service/user-service.service';
import { PaginatorModule } from 'primeng/paginator';

import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { CustomizationService } from './service/customization.service';

defineLocale('it', itLocale);

const cookieConfig: NgcCookieConsentConfig = {
  "cookie": {
    "domain": "tinesoft.github.io"
  },
  "position": "bottom",
  "theme": "classic",
  "palette": {
    "popup": {
      "background": "#011a54",
      "text": "#ffffff",
      "link": "#ffffff"
    },
    "button": {
      "background": "#009479",
      "text": "#ffffff",
      "border": "transparent"
    }
  },
  "type": "opt-in",
  "content": {
    "message": "Questo sito o gli strumenti terzi da questo utilizzati si avvalgono di cookie necessari al funzionamento ed utili alle finalità illustrate nella cookie policy. <br>Se vuoi saperne di più o negare il consenso a tutti o ad alcuni cookie, consulta la <a href=\"https://www.eda.it\">cookie policy</a>. <br>Cliccando sul bottone \"Accetta\", acconsenti all’uso dei cookie.",
    "dismiss": "Accetta",
    "allow": "Accetta",
    "deny": "Rifiuta",
    "link": "",
    "href": "https://cookiesandyou.com",
    "policy": "Cookie Policy"
  }
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ConfirmModalComponent,
    HelpModalComponent,
    InputModalComponent,
    PrivacyModalComponent,
    ReportComponent,
  ],
  imports: [
    TableModule,
    SharedGraficiModule,
    PaginatorModule,
    BsDatepickerModule.forRoot(),
    ToggleButtonModule,
    OverlayPanelModule,
    AccordionModule,
    GlobalModule,
    BrowserModule,
    BrowserAnimationsModule,
    LayoutModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    HttpClientModule,
    MainModule,
    DialogModule,
    ToastrModule.forRoot({ preventDuplicates: true, positionClass: 'toast-top-center' }),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    InputTextModule,
    ButtonModule,
    EditorModule,
    FormsModule,
    CheckboxModule,
    DropdownModule,
    BsDropdownModule.forRoot(),
    NgcCookieConsentModule.forRoot(cookieConfig),
    AppRoutingModule // DEVE restare l'ultimo
  ],
  providers: [
    CustomizationService,
    NetworkService,
    AppService,
    AuthGuardService,
    TokenService,
    UserService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
