import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/service/app.service';
import { UserService } from 'src/app/service/user-service.service';

declare var $;

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {

  public sidebarMenuCollapsed = false;

  observer;


  // tslint:disable-next-line: max-line-length
  constructor(private renderer: Renderer2, private userService: UserService, private appSrv: AppService, @Inject(DOCUMENT) document, private router: Router) { }

  ngOnInit() {
    this.renderer.setStyle(document.body, 'background-color', '#e7e9f1');
    this.userService.retriveUser();
    /*this.observer = this.appSrv.mdAppObs
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.renderer.removeClass(document.body, 'sidebar-open');
          this.renderer.addClass(document.body, 'sidebar-collapse');
          this.sidebarMenuCollapsed = false;
        } else {
          this.renderer.removeClass(document.body, 'sidebar-collapse');
          this.renderer.addClass(document.body, 'sidebar-open');
          this.sidebarMenuCollapsed = true;
        }
      });*/
      document.body.classList.add("sidebar-mini");
      document.body.classList.add("layout-fixed");
      document.body.classList.add("layout-navbar-fixed");
      document.body.classList.add("sidebar-open");
    // this.renderer.addClass(document.body, 'hold-transitiond');
    /*this.renderer.addClass(document.body, 'sidebar-mini');
    this.renderer.addClass(document.body, 'layout-fixed');
    this.renderer.addClass(document.body, 'layout-navbar-fixed');*/

  }

  ngOnDestroy(): void {
    //this.observer.unsubscribe();
  }
}
