<p-dialog>

</p-dialog>
<div class="row">
  <div class="col-12">
    <h3>Hai bisogno di aiuto? </h3>
    <button pButton label="Scarica il manuale d'uso" icon="fas fa-download" iconPosition="left" (click)="openIFU()"
      class="p-button-secondary"></button>
  </div>
</div>
<div class="row mt-3">
  <div class="col-12">
    <h5 class="mb-2">oppure contattaci: </h5>
    <form [formGroup]="form" class="p-fluid">
      <div class="row mb-2 mt-2">
        <div class="col-6">
          <label>Nome e Cognome*</label>
          <input formControlName="name" type="text" placeholder="" pInputText />
          <div class="invalid-text"
            *ngIf="form.get('name').invalid && (form.get('name').dirty || form.get('name').touched)">
            Inserire Nome e Cognome.
          </div>
        </div>
        <div class="col-6">
          <label>Email*</label>
          <input formControlName="email" type="text" placeholder="" pInputText />
          <div class="invalid-text"
            *ngIf="form.get('email').invalid  && (form.get('email').dirty || form.get('email').touched)">
            Inserire una email valida.
          </div>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-3">
          <label>Tag*</label>
          <div id="tagDropdown" class="input-group mb-2">
            <p-dropdown id="inputGroupSelect01" [options]="listTag" formControlName="selectedTag"
              dropdownIcon="fas fa-chevron-down"></p-dropdown>
          </div>
        </div>
        <div class="col-9">
          <label>Oggetto*</label>
          <input formControlName="oggetto" type="text" placeholder="" pInputText />
          <div class="invalid-text"
            *ngIf="form.get('oggetto').invalid && (form.get('oggetto').dirty || form.get('oggetto').touched)">
            Inserire Oggetto.
          </div>
        </div>
      </div>
      <div class="row mb-4 mt-2">
        <div class="col-12">
          <p-checkbox name="eventoSospetto" value="true" label="Evento sospetto" formControlName="eventoSospetto">
          </p-checkbox>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-12">
          <label>Descrizione*</label>
          <p-editor formControlName="testo" [style]="{'height':'250px'}"></p-editor>
          <div class="invalid-text"
            *ngIf="form.get('testo').invalid && (form.get('testo').dirty || form.get('testo').touched)">
            Inserire una descrizione.
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-12">
          <div class="float-left">
            <button pButton type="button" class="p-button-success" label="Invia" (click)="invia()"></button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>