import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { PazientiService } from "../main/pazienti/service/pazienti.service";

@Injectable({
    providedIn: 'root'
})
export class ReportDataResolver implements Resolve <any> {

    constructor(public pazientiService: PazientiService){}

    /**
     * Metodo che si occuperà di recuperare tutti i dati necessari
     * a compilare il report
     * @param route 
     * @param state 
     */
    async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any>{
        /*if (null === route.queryParamMap.get("id") || null === route.queryParamMap.get("token")) {
            // non visualizza nulla
            return null;
        }
        return await this.creaOggettoDaRestituire(route);*/
    }

    async creaOggettoDaRestituire(route: ActivatedRouteSnapshot) {
        let toReturn: any = {};
        toReturn.patientId = route.queryParamMap.get("id");
        toReturn.token = route.queryParamMap.get("token");
        toReturn = await this.setListaICD(toReturn.patientId, toReturn);
        return toReturn;
    }

    async setListaICD(idPaziente, toReturn) {
        return await this.pazientiService.getListaICDRequest(idPaziente).then((resp:any) => {
            let tempICD = resp.message;
            tempICD = tempICD.filter(elem => {
                if (elem.code.coding[0].code === 'DescrizioneAnamnesiPatologicaRemota' ||
                elem.code.coding[0].code === 'DescrizioneAnamnesiPatologicaProssima') {
                return false;
                } else {
                return true;
                }
            });
            toReturn.condizioniCliniche = new Array();
            tempICD.forEach(obj => {
                toReturn.condizioniCliniche.push(obj.code.coding[0].code + " - " + obj.code.coding[0].display);
            });
            // Recupero le descrizioni delle anamnesi patologiche evitando di fare un'altra chiamata
            let tempAnamnesiPatologica = resp.message.find(elem => elem.code.coding[0].code === 'DescrizioneAnamnesiPatologicaProssima');
            toReturn.descrizioneAnamnesiPatologicaProssima = "";
            if (tempAnamnesiPatologica !== undefined) {
                toReturn.descrizioneAnamnesiPatologicaProssima = tempAnamnesiPatologica.code.coding[0].display;
            }
            tempAnamnesiPatologica = undefined;
            tempAnamnesiPatologica = resp.message.find(elem => elem.code.coding[0].code === 'DescrizioneAnamnesiPatologicaremota');
            toReturn.descrizioneAnamnesiPatologicaRemota = "";
            if (tempAnamnesiPatologica !== undefined) {
                toReturn.descrizioneAnamnesiPatologicaRemota = tempAnamnesiPatologica.code.coding[0].display;
            }
            return toReturn;
        }).catch(err => {
            console.error(err);
            return toReturn;
        });
    }

}