export const environment = {
  production: true,
  authServer: '{{ .Env.PUBLIC_API_GATEWAY_URL }}/authorization',
  resourceServer: '{{ .Env.PUBLIC_API_GATEWAY_URL }}/resource',
  fhirServer: '{{ .Env.PUBLIC_API_GATEWAY_URL }}/fhir',
  elastic: '{{ .Env.PUBLIC_API_GATEWAY_URL }}/resource',
  mobile: '{{ .Env.PUBLIC_API_GATEWAY_URL }}/mobile',
  company: 'eda.it',
  baseHref: 'cloud'
};
