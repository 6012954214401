import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PasswordChangeComponent } from '../global/password-change/password-change.component';
import { PageNotFoundComponent } from '../page-not-found/page-not-found.component';
import { AuthGuardService as AuthGuard } from '../service/auth-guard.service';
import { DashboardComponent } from './dashboard-component/dashboard.component';
import { MainComponent } from './main/main.component';



const routesDash: Routes = [
  { path: '', redirectTo: '/main/pazienti/listapazienti', pathMatch: 'full' },
  {
    path: 'main', component: MainComponent, canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/main/pazienti/listapazienti', pathMatch: 'full' },
      {
        path: 'adminpanel',
        loadChildren: () => import('./administration-panel/administration-panel.module').then(mod => mod.AdministrationPanelModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'pazienti',
        loadChildren: () => import('./pazienti/pazienti.module').then(mod => mod.PazientiModule),
        canActivate: [AuthGuard]
      },
      //{ path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
      { path: 'pagenotfound', component: PageNotFoundComponent, canActivate: [AuthGuard] },
      { path: 'password-change', component: PasswordChangeComponent, canActivate: [AuthGuard] },
    ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routesDash)],
  exports: [RouterModule]
})
export class MainRoutingModule { }
