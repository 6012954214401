import { FormGroup, Validators } from "@angular/forms";


export class ValidatorsManager {
    
    form: FormGroup;
    campiObbligatori = new Array<string>();
    validators = new Array<Validators>();

    constructor() {}

    // Gestione dei campi obbligatori del form
    /**
     * Cicla sui campi del form e setta i Validators.
     */
    setAllValidatorsToForm() {
      for (let k in this.form.controls) {
        this.form.get(k).setValidators(this.getValidatorsByFormControlName(k));
      }
      this.form.markAsUntouched();
      this.form.updateValueAndValidity();
    }
    /**
     * Aggiunge il validator al campo designato.
     * @param formControlName campo del form
     * @param validator oggetto Validator
     */
    addValidatorByFormControlName (formControlName: string, validator: Validators) {
      if (this.validators[formControlName] == undefined) {
        this.validators[formControlName] = new Array();
      }
      this.validators[formControlName].push(validator);
    }
    /**
     * Aggiunge il validator che rende il campo obbligatorio in base
     * alla lista dei campi obbligatori DEFINITI PRECEDENTEMENTE
     */
    aggiungiValidatorObbligatori () {
      // Aggiungo i Validators dinamicamente in base alla lista dei campi obbligatori
      for(let campo of this.campiObbligatori) {
        this.addValidatorByFormControlName(campo, Validators.required);
      }
    }

    isRequired (formControlName: string) {
      return this.campiObbligatori.includes(formControlName)
    }

    getValidatorsByFormControlName (formcontrolName: string) {
      if (this.validators[formcontrolName] == undefined) {
        this.validators[formcontrolName] = new Array();
      }
      return this.validators[formcontrolName];
    }

    addCampoObbligatorio(formControlName: string) {
        this.campiObbligatori.push(formControlName);
    }
}