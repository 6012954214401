import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SelectItem } from 'primeng/api';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { CustomizationService } from 'src/app/service/customization.service';
import { NetworkService } from 'src/app/service/network-service.service';
import { UserService } from 'src/app/service/user-service.service';
import { endpoints } from 'src/endpoint/endpoints';
import { PazientiService } from '../../service/pazienti.service';
import { DateFromTo } from '../Model/date-from-to';
import { getAssiYCombo, getBarChartOptions } from '../Utils/apexchart-opt';
// tslint:disable-next-line: max-line-length
import { cutSeries, getSeriesDataValue, getSeriesResponses, getSeriesScores, SeriesLabels, SerieType } from '../Utils/grafici-utils';
import { getInputHrMeasures } from '../Utils/query-input';

@Component({
  selector: 'app-grafici-vs',
  templateUrl: './grafici-vs.component.html',
  styleUrls: ['./grafici-vs.component.scss']
})
export class GraficiVsComponent implements OnInit, OnDestroy {

  @Input() identifier;

  @Input('date') set date(date: DateFromTo) {
    if (!date.compare(this._date)) {
      this.observer.next(date);
    }
  }

  // tslint:disable-next-line: variable-name
  _date: DateFromTo;

  // Colori definiti dal tema
  COLORE_DOLORE = '#de2826';
  COLORE_NAUSEA = '#fedb00';
  COLORE_FATICA = '#ff6c00';

  observer: BehaviorSubject<DateFromTo>;

  dateFromString: string;
  dateToString: string;
  dateFromTimeStamp;
  dateToTimeStamp;

  opt2;

  optDolore;

  patientId;

  optFatica: SelectItem[] = [
    { label: 'Frequenza cardiaca', value: 'Frequenza cardiaca' },
    { label: 'Pressione sanguigna', value: 'Pressione sanguigna' },
    { label: 'Frequenza respiratoria', value: 'Frequenza respiratoria' },
    { label: 'Passi', value: 'Passi' },
    { label: 'Mood', value: 'Mood' },
    { label: 'Energia', value: 'Energia' },
  ];
  optNausea: SelectItem[] = [
    { label: 'Frequenza cardiaca', value: 'Frequenza cardiaca' },
    { label: 'Pressione sanguigna', value: 'Pressione sanguigna' },
    { label: 'Frequenza respiratoria', value: 'Frequenza respiratoria' },
    { label: 'Passi', value: 'Passi' },
    { label: 'Mood', value: 'Mood' },
    { label: 'Energia', value: 'Energia' },
  ];
  optDiarrea: SelectItem[] = [
    { label: 'Peso', value: 'Peso' },
  ];
  optPassi: SelectItem[] = [
    { label: 'Energia', value: 'Energia' },
    // { label: 'Peso', value: 'Peso' },
  ];
  opt;

  selected1 = null;
  selected = null;

  stroke: ApexStroke = {
    show: true,
    curve: 'straight',
    lineCap: 'butt',
    width: 2,
    dashArray: 0,
  };

  @ViewChild('chart', { static: true }) chartRef: ElementRef;

  chart: ApexCharts;

  doloreType = { name: 'Dolore', color: this.COLORE_DOLORE, serie: [], topic: 'dolore', type: 'column', display: ['Dolore'] };

  // tslint:disable-next-line: max-line-length
  constructor(private http: HttpClient, private toastr: ToastrService, private datePipe: DatePipe, private network: NetworkService,
    private userService: UserService, private patientService: PazientiService, private customizationService: CustomizationService) {
    this.observer = new BehaviorSubject<DateFromTo>(null);
  }

  ngOnInit() {
    const paziente = this.patientService.getPatient();
    this.patientId = paziente.id;
    this.opt2 = this.customizationService.getOpt2ByOrganizationName(this.userService.getCurrentOrganization());
    this.optDolore = this.customizationService.getOptDoloreByOrganizationName(this.userService.getCurrentOrganization());
    this.opt = this.optDolore;
    this.observer.subscribe((input) => {
      if (this.identifier && input) {
        this._date = input;
        this.dateFromString = this.datePipe.transform(input.getDateFrom(), 'yyyy-MM-dd');
        this.dateToString = this.datePipe.transform(input.getDateTo(), 'yyyy-MM-dd');
        this.dateFromTimeStamp = Math.round(input.getDateFrom().getTime());
        this.dateToTimeStamp = Math.round(input.getDateTo().getTime());
        this.dropdownChange(this.selected1);
      }
    });
  }

  ngOnDestroy(): void {
    this.observer.unsubscribe();
  }

  getTendinaOptChange(key) {
    switch (key) {
      case 'Dolore':
        // this.opt = this.optDolore;
        this.selected = this.opt[0].value;
        break;
      case 'Fatica':
        // this.opt = this.optFatica;
        this.selected = this.opt[0].value;
        break;
      case 'Nausea':
        // this.opt = this.optNausea;
        this.selected = this.opt[0].value;
        break;
      case 'Funzionalita':
        // this.opt = this.optNausea;
        this.selected = this.opt[0].value;
        break;
      case 'Diarrea (Frequenza)':
        // this.opt = this.optDiarrea;
        this.selected = this.opt[0].value;
        break;
      case 'Stipsi (Frequenza)':
        // this.opt = this.optDiarrea;
        this.selected = this.opt[0].value;
        break;
      case 'Passi':
        // this.opt = this.optPassi;
        this.selected = this.opt[0].value;
        break;
      default:
        break;
    }
  }

  dropdownChange(event, tendina?) {
    if (tendina === 1) {
      this.getTendinaOptChange(event);
    }
    switch (this.selected1) {
      case 'Dolore':
        this.callCreateChart('DoloreComboChartID', this.selected, this.selected1);
        break;
      case 'Fatica':
        this.callCreateChart('FaticaComboChartID', this.selected, this.selected1);
        break;
      case 'Nausea':
        this.callCreateChart('NauseaComboChartID', this.selected, this.selected1);
        break;
      case 'Funzionalita':
        this.callCreateChart('FunzionalitaComboChartID', this.selected, this.selected1);
        break;
      case 'Diarrea (Frequenza)':
        this.callCreateChart('DiarreaComboChartID', this.selected, this.selected1);
        break;
      case 'Stipsi (Frequenza)':
        this.callCreateChart('StipsiComboChartID', this.selected, this.selected1);
        break;
      case 'Passi':
        this.callCreateChart('PassiComboChartID', this.selected, this.selected1);
        break;
      default:
        break;
    }
  }

  callCreateChart(id, selected, selected1) {
    if (selected && selected1) {
      const array = new Array<SerieType>();
      let serie = this.getSerieType(selected1, this.selected);
      if (Array.isArray(serie)) {
        serie.forEach(el => array.push(el));
      } else {
        array.push(serie);
      }
      serie = this.getSerieType(selected, this.selected);
      if (Array.isArray(serie)) {
        serie.forEach(el => array.push(el));
      } else {
        array.push(serie);
      }
      this.createChart(id, selected1 + ' vs ' + selected, array);
    }
  }

  createChart(id, title, chartArray, assiY?) {
    forkJoin(chartArray.map(sintomo => {
      return this.getSelfReportedData(sintomo.name, sintomo.topic);
    })).subscribe((resp: any) => {

      if (resp) {
        let series: ApexAxisChartSeries = [];
        const labels = [];
        let countResp = 0;

        resp.forEach((response, index) => {
          let seriesLabels = {} as SeriesLabels;
          seriesLabels.serie = new Array();
          seriesLabels.labels = new Array();
          if (title.includes('Passi')) {
            seriesLabels = getSeriesDataValue(response, chartArray[index].display, chartArray[index].type, chartArray[index].name);
          }
          if (title.includes('Frequenza cardiaca')) {
            seriesLabels = getSeriesDataValue(response, chartArray[index].display, chartArray[index].type, chartArray[index].name);
          }
          if (response.hits && response.hits.hits && response.hits.hits.length > 0) {
            // tslint:disable-next-line: max-line-length
            if (response.hits.hits[0]._source.idDomanda === 'fatica' || response.hits.hits[0]._source.idDomanda === 'dolore' || response.hits.hits[0]._source.idDomanda === 'nausea'
              || response.hits.hits[0]._source.idDomanda === 'funzionalita_fisica') {
              seriesLabels = getSeriesScores(response, chartArray[index].display, chartArray[index].type);
            } else if (response.hits.hits[0]._index.includes('answers')) {
              seriesLabels = getSeriesResponses(response, chartArray[index].name, chartArray[index].display, chartArray[index].type, this.userService.getCurrentOrganization());
            } else if (response.hits.hits[0]._index.includes('hr-br') ||
              response.hits.hits[0]._index.includes('sleep') ||
              response.hits.hits[0]._index.includes('pressure') ||
              response.hits.hits[0]._index.includes('spo2') ||
              response.hits.hits[0]._index.includes('mood') ||
              response.hits.hits[0]._index.includes('steps')) {
              seriesLabels = getSeriesDataValue(response, chartArray[index].display, chartArray[index].type, chartArray[index].name);
            }
          }
          if (Array.isArray(seriesLabels.serie)) {
            seriesLabels.serie.forEach(element => {
              series.push(element);
            });
          } else {
            series.push(seriesLabels.serie);
          }

          countResp++;
        });
        if (series != undefined && series.length > 1) {
          if (series[0] != undefined && (series[0].name === 'Diarrea (Frequenza)' || series[0].name === 'Stipsi (Frequenza)')) {
            const seriess = cutSeries(series[0], series[1]);
            series[0] = seriess[0];
            series.splice(1, 1);
            chartArray.splice(1, 1);
          }
          if (series[0] != undefined && series[1] != undefined && series[1] && series[0] && series[1].data && series[0].data) {
            const ss = this.getCC(series[1], series[0]);
            series[0].data = ss;
            if (series[1].name === 'Passi') {
              series[0].type = 'column';
            }
          }
        }
        const options = getBarChartOptions(id, title, series, chartArray, [], getAssiYCombo(chartArray), this.stroke,
          chartArray[0].markers, true);
        if (this.chart) {
          this.chart.updateOptions(options, false, false, false);
        } else {
          this.chart = new ApexCharts(this.chartRef.nativeElement, options);
          this.chart.render();
        }

      }
    }, (err) => {
      console.error(err);
      this.toastr.error('Errore nel recupero dei dati.');
    });
  }


  getCC(serie1, serie2) {
    const ret = new Array();
    const newSerieData = new Array();
    serie1.data.forEach(elem => {
      let found = false;
      serie2.data.forEach((element) => {
        const dateElem = new Date(elem.x);
        const dateElemStr = dateElem.getFullYear() + '/' + dateElem.getMonth() + '/' + dateElem.getDate();
        const dateElement = new Date(element.x);
        const dateElementStr = dateElement.getFullYear() + '/' + dateElement.getMonth() + '/' + dateElement.getDate();
        if (dateElemStr === dateElementStr) {
          const newElem = Object.assign({}, elem);
          newElem.y = element.y;
          newSerieData.push(newElem);
          found = true;
        }
      });
      if (!found) {
        const newElem = Object.assign({}, elem);
        newElem.y = null;
        newSerieData.push(newElem);
      }
    });
    return newSerieData;
  }

  getSelfReportedData(sintomo, topic) {
    var tempBool;
    var returnObj;

    if (topic === 'werable') {
      switch (sintomo) {
        case sintomo: 'Pressione sanguigna'
          returnObj = this.http.get(endpoints.elasticQuery + "getPressure/" + this.patientId + "/" + 'Lite OR Vista Or VYVO Or VYVO' + "/" + this.dateFromTimeStamp + "/" + this.dateToTimeStamp + "/-pressure").toPromise();
          break;
        case sintomo: 'Frequenza cardiaca'
          if (getInputHrMeasures(this.dateFromTimeStamp, this.dateToTimeStamp, this.patientId, 'Lite OR Vista Or VYVO').size == 0) {
            tempBool = true;
          } else {
            tempBool = false;
          }
          returnObj = this.http.get(endpoints.elasticQuery + "getHR/" + this.patientId + "/" + 'Lite OR Vista Or VYVO' + "/" + this.dateFromTimeStamp + "/" + this.dateToTimeStamp + "/-hr-br/" + tempBool).toPromise();
          break;
        case sintomo: 'Ossigenazione'
          returnObj = this.http.get(endpoints.elasticQuery + "getOx/" + this.patientId + "/" + 'Lite OR Vista Or VYVO' + "/" + this.dateFromTimeStamp + "/" + this.dateToTimeStamp + "/-spo2").toPromise();
          break;
        case sintomo: 'Frequenza respiratoria'
          returnObj = this.http.get(endpoints.elasticQuery + "getBR/" + this.patientId + "/" + 'Lite OR Vista Or VYVO' + "/" + this.dateFromTimeStamp + "/" + this.dateToTimeStamp + "/-hr-br").toPromise();
          break;
        case sintomo: 'Mood'
          returnObj = this.http.get(endpoints.elasticQuery + "getMood/" + this.patientId + "/" + this.dateFromTimeStamp + "/" + this.dateToTimeStamp + "/-mood").toPromise();
          break;
        case sintomo: 'Energia'
          returnObj = this.http.get(endpoints.elasticQuery + "getEnergy/" + this.patientId + "/" + this.dateFromTimeStamp + "/" + this.dateToTimeStamp + "/-mood").toPromise();
          break;
        case sintomo: 'Passi'
          returnObj = this.http.get(endpoints.elasticQuery + "getSteps/" + this.patientId + "/" + this.dateFromTimeStamp + "/" + this.dateToTimeStamp + "/-steps").toPromise();
          break;
        default:
          returnObj = {} as JSON;
          break;
      }
      return returnObj;
    } else {
      return this.http.get(endpoints.elasticQuery + "getSintomi" + "/" + this.patientId + "/" + this.dateFromTimeStamp + "/" + this.dateToTimeStamp + "/" + topic + "/-answers");
    }
  }

  getSerieType(selected, selected1) {
    switch (selected) {
      case 'Dolore':
        if (selected1 === 'Frequenza cardiaca' || selected1 === 'Pressione sanguigna' || selected1 === 'Frequenza respiratoria'
          || selected1 === 'Mood' || selected1 === 'Energia' || selected1 === 'Ossigenazione' || selected1 === 'Passi') {
          return { name: 'Dolore', color: this.COLORE_DOLORE, topic: 'dolore', type: 'area', display: ['Dolore'], stroke: this.stroke, };
        } else {
          return { name: 'Dolore', color: this.COLORE_DOLORE, topic: 'dolore', type: 'column', display: ['Dolore'] };
        }
      case 'Diarrea (Frequenza)':
        return [
          {
            name: 'Evacuazioni giornaliere (frequenza)',
            color: '#FA4443',
            topic: '1',
            display: ['Diarrea (Frequenza)'],
            type: 'area'
          },
          { name: 'Diarrea', color: '#FA4443', serie: [], topic: '3', display: ['Diarrea (Farmaci)'], type: 'line' }
        ];
      case 'Diarrea (Farmaci)':
        return { name: 'Diarrea', color: '#FA4443', topic: '3', display: ['Diarrea (Farmaci)'], type: 'column' };
      case 'Stipsi (Frequenza)':
        return [
          {
            name: 'Evacuazioni giornaliere (frequenza)',
            color: '#FA4443',
            topic: '1',
            display: ['Stipsi (Frequenza)'],
            type: 'area'
          },
          { name: 'Stipsi', color: '#FA4443', serie: [], topic: '4', display: ['Stipsi (Farmaci)'], type: 'line' }
        ];
      case 'Stipsi (Farmaci)':
        return { name: 'Stipsi', color: '#FA4443', topic: '4', display: ['Stipsi (Farmaci)'], type: 'column' };
      case 'Frequenza cardiaca':
        return {
          name: 'Frequenza cardiaca',
          color: '#008FFB',
          topic: 'werable',
          display: ['Frequenza cardiaca'],
          type: ['area'],
          maxyaxis: 230,
          markers: false,
          fill: true,
          stroke: this.stroke
        };
      case 'Ossigenazione':
        return {
          name: 'Ossigenazione',
          color: '#FA4443',
          topic: 'werable',
          display: ['Ossigenazione'],
          type: ['area'],
          maxyaxis: 230,
          markers: false,
          fill: true,
          stroke: this.stroke
        };
      case 'Pressione sanguigna':
        return [
          {
            name: 'Pressione sanguigna',
            color: ['#008FFB', '#FA4443'],
            topic: 'werable',
            display: ['Pressione (Max)', 'Pressione (Min)'],
            type: ['area', 'area'],
            maxyaxis: 200,
            stroke: this.stroke,
            markers: false,
            fill: true
          }
        ];
      case 'Frequenza respiratoria':
        return {
          name: 'Frequenza respiratoria',
          color: '#FA4443',
          topic: 'werable',
          display: ['Frequenza respiratoria'],
          type: ['area'],
          maxyaxis: 80,
          stroke: this.stroke,
          markers: false,
          fill: true
        };
      case 'Mood':
        return {
          name: 'Mood',
          color: '#008FFB',
          topic: 'werable',
          display: ['Mood'],
          type: ['column'],
          maxyaxis: 90,
          markers: false,
          fill: true
        };
      case 'Energia':
        return {
          name: 'Energia',
          color: '#FA4443',
          topic: 'werable',
          display: ['Energia'],
          type: ['area'],
          maxyaxis: 90,
          stroke: this.stroke,
          markers: false,
          fill: true
        };
      case 'Temperatura':
        return {
          name: 'Temperatura',
          color: '#FA4443',
          topic: 'valutazione',
          display: ['Temperatura'],
          type: ['column'],
          maxyaxis: 45,
          markers: false,
          fill: true
        };
      case 'Fatica':
        if (selected1 === 'Frequenza cardiaca' || selected1 === 'Pressione sanguigna' || selected1 === 'Frequenza respiratoria'
          || selected1 === 'Mood' || selected1 === 'Energia' || selected1 === 'Passi' || selected1 === 'Ossigenazione') {
          return {
            name: 'Fatica',
            color: this.COLORE_FATICA,
            topic: 'fatica',
            type: 'area',
            display: ['Fatica'],
            stroke: this.stroke,
            maxyaxis: 100
          };
        } else {
          return { name: 'Fatica', color: this.COLORE_FATICA, serie: [], topic: 'fatica', type: 'column', display: ['Fatica'], maxyaxis: 100 };
        }
      case 'Passi':
        if (selected1 === 'Peso') {
          return {
            name: 'Passi',
            color: '#008FFB',
            topic: 'werable',
            display: ['Passi'],
            type: 'column',
            maxyaxis: 30000,
            stroke: this.stroke,
            markers: false,
            fill: true
          };
        } else {
          return {
            name: 'Passi',
            color: '#008FFB',
            topic: 'werable',
            display: ['Passi'],
            type: 'column',
            maxyaxis: 30000,
            stroke: this.stroke,
            markers: false,
            fill: true
          };
        }
      case 'Nausea':
        if (selected1 === 'Frequenza cardiaca' || selected1 === 'Pressione sanguigna' || selected1 === 'Frequenza respiratoria'
          || selected1 === 'Mood' || selected1 === 'Energia' || selected1 === 'Passi') {
          return {
            name: 'Nausea',
            color: this.COLORE_NAUSEA,
            topic: 'nausea',
            type: 'area',
            display: ['Nausea'],
            stroke: this.stroke,
            maxyaxis: 100
          };
        } else {
          return { name: 'Nausea', color: this.COLORE_NAUSEA, topic: 'nausea', type: 'column', display: ['Nausea'], maxyaxis: 100 };
        }
      case 'Funzionalita':
        if (selected1 === 'Frequenza cardiaca' || selected1 === 'Pressione sanguigna' || selected1 === 'Frequenza respiratoria'
          || selected1 === 'Mood' || selected1 === 'Energia' || selected1 === 'Passi' || selected1 === 'Ossigenazione') {
          return {
            name: 'Funzionalita',
            color: this.COLORE_NAUSEA,
            topic: 'funzionalita_fisica',
            type: 'area',
            display: ['Funzionalità fisica'],
            stroke: this.stroke,
            maxyaxis: 100
          };
        } else {
          // tslint:disable-next-line: max-line-length
          return { name: 'Funzionalita', color: this.COLORE_NAUSEA, topic: 'funzionalita_fisica', type: 'column', display: ['Funzionalità fisica'], maxyaxis: 100 };
        }
      case 'Peso':
        return {
          name: 'Peso corporeo',
          color: '#008FFB',
          topic: 'valutazione',
          display: ['Peso (kg)'],
          type: 'column',
          maxyaxis: 200
        };
      default:
        break;
    }
  }
}
