import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/service/user-service.service';
import { endpoints } from 'src/endpoint/endpoints';
import { version } from '../../../../package.json';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  version = version;

  linkPrivacy = undefined;
  linkDisclaimer = undefined;

  constructor(private userService: UserService, private http: HttpClient) { }

  ngOnInit() {
    const user = this.userService.retriveUser();
    if (user !== undefined) {
      this.getConfigurazioneByOrgNamePromise(this.userService.getCurrentOrganization()).subscribe((resp: any) => {
        if (resp) {
          if (resp.status === 'OK') {
            const conf: Array<any> = resp.object;
            this.linkPrivacy = conf.find(x => x.parametro === 'ORGANIZATION_PRIVACY_LINK');
            if (this.linkPrivacy !== undefined) {
              this.linkPrivacy = this.linkPrivacy.valore;
            }

            this.linkDisclaimer = conf.find(x => x.parametro === 'ORGANIZATION_DISCLAIMER_LINK');
            if (this.linkDisclaimer !== undefined) {
              this.linkDisclaimer = this.linkDisclaimer.valore;
            }
          } 
        } 
      }, err => {
        console.error(err);
      });
    }
  }

  getConfigurazioneByOrgNamePromise(organization: String):any {
    return this.http.get(endpoints.configurazionebyorg + '/' + organization);
  }

}
