import { Component, OnInit } from '@angular/core';
import { LogEventService } from '../main/pazienti/service/log-event.service';

@Component({
  selector: 'app-info-user',
  templateUrl: './info-user.component.html',
  styleUrls: ['./info-user.component.scss']
})
export class InfoUserComponent implements OnInit {

info:any;
  constructor(private logService: LogEventService) { }

  ngOnInit(): void {
    this.logService.info.subscribe(val => {
      if(val != null){
        this.info=val;
      }else{
        this.info={creato: "-Non implementato", modificato:"- Non implementato",dal:"",al:""};
      }
      
    })
    
  }

}
