<div class="card">
    <div class="card-header border-0">
        <div class="p-fluid">
            <h3 class="card-title">Fonte: "SmartWatch + Mobile App"</h3>
            <div class="to-right">
                <div class="row">
                    <div class="col-md-6" style="width: 200px;">
                        <p-dropdown [options]="opt2" [(ngModel)]="selected1" dropdownIcon="fas fa-chevron-down" (ngModelChange)="dropdownChange($event, 1)"></p-dropdown>
                    </div>
                    <div class="col-md-6" style="width: 200px;">
                        <p-dropdown [options]="opt" [(ngModel)]="selected" dropdownIcon="fas fa-chevron-down" (ngModelChange)="dropdownChange($event, 2)"></p-dropdown>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div #chart></div>
    </div>
</div>