<div class="card">
    <div class="card-header border-0" *ngIf="showHeader !== undefined && showHeader === true">
        <div class="p-fluid justify-content-between">
            <h3 class="card-title">Fonte: "Mobile App" {{titolo}}</h3>
            <div class="to-right" style="width: 281px;">
                <ng-container *ngIf="opt.length > 1">
                    <p-dropdown [options]="opt" [(ngModel)]="selected" dropdownIcon="fas fa-chevron-down"
                        (ngModelChange)="dropdownChange($event)" [scrollHeight]="'300px'"></p-dropdown>
                </ng-container>
                <ng-container *ngIf="opt.length == 1">
                    <h5 style="text-align: right">{{opt[0].label}}</h5>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div #chart></div>
    </div>
</div>