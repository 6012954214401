import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  // tslint:disable-next-line: variable-name
  constructor(private _http: HttpClient, private _router: Router, private _token: TokenService) { }

  getRequest(path, params?) {
    if (!this._token.checkToken()) {
      if (params) {
        return this._http.get(path, params).toPromise();
      } else {
        return this._http.get(path).toPromise();
      }
    }
  }

  deleteRequest(path, param) {
    if (!this._token.checkToken()) {
      return this._http.delete(path, {params: param}).toPromise();
    }
  }

  postRequest(path, body) {
    if (!this._token.checkToken()) {
      return this._http.post(path, body).toPromise();
    }
  }

  logout() {
    this._token.deleteToken();
    this._router.navigate(['/login']);
  }

}
