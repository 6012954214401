import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DettagliPazienteModalComponent } from '../dettagli-paziente-modal/dettagli-paziente-modal.component';

@Component({
  selector: 'app-info-paziente-modal',
  templateUrl: './info-paziente-modal.component.html',
  styleUrls: ['./info-paziente-modal.component.scss']
})
export class InfoPazienteModalComponent implements OnInit {

  patientId;

  constructor(private ref: DynamicDialogRef, private config: DynamicDialogConfig, private dialogService: DialogService) { }


  static open(dialogService: DialogService, input, altezza: string, larghezza: string) {
    return dialogService.open(InfoPazienteModalComponent, {
      baseZIndex: 1500,
      // showHeader: false,
      header: 'Info paziente',
      height: '90%',
      width: larghezza,
      dismissableMask: true,
      data: input,
    });
  }

  ngOnInit() {
    this.patientId = this.config.data.patientId;
  }

  showPanelDettagli() {
    this.ref.close();
    const input = { patientId: this.patientId };
    const ref2 = DettagliPazienteModalComponent.open(this.dialogService, input);
    ref2.onClose.subscribe((res: any) => { });
  }
}
