import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, empty, Observable } from "rxjs";
import { UserService } from "src/app/service/user-service.service";
import { endpoints } from "src/endpoint/endpoints";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class LogEventService {

  info = new BehaviorSubject(null);

  constructor(private http: HttpClient, private userService: UserService) {

  }

  getAllOpt(elements, code): any {

    var ids = "";
    var tempObj = {} as any;
    const tempInfo = {} as any;

    if (elements == undefined) {
      this.resetGlobalVarLog();
      return;
    }
    elements.forEach((el, i) => {
      if (el != undefined || el[i] != undefined) {
        el.forEach(child => {
          if (child.id != undefined && child.resourceType != undefined) {
            ids = ids + child.id + ",";
          }
        });
      }
    });
    if (ids !== "") {
      return this.http.get(environment.resourceServer + "/logevent/getMultipleId/" + ids).toPromise().then((resp: any) => {
        resp.sort((a, b) => a.effective_date - b.effective_date);
        tempObj = resp.shift();
        if (tempObj == undefined) {
          this.resetGlobalVarLog();
          return;
        }

        tempInfo.dal = tempObj.effective_date;
        tempInfo.creato = tempObj.utente;
        if (resp.toString() != "") {
          tempObj = resp.pop();
        }
        tempInfo.modificato = tempObj.utente;
        tempInfo.al = tempObj.effective_date;

        this.info.next(tempInfo);

        if (code != null) {
          code.split("/") as Array<any>;
          this.getByCode(code);
        }
      }).catch((err: any) => {
        console.error(err);
      })
    } else {
      this.resetGlobalVarLog();
      if (code != null) {
        this.getByCode(code);
      }
      return;
    }
  }
  getArrayMax(array) {
    return Math.max.apply(null, array);
  }
  resetGlobalVarLog() {
    const tempInfo = {} as any;
    tempInfo.creato = "- Nessuna creazione";
    tempInfo.modificato = "-Nessuna modifica";
    this.info.next(tempInfo);
  }
  getByCode(code) {
    var tempObjCode = {} as any;
    var tempDates: any =[];
    var tempUsers: any =[];
     return this.http.get(environment.resourceServer + "/logevent/getCode/" + code).toPromise().then((resp: any) => {
      resp.forEach(element => {
        tempDates.push(element.effective_date);
        tempUsers.push(element.utente);
      });
      if (tempDates.toString() != "") {
        tempDates.sort((a, b) => a - b);
        tempObjCode = {data: tempDates.shift(), utente: tempUsers.shift()};
        const info = this.info.getValue();
        if (info.dal == undefined || tempObjCode.data < info.dal) {
          info.creato = tempObjCode.utente;
          info.dal = tempObjCode.data;
        }
        if (info.al==undefined || tempObjCode.data > info.al) {
          info.modificato = tempObjCode.utente;
          info.al = tempObjCode.data;
        }
        if (tempDates.toString() != "") {
          tempObjCode.data = tempDates.pop();
          tempObjCode.utente = tempUsers.pop();
          if (tempObjCode.data > info.al) {
            info.modificato = tempObjCode.utente;
            info.al = tempObjCode.data;
          }
        }
        this.info.next(info);
      }
    });
  }
  newEvent(resourceHistory, code, isCoded, user){
    var newE;
  return this.http.post(endpoints.checkLogEventResource, resourceHistory).toPromise()
       .then((resp:any) => {
           if(!resp){
              if(isCoded){
             var tempSplit = code.split("/");
           newE={utente: user, risorsa: resourceHistory , effective_date: new Date().toUTCString() , tipologia:"PUT", code: tempSplit.shift()};
           } else {
           newE={utente: user, risorsa: resourceHistory , effective_date: new Date().toUTCString() , tipologia:"PUT", code: null};
           }
       return this.http.post(endpoints.logNewEvent, newE).toPromise()
}
  }).catch((err:any)=>{
    console.error(err);
  });
}
    newEventNoCheck(resourceHistory, code, isCoded, user){
      var newE;
             if(isCoded){
               var tempSplit = code.split("/");
             newE={utente: user, risorsa: resourceHistory , effective_date: new Date().toUTCString() , tipologia:"PUT", code: tempSplit.shift()};
             } else {
             newE={utente: user, risorsa: resourceHistory , effective_date: new Date().toUTCString() , tipologia:"PUT", code: null};
             }
         return this.http.post(endpoints.logNewEvent, newE).toPromise()
  }
}

