import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  private tokenName = environment.baseHref + '_access_token';

  private jwtTokenService;

  constructor() {
    this.jwtTokenService = new JwtHelperService();
  }

  saveToken(token) {
    if (this.getToken()) {
      this.deleteToken();
    }
    localStorage.setItem(this.tokenName, token.access_token);
    // const decodedToken = this.jwtTokenService.decodeToken(token.access_token);
    // this.userService.setUser(decodedToken.user);
  }

  checkToken() {
    if (!(localStorage.getItem(this.tokenName) === null)) {
      return this.jwtTokenService.isTokenExpired(localStorage.getItem(this.tokenName));
    } else { return true; }
  }

  getToken() {
    return localStorage.getItem(this.tokenName);
  }

  deleteToken() {
    localStorage.removeItem(this.tokenName);
  }
}
