export class DateFromTo {

    private dateFrom: Date;

    private dateTo: Date;

    constructor(dateFrom: Date, dateTo: Date) {
        this.dateFrom = dateFrom;
        this.dateTo = dateTo;
    }

    public getDateFrom(): Date {
        return this.dateFrom;
    }

    public setDateFrom(dateFrom: Date) {
        this.dateFrom = dateFrom;
    }

    public getDateTo(): Date {
        return this.dateTo;
    }

    public setDateTo(dateTo: Date) {
        this.dateTo = dateTo;
    }
    /** ritorna true se gli oggetti sono uguali, false se undefined/null o diversi */
    public compare(input: DateFromTo): boolean {
        if (input) {
            return (input.getDateFrom() === this.dateFrom) && (input.getDateTo() === this.dateTo);
        } else {
            return false;
        }
    }
}
