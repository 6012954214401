import { FormControl } from '@angular/forms';

export function validateEmail(c: FormControl) {
    // tslint:disable-next-line: max-line-length
    const EMAIL_REGEXP: RegExp = new RegExp('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}');
    if (EMAIL_REGEXP.test(c.value)) {
        return null;
    } else {
        return { validateEmail: { valid: false } };
    }
}
