<form [formGroup]="formAmmissione">
    <div class="row mb-3" *ngIf="this.org !== 'NEMO'">
        <div class="col-md-6">
            <label>ID dispositivo</label>
            <input type="text" id="id_monitoring_device" class="form-control" placeholder="ID dispositivo"
                formControlName="id_monitoring_device" />
        </div>
        <div class="col-md-6">
            <label>MAC dispositivo</label>
            <input type="text" id="mac_monitoring_device" class="form-control" placeholder="MAC dispositivo"
                formControlName="mac_monitoring_device" />
        </div>
    </div>
    <div class="row">
        <div class="col-md-5">
            <label>Data di ammissione*</label>
            <div class="input-group date">
                <div class="input-group-prepend">
                    <span class="input-group-text">
                        <i class="fa fa-calendar"></i>
                    </span>
                </div>
                <input type="text" id="data_ammissione" class="form-control" placeholder="Data di ammissione"
                    bsDatepicker formControlName="data_ammissione" [bsConfig]="datePickerConf" [minDate]="today" />
            </div>
        </div>
        <div class="col-md-7">
            <label>Periodo di attivazione*</label>
            <div class="input-group date">
                <div class="input-group-prepend">
                    <span class="input-group-text">
                        <i class="fa fa-calendar"></i>
                    </span>
                </div>
                <input formControlName="periodo_attivazione" class="mt-2" type="text"
                    #daterangepicker="bsDaterangepicker" class="form-control" placeholder="Periodo di attivazione"
                    bsDaterangepicker [bsConfig]="datePickerConf" [minDate]="today" placement="left">
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-12 d-flex" *ngIf="policyVisible">
            <p-checkbox id="policy_acceptance" formControlName="policy_acceptance" [binary]="true"></p-checkbox>
            <label class="ml-1 mt-2" for="policy_acceptance">il paziente ha compreso e firmato l'<a target="_blank"
                    href="{{informativaPrivacy}}">informativa sulla privacy</a> e i <a target="_blank"
                    href="{{terminiCondizioni}}">
                    termini e condizioni d'uso</a>.*</label>
        </div>
    </div>
</form>
<div>
    <div class="row mt-2">
        <div class="col-md-12">
            <div class="float-right">
                <button pButton label="Salva" icon="fa fa-save" iconPos="left" id="" class="p-button-success"
                    type="button" (click)="savePatient()" *ngIf="checkPermessoModifica()"></button>
            </div>
        </div>
    </div>