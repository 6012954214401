import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
// tslint:disable-next-line: max-line-length
import { ApexFill, ApexStroke, ApexTitleSubtitle, ApexTooltip, ApexXAxis, ApexYAxis } from 'ng-apexcharts';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { CustomizationService } from 'src/app/service/customization.service';
import { NetworkService } from 'src/app/service/network-service.service';
import { UserService } from 'src/app/service/user-service.service';
import { endpoints } from 'src/endpoint/endpoints';
import { PazientiService } from '../../service/pazienti.service';
import { getEndpointsData } from '../endpoint-charts';
import { DateFromTo } from '../Model/date-from-to';
import { getInputSelfReported } from '../Utils/query-input';

@Component({
  selector: 'app-monitoraggio-header',
  templateUrl: './monitoraggio-header.component.html',
  styleUrls: ['./monitoraggio-header.component.scss']
})
export class MonitoraggioHeaderComponent implements OnInit, OnDestroy {

  // -----------------------------------------------------------------
  // Variabili che vengono usate nelle configurazioni dei vari grafici
  monthsArray = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];
  shortMonthsArray = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'];
  daysArray = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'];
  shortDaysArray = ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'];
  // Toolbar che viene usata da tutti i grafici
  defaultToolbar = {
    show: false,
    tools: {
      download: false,
      selection: false,
      zoom: false,
      zoomin: false,
      zoomout: false,
      pan: false,
      reset: false
    },
    autoSelected: 'zoom'
  };
  // Oggetto title che viene usato da tutti i grafici
  defaultTitle: ApexTitleSubtitle = {
    text: '0%',
    align: 'center',
    style: {
      fontSize: '20px',
      color: '#fff',
    }
  };
  // Colori dei sintomi definiti dal tema
  COLORE_FATICA = '#ff6c00';
  COLORE_NAUSEA = '#fedb00';
  COLORE_DOLORE = '#de2826';
  COLORE_ASSORBENTI = '#ff6c00';
  // -----------------------------------------------------------------



  // tslint:disable-next-line: variable-name
  _date: DateFromTo;

  observer: BehaviorSubject<DateFromTo>;

  dateFromString: string;
  dateToString: string;
  dateFromTimeStamp;
  dateToTimeStamp;
  patientId;

  @Input() identifier;

  @Input('date') set date(date: DateFromTo) {
    if (!date.compare(this._date)) {
      this.observer.next(date);
    }
  }

  // Usato solo per il report
  @Input() showHeader: boolean;
  @Input() tipoGrafico;
  @Input() altezzaGrafico;
  @Input() larghezzaGrafico;
  @Input() isReport;


  doloreChart = {
    type: 'area',
    height: 160,
    width: "100%",
    id: 'dolore',
    group: 'pain',
    background: '#de2826',
    defaultLocale: 'it',
    locales: [{
      name: 'it',
      options: {
        // tslint:disable-next-line: max-line-length
        months: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
        shortMonths: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
        days: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
        shortDays: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
      }
    }],
    toolbar: {
      show: false,
      tools: {
        download: false,
        selection: false,
        zoom: false,
        zoomin: false,
        zoomout: false,
        pan: false,
        reset: false
      },
      autoSelected: 'zoom'
    }
  };

  faticaChart = {
    type: 'area',
    height: 160,
    width: "100%",
    id: 'fatica',
    group: 'pain',
    defaultLocale: 'it',
    background: '#ff6c00',
    locales: [{
      name: 'it',
      options: {
        // tslint:disable-next-line: max-line-length
        months: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
        shortMonths: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
        days: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
        shortDays: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
      }
    }],
    toolbar: {
      show: false,
      tools: {
        download: false,
        selection: false,
        zoom: false,
        zoomin: false,
        zoomout: false,
        pan: false,
        reset: false
      },
      autoSelected: 'zoom'
    }
  };

  nauseaChart = {
    type: 'area',
    height: 160,
    width: "100%",
    id: 'nausea',
    group: 'pain',
    defaultLocale: 'it',
    background: '#fedb00',
    locales: [{
      name: 'it',
      options: {
        // tslint:disable-next-line: max-line-length
        months: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
        shortMonths: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
        days: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
        shortDays: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
      }
    }],
    toolbar: {
      show: false,
      tools: {
        download: false,
        selection: false,
        zoom: false,
        zoomin: false,
        zoomout: false,
        pan: false,
        reset: false
      },
      autoSelected: 'zoom'
    }
  };

  funzionalitaChart = {
    type: 'area',
    height: 160,
    width: "100%",
    id: 'funzionalita',
    group: 'pain',
    defaultLocale: 'it',
    locales: [{
      name: 'it',
      options: {
        // tslint:disable-next-line: max-line-length
        months: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
        shortMonths: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
        days: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
        shortDays: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
      }
    }],
    toolbar: {
      show: false,
      tools: {
        download: false,
        selection: false,
        zoom: false,
        zoomin: false,
        zoomout: false,
        pan: false,
        reset: false
      },
      autoSelected: 'zoom'
    }
  };

  assorbentiChart = {
    type: 'area',
    height: 160,
    width: "100%",
    id: 'assorbenti',
    group: 'pain',
    defaultLocale: 'it',
    background: this.COLORE_ASSORBENTI,
    locales: [{
      name: 'it',
      options: {
        months: this.monthsArray,
        shortMonths: this.shortMonthsArray,
        days: this.daysArray,
        shortDays: this.shortDaysArray,
      }
    }],
    toolbar: this.defaultToolbar
  };

  xaxis: ApexXAxis = {
    type: 'datetime',
    labels: {
      datetimeUTC: false,
      style: {
        colors: '#fff',
        fontSize: '12px'
      }
    }
  };

  yaxis: ApexYAxis = {
    min: 0,
    max: 100,
    tickAmount: 5,
    labels: {
      show: true,
      style: {
        colors: '#fff',
        fontSize: '12px'
      }
    }
  };

  tooltipDolore: ApexTooltip = {
    x: {
      format: 'dd MMM yyyy'
    },
    y: {
      formatter: (value) => {
        if (value === 0) {
          return 'Dolore: ' + 0 + '';
        }
        return 'Dolore: ' + value.toString();
      },
    },
    fixed: {
      enabled: true,
      position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
      offsetY: 30,
      offsetX: 60
    },
  };

  stroke: ApexStroke = {
    curve: 'straight'
  };

  fill: ApexFill = {
    opacity: 1
  };

  doloreSeries;

  titleDolore: ApexTitleSubtitle = {
    text: '0%',
    align: 'center',
    style: {
      fontSize: '20px',
      color: '#fff',
    }
  };

  subtitleDolore: ApexTitleSubtitle = {
    text: 'Dolore',
    offsetX: 20,
    offsetY: 45,
    style: {
      fontSize: '16px',
      color: '#fff',
    }
  };

  labelsDolore: string[] = [];

  titleFatica: ApexTitleSubtitle = {
    text: '0%',
    align: 'center',
    style: {
      fontSize: '20px',
      color: '#fff',
    }
  };

  subtitleFatica: ApexTitleSubtitle = {
    text: 'Fatica',
    offsetX: 20,
    offsetY: 45,
    style: {
      fontSize: '16px',
      color: '#fff',
    }
  };

  labelsFatica: string[] = [];

  faticaSeries;

  tooltipFatica: ApexTooltip = {
    x: {
      format: 'dd MMM yyyy'
    },
    y: {
      formatter: (value) => {
        if (value === 0) {
          return 'Fatica: ' + 0 + '';
        }
        return 'Fatica: ' + value.toString();
      },
    },
    fixed: {
      enabled: true,
      position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
      offsetY: 30,
      offsetX: 60
    },
  };

  titleNausea: ApexTitleSubtitle = {
    text: '0%',
    align: 'center',
    style: {
      fontSize: '20px',
      color: '#fff',
    }
  };

  subtitleNausea: ApexTitleSubtitle = {
    text: 'Nausea',
    offsetX: 20,
    offsetY: 45,
    style: {
      fontSize: '16px',
      color: '#fff',
    }
  };

  labelsNausea: string[] = [];

  nauseaSeries;

  tooltipNausea: ApexTooltip = {
    x: {
      format: 'dd MMM yyyy'
    },
    y: {
      formatter: (value) => {
        if (value === 0) {
          return 'Nausea: ' + 0 + '';
        }
        return 'Nausea: ' + value.toString();
      },
    },
    fixed: {
      enabled: true,
      position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
      offsetY: 30,
      offsetX: 60
    },
  };


  titleFunzionalita: ApexTitleSubtitle = {
    text: '0%',
    align: 'center',
    style: {
      fontSize: '20px',
      color: '#fff',
    }
  };

  subtitleFunzionalita: ApexTitleSubtitle = {
    text: 'Funzionalità fisiche',
    offsetX: 20,
    offsetY: 45,
    style: {
      fontSize: '16px',
      color: '#fff',
    }
  };

  labelsFunzionalita: string[] = [];

  funzionalitaSeries;

  tooltipFunzionalita: ApexTooltip = {
    x: {
      format: 'dd MMM yyyy'
    },
    y: {
      formatter: (value) => {
        if (value === 0) {
          return 'Funzionalità fisiche: ' + 0 + '';
        }
        return 'Funzionalità fisiche: ' + value.toString();
      },
    },
    fixed: {
      enabled: true,
      position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
      offsetY: 30,
      offsetX: 60
    },
  };

  // Grafico assorbenti
  titleAssorbenti = this.defaultTitle;
  tooltipAssorbenti: ApexTooltip = {
    x: {
      format: 'dd MMM yyyy'
    },
    y: {
      formatter: (value) => {
        return 'Assorbenti cambiati: ' + this.getAssorbentiValueFromIdRisposta(value);
      },
    },
    fixed: {
      enabled: true,
      position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
      offsetY: 30,
      offsetX: 60
    },
  };
  yAxisAssorbenti = {
    min: 89,
    max: 92,
    tickAmount: 92 - 89,
    labels: {
      show: true,
      style: {
        colors: '#fff',
        fontSize: '12px'
      },
      formatter: (y, index) => {
        if (typeof y === 'undefined' || y == null) {
          return 'Nessun assorbente cambiato';
        }
        switch (y) {
          case 89:
            return '0';
          case 90:
            return '1';
          case 91:
            return '3';
          case 92:
            return 'Più di 3';
        }
      }
    }
  }
  assorbentiSeries;
  labelsAssorbenti;
  @ViewChild('assorbentiChartRef') assorbentiChartRef: ElementRef;
  chartAssorbenti: ApexCharts;
  // --------------------------

  // @ViewChild('doloreChartRef', { static: false }) doloreChartRef: ChartComponent;
  @ViewChild('faticaChartRef') faticaChartRef: ElementRef;
  chartFatica: ApexCharts;
  @ViewChild('nauseaChartRef') nauseaChartRef: ElementRef;
  chartNausea;
  @ViewChild('doloreChartRef') doloreChartRef: ElementRef;
  chartDolore: ApexCharts;
  @ViewChild('funzionalitaChartRef') funzionalitaChartRef: ElementRef;
  chartFunzionalita: ApexCharts;

  // tslint:disable-next-line: max-line-length
  constructor(private http: HttpClient, private toastr: ToastrService, private datePipe: DatePipe,
    private network: NetworkService, private userService: UserService, private patientService: PazientiService,
    private customizationService: CustomizationService) {
    this.observer = new BehaviorSubject<DateFromTo>(null);
  }

  ngOnInit() {
    const paziente = this.patientService.getPatient();
    this.patientId = paziente.id;

    if (this.isReport === undefined) {
      this.isReport = false;
    }
    if (this.altezzaGrafico !== undefined) {
      this.doloreChart.height = this.altezzaGrafico;
      this.faticaChart.height = this.altezzaGrafico;
      this.nauseaChart.height = this.altezzaGrafico;
      this.funzionalitaChart.height = this.altezzaGrafico;
      this.assorbentiChart.height = this.altezzaGrafico;
    }
    if (this.larghezzaGrafico !== undefined) {
      this.doloreChart.width = this.larghezzaGrafico;
      this.faticaChart.width = this.larghezzaGrafico;
      this.nauseaChart.width = this.larghezzaGrafico;
      this.funzionalitaChart.width = this.larghezzaGrafico;
      this.assorbentiChart.width = this.larghezzaGrafico;
    }
    this.observer.subscribe((input) => {
      if (this.identifier && input) {
        this._date = input;
        this.dateFromString = this.datePipe.transform(input.getDateFrom(), 'yyyy-MM-dd');
        this.dateToString = this.datePipe.transform(input.getDateTo(), 'yyyy-MM-dd');
        this.dateFromTimeStamp = Math.round(input.getDateFrom().getTime());
        this.dateToTimeStamp = Math.round(input.getDateTo().getTime());
        const organization = this.userService.getCurrentOrganization();
        if (organization === 'HOSPITAL_1') {
          this.getChartDataDolore();
          this.getChartDataFatica();
          this.getChartDataNausea();
        } else if (organization === 'NEMO') {
          this.getChartDataDolore();
          this.getChartDataFatica();
          this.getChartDataFunzionalita();
        } else if (organization === 'VIVISOL') {
          this.getChartDataDolore();
          this.getChartDataFatica();
          this.getChartDataFunzionalita();
        } else if (organization === 'AQUA') {
          this.getChartDataDolore();
          this.getChartDataFatica();
          this.getChartDataFunzionalita();
        } else if (organization === 'RUGGI') {
          this.getChartDataDolore();
          this.getChartDataFatica();
          this.getChartDataNausea();
        } else if (organization === '150910-IRCCS-Pascale' || organization === 'TEST') {
          // DEBUG: rimuovere TEST e lasciare solo Pascale
          this.getChartDataDolore(true);

        } else {
          this.getChartDataDolore();
          this.getChartDataFatica();
          //this.getChartDataNausea();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.observer.unsubscribe();
  }

  getChartDataDolore(createGraficoAssorbenti = false) {
    // tslint:disable-next-line: max-line-length
    this.http.get(endpoints.elasticQuery + "getSintomi" + "/" + this.patientId + "/" + this.dateFromTimeStamp + "/" + this.dateToTimeStamp + "/" + 'dolore' + "/-answers").toPromise()
      .then((resp: any) => {
        if (resp) {
          if (resp.hits && resp.hits.hits) {
            this.doloreSeries = null;
            const serie = [{ name: '', data: new Array<any>() }];
            const labels: string[] = [];
            const count = resp.hits.total.value;
            let total = 0;
            resp.hits.hits.forEach(el => {
              const d = new Date(el._source.timestamp);
              if (labels.includes(this.datePipe.transform(d, 'dd MMM yyyy'))) {
                return;
              }
              let numb = Number.parseInt(el._source.value, 10);
              console.log(numb)
              if (numb == 333) {
                numb = 17;
              }
              if (numb == 417) {
                numb = 0;
              }
              if (numb == 167) {
                numb = 0;
              }
              if (numb == 250) {
                numb = 0;
              }
              if (numb == 83) {
                numb = 0;
              }
              serie[0].data.push(numb);
              labels.push(this.datePipe.transform(d, 'dd MMM yyyy'));
              total += numb;
            });
            this.titleDolore.text = 'Dolore: ' + ((count === 0) ? '0%' : Math.round(total / count) + '%');
            this.labelsDolore = labels;
            this.doloreSeries = serie;
            const options = {} as any;
            options.chart = this.doloreChart;
            options.series = this.doloreSeries;
            options.xaxis = this.xaxis;
            options.yaxis = this.yaxis;
            options.tooltip = this.tooltipDolore;
            options.stroke = this.stroke;
            options.fill = this.fill;
            options.labels = this.labelsDolore;
            options.colors = ['#546E7A'];
            options.title = this.titleDolore;
            // options.subtitle = this.subtitleDolore;
            options.grid = { show: false };
            options.markers = {
              size: 0,
              hover: {
                size: 6,
              }
            };
            options.dataLabels = { enabled: false };
            if (this.chartDolore) {
              this.chartDolore.updateOptions(options, false, true, false);
            } else {
              this.chartDolore = new ApexCharts(this.doloreChartRef.nativeElement, options);
              this.chartDolore.render();
              this.chartDolore.updateOptions(options, false, false, false);
            }
            if (createGraficoAssorbenti) {
              this.getChartDataAssorbenti();
            }
          }
        }
      }).catch(err => {
        console.error(err);
        this.toastr.error('Errore nel recupero dei dati.');
      });
  }

  getChartDataFatica() {
    // tslint:disable-next-line: max-line-length
    this.http.get(endpoints.elasticQuery + "getSintomi" + "/" + this.patientId + "/" + this.dateFromTimeStamp + "/" + this.dateToTimeStamp + "/" + 'fatica' + "/-answers").toPromise()
      .then((resp: any) => {
        if (resp) {
          if (resp.hits && resp.hits.hits) {
            this.faticaSeries = null;
            const serie = [{ data: new Array(), name: new Array() }];
            const labels: string[] = [];
            const count = resp.hits.total.value;
            let total = 0;
            resp.hits.hits.forEach(el => {
              const numb = Number.parseInt(el._source.value, 10);
              serie[0].data.push(numb);
              const d = new Date(el._source.timestamp);
              labels.push(this.datePipe.transform(d, 'dd MMM yyyy'));
              total += numb;
            });
            this.titleFatica.text = 'Fatica: ' + ((count === 0) ? '0%' : Math.round(total / count) + '%');
            this.labelsFatica = labels;
            this.faticaSeries = serie;
            const options = {} as any;
            options.chart = this.faticaChart;
            options.series = this.faticaSeries;
            options.xaxis = this.xaxis;
            options.yaxis = this.yaxis;
            options.tooltip = this.tooltipFatica;
            options.stroke = this.stroke;
            options.fill = this.fill;
            options.labels = this.labelsFatica;
            options.colors = ['#546E7A'];
            options.title = this.titleFatica;
            // options.subtitle = this.subtitleFatica;
            options.grid = { show: false };
            options.markers = {
              size: 0,
              hover: {
                size: 6,
              }
            };
            options.dataLabels = { enabled: false };
            if (this.chartFatica) {
              this.chartFatica.updateOptions(options, false, true, false);
            } else {
              this.chartFatica = new ApexCharts(this.faticaChartRef.nativeElement, options);
              this.chartFatica.render();
              this.chartFatica.updateOptions(options, false, false, false);
            }
          }
        }
      }).catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Errore nel recupero dei dati.');
      });
  }

  getChartDataNausea() {
    // tslint:disable-next-line: max-line-length
    this.http.get(endpoints.elasticQuery + "getSintomi" + "/" + this.patientId + "/" + this.dateFromTimeStamp + "/" + this.dateToTimeStamp + "/" + 'nausea' + "/-answers").toPromise()
      .then((resp: any) => {
        if (resp) {
          if (resp.hits && resp.hits.hits) {
            this.nauseaSeries = null;
            const serie = [{ data: new Array(), name: new Array() }];
            const labels: string[] = [];
            const count = resp.hits.total.value;
            let total = 0;
            resp.hits.hits.forEach(el => {
              const numb = Number.parseInt(el._source.value, 10);
              serie[0].data.push(numb);
              const d = new Date(el._source.timestamp);
              labels.push(this.datePipe.transform(d, 'dd MMM yyyy'));
              total += numb;
            });
            this.titleNausea.text = 'Nausea: ' + ((count === 0) ? '0%' : Math.round(total / count) + '%');
            this.labelsNausea = labels;
            this.nauseaSeries = serie;
            const options = {} as any;
            options.chart = this.nauseaChart;
            options.series = this.nauseaSeries;
            options.xaxis = this.xaxis;
            options.yaxis = this.yaxis;
            options.tooltip = this.tooltipNausea;
            options.stroke = this.stroke;
            options.fill = this.fill;
            options.labels = this.labelsNausea;
            options.colors = ['#546E7A'];
            options.title = this.titleNausea;
            // options.subtitle = this.subtitleNausea;
            options.grid = { show: false };
            options.markers = {
              size: 0,
              hover: {
                size: 6,
              }
            };
            options.dataLabels = { enabled: false };
            if (this.chartNausea) {
              this.chartNausea.updateOptions(options, false, true, false);
            } else {
              this.chartNausea = new ApexCharts(this.nauseaChartRef.nativeElement, options);
              this.chartNausea.render();
              this.chartNausea.updateOptions(options, false, false, false);
            }
          }
        }
      }).catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Errore nel recupero dei dati.');
      });
  }

  getChartDataFunzionalita() {
    // tslint:disable-next-line: max-line-length
    this.http.post(getEndpointsData('answer', this.userService.getCurrentOrganization()), getInputSelfReported(this.dateFromTimeStamp, this.dateToTimeStamp, this.identifier, 'funzionalita_fisica', 'funzionalita_fisica')).toPromise()
      .then((resp: any) => {
        if (resp) {
          if (resp.hits && resp.hits.hits) {
            this.funzionalitaSeries = null;
            const serie = [{ data: new Array(), name: new Array() }];
            const labels: string[] = [];
            const count = resp.hits.total.value;
            let total = 0;
            resp.hits.hits.forEach(el => {
              const numb = Number.parseInt(el._source.value, 10);
              serie[0].data.push(numb);
              const d = new Date(el._source.timestamp);
              labels.push(this.datePipe.transform(d, 'dd MMM yyyy'));
              total += numb;
            });
            this.titleFunzionalita.text = 'Funzionalità fisica: ' + ((count === 0) ? '0%' : Math.round(total / count) + '%');
            this.labelsFunzionalita = labels;
            this.funzionalitaSeries = serie;
            const options = {} as any;
            options.chart = this.funzionalitaChart;
            options.series = this.funzionalitaSeries;
            options.xaxis = this.xaxis;
            options.yaxis = this.yaxis;
            options.tooltip = this.tooltipFunzionalita;
            options.stroke = this.stroke;
            options.fill = this.fill;
            options.labels = this.labelsFunzionalita;
            options.colors = ['#546E7A'];
            options.title = this.titleFunzionalita;
            // options.subtitle = this.subtitleFunzionalita;
            options.grid = { show: false };
            options.markers = {
              size: 0,
              hover: {
                size: 6,
              }
            };
            options.dataLabels = { enabled: false };
            if (this.chartFunzionalita) {
              this.chartFunzionalita.updateOptions(options, false, true, false);
            } else {
              if (this.funzionalitaChartRef !== undefined) {
                this.chartFunzionalita = new ApexCharts(this.funzionalitaChartRef.nativeElement, options);
                this.chartFunzionalita.render();
                this.chartFunzionalita.updateOptions(options, false, false, false);
              }
            }
          }
        }
      }).catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Errore nel recupero dei dati.');
      });
  }

  getAssorbentiValueFromIdRisposta(value) {
    if (typeof value === 'undefined' || value == null) {
      return 'nessuna perdita urinaria';
    }
    switch (Number(value)) {
      case 89:
        return '0';
      case 90:
        return '1';
      case 91:
        return '3';
      case 92:
        return 'Più di 3';
    }
  }

  getChartDataAssorbenti() {
    this.http.get(endpoints.elasticQuery + "getSintomi" + "/" + this.patientId + "/" + this.dateFromTimeStamp + "/" + this.dateToTimeStamp + "/" + '84' + "/-answers").toPromise()
      .then((resp: any) => {
        if (resp) {
          if (resp.hits && resp.hits.hits) {
            this.assorbentiSeries = null;
            const serie = [{ name: '', data: new Array<any>() }];
            const labels: string[] = [];
            this.labelsDolore.forEach(dataDolore => {
              let f = resp.hits.hits.find(x => {
                const d = new Date(x._source.timestamp);
                const tmpLabel = this.datePipe.transform(d, 'dd MMM yyyy');
                return tmpLabel == dataDolore;
              });
              if (f) {
                serie[0].data.push(f._source.idRisposta);
                console.log("Popolo con ", f._source.idRisposta, " il giorno ", dataDolore);
              } else {
                serie[0].data.push(89);
                console.log("Popolo con 89 il giorno ", dataDolore);
              }
            });
            // resp.hits.hits.forEach(el => {
            //   const d = new Date(el._source.timestamp);
            //   const tmpLabel = this.datePipe.transform(d, 'dd MMM yyyy');
            //   labels.push(tmpLabel);
            //   serie[0].data.push(el._source.idRisposta);
            //   console.log("Assorbenti cambiati ", this.datePipe.transform(d, 'dd MMM yyyy'), " ", el._source.idRisposta);
            // });
            this.titleAssorbenti.text = 'Assorbenti cambiati';
            this.labelsAssorbenti = this.labelsDolore;
            console.log("labelsDolore ", this.labelsDolore);
            this.assorbentiSeries = serie;
            const options = {} as any;
            options.chart = this.assorbentiChart;
            options.series = this.assorbentiSeries;
            options.xaxis = this.xaxis;
            options.yaxis = this.yAxisAssorbenti;
            options.tooltip = this.tooltipAssorbenti;
            options.stroke = this.stroke;
            options.fill = this.fill;
            options.labels = this.labelsAssorbenti;
            options.colors = ['#546E7A'];
            options.title = this.titleAssorbenti;
            // options.subtitle = this.subtitleDolore;
            options.grid = { show: false };
            options.markers = {
              size: 0,
              hover: {
                size: 6,
              }
            };
            options.dataLabels = { enabled: false };
            console.log("chartAssorbenti ", options.xaxis, options.yaxis, options.labels);
            if (this.chartAssorbenti) {
              this.chartAssorbenti.updateOptions(options, false, true, false);
            } else {
              this.chartAssorbenti = new ApexCharts(this.assorbentiChartRef.nativeElement, options);
              this.chartAssorbenti.render();
              this.chartAssorbenti.updateOptions(options, false, false, false);
            }
          }
        }
      }).catch(err => {
        console.error(err);
        this.toastr.error('Errore nel recupero dei dati.');
      });
  }

  showChart(chart) {
    return this.customizationService.showChartHeaderByOrganizations(this.userService.getCurrentOrganization(), chart);
  }

}
