import { isNullOrEmpty } from 'src/app/utils/utils';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from 'src/app/main/administration-panel/domain/user';
import { PazientiService } from 'src/app/main/pazienti/service/pazienti.service';
import { CustomValidators } from 'src/app/model/custom-validators';
import { TokenService } from 'src/app/service/token.service';
import { UserService } from 'src/app/service/user-service.service';
import { endpoints } from 'src/endpoint/endpoints';
import { ChangePassword } from './../../model/change-password';
import { NonLoggedUser } from './../../model/non-logged-user';


@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss']
})
export class PasswordChangeComponent implements OnInit {


  // tslint:disable-next-line: variable-name
  _user: User;
  nonLoggedUser: NonLoggedUser;
  form: FormGroup;
  randomEle = [];
  locale = 'it';
  isLogged: boolean;
  isNotNew: boolean;
  incorrectPasswordChange = false;
  cp: ChangePassword;
  updateUser: User;
  chiavi: any;
  confirmChiavi: any;

  showToLogin = false;

  isPaziente = false;

  constructor(private fb: FormBuilder, private router: Router, private http: HttpClient,
    private toastr: ToastrService, private userService: UserService, private route: ActivatedRoute,
    private tokenService: TokenService, private patientService: PazientiService) {
    this.random();
  }
  ngOnInit() {

    this.updateUser = {} as User;
    this._user = this.userService.retriveUser();

    if (this._user) {
      this.isLogged = true;
      this.route.queryParams.subscribe((params) => {
        if (params.username && params.code) {
          this.toastr.error('Abbiamo rilevato un utente loggato su questo browser. Fai il logout e riprova.');
          this.router.navigate(['/login']);
        }
      });
      // this.getAuthorities();
      const fromLogin = this.route.snapshot.paramMap.get('fromLogin');
      if (this.tokenService.checkToken()) {
        this.tokenService.deleteToken();
        this.userService.deleteUser();
        this.patientService.resetPatient();
        this.router.navigate(['/login']);
      }
      this.isNotNew = this.userService.isAccountNotnew();
      // if (this.isNotNew) {
      //   this.router.navigate(['/main/pazienti/listapazienti']);
      //   return;
      // }
      if (!this.isNotNew && !fromLogin) {
        this.tokenService.deleteToken();
        this.userService.deleteUser();
        this.patientService.resetPatient();
        this.router.navigate(['/login']);
      } else if (this.isNotNew && fromLogin) {
        this.router.navigate(['/main/pazienti/listapazienti']);
        return;
      }
      this.buildForm();
    } else {
      this.route.queryParams.subscribe((params) => {
        if (params.username && params.code) {
          this.cp = new ChangePassword();
          this.cp.username = params.username;
          this.cp.code = params.code;
          this.getUserDetails(this.cp);
          this.isLogged = false;
        } else {
          this.router.navigate(['/login']);
        }
      });
    }
  }

  getUserDetail() {
    return this.userService.getUser() === null;
  }


  buildForm() {
    this.form = this.fb.group(
      {
        password: ['', Validators.compose([
          // 1. Password Field is Required
          Validators.required,
          // 2. check whether the entered password has a number
          CustomValidators.patternValidator(/\d/, { hasNumber: true }),
          // 3. check whether the entered password has upper case letter
          CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
          // 4. check whether the entered password has a lower-case letter
          CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
          // 5. check whether the entered password has a special character
          CustomValidators.patternValidator(/[*.'£"!@#$%^&(){}[\]:;<>,.?/~_+\-=|]/, { hasSpecialCharacters: true }),
          // 6. Has a minimum length of 10 characters
          Validators.minLength(10),
          // 7. Has a maximum length of 16 characters
          // Validators.maxLength(16)
        ]),
          [
            this.usernameValidator(),
            this.sequenceValidator(),
            this.historyValidator()
          ]
        ],
        confirmPassword: ['', Validators.compose([Validators.required])]
      },
      { validator: CustomValidators.passwordMatchValidator }
    );
  }


  sequenceValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      if (control.value) {
        return this.http.post(endpoints.sequencevalidation, control.value).pipe(
          map((result: any) => {
            if (result) {
              if (result.status === 'OK') {
                return null;
              } else {
                return { sequenceInvalid: true };
              }
            }
          }
          )
        );
      } else {
        return of(null);
      }
    };
  }

  historyValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      if (control.value) {
        this.nonLoggedUser = new NonLoggedUser();
        if (this._user) {
          this.nonLoggedUser.username = this._user.username;
          this.nonLoggedUser.password = control.value;

        } else {
          this.nonLoggedUser.username = this.updateUser.username;
          this.nonLoggedUser.password = control.value;
        }
        return this.http.post(endpoints.historyvalidation, this.nonLoggedUser).pipe(
          map((result: any) => {
            if (result) {
              if (result.status === 'OK') {
                return null;
              } else {
                return { historyInvalid: true };
              }
            }
          }
          )
        );
      } else {
        return of(null);
      }
    };
  }

  usernameValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      if (control.value) {
        this.nonLoggedUser = new NonLoggedUser();
        if (this._user) {
          this.nonLoggedUser.username = this._user.username;
          this.nonLoggedUser.password = control.value;

        } else {
          this.nonLoggedUser.username = this.updateUser.username;
          this.nonLoggedUser.password = control.value;
        }
        return this.http.post(endpoints.usernamevalidation, this.nonLoggedUser).pipe(
          map((result: any) => {
            if (result) {
              if (result.status === 'OK') {
                return null;
              } else {
                return { usernameInvalid: true };
              }
            }
          }
          )
        );
      } else {
        return of(null);
      }
    };
  }



  getUserDetails(cp: ChangePassword) {
    this.http.post(endpoints.validateUsernameAndCode, cp).toPromise()
      .then((resp: any) => {
        if (resp) {
          if (resp.status === 'OK') {
            if (resp.object) {
              this.updateUser = resp.object;
              this.buildForm();
            }
          } else {
            this.router.navigate(['/login']);
          }
        }
      }).catch(err => {
        console.error(err);
        this.toastr.error('Errore nel caricamento del dettaglio degli utenti.');
        this.router.navigate(['/login']);
      });
  }

  random() {
    for (let i = 0; i < 15; i++) {
      this.randomEle.push('_' + Math.random().toString(36).substr(2, 9));
    }
  }

  salva() {
    this.form.markAllAsTouched();
    let usr: User;
    if (!this.form.invalid) {
      usr = this.form.getRawValue();

      this._user.password = usr.password;
      const unversioned: any = this._user;
      delete unversioned.version;
      delete unversioned.credentialExpirationDate;
      this.http.put(endpoints.cambiopasswordlogged, this._user).toPromise().then((resp: any) => {
        if (resp) {
          if (resp.status === 'OK') {
            if (resp.object) {
              this.toastr.success('Password Aggiornata Correttamente');
              this.showToLogin = true;
              this.userService.saveUser(resp.object);
              this.isPaziente = this.userService.isPaziente();
            }
          } else {
            this.toastr.error(resp.desc);
          }
        }
      }).catch(() => {
        this.toastr.error('Errore nel salvataggio della password.');
      });

    }
    // else {

    //   this.toastr.error('Compilare tutti i campi');
    // }
  }

  toLogin() {
    this.router.navigate(['/login']);
  }


  cambiaPassword() {
    this.form.markAllAsTouched();
    if (!this.form.invalid) {
      // Se utente non è loggato, chiama servizio changepassword con username e codice
      if (this.isNotNew === false) {
        const nlf = this.form.getRawValue();
        this._user.password = nlf.password;
        this.http.put(endpoints.changePasswordIsNew, this._user).toPromise().then((resp: any) => {
          if (resp) {
            if (resp.status === 'OK') {
              if (resp.object) {
                // rifare il retrive del me
                this.http.get(endpoints.me).subscribe((re) => {
                  if (re) {
                    this.userService.saveUser(re);
                    this.toastr.success('Password Aggiornata Correttamente');
                    this.showToLogin = true;
                    this.userService.saveUser(resp.object);
                    this.isPaziente = this.userService.isPaziente();
                  }
                }, (err) => {
                  this.tokenService.deleteToken();
                  this.userService.deleteUser();
                  this.patientService.resetPatient();
                  this.router.navigate(['/login']);
                });
              }
            } else {
              this.toastr.error(resp.desc);
            }
          }
        }).catch((err) => {
          console.error(err);
          this.incorrectPasswordChange = true;
          this.toastr.error('Errore nel salvataggio della password.');
        });
      } else {
        this.updateUser = {} as User;
        this.updateUser.username = this.cp.username;
        const nlf = this.form.getRawValue();
        this.updateUser.password = nlf.password;
        this.http.put(endpoints.changePassword + '?code=' + this.cp.code, this.updateUser).toPromise().then((resp: any) => {
          if (resp) {
            if (resp.status === 'OK') {
              if (resp.object) {
                this.toastr.success('Password Aggiornata Correttamente');
                this.showToLogin = true;
                this.userService.saveUser(resp.object);
                this.isPaziente = this.userService.isPaziente();
              }
            } else {
              this.toastr.error(resp.desc);
            }
          }
        }).catch((err) => {
          console.error(err);
          this.incorrectPasswordChange = true;
          this.toastr.error('Errore nel salvataggio della password.');
        });
      }

    } else {

      this.toastr.error('Compilare tutti i campi');
    }
  }

  isSuperAdmin() {
    return this.userService.isSuperAdmin();
  }

  getFirstOfObject(obj) {
    return obj[Object.keys(obj)[0]];
  }

  getFirstKey(obj) {
    return Object.keys(obj)[0];
  }
}
