import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { UserService } from 'src/app/service/user-service.service';
import { endpoints } from 'src/endpoint/endpoints';
import { endpointsChart } from '../../monitoraggio/endpoint-charts';
import { DateFromTo } from '../../monitoraggio/Model/date-from-to';
import { getBarChartOptions } from '../../monitoraggio/Utils/apexchart-opt';
import { SerieType } from '../../monitoraggio/Utils/grafici-utils';

@Component({
  selector: 'app-grafico-icf-header',
  templateUrl: './grafico-icf-header.component.html',
  styleUrls: ['./grafico-icf-header.component.scss']
})
export class GraficoIcfHeaderComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() patientId;

  @Input('date') set date(date: DateFromTo) {
    if (!date.compare(this._date)) {
      this.observer.next(date);
    }
  }

  // Usato solo per il report
  @Input() altezzaGrafico;
  @Input() larghezzaGrafico;
  @Input() isReport;

  // tslint:disable-next-line: variable-name
  _date: DateFromTo;

  observer: BehaviorSubject<DateFromTo>;

  dateFromString: string;
  dateToString: string;

  public bgcolorB = {
    backgroundColor: '#32adc0'
  };

  public bgcolorD = {
    backgroundColor: '#3daf57'
  };

  stroke: ApexStroke = { curve: 'smooth', width: 3 };
  markers = { size: 3, hover: { size: undefined, sizeOffset: 2 } };

  @ViewChild('groupB') groupBRef: ElementRef;
  @ViewChild('groupD') groupDRef: ElementRef;
  @ViewChild('nlab') nlabRef: ElementRef;

  chartB: ApexCharts;
  chartD: ApexCharts;
  nlab: ApexCharts;

  org;

  constructor(private http: HttpClient, private toastr: ToastrService, private datePipe: DatePipe, private userService: UserService) {
    this.observer = new BehaviorSubject<DateFromTo>(null);
  }

  ngOnInit() {
    if (this.isReport === undefined) {
      this.isReport = false;
    }
    this.org = this.userService.getCurrentOrganization();
    if (this.org === 'VIVISOL' || this.org === 'AQUA') {
      this.org = 'NEMO';
    }
  }

  ngAfterViewInit() {
    this.observer.subscribe((input) => {
      if (this.patientId && input && this.groupBRef && this.groupDRef) {
        this._date = input;
        this.dateFromString = this.datePipe.transform(input.getDateFrom(), 'yyyy-MM-dd');
        this.dateToString = this.datePipe.transform(input.getDateTo(), 'yyyy-MM-dd');
        this.createChart(this.getChartArray('B - FUNZIONI CORPOREE'), 'groupB', 'B - FUNZIONI CORPOREE', 'b',
          this.groupBRef, '#32adc0', this.chartB);
        this.createChart(this.getChartArray('D - ATTIVITA\' E PARTECIPAZIONE'), 'groupd', 'D - ATTIVITA\' E PARTECIPAZIONE', 'd',
          this.groupDRef, '#3daf57', this.chartD);
        if (this.org === 'NEMO') {
          this.createChart(this.getChartArray('NLAB SCALE'), 'nlab', 'NLAB SCALE', 'd',
            this.nlabRef, '#ffff', this.nlab);
        }
      }
    });
  }



  ngOnDestroy(): void {
    this.observer.unsubscribe();
  }

  getChartArray(input): SerieType {
    return {
      name: input,
      color: ['#FFF'],
      display: [input],
      type: ['line'],
      yaxis: this.getYaxis('Qualifier'),
      stroke: this.stroke,
      markers: true,
      xaxis: {
        title: {
          text: 'Visite',
          style: {
            color: '#FFF',
            fontSize: '20px'
          },
          offsetY: 5,
          offsetX: 0,
          align: 'center',
        },
        type: 'datetime',
        labels: {
          datetimeUTC: false,
          style: {
            colors: '#FFF',
          }
        }
      }
    } as SerieType;
  }

  createChartDataURI(chartArray: SerieType, id, title, date, group, chartRef, color, chart) {
    return new Promise((resolve, reject) => {
      this.dateFromString = this.datePipe.transform(date.getDateFrom(), 'yyyy-MM-dd');
      this.dateToString = this.datePipe.transform(date.getDateTo(), 'yyyy-MM-dd');
      this.getData().then((response: any) => {
        const series: ApexAxisChartSeries = [];
        const seriesLabels = [];
        const labels = [];

        Object.keys(response).forEach(key => {
          const split = key.split(' - ');
          if (split[0].startsWith(group)) {
            response[key].forEach(item => {
              let entrato = false;
              seriesLabels.forEach((item1, index) => {
                if (item.x === item1.x) {
                  seriesLabels[index].y = (seriesLabels[index].y + item.y) / 2;
                  entrato = true;
                }
              });
              if (!entrato) {
                seriesLabels.push(item);
                labels.push(item.y);
              }
            });
          }
        });

        const d: any = {};
        d.name = title;
        d.data = seriesLabels;

        series.push(d);
        const chartArr = new Array<SerieType>();
        chartArr.push(chartArray);
        // tslint:disable-next-line: max-line-length
        const options = getBarChartOptions(
          id,
          title,
          series,
          chartArr,
          labels,
          chartArray.yaxis,
          chartArray.stroke,
          chartArray.markers,
          chartArray.fill,
          chartArray.grid,
          chartArray.xaxis
        );
        options.dataLabels = { enabled: false };
        options.chart.background = color;
        (options.chart as any).group = 'icf';
        options.title.offsetX = 0;
        options.title.align = 'center';

        chart = new ApexCharts(chartRef.nativeElement, options);
        chart.render();
        resolve(chart);

      }).catch(err => {
        console.error(err);
        this.toastr.error('Errore nel recupero dei dati.');
      });
    });
  }

  createChart(chartArray: SerieType, id, title, group, chartRef, color, chart) {
    if (id === 'nlab') {
      this.getDataNlab().then((response: any) => {
        const series: ApexAxisChartSeries = [];
        // chartArray.display.forEach((el, i) => {
        //   series.push({ data: [], type: chartArray.type[i], name: el });
        // });
        const seriesLabels = [];
        const labels = [];

        Object.keys(response).forEach(key => {
          const split = key.split(' - ');
          if (split[0].startsWith(group)) {
            response[key].forEach(item => {
              let entrato = false;
              seriesLabels.forEach((item1, index) => {
                if (item.x === item1.x) {
                  seriesLabels[index].y = (seriesLabels[index].y + item.y) / 2;
                  entrato = true;
                }
              });
              if (!entrato) {
                seriesLabels.push(item);
                labels.push(item.y);
              }
            });
          }
        });

        response.forEach(element => {
          seriesLabels.push(element);
          labels.push(element.y);
        });

        const d: any = {};
        d.name = title;
        d.data = seriesLabels;

        chartArray.color = ['#007ad9'];
        chartArray.xaxis.title.style.color = '#000000';
        chartArray.yaxis.title.style.color = '#000000';
        chartArray.yaxis.title.text = 'Score';
        chartArray.xaxis.labels.style.colors = '#000000';

        series.push(d);
        const chartArr = new Array<SerieType>();
        chartArr.push(chartArray);
        // tslint:disable-next-line: max-line-length
        const options = getBarChartOptions(
          id,
          title,
          series,
          chartArr,
          labels,
          chartArray.yaxis,
          chartArray.stroke,
          chartArray.markers,
          chartArray.fill,
          chartArray.grid,
          chartArray.xaxis
        );
        options.dataLabels = { enabled: false };
        options.chart.background = color;
        options.title.offsetX = 0;
        options.title.align = 'center';

        if (this.altezzaGrafico !== undefined) {
          options.chart.height = this.altezzaGrafico;
        }
        if (this.larghezzaGrafico !== undefined) {
          options.chart.width = this.larghezzaGrafico;
        }

        if (chart) {
          chart.updateOptions(options, false, false, false);
          // ApexCharts.exec(id,'updateOptions',options)
        } else {
          if (chartRef !== undefined) {
            chart = new ApexCharts(chartRef.nativeElement, options);
            chart.render();
          }
        }
      }).catch(err => {
        console.error(err);
        this.toastr.error('Errore nel recupero dei dati.');
      });
    } else {
      this.getData().then((response: any) => {
        const series: ApexAxisChartSeries = [];
        // chartArray.display.forEach((el, i) => {
        //   series.push({ data: [], type: chartArray.type[i], name: el });
        // });
        const seriesLabels = [];
        const labels = [];

        Object.keys(response).forEach(key => {
          const split = key.split(' - ');
          if (split[0].startsWith(group)) {
            response[key].forEach(item => {
              let entrato = false;
              seriesLabels.forEach((item1, index) => {
                if (item.x === item1.x) {
                  seriesLabels[index].y = (seriesLabels[index].y + item.y) / 2;
                  entrato = true;
                }
              });
              if (!entrato) {
                seriesLabels.push(item);
                labels.push(item.y);
              }
            });
          }
        });

        const d: any = {};
        d.name = title;
        d.data = seriesLabels;


        series.push(d);
        const chartArr = new Array<SerieType>();
        chartArr.push(chartArray);
        // tslint:disable-next-line: max-line-length
        const options = getBarChartOptions(
          id,
          title,
          series,
          chartArr,
          labels,
          chartArray.yaxis,
          chartArray.stroke,
          chartArray.markers,
          chartArray.fill,
          chartArray.grid,
          chartArray.xaxis
        );
        options.dataLabels = { enabled: false };
        options.chart.background = color;
        (options.chart as any).group = 'icf';
        options.title.offsetX = 0;
        options.title.align = 'center';
        if (this.altezzaGrafico !== undefined) {
          options.chart.height = this.altezzaGrafico;
        }
        if (this.larghezzaGrafico !== undefined) {
          options.chart.width = this.larghezzaGrafico;
        }
        if (chart) {
          chart.updateOptions(options, false, false, false);
          // ApexCharts.exec(id,'updateOptions',options)
        } else {
          chart = new ApexCharts(chartRef.nativeElement, options);
          chart.render();
        }
      }).catch(err => {
        console.error(err);
        this.toastr.error('Errore nel recupero dei dati.');
      });
    }


  }


  getData() {
    return this.http.get(endpointsChart.searchICFs + this.patientId).toPromise();
  }

  getDataNlab() {
    return this.http.get(endpoints.getNlabScoreByPatientId + this.patientId).toPromise();
  }


  getYaxis(label) {
    const yAxis: ApexYAxis = {
      axisTicks: {
        show: true
      },
      axisBorder: {
        show: true,
        color: '#FFF'
      },
      labels: {},
      title: {
        text: label,
        style: {
          color: '#FFF'
        }
      },
    };

    const labels = {
      style: {
        color: '#FFF',
        fontSize: '15px',
      },
      formatter: (y, index) => {
        return y + '';
      }
    };

    yAxis.labels = labels;
    yAxis.min = 0;
    yAxis.max = 4;
    yAxis.tickAmount = 4;

    return yAxis;
  }

}
