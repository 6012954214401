<div class="login-page" *ngIf="currentOrg != 'NEMO'">
  <form [formGroup]="loginForm" (ngSubmit)="obtainAccessToken()">
    <div class="login-box">
      <div class="login-logo">
        <img style="width: 25rem; margin-left: -1%;" src="assets/img/Logo-Login1.png" alt="" />
      </div>
      <!-- /.login-logo -->
      <span class="p-fluid">
        <div class="mb-3">
          <!-- TODO Mettere icona font awesome come specifica -->
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="far fa-envelope"></i>
            </span>
            <input type='text' pInputText placeholder="Nome utente" formControlName="userName" />
          </div>
          <div class="invalid-text"
            *ngIf="loginForm.get('userName').invalid && (loginForm.get('userName').dirty || loginForm.get('userName').touched)">
            Inserire un nome utente.
          </div>
        </div>
        <div class="mb-3">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="fas fa-lock"></i>
            </span>
            <input type='password' pInputText name='password' placeholder="Password" formControlName="password"
              #password>
          </div>
          <div class="invalid-text"
            *ngIf="loginForm.get('password').invalid && (loginForm.get('password').dirty || loginForm.get('password').touched)">
            Inserire un password.
          </div>
          <div class="col-md-12 mt-2 d-flex" *ngIf="linkTermini != undefined && !privacyAccepted">
            <p-checkbox id="checkTerms" formControlName="checkTerms" [binary]="true"></p-checkbox>
            <label class="ml-1 mt-2" for="checkTerms">accetto i <a href="{{linkTermini}}" target="_blank">termini e
                condizioni d’uso </a></label>
          </div>
          <div class="col-md-12 mt-1 d-flex" *ngIf="!privacyAccepted">
            <p-checkbox id="checkPrivacy" formControlName="checkPrivacy" [binary]="true"></p-checkbox>
            <label class="ml-1 mt-2" for="checkPrivacy">dichiaro di aver preso visione dell’<a [href]="linkInformativa"
                target="_blank">informativa sulla privacy</a></label>
          </div>
          <div class="col-md-12 mt-1 d-flex" *ngIf="!privacyAccepted">
            <p-checkbox id="checkUsage" formControlName="checkUsage" [binary]="true"></p-checkbox>
            <label class="ml-1 mt-2" for="checkUsage">do il consenso al trattamento dei dati</label>
          </div>
        </div>

        <div class="row" *ngIf="incorrectLogin">
          <div class="invalid-text">
            {{incorrectLoginMessage}}
          </div>
        </div>
        <div class="row">
          <div class="col-8">
            <label class="fintoLink" (click)="showPopupRecuperoPassword()">Password
              dimenticata?</label>
          </div>
          <div class="col-4">
            <!-- <button name="submit" type="submit" value="submit"
                            class="btn btn-primary to-right">Entra</button> -->
            <p-button type="submit" class="p-button-raised p-button-rounded p-button-small" label="Entra"
              [disabled]="!enableFormStatus"></p-button>
          </div>
          <!-- /.col -->

        </div>
        <div class="row mt-3">
          <div class="col-12 " *ngIf="privacyAccepted">
            <span class="mt-2">Accedendo al tuo account dichiari di aver preso
              visione
              dell'<a [href]="linkInformativa" target="_blank">informativa sulla privacy</a> <br> e dato il consenso
              al
              trattamento dei dati.</span>
          </div>
        </div>

      </span>
    </div>
    <!-- /.login-box -->
  </form>

  <!-- Dialog recupero password -->
  <p-dialog [(visible)]="visiblePopupRecuperoPassword" appendTo="body" [responsive]="true" [dismissableMask]="true"
    [style]="{width: '25%'}" [modal]="true" [positionTop]="200" [focusOnShow]="false" [draggable]="false"
    baseZIndex="2000" header="Recupero password" class="p-fluid">
    <label>Inserire il nome utente di cui si vuole recuperare la password</label>
    <div class="p-inputgroup">
      <input style="width:100%" type='text' pInputText placeholder="Nome utente" [(ngModel)]="recuperoFromUsername" />
      <span class="p-inputgroup-addon">
        <i class="far fa-envelope"></i>
      </span>
    </div>
    <!-- <div class="invalid-text" *ngIf="invalidUser">
      Nome utente non trovato.
    </div> -->
    <span style="text-align:right" class="row justify-content-end mt-2">
      <div class="col-md-6 mt-2">
        <p-button type="button" class="p-button-raised mt-2" label="Richiedi password" (click)="recuperoPassword()">
        </p-button>
      </div>
    </span>
  </p-dialog>

</div>

<!-- <div class="row" style="width: 102%; height: 15%; position: absolute; bottom: 0px; background-color: white">

    <div class="col-md-4">
        <img style="width: 80%;" src="assets/img/loghiProgetto.png" alt="" />
    </div>
    <div class="col-md-6">
        <b>CWA - ONCOLOGY CARE MANAGEMENT</b> <span>Progetto
            trasferimento tecnologico e di prima industrializzazione per le
            imprese innovative ad alto potenziale per la lotta alle patologie
            oncologiche – <b>Campania Terra del Buono</b>
        </span>
    </div>

</div> -->

<div class="login-page" *ngIf="currentOrg == 'NEMO'">
  <form [formGroup]="loginForm" (ngSubmit)="obtainAccessToken()">
    <div class="login-box">
      <div class="login-logo">
        <div id="Group_10" class="mb-5">
          <svg class="Path_6" viewBox="-736.109 464.289 276.005 276.143">
            <path id="Path_6"
              d="M -461.2340698242188 587.4947509765625 C -458.9631958007813 604.098876953125 -460.1589965820313 620.56591796875 -464.4393920898438 636.71728515625 C -471.9812622070313 665.1904296875 -486.7941284179688 689.298583984375 -509.5331420898438 708.1683349609375 C -567.1380004882813 755.974853515625 -642.4238891601563 746.25048828125 -687.2324829101563 707.7191162109375 C -739.2311401367188 663.0079345703125 -748.3926391601563 593.203857421875 -721.1135864257813 539.8343505859375 C -688.9623413085938 476.9334411621094 -620.3632202148438 454.4740905761719 -567.6160278320313 468.1361999511719 C -612.1378784179688 460.5944519042969 -651.8740844726563 471.2117614746094 -684.7594604492188 501.9920349121094 C -714.8740844726563 530.1802978515625 -728.9259643554688 566.150146484375 -726.0974731445313 607.36376953125 C -721.1226196289063 679.848388671875 -660.7289428710938 734.6409912109375 -587.5933227539063 731.156005859375 C -515.0221557617188 727.6981201171875 -456.3963012695313 666.9942626953125 -461.2340698242188 587.4947509765625 Z">
            </path>
          </svg>
          <div id="Group_3">
            <svg class="Path_7" viewBox="-706.846 516.2 44.398 81.969">
              <path id="Path_7"
                d="M -706.8460693359375 520.6587524414063 C -706.73779296875 519.9137573242188 -706.297607421875 519.7406616210938 -705.90625 519.5223388671875 C -702.1236572265625 517.4100341796875 -697.9837646484375 516.4486694335938 -693.7286376953125 516.2448120117188 C -687.3719482421875 515.9400634765625 -681.2984619140625 517.1810302734375 -675.829345703125 520.6136474609375 C -670.803955078125 523.7685546875 -667.216064453125 528.1842651367188 -664.9866943359375 533.6570434570313 C -663.4354248046875 537.4630737304688 -662.5838623046875 541.4495849609375 -662.5731201171875 545.5839233398438 C -662.5279541015625 562.350341796875 -662.486572265625 579.1168823242188 -662.4486083984375 595.8833618164063 C -662.4432373046875 598.1273193359375 -662.4937744140625 598.1688232421875 -664.7557373046875 598.1688232421875 C -671.593994140625 598.1688232421875 -678.43408203125 598.1705932617188 -685.274169921875 598.1669311523438 C -687.169921875 598.1652221679688 -687.3468017578125 597.9920043945313 -687.3468017578125 596.0726928710938 C -687.3538818359375 579.151123046875 -687.453125 562.2295532226563 -687.2926025390625 545.307861328125 C -687.249267578125 540.7027587890625 -688.9720458984375 536.90576171875 -691.251953125 533.204345703125 C -692.170166015625 531.7125244140625 -693.2452392578125 530.2803344726563 -694.451904296875 529.01220703125 C -697.9459228515625 525.3397216796875 -701.827880859375 522.1812133789063 -706.8460693359375 520.6587524414063 Z">
              </path>
            </svg>
            <img id="Group_2" src="assets/img/Group_2.png"
              srcset="assets/img/Group_2.png 1x, assets/img/Group_2@2x.png 2x">

          </div>
          <div id="Group_5">
            <svg class="Path_9" viewBox="-714.647 519.991 28.114 75.041">
              <path id="Path_9"
                d="M -714.6215209960938 571.2139892578125 C -714.6215209960938 564.2241821289063 -714.6936645507813 557.2307739257813 -714.5962524414063 550.2428588867188 C -714.5259399414063 545.1524658203125 -714.6864624023438 540.0404663085938 -713.3335571289063 535.03662109375 C -711.8147583007813 529.412353515625 -709.3434448242188 524.3760986328125 -704.9818725585938 520.3895874023438 C -704.5617065429688 520.0036010742188 -704.2459106445313 519.8828735351563 -703.6597290039063 520.0938110351563 C -697.9542846679688 522.1430053710938 -692.9937133789063 525.2058715820313 -689.3356323242188 530.1825561523438 C -688.4048461914063 531.4489135742188 -687.5949096679688 532.7710571289063 -686.8084106445313 534.1167602539063 C -686.4006958007813 534.81298828125 -686.3809204101563 535.3179931640625 -687.1963500976563 535.8682861328125 C -688.8377075195313 536.9722290039063 -688.7926635742188 538.8318481445313 -688.7962036132813 540.51123046875 C -688.8377075195313 557.9884643554688 -688.8269653320313 575.4673461914063 -688.8286743164063 592.944580078125 C -688.8286743164063 594.9990844726563 -688.8286743164063 595.0009155273438 -690.8723754882813 595.0009155273438 C -698.1670532226563 595.0009155273438 -705.4617309570313 594.96484375 -712.7564086914063 595.0315551757813 C -714.2516479492188 595.0459594726563 -714.6576538085938 594.53369140625 -714.6466674804688 593.0996704101563 C -714.5890502929688 585.8050537109375 -714.6215209960938 578.5103759765625 -714.6215209960938 571.2139892578125 Z">
              </path>
            </svg>
            <img id="Group_4" src="assets/img/Group_4.png"
              srcset="assets/img/Group_4.png 1x, assets/img/Group_4@2x.png 2x">

          </div>
          <div id="Group_7">
            <svg class="Path_11" viewBox="-652.71 520.712 86.854 73.801">
              <path id="Path_11"
                d="M -565.856201171875 594.4661865234375 C -571.2928466796875 594.4661865234375 -576.327392578125 594.4661865234375 -581.363525390625 594.4661865234375 C -582.98876953125 594.4661865234375 -584.626708984375 594.3526611328125 -586.2392578125 594.4986572265625 C -587.7274169921875 594.6322021484375 -588.1910400390625 593.798828125 -588.6474609375 592.658935546875 C -591.1258544921875 586.4771728515625 -593.6385498046875 580.30810546875 -596.1531982421875 574.140869140625 C -600.3505859375 563.835693359375 -604.55712890625 553.5340576171875 -608.7635498046875 543.2325439453125 C -608.94580078125 542.7850341796875 -609.1658935546875 542.35400390625 -609.5555419921875 541.50439453125 C -610.0352783203125 542.5615234375 -610.4014892578125 543.29736328125 -610.7099609375 544.0567626953125 C -617.2379150390625 560.078369140625 -623.7586669921875 576.105224609375 -630.2867431640625 592.126708984375 C -631.39794921875 594.8504638671875 -630.975830078125 594.4500732421875 -633.7734375 594.4608154296875 C -639.2210693359375 594.4788818359375 -644.6685791015625 594.4661865234375 -650.117919921875 594.4661865234375 L -652.7099609375 594.4661865234375 C -652.4232177734375 593.6834716796875 -652.2625732421875 593.153076171875 -652.0389404296875 592.6534423828125 C -641.574951171875 569.3011474609375 -631.0894775390625 545.9580078125 -620.6705322265625 522.584228515625 C -620.03564453125 521.1591796875 -619.2672119140625 520.713623046875 -617.7628173828125 520.7388916015625 C -612.4793701171875 520.8255615234375 -607.190673828125 520.8868408203125 -601.910888671875 520.71728515625 C -599.728271484375 520.64697265625 -598.6622314453125 521.2908935546875 -597.7476806640625 523.350830078125 C -588.6474609375 543.8421630859375 -579.4190673828125 564.2757568359375 -570.226806640625 584.7274169921875 C -568.83251953125 587.8282470703125 -567.4417724609375 590.9307861328125 -565.856201171875 594.4661865234375 Z">
              </path>
            </svg>
            <img id="Group_6" src="assets/img/Group_6.png"
              srcset="assets/img/Group_6.png 1x, assets/img/Group_6@2x.png 2x">

          </div>
          <div id="Group_9">
            <svg class="Path_13" viewBox="-670.568 520.752 21.259 73.706">
              <path id="Path_13"
                d="M -670.567626953125 557.4849243164063 C -670.567626953125 546.185791015625 -670.567626953125 534.8848266601563 -670.567626953125 523.5857543945313 C -670.567626953125 523.017578125 -670.551513671875 522.4475708007813 -670.562255859375 521.87939453125 C -670.572998046875 521.2227783203125 -670.3656005859375 520.755615234375 -669.618896484375 520.755615234375 C -663.197265625 520.7537841796875 -656.775634765625 520.7573852539063 -650.3541259765625 520.7520141601563 C -649.6036376953125 520.7520141601563 -649.37451171875 521.2010498046875 -649.349365234375 521.8396606445313 C -649.3277587890625 522.4078369140625 -649.325927734375 522.9779052734375 -649.325927734375 523.546142578125 C -649.325927734375 546.227294921875 -649.325927734375 568.9083862304688 -649.325927734375 591.5896606445313 C -649.325927734375 591.9954833984375 -649.3583984375 592.4049072265625 -649.322265625 592.8071899414063 C -649.2122802734375 594.0914916992188 -649.791259765625 594.4685668945313 -651.0467529296875 594.4576416015625 C -656.900146484375 594.4036254882813 -662.7535400390625 594.4432373046875 -668.60693359375 594.4288330078125 C -670.53515625 594.4234008789063 -670.560546875 594.392822265625 -670.562255859375 592.3599243164063 C -670.5712890625 580.7360229492188 -670.567626953125 569.1104125976563 -670.567626953125 557.4849243164063 Z">
              </path>
            </svg>
            <img id="Group_8" src="assets/img/Group_8.png"
              srcset="assets/img/Group_8.png 1x, assets/img/Group_8@2x.png 2x">

          </div>
          <svg class="Ellipse_1">
            <ellipse id="Ellipse_1" rx="8.11717414855957" ry="8.11717414855957" cx="8.11717414855957"
              cy="8.11717414855957">
            </ellipse>
          </svg>
        </div>
      </div>
      <!-- /.login-logo -->
      <div class="p-fluid rectangle-nemo">
        <div class="mb-3">
          <!-- TODO Mettere icona font awesome come specifica -->
          <input class="input-nemo" type='text' pInputText placeholder="Nome utente" formControlName="userName" />
          <div class="invalid-text"
            *ngIf="loginForm.get('userName').invalid && (loginForm.get('userName').dirty || loginForm.get('userName').touched)">
            Inserire un nome utente.
          </div>
        </div>
        <div class="mb-3">
          <input class="input-nemo" type='password' pInputText name='password' placeholder="Password"
            formControlName="password" #password>
          <div class="invalid-text"
            *ngIf="loginForm.get('password').invalid && (loginForm.get('password').dirty || loginForm.get('password').touched)">
            Inserire un password.
          </div>
        </div>
        <div class="row" *ngIf="incorrectLogin">
          <div class="invalid-text">
            {{incorrectLoginMessage}}
          </div>
        </div>
        <div class="row">
          <div class="col-8">
            <label class="fintoLinkNemo" (click)="showPopupRecuperoPassword()">Password
              dimenticata?</label>
          </div>
          <div class="col-8 mt-2 d-flex" *ngIf="linkTermini != undefined && !privacyAccepted">
            <p-checkbox id="checkTerms" formControlName="checkTerms" [binary]="true"></p-checkbox>
            <label class="ml-1 mt-2" for="checkTerms">accetto i <a class="linkNemo" href="{{linkTermini}}"
                target="_blank">termini e
                condizioni d’uso </a></label>
          </div>
          <div class="col-8 mt-1 d-flex" *ngIf="!privacyAccepted">
            <p-checkbox id="checkPrivacy" formControlName="checkPrivacy" [binary]="true"></p-checkbox>
            <label class="ml-1 mt-2" for="checkPrivacy">dichiaro di aver preso visione dell’<a class="linkNemo"
                [href]="linkInformativa" target="_blank">informativa sulla privacy</a></label>
          </div>
          <div class="col-8 mt-1 d-flex" *ngIf="!privacyAccepted">
            <p-checkbox id="checkUsage" formControlName="checkUsage" [binary]="true"></p-checkbox>
            <label class="ml-1 mt-2" for="checkUsage">do il consenso al trattamento dei dati</label>
          </div>
          <!-- /.col -->
        </div>
        <div class="row mt-3 mb-3">
          <div class="col-12" *ngIf="privacyAccepted">
            <span class="mt-2">Accedendo al tuo account dichiari di aver preso
              visione
              dell'<a class="linkNemo" [href]="linkInformativa" target="_blank">informativa sulla privacy</a> e dato il
              consenso
              al
              trattamento dei dati.</span>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <!-- <button name="submit" type="submit" value="submit"
                          class="btn btn-primary to-right">Entra</button> -->
            <button type="submit" class="enter-button-nemo" [disabled]="!enableFormStatus">ENTRA</button>
          </div>
          <!-- /.col -->
        </div>
      </div>
    </div>
    <!-- /.login-box -->
  </form>

  <!-- Dialog recupero password -->
  <p-dialog [(visible)]="visiblePopupRecuperoPassword" appendTo="body" [responsive]="true" [dismissableMask]="true"
    [style]="{width: '25%'}" [modal]="true" [positionTop]="200" [focusOnShow]="false" [draggable]="false"
    baseZIndex="2000" header="Recupero password" class="p-fluid">
    <label>Inserire il nome utente di cui si vuole recuperare la password</label>
    <div class="p-inputgroup">
      <input style="width:100%" type='text' pInputText placeholder="Nome utente" [(ngModel)]="recuperoFromUsername" />
      <span class="p-inputgroup-addon">
        <i class="far fa-envelope"></i>
      </span>
    </div>
    <!-- <div class="invalid-text" *ngIf="invalidUser">
      Nome utente non trovato.
    </div> -->
    <span style="text-align:right" class="row justify-content-end mt-2">
      <div class="col-md-6">
        <p-button type="button" class="p-button-raised" label="Richiedi password" (click)="recuperoPassword()">
        </p-button>
      </div>
    </span>
  </p-dialog>

</div>
<!-- <div class="row" style="width: 102%; height: 15%; position: absolute; bottom: 0px; background-color: white">

  <div class="col-md-4">
      <img style="width: 80%;" src="assets/img/loghiProgetto.png" alt="" />
  </div>
  <div class="col-md-6">
      <b>CWA - ONCOLOGY CARE MANAGEMENT</b> <span>Progetto
          trasferimento tecnologico e di prima industrializzazione per le
          imprese innovative ad alto potenziale per la lotta alle patologie
          oncologiche – <b>Campania Terra del Buono</b>
      </span>
  </div>

</div> -->
