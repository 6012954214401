<div class="row modal-body">
    <form class="col-md-12" [formGroup]="form">
        <div class="col-md-12 mt-1 d-flex">
            Per l'utilizzo dell'applicazione è necessario dare il consenso al trattamento dei dati in conformità al GDPR
            e alle condizioni d'uso.
        </div>
        <div class="col-md-12 mt-2 d-flex" *ngIf="linkTermini != undefined">
            <p-checkbox id="checkTerms" formControlName="checkTerms" [binary]="true"></p-checkbox>
            <label class="ml-1 mt-2" for="checkTerms">accetto i <a href="{{linkTermini}}" target="_blank">termini e
                    condizioni d’uso </a></label>
        </div>

        <div class="col-md-12 mt-1 d-flex">
            <p-checkbox id="checkPrivacy" formControlName="checkPrivacy" [binary]="true"></p-checkbox>
            <label class="ml-1 mt-2" for="checkPrivacy">dichiaro di aver preso visione dell’<a [href]="linkInformativa"
                    target="_blank">informativa sulla privacy</a></label>
        </div>
        <div class="col-md-12 mt-1 d-flex">
            <p-checkbox id="checkUsage" formControlName="checkUsage" [binary]="true"></p-checkbox>
            <label class="ml-1 mt-2" for="checkUsage">do il consenso al trattamento dei dati</label>
        </div>
        <div class="col-md-12 mt-2 text-center">
            <button type="button" class="btn btn-danger mr-2" (click)="decline()">Rifiuta</button>
            <button type="button" class="btn btn-success ml-2" (click)="save()">Salva</button>
        </div>
    </form>
</div>