import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { endpoints } from 'src/endpoint/endpoints';
import { PazientiService } from '../../service/pazienti.service';

@Component({
  selector: 'app-stato-di-salute',
  templateUrl: './stato-di-salute.component.html',
  styleUrls: ['./stato-di-salute.component.scss']
})
export class StatoDiSaluteComponent implements OnInit, OnDestroy {

  @Input() set id(id) {
    this.id$ = id;
    if (this.id$) {
      this.getStatoDiSalute();
    }
  }

  clinicalImpressionId;
  icf = [];
  aggiorna;
  id$;

  // Per controllare se è nella visita
  isInVisita: boolean;

  constructor(private http: HttpClient, private toastr: ToastrService, private pazientiService: PazientiService, private route: ActivatedRoute) { }

  ngOnInit() {
    if (this.id$) {
      this.getStatoDiSalute();
    }
    this.aggiorna = this.pazientiService.getAggiornaStatSal().subscribe(input => {
      if (input) {
        this.getStatoDiSalute();
      }
    });
  }

  setIsInVisita() {
    this.isInVisita = this.route.parent.snapshot.paramMap.get('vId') != undefined || this.route.parent.snapshot.url.find(obj => obj.path == "visite") != undefined;
  }

  ngOnDestroy(): void {
    this.aggiorna.unsubscribe();
  }

  getStatoDiSalute() {
    this.setIsInVisita();
    this.http.get((this.isInVisita ? endpoints.getLastClinicalImpressionByPatientIdVisite : endpoints.getLastClinicalImpressionByPatientIdDettagli) + "/" + this.id$)
      .toPromise().then((resp: any) => {
        if (resp) {
          if (resp.entry && resp.entry.length > 0) {
            this.clinicalImpressionId = resp.entry[0].resource.id;
            this.getFindingICF();
          }
        }
      }).catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Impossibile caricare lo stato di salute.', 'Errore');
      });
  }

  getFindingICF() {
    this.http.get((this.isInVisita ? endpoints.getFindingICFVisite : endpoints.getFindingICFDettagli) + '?clinicalImpressionId=' + this.clinicalImpressionId)
      .toPromise().then((resp: any) => {
        if (resp) {
          this.icf = resp.message;
        }
      })
      .catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Impossibile caricare lo stato di salute.', 'Errore');
      });
  }

}
