import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { SelectItem } from 'primeng/api';
import { forkJoin, Observable } from 'rxjs';
import { Condition } from 'src/app/model/Condition';
import { checkIsModified, Code, CodeCoding, Observation, updateFormByObs, updateObsByForm } from 'src/app/model/Observation';
import { UserService } from 'src/app/service/user-service.service';
import { endpoints } from 'src/endpoint/endpoints';
import { LogEventService } from '../../service/log-event.service';
import { PazientiService } from '../../service/pazienti.service';

@Component({
  selector: 'app-anamnesi-patologica-prossima',
  templateUrl: './anamnesi-patologica-prossima.component.html',
  styleUrls: ['./anamnesi-patologica-prossima.component.scss']
})
export class AnamnesiPatologicaProssimaComponent implements OnInit {

  @Input() clinicalImpressionId;

  @Output() save = new EventEmitter<any>();

  patientId;

  datePickerConf = { isAnimated: true, adaptivePosition: true, dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue' };
  locale = 'it';
  stageOpt: SelectItem[];
  stageOptCovid: SelectItem[];
  percorsi: SelectItem[];
  oncologyObs: Observation[];
  covidObs: Observation[];
  listICD: Condition[];

  formOnco: FormGroup;
  formCovid: FormGroup;
  text;
  descrizione;
  cols: any[];
  lastVisit;
  percorso;

  percorsoOnco = false;
  percorsoCovid = false;

  newListICD: Condition[];

  // Per controllare se è in una visita
  isInVisita = false;

  today: Date;
  maxDate: Date;

  org;

  tempObj = [];

  constructor(private pazientiService: PazientiService, private http: HttpClient,
    private fb: FormBuilder, private toastr: ToastrService, private localeService: BsLocaleService,
    private datePipe: DatePipe, private route: ActivatedRoute, private userService: UserService,
    private logService: LogEventService) { }


  ngOnInit() {

    this.today = new Date();
    this.org = this.userService.getCurrentOrganization();
    if (this.org === 'VIVISOL' || this.org === 'AQUA') {
      this.org = 'NEMO';
    }
    this.maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), 25);
    this.patientId = this.pazientiService.getPatientId();
    this.isInVisita = this.getIsInVisite();
    this.buildForm();
    this.localeService.use(this.locale);
    if (!this.isInVisita) {
      this.getAnamnesi();
      this.getOncologyInfo();
      this.getCovidInfo();
    } else {
      this.getOncologyInfoByClinicalImpressionId();
      this.getCovidInfoByClinicalImpressionId();
      this.getFindingICD();
      this.getLastClinicalImpression();
    }
    this.stageOpt = [
      { label: 'Seleziona', value: '' },
      { label: 'I', value: '1' },
      { label: 'II', value: '2' },
      { label: 'III', value: '3' },
      { label: 'IV', value: '4' }
    ];
    this.stageOptCovid = [
      { label: 'Seleziona', value: '' },
      { label: 'I - MALATTIA SEMPLICE', value: '1' },
      { label: 'II - POLMONITE LIEVE', value: '2' },
      { label: 'III - POLMONITE GRAVE', value: '3' },
      { label: 'IV - SINDROME DA STRESS RESPIRATORIO', value: '4' },
      { label: 'V - SEPSI', value: '4' },
      { label: 'VI - SHOCK SETTICO', value: '4' }
    ];
    this.percorsi = [
      { label: 'Seleziona', value: '' },
      { label: 'Percorso oncologico', value: 'onco' },
      { label: 'Percorso covid', value: 'covid' }
    ];
    this.cols = [
      { value: 'code', label: 'Codice' },
      { value: 'display', label: 'Descrizione' }
    ];
    if (this.isInVisita) {
      this.formOnco.get('dataDiagnosi').disable();
      this.formOnco.get('dataTrattamento').disable();
    }
  }

  buildForm() {
    if (this.org === 'NEMO') {
      this.percorsi = [
        { label: 'Seleziona', value: '' },
        { label: 'Percorso covid', value: 'covid' }
      ];
    }
    this.formOnco = this.fb.group({
      dataDiagnosi: [''],
      dataTrattamento: [''],
      stage: ['']
    });
    if (!this.checkPermessoModifica()) {
      this.formOnco.disable();
    }

  }

  getAnamnesi() {
    this.http.get(endpoints.getAnaPatProx + '?patientId=' + this.patientId).toPromise()
      .then((resp: any) => {
        if (resp && resp.success === '1') {
          this.listICD = resp.message;
          this.descrizione = this.listICD.find(el => el.code.coding[0].code === 'DescrizioneAnamnesiPatologicaProssima');
          if (!this.descrizione) {
            this.descrizione = this.createIcd('DescrizioneAnamnesiPatologicaProssima', '');
          }
          this.text = JSON.parse(JSON.stringify(this.descrizione));
          if (this.oncologyObs == undefined) {
            this.oncologyObs = [];
          }
          this.logService.getAllOpt([this.listICD, this.oncologyObs], null);
          this.listICD = this.listICD.filter(elem => {
            if (elem.code.coding[0].code === 'DescrizioneAnamnesiPatologicaRemota' ||
              elem.code.coding[0].code === 'DescrizioneAnamnesiPatologicaProssima') {
              return false;
            } else {
              return true;
            }
          });
        } else {
          this.toastr.error('Errore nel recupero dei dati.');
        }
      }).catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Errore nel recupero dei dati.');
      });
  }
  aggiungiPercorso() {
    if (this.percorso === 'onco') {
      this.percorsoOnco = true;
      this.formOnco = this.fb.group({
        dataDiagnosi: [''],
        dataTrattamento: [''],
        stage: ['']
      });
    }
    if (this.percorso === 'covid') {
      this.percorsoCovid = true;
      // this.formCovid = this.fb.group({
      //   dataDiagnosiCovid: [''],
      //   dataTrattamentoCovid: [''],
      //   stageCovid: ['']
      // });
      // if (!this.isInVisita) {
      //   this.getCovidInfo();
      // } else {
      //   this.getCovidInfoByClinicalImpressionId();
      // }
    }
  }

  verificaPercorsoCovid(event) {
    if (event) {
      this.percorsoCovid = true;
      this.percorso = 'covid';
    } else {
      //this.percorsoCovid = false;
    }
  }

  getCovidInfo() {
    this.http.get(endpoints.getCovidInfoByPatientId + this.patientId).toPromise()
      .then((resp: any) => {
        this.covidObs = new Array<Observation>();
        if (resp) {
          if (resp.entry) {
            if (resp.entry.length > 0) {
              this.percorsoCovid = true;
              resp.entry.forEach(element => {
                this.covidObs.push(element.resource);
              });
            } else {
              //this.percorsoCovid = true;
            }
          }
        }
        this.formCovid = this.fb.group({
          dataDiagnosiCovid: [''],
          dataTrattamentoCovid: [''],
          stageCovid: ['']
        });
        this.covidObs = this.checkOrCreate(this.covidObs);
        updateFormByObs(this.covidObs, this.formCovid);
      }).catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Errore nel recupero dei dati.');
      });
  }

  getFindingICD() {
    this.http.get(endpoints.getFindingICD + this.clinicalImpressionId).toPromise()
      .then((resp: any) => {
        if (resp && resp.success === '1') {
          this.listICD = resp.message;
          this.descrizione = this.listICD.find(el => el.code.coding[0].code === 'DescrizioneAnamnesiPatologicaProssima');
          if (!this.descrizione) {
            this.descrizione = this.createIcd('DescrizioneAnamnesiPatologicaProssima', '');
          }
          this.text = JSON.parse(JSON.stringify(this.descrizione));
          //this.logService.getAllOpt([this.listICD, this.oncologyObs], null);
          this.listICD = this.listICD.filter(elem => {
            if (elem.code.coding[0].code === 'DescrizioneAnamnesiPatologicaRemota' ||
              elem.code.coding[0].code === 'DescrizioneAnamnesiPatologicaProssima') {
              return false;
            } else {
              return true;
            }
          });
        } else {
          console.error(resp);
          this.toastr.error('Errore nel recupero dei dati.');
        }
      }).catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Errore nel recupero dei dati.');
      });
  }

  getOncologyInfo() {
    this.http.get(endpoints.getOncologyInfoByPatientId + this.patientId).toPromise()
      .then((resp: any) => {
        this.oncologyObs = new Array<Observation>();
        if (resp) {
          if (resp.entry) {
            if (resp.entry.length > 0) {
              this.percorsoOnco = true;
              resp.entry.forEach(element => {
                this.oncologyObs.push(element.resource);
              });
            }
          } else {
            this.percorsoOnco = false;
          }
        }
        this.formOnco = this.fb.group({
          dataDiagnosi: [''],
          dataTrattamento: [''],
          stage: ['']
        });
        this.oncologyObs = this.checkOrCreate(this.oncologyObs);
        updateFormByObs(this.oncologyObs, this.formOnco);
      }).catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Errore nel recupero dei dati.');
      });
  }

  trasformDate(input: Observation[]): Observation[] {
    for (const el of input) {
      if (el.code.coding[0].code === 'dataDiagnosi' || el.code.coding[0].code === 'dataTrattamento') {
        el.valueString = this.datePipe.transform(el.valueString, 'dd/MM/yyyy');
      }
      if (el.code.coding[0].code === 'dataDiagnosiCovid' || el.code.coding[0].code === 'dataTrattamentoCovid') {
        el.valueString = this.datePipe.transform(el.valueString, 'dd/MM/yyyy');
      }
    }
    return input;
  }

  checkOrCreate(input: Observation[]): Observation[] {
    const ret = new Array<Observation>();
    if (this.formOnco) {
      Object.keys(this.formOnco.controls).forEach(elem => {
        const find = input.find(x => x.code.coding[0].code === elem);
        if (!find) {
          const obs = this.createObservation(elem);
          if (obs) {
            ret.push(obs);
          }
        } else {
          if (elem === 'stage') {
            // tslint:disable-next-line: no-string-literal
            if (!find['valueString']) {
              find.valueString = '';
            }
          }
          ret.push(find);
        }
      });
    }
    // if (this.formCovid) {
    //   Object.keys(this.formCovid.controls).forEach(elem => {
    //     const find = input.find(x => x.code.coding[0].code === elem);
    //     if (!find) {
    //       const obs = this.createObservation(elem);
    //       if (obs) {
    //         ret.push(obs);
    //       }
    //     } else {
    //       if (elem === 'stageCovid') {
    //         // tslint:disable-next-line: no-string-literal
    //         if (!find['valueString']) {
    //           find.valueString = '';
    //         }
    //       }
    //       if (elem === 'stageCovid') {
    //         // tslint:disable-next-line: no-string-literal
    //         if (!find['valueString']) {
    //           find.valueString = '';
    //         }
    //       }
    //       ret.push(find);
    //     }
    //   });
    // }
    return ret;
  }

  createObservation(key) {
    switch (key) {
      case 'dataDiagnosi':
        return new Observation('oncology-extension', 'dataDiagnosi', 'Data Diagnosi', null, 'Patient/' + this.patientId, '', null);
      case 'dataTrattamento':
        // tslint:disable-next-line: max-line-length
        return new Observation('oncology-extension', 'dataTrattamento', 'Data primo trattamento', null, 'Patient/' + this.patientId, '', null);
      case 'stage':
        return new Observation('oncology-extension', 'stage', 'Stadio', null, 'Patient/' + this.patientId, '', null);
      case 'dataDiagnosiCovid':
        return new Observation('covid-extension', 'dataDiagnosiCovid', 'Data Diagnosi Covid', null, 'Patient/' + this.patientId, '', null);
      case 'dataTrattamentoCovid':
        // tslint:disable-next-line: max-line-length
        return new Observation('covid-extension', 'dataTrattamentoCovid', 'Data primo trattamento Covid', null, 'Patient/' + this.patientId, '', null);
      case 'stageCovid':
        return new Observation('covid-extension', 'stageCovid', 'Stadio Covid', null, 'Patient/' + this.patientId, '', null);
    }
  }

  salva() {
    if (this.isInVisita) {
      this.salvaVisit();
    } else {
      this.salvaDettPatient();
    }
  }

  salvaDettPatient() {
    const obsToSave = checkIsModified(this.formOnco, this.oncologyObs);
    let obsPostUpdate = updateObsByForm(obsToSave, this.formOnco);
    const obsToSaveCovid = checkIsModified(this.formCovid, this.covidObs);
    // let obsPostUpdateCovid = updateObsByForm(obsToSaveCovid, this.formCovid);
    obsPostUpdate = this.trasformDate(obsPostUpdate);
    // obsPostUpdateCovid = this.trasformDate(obsPostUpdateCovid);
    // obsPostUpdate = obsPostUpdate.concat(obsPostUpdateCovid);
    if (this.descrizione.code.coding[0].display !== this.text.code.coding[0].display) {
      if (!this.newListICD) {
        this.newListICD = [];
        this.newListICD.push(this.descrizione);
      } else {
        this.newListICD.push(this.descrizione);
      }
    }
    if (this.newListICD) {
      const condToSave = [];
      this.newListICD.forEach((el: any) => {
        const elem = {} as any;
        elem.patientId = this.patientId;
        elem.condition = {} as any;
        elem.condition.code = {} as any;
        elem.condition.code.coding = el.code.coding[0];
        elem.condition.code.coding.code = String(elem.condition.code.coding.code);
        condToSave.push(elem);
      });
      const objToSave = {} as any;
      objToSave.patientId = this.patientId;
      objToSave.entry = condToSave;
      (obsPostUpdate as any[]).push(objToSave);
    }
    forkJoin(obsPostUpdate.map(el => {
      if (el.resourceType && el.resourceType === 'Observation') {
        if (el.id) {
          return this.putObservation(el.id, el);
        } else {
          return this.postObservation(el);
        }
      } else {
        return this.addListAnamnesiPatologicaProssima(el);
      }
    })).subscribe((resp: Observation[]) => {
      this.getAnamnesi();
      this.getOncologyInfo();
      this.getCovidInfo();
      this.newListICD = null;
      this.pazientiService.aggiornamentoCondCli(true);

      this.toastr.success('Aggiornamento effettuato con successo.');
    }, (err) => {
      console.error(err);
      this.toastr.error('Errore nell\'aggiornamento dei dati.');
    });
    if ((obsPostUpdate && obsPostUpdate.length === 0) && (obsToSave && obsToSave.length === 0)) {
      this.toastr.warning('Nessun campo modificato.');
      return;
    }
  }

  salvaVisit() {
    const obsToSave = checkIsModified(this.formOnco, this.oncologyObs);
    if (obsToSave && obsToSave.length === 0) {
      this.getOncologyInfoByClinicalImpressionId();
      this.getCovidInfoByClinicalImpressionId();
      this.getFindingICD();
      //this.toastr.warning('Nessun campo modificato.');
    }
    const obsToSaveCovid = checkIsModified(this.formCovid, this.covidObs);
    let obsPostUpdate = updateObsByForm(obsToSave, this.formOnco);
    let obsPostUpdateCovid = updateObsByForm(obsToSaveCovid, this.formCovid);
    obsPostUpdate = this.trasformDate(obsPostUpdate);
    obsPostUpdateCovid = this.trasformDate(obsPostUpdateCovid);
    obsPostUpdate = obsPostUpdate.concat(obsPostUpdateCovid);
    if (this.descrizione.code.coding[0].display !== this.text.code.coding[0].display) {
      if (!this.newListICD) {
        this.newListICD = [];
        this.newListICD.push(this.descrizione);
      } else {
        this.newListICD.push(this.descrizione);
      }
    }
    if (this.newListICD) {
      const condToSave = [];
      this.newListICD.forEach((el: any) => {
        const elem = {} as any;
        elem.patientId = this.patientId;
        elem.condition = {} as any;
        elem.condition.code = {} as any;
        elem.condition.code.coding = el.code.coding[0];
        condToSave.push(elem);
      });
      const objToSave = {} as any;
      objToSave.patientId = this.patientId;
      objToSave.entry = condToSave;
      (obsPostUpdate as any[]).push(objToSave);
    }
    if (obsPostUpdate && obsPostUpdate.length === 0) {
      this.save.emit(true);
    }
    let finding;
    forkJoin(obsPostUpdate.map(el => {
      if (el.resourceType && el.resourceType === 'Observation') {
        if (el.id) {
          return this.updateObservation(this.trasformForUpdateObs(el));
        } else {
          return this.addInvestigation(this.trasformForAddInvestigation(el));
        }
      } else {
        finding = el;
        // tslint:disable-next-line: deprecation
        return Observable.create(sub => {
          sub.next(el);
          sub.complete();
        });
      }
    })).subscribe((resp: any[]) => {
      if (finding) {
        this.addFindingICD(this.trasformForAddICD(finding)).subscribe(re => {
          resp.push(re);
          this.getOncologyInfoByClinicalImpressionId();
          this.getFindingICD();
          this.newListICD = null;
          let success = true;
          if (resp) {
            resp.forEach(element => {
              if (element && element.success && element.success !== '1') {
                success = false;
              }
            });
          }
          if (success) {
            this.pazientiService.aggiornamentoCondCli(true);
            this.getOncologyInfoByClinicalImpressionId();
            this.getCovidInfoByClinicalImpressionId();
            this.getFindingICD();
            this.getLastClinicalImpression();
            this.toastr.success('Aggiornamento effettuato con successo.');
            this.save.emit(true);
          } else {
            console.error(resp);
            this.toastr.error('Errore nell\'aggiornamento dei dati.');
          }
        }, (err) => {
          console.error(err);
          this.toastr.error('Errore nell\'aggiornamento dei dati.');
        });
      }
      if (resp && resp[0]) {
        if (resp[0].success === '1') {
          this.getOncologyInfoByClinicalImpressionId();
          this.getCovidInfoByClinicalImpressionId();
          this.getFindingICD();
          this.toastr.success('Aggiornamento effettuato con successo.');
          this.save.emit(true);
        }
      }
    }, (err) => {
      console.error(err);
      this.toastr.error('Errore nell\'aggiornamento dei dati.');
    });
    if ((obsPostUpdate && obsPostUpdate.length === 0) && (obsToSave && obsToSave.length === 0)) {
      this.save.emit(false);
    }
  }

  /**
   * chiamata http PUT observation
   */
  putObservation(id, obsr) {
    return this.http.put(this.isInVisita ? endpoints.putObservationVisite : endpoints.putObservationDettagli + '/' + id, obsr);
  }

  /**
   * chiamata http POST observation
   */
  postObservation(obsr) {
    if (this.isInVisita) {
      return this.http.post(endpoints.updateObservationVisite, obsr);
    } else {
      return this.http.post(endpoints.updateObservationDettagli, obsr);
    }
  }

  addListAnamnesiPatologicaProssima(input) {
    if (this.isInVisita) {
      return this.http.post(endpoints.addListAnamnesiPatologicaProssimaVisite, input);
    } else {
      return this.http.post(endpoints.addListAnamnesiPatologicaProssimaDettagli, input);
    }
  }

  addInvestigation(input) {
    if (this.isInVisita) {
      return this.http.post(endpoints.addInvestigationVisite, input);
    } else {
      return this.http.post(endpoints.addInvestigationDettagli, input);
    }
  }

  addFindingICD(input) {
    return this.http.post(endpoints.addFindingICD, input);
  }

  updateObservation(input) {
    if (this.getIsInVisite()) {
      return this.http.post(endpoints.updateObservationVisite, input);
    } else {
      return this.http.post(endpoints.updateObservationDettagli, input);
    }
  }

  aggiungi(icd) {
    const add = this.createIcd(icd.data, icd.label);
    const find = this.listICD.find(x => x.code.coding[0].code === add.code.coding[0].code);
    if (find) {
      this.toastr.info('Impossibile aggiungere l\'elemento perchè duplicato.');
    } else {
      if (this.newListICD) {
        this.newListICD.push(add);
      } else {
        this.newListICD = new Array<Condition>();
        this.newListICD.push(add);
      }
      this.listICD.push(add);
    }
  }

  createIcd(codeIn, display): Condition {
    const condition = {} as Condition;
    const code = {} as Code;
    code.coding = new Array<CodeCoding>();
    code.coding.push({} as CodeCoding);
    code.coding[0].code = codeIn;
    code.coding[0].display = display;
    code.coding[0].system = 'http://hl7.org/fhir/sid/icd-9-cm';
    code.coding[0].version = '9';
    condition.code = code;
    condition.resourceType = 'Condition';
    return condition;
  }

  elimina(row) {
    if (row.id) {
      if (!this.getIsInVisite()) {
        const input: any = {};
        input.patientId = this.patientId;
        input.conditionId = row.id;
        this.http.post(this.isInVisita ? endpoints.deleteAnamnesiPatologicaProssimaVisite : endpoints.deleteAnamnesiPatologicaProssimaDettagli, input).toPromise()
          .then((resp: any) => {
            if (resp && resp.success === '1') {
              this.toastr.success('Condizione clinica eleminata correttamente.');
              this.getAnamnesi();
              this.pazientiService.aggiornamentoCondCli(true);
            } else {
              this.toastr.error('Errore nell\'eliminazione della condizione clinica.');
            }
          }).catch(err => {
            console.error(err);
            if (err.status == 403) {
              this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
              return;
            }
            this.toastr.error('Errore nell\'eliminazione della condizione clinica.');
          });
      } else {
        const input: any = {};
        input.conditionId = row.id;
        input.clinicalImpressionId = this.clinicalImpressionId;
        if (this.isLastVisit()) {
          input.updatePatient = this.patientId;
        }
        this.http.post(endpoints.deleteFindingICD, input).toPromise()
          .then((resp: any) => {
            if (resp && resp.success === '1') {
              this.toastr.success('Condizione clinica eleminata correttamente.');
              this.getFindingICD();
              this.pazientiService.aggiornamentoCondCli(true);
            } else {
              this.toastr.error('Errore nell\'eliminazione della condizione clinica.');
            }
          }).catch(err => {
            console.error(err);
            if (err.status == 403) {
              this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
              return;
            }
            this.toastr.error('Errore nell\'eliminazione della condizione clinica.');
          });

      }
    } else {
      this.listICD.splice(this.listICD.findIndex(el => el.code.coding[0].code === row.code.coding[0].code), 1);
      this.newListICD.splice(this.newListICD.findIndex(el => el.code.coding[0].code === row.code.coding[0].code), 1);
    }
  }

  checkPermessoModifica(): boolean {
    if (!this.isInVisita) {
      return this.userService.checkPermission('Dettagli', 'modify');
    } else {
      return this.userService.checkPermission('Visite', 'modify');
    }
  }

  getIsInVisite(): boolean {
    const cId = this.route.snapshot.paramMap.get('vId');
    if (cId) {
      return true;
    } else {
      return false;
    }
  }

  getOncologyInfoByClinicalImpressionId() {
    this.http.get(endpoints.getOncologyInfoByCIId + this.clinicalImpressionId).toPromise()
      .then((resp: any) => {
        if (resp && resp.success === '1') {

          this.formOnco = this.fb.group({
            dataDiagnosi: [''],
            dataTrattamento: [''],
            stage: ['']
          });
          this.oncologyObs = resp.message;
          if (resp.message.length > 0) {
            this.percorsoOnco = true;
          } else {
            this.percorsoOnco = false;
          }
          this.oncologyObs = this.checkOrCreate(this.oncologyObs);
          updateFormByObs(this.oncologyObs, this.formOnco);
        } else {
          this.percorsoOnco = false;
          console.error(resp);
          this.toastr.error('Errore nel recupero dei dati.');
        }
      }).catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Errore nel recupero dei dati.');
      });
  }

  getCovidInfoByClinicalImpressionId() {
    this.http.get(endpoints.getCovidInfoByCIId + this.clinicalImpressionId).toPromise()
      .then((resp: any) => {
        if (resp && resp.success === '1') {
          this.formCovid = this.fb.group({
            dataDiagnosiCovid: [''],
            dataTrattamentoCovid: [''],
            stageCovid: ['']
          });
          this.covidObs = resp.message;
          if (resp.message.length > 0) {
            this.percorsoCovid = true;
          } else {
            this.percorsoCovid = false;
          }
          this.covidObs = this.checkOrCreate(this.covidObs);
          updateFormByObs(this.covidObs, this.formCovid);
        } else {
          this.percorsoCovid = false;
          console.error(resp);
          this.toastr.error('Errore nel recupero dei dati.');
        }
      }).catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Errore nel recupero dei dati.');
      });
  }

  getLastClinicalImpression() {
    this.http.get((this.isInVisita ? endpoints.getLastClinicalImpressionByPatientIdVisite : endpoints.getLastClinicalImpressionByPatientIdDettagli) + "/" + this.patientId).toPromise()
      .then((resp: any) => {
        this.lastVisit = undefined;
        if (resp.total > 0) {
          this.lastVisit = resp.entry[0].resource;
        }
      }).catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Errore nel caricamento dei Dati.');
      });
  }

  isLastVisit(): boolean {
    if (this.lastVisit) {
      // tslint:disable-next-line: triple-equals
      if (this.clinicalImpressionId == this.lastVisit.id) {
        return true;
      }
    }
    return false;
  }

  trasformForAddInvestigation(input: Observation) {
    const ret = {
      clinicalImpressionId: this.clinicalImpressionId,
      code: {
        coding: input.category[0].coding[0]
      },
      observations: [
        {
          category: {
            coding: input.category[0].coding[0]
          },
          code: {
            coding: input.code.coding[0]
          },
          value: {
            type: 'string',
            value: input.valueString,
          }
        }
      ]
    } as any;
    ret.observations[0].code.coding.system = '';
    if (this.isLastVisit()) {
      ret.updatePatient = this.patientId;
    }
    return ret;
  }

  trasformForAddICD(input) {
    input.clinicalImpressionId = this.clinicalImpressionId;
    delete input.patientId;
    if (this.isLastVisit()) {
      input.updatePatient = this.patientId;
    }
    return input;
  }

  trasformForUpdateObs(input: Observation) {
    const ret = {
      id: input.id,
      value: {
        type: 'string',
        value: input.valueString
      }
    } as any;
    if (this.isLastVisit()) {
      ret.updatePatient = this.patientId;
    }
    return ret;
  }

}
