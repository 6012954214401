<!-- Navbar -->
<nav class="main-header navbar navbar-expand bg-white navbar-light border-bottom">
  <!-- Left navbar links -->
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link" data-widget="pushmenu">
        <i class="color-nemo fas fa-bars"></i>
      </a>
    </li>
    <li class="nav-item" *ngIf="paziente">
      <a class="nav-link" *ngIf="!mobile">
        <img [src]="getSanitizedPhoto()" class="image" pTooltip="{{patIdentifier}} {{patName}} {{patSurname}}">
        <label class="color-nemo" style="margin-left:7px">ID :{{patIdentifier}} {{patName}} {{patSurname}}</label>
      </a>
      <a class="nav-link p-fluid" *ngIf="mobile" style="cursor: pointer;" (click)="showPanelInfo()">
        <img [src]="getSanitizedPhoto()" class="image" pTooltip="{{patIdentifier}} {{patName}} {{patSurname}}">
        <label class="color-nemo" style="margin-left:7px; cursor: pointer"> ID :{{patIdentifier}}</label>
      </a>
    </li>

    <!-- <li class="nav-item d-none d-sm-inline-block">
      <a [routerLink]="['/']" class="nav-link">Home</a>
    </li>
    <li class="nav-item d-none d-sm-inline-block">
      <a [routerLink]="['/']" class="nav-link">Contact</a>
    </li> -->
  </ul>

  <!-- SEARCH FORM -->
  <!-- <form class="form-inline ml-3" [formGroup]="searchForm">
    <div class="input-group input-group-sm">
      <input class="form-control form-control-navbar" type="search" formControlName="search" placeholder="Search"
        aria-label="Search" />
      <div class="input-group-append">
        <button class="btn btn-navbar" type="submit">
          <i class="fa fa-search"></i>
        </button>
      </div>
    </div>
  </form> -->

  <!-- Right navbar links -->

  <!-- <li class="nav-item dropdown" *ngIf="isAdmin() || checkPermessoGestioneUtenti()">
      <a class="nav-link" data-toggle="dropdown" style="cursor: pointer;">
        <i class="fas fa-cogs"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
        <span class="dropdown-item dropdown-header">Admin Panel</span>
        <div class="dropdown-divider"></div>
        <a routerLink="/main/adminpanel/utenti" class="dropdown-item" *ngIf="checkPermessoGestioneUtenti()">
          <i class="fas fa-user mr-2"></i> Gestione Utenti
        </a>
        <div class="dropdown-divider"></div>
        <a routerLink="/main/adminpanel/ruoli" class="dropdown-item" *ngIf="isAdmin()">
          <i class="fas fa-users mr-2"></i> Gestione Ruoli
        </a>
      </div>
    </li>
    <li>
      <button style="margin-left:5px" type="button" class="btn btn-danger" (click)="logout()">Esci</button>
    </li>
  </ul> -->
  <ul class="navbar-nav ml-auto" *ngIf="showMenuDettagli">
    <li class="nav-item" *ngIf="mobile && checkPermessoLetturaNotifiche()">
      <a class="nav-link">
        <i style="font-size: 1.5em;" class="far fa-bell"></i>
        <span style="font-size: 0.8em;" *ngIf="countAlerts && countAlerts!=0"
          class="color-nemo badge badge-danger navbar-badge">{{countAlerts}}</span>
      </a>
    </li>
    <li class="nav-item dropdown" *ngIf="paziente && mobile">
      <a class="nav-link" data-toggle="dropdown" style="cursor: pointer;">
        <!-- <div>
          <p-menu #menu appendTo="body" [popup]="true" [model]="items"></p-menu>
          <i class="fas fa-ellipsis-v" style="cursor: pointer;" (click)="menu.toggle($event)"></i>
        </div> -->
        <i class="fas fa-ellipsis-v"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
        <span class="dropdown-item dropdown-header">Dettagli</span>
        <div class="dropdown-divider"></div>
        <a [routerLink]="['/main/pazienti/profilosanitario/' + patientId + '/stiledivita']" class="dropdown-item">
          <i class="color-nemo far fa-id-badge mr-2"></i> Stile di vita
        </a>
        <div class="dropdown-divider"></div>
        <a [routerLink]="['/main/pazienti/profilosanitario/' + patientId + '/terapiefarmacologiche']"
          class="dropdown-item">
          <i class="color-nemo fas fa-pills mr-2"></i> Terapie Farmacologiche
        </a>
        <div class="dropdown-divider"></div>
        <a [routerLink]="['/main/pazienti/profilosanitario/' + patientId + '/devices']" class="dropdown-item">
          <i class="color-nemo fas fa-first-aid mr-2"></i> Devices
        </a>
        <div class="dropdown-divider"></div>
        <a [routerLink]="['/main/pazienti/profilosanitario/' + patientId + '/statodisalute']" class="dropdown-item">
          <i class="color-nemo fas fa-briefcase-medical mr-2"></i> Stato di salute
        </a>
        <div class="dropdown-divider"></div>
        <a [routerLink]="['/main/pazienti/profilosanitario/' + patientId + '/anamnesipatologicaprossima']"
          class="dropdown-item">
          <i class="color-nemo fas fa-file-medical-alt mr-2"></i> Anamnesi Pat. Prossima
        </a>
        <div class="dropdown-divider"></div>
        <a [routerLink]="['/main/pazienti/profilosanitario/' + patientId + '/anamnesipatologicaremota']"
          class="dropdown-item">
          <i class="color-nemo fas fa-file-medical mr-2"></i> Anamnesi Pat. Remota
        </a>
      </div>
    </li>
  </ul>

  <ul class="navbar-nav ml-auto"
    *ngIf="mobile && showMenuVisite && (checkPermessoVisualizzaOsservazioni() || checkPermessoVisualizzaEsameObiettivo() || checkPermessoVisualizzaTrattamentiProcedure())">
    <li class="nav-item" *ngIf="checkPermessoLetturaNotifiche()">
      <a class="nav-link">
        <i style="font-size: 1.5em;" class="far fa-bell"></i>
        <span style="font-size: 0.8em;" *ngIf="countAlerts && countAlerts!=0"
          class="color-nemo badge badge-danger navbar-badge">{{countAlerts}}</span>
      </a>
    </li>
    <li class="nav-item dropdown" *ngIf="visita">
      <a class="nav-link" data-toggle="dropdown" style="cursor: pointer;">
        <i class="color-nemo fas fa-ellipsis-v"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
        <span class="dropdown-item dropdown-header"
          *ngIf="checkPermessoVisualizzaOsservazioni()"><b>Osservazioni</b></span>
        <div class="dropdown-divider" *ngIf="checkPermessoVisualizzaOsservazioni()"></div>
        <a [routerLink]="['/main/pazienti/visite/' + patientId + '/osservazioni/'+ visita.id +'/stiledivita']"
          class="dropdown-item" *ngIf="checkPermessoVisualizzaOsservazioni()">
          <i class="color-nemo far fa-id-badge mr-2"></i> Stile di vita
        </a>
        <div class="dropdown-divider"></div>
        <a [routerLink]="['/main/pazienti/visite/' + patientId + '/osservazioni/'+ visita.id +'/statodisalute']"
          class="dropdown-item" *ngIf="checkPermessoVisualizzaOsservazioni()">
          <i class="color-nemo fas fa-briefcase-medical mr-2"></i> Stato di salute
        </a>
        <div class="dropdown-divider"></div>
        <a [routerLink]="['/main/pazienti/visite/' + patientId + '/osservazioni/'+ visita.id +'/parametrivitali']"
          class="dropdown-item" *ngIf="checkPermessoVisualizzaOsservazioni()">
          <i class="color-nemo fas fa-weight mr-2"></i> Parametri vitali
        </a>
        <div class="dropdown-divider"></div>
        <a [routerLink]="['/main/pazienti/visite/' + patientId + '/osservazioni/'+ visita.id +'/esamilaboratorio']"
          class="dropdown-item" *ngIf="checkPermessoVisualizzaOsservazioni()">
          <i class="color-nemo fas fa-vial mr-2"></i> Esami di laboratorio
        </a>
        <div class="dropdown-divider"></div>
        <a [routerLink]="['/main/pazienti/visite/' + patientId + '/osservazioni/'+ visita.id +'/scaledivalutazione']"
          class="dropdown-item" *ngIf="checkPermessoVisualizzaOsservazioni()">
          <i class="color-nemo fas fa-id-card-alt mr-2"></i> Scale di valutazione
        </a>
        <div class="dropdown-divider"></div>
        <a [routerLink]="['/main/pazienti/visite/' + patientId + '/osservazioni/'+ visita.id +'/devices']"
          class="dropdown-item" *ngIf="checkPermessoVisualizzaOsservazioni()">
          <i class="color-nemo fas fa-first-aid mr-2"></i> Devices
        </a>
        <div class="dropdown-divider"></div>
        <a [routerLink]="['/main/pazienti/visite/' + patientId + '/osservazioni/'+ visita.id +'/monitoring']"
          class="dropdown-item" *ngIf="checkPermessoVisualizzaOsservazioni()">
          <i class="color-nemo fas fa-ruler-combined mr-2"></i> Monitoring
        </a>
        <div class="dropdown-divider" *ngIf="checkPermessoVisualizzaOsservazioni()"></div>
        <span class="dropdown-item dropdown-header" *ngIf="checkPermessoVisualizzaEsameObiettivo()"><b>Esame
            Obiettivo</b></span>
        <div class="dropdown-divider" *ngIf="checkPermessoVisualizzaEsameObiettivo()"></div>
        <a [routerLink]="['/main/pazienti/visite/' + patientId + '/esameobiettivo/'+ visita.id]" class="dropdown-item"
          *ngIf="checkPermessoVisualizzaEsameObiettivo()">
          <i class="color-nemo fas fa-notes-medical mr-2"></i> Esame Obiettivo
        </a>
        <div class="dropdown-divider" *ngIf="checkPermessoVisualizzaEsameObiettivo()"></div>
        <span class="dropdown-item dropdown-header" *ngIf="checkPermessoVisualizzaTrattamentiProcedure()"><b>Trattamenti
            e Procedure</b></span>
        <div class="dropdown-divider" *ngIf="checkPermessoVisualizzaTrattamentiProcedure()"></div>
        <a [routerLink]="['/main/pazienti/visite/' + patientId + '/trattamentiprocedure/'+ visita.id]"
          class="dropdown-item" *ngIf="checkPermessoVisualizzaTrattamentiProcedure()">
          <i class="color-nemo fas fa-user-nurse mr-2"></i> Trattamenti e Procedure
        </a>
      </div>
    </li>
  </ul>



  <ul class="navbar-nav ml-auto" *ngIf="mobile && !showMenuVisite && !showMenuDettagli">
    <li class="nav-item" *ngIf="checkPermessoLetturaNotifiche()">
      <a class="nav-link">
        <i style="font-size: 1.5em;" class="far fa-bell"></i>
        <span style="font-size: 0.8em;" *ngIf="countAlerts && countAlerts!=0"
          class="color-nemo badge badge-danger navbar-badge">{{countAlerts}}</span>
      </a>
    </li>
  </ul>

  <ul class="navbar-nav ml-auto" *ngIf="!mobile">

    <li class="nav-item">
      <div class="row" *ngIf="isSuperAdmin()">
        <div class="col">
          <label>Organization</label>
          <p-dropdown [options]="orgOpt" dropdownIcon="fas fa-chevron-down" optionLabel="label"
            (onChange)="selectOrganization($event)" [(ngModel)]="selectedOrg"></p-dropdown>
        </div>
      </div>
    </li>

    <li class="nav-item dropdown" *ngIf="checkPermessoGestioneUtenti()">
      <a class="nav-link" data-toggle="dropdown" style="cursor: pointer;">
        <i class="color-nemo fas fa-cogs"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
        <span class="dropdown-item dropdown-header">Gestione Profilo</span>
        <div class="dropdown-divider"></div>
        <a routerLink="password-change" class="dropdown-item" *ngIf="checkPermessoGestioneUtenti()">
          <i class="color-nemo fas fa-user mr-2"></i> Cambia Password
        </a>
      </div>
    </li>

    <li class="nav-item" *ngIf="checkPermessoLetturaNotifiche()">
      <a class="nav-link">
        <i style="font-size: 1.5em;" class="color-nemo far fa-bell"></i>
        <span style="font-size: 0.8em;" *ngIf="countAlerts && countAlerts!=0"
          class="color-nemo badge badge-danger navbar-badge">{{countAlerts}}</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" style="cursor: pointer;">
        <i class="color-nemo fas fa-user-md" style="font-size: 1.5em;"></i>
        <label class="color-nemo" style="margin-left:7px">{{username}}</label>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" style="cursor: pointer;" pTooltip="Help" tooltipPosition="left"
        (click)="showPanelHelp($event)">
        <i class="color-nemo far fa-question-circle" style="font-size: 1.5em;"></i>
      </a>
    </li>
    <li class="nav-item">
      <button pButton label="Esci" class="p-button-danger" icon="fas fa-sign-out-alt" iconPos="left" type="button"
        (click)="logout()"></button>
    </li>
  </ul>
</nav>
<!-- /.navbar -->