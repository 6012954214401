import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-dettagli-paziente-modal',
  templateUrl: './dettagli-paziente-modal.component.html',
  styleUrls: ['./dettagli-paziente-modal.component.scss']
})
export class DettagliPazienteModalComponent implements OnInit {

  patientId;

  constructor(private config: DynamicDialogConfig) { }

  static open(dialogService: DialogService, input) {
    return dialogService.open(DettagliPazienteModalComponent, {
      baseZIndex: 1500,
      // showHeader: false,
      header: 'Dettagli paziente',
      height: '90%',
      width: '90%',
      dismissableMask: true,
      data: input,
    });
  }

  ngOnInit() {
    this.patientId = this.config.data.patientId;
  }
}
